import { Children } from "react";
import PropTypes from "prop-types";

import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-container > .MuiPaper-root": {
    backgroundColor: "#fff",
    "& .MuiDialogTitle-root": {
      "& .MuiIconButton-root": {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
      },
    },
    "& .MuiDialogContent-root": {
      "& .MuiCardContent-root": {
        backgroundColor: "#E5E5E5",
      },
      "& .MuiCollapse-root": {
        backgroundColor: "#E5E5E5",
      },
    },
  },
}));

const getChildrenOnDisplayName = (children, displayName) =>
  Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null
  );

const Modal = ({
  fullWidth,
  maxWidth,
  openModal,
  onCloseModal,
  fullScreen,
  dividers,
  ...props
}) => {
  const header = getChildrenOnDisplayName(props.children, "Header");
  const content = getChildrenOnDisplayName(props.children, "Content");
  const footer = getChildrenOnDisplayName(props.children, "Footer");

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openModal}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={props.sx}
    >
      {header}
      {content}
      {footer}
    </Dialog>
  );
};

const Header = ({ closeButton, onCloseModal, children, sx, ...rest }) => {
  const handleClose = () => {
    onCloseModal();
  };

  return (
    <DialogTitle id="dialog-title" sx={sx} {...rest}>
      {closeButton && (
        <IconButton aria-label="close" onClick={handleClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      {children}
    </DialogTitle>
  );
};
Header.displayName = "Header";
Modal.Header = Header;

const Content = ({ dividers, onScrollModalContent, children, sx, ...rest }) => {
  const handleScroll = (e) => {
    if (!onScrollModalContent) return;
    onScrollModalContent(e);
  };

  return (
    <DialogContent
      onScroll={handleScroll}
      id="dialog-description"
      dividers={dividers}
      sx={sx}
      {...rest}
    >
      {children}
    </DialogContent>
  );
};
Content.displayName = "Content";
Modal.Content = Content;

const Footer = ({ children, sx, ...rest }) => {
  return (
    <DialogActions sx={sx} {...rest}>
      {children}
    </DialogActions>
  );
};
Footer.displayName = "Footer";
Modal.Footer = Footer;

Modal.propTypes = {
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs", false]),
  openModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onScrollContent: PropTypes.func,
  fullScreen: PropTypes.bool,
  dividers: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  openModal: false,
  titleWithClose: false,
  fullScreen: false,
  dividers: false,
};

export default Modal;
