import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const DividerFullWidth = styled(Divider, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ sx }) => ({
  position: "absolute",
  left: 0,
  width: "100%",
  ...sx,
}));

export default DividerFullWidth;
