import { api } from "services/api";
import { VEHICLES } from "constants/Vehicles";
import { EXCAVATOR_SERVICE } from "constants/Service";

export const masterApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMasterMachinery: build.query({
      query: () => ({ url: "vehicles/lists", method: "GET" }),
      providesTags: ["MasterMachinery"],
    }),
    getMasterServiceTypes: build.query({
      query: () => ({ url: "services/types" }),
      providesTags: ["MasterShortService"],
    }),
    getMasterServiceName: build.query({
      query: (serviceName) => ({ url: `services/lists/${serviceName}` }),
      providesTags: (_masterService, _err, serviceName) => [
        { type: "MasterService", id: serviceName },
      ],
      transformResponse: (response) => {
        if (response.nameEN === VEHICLES.excavator.nameEN)
          return {
            serviceNameEN: response.nameEN,
            data: {
              ...response,
              mainService: response.mainService.map((service) => {
                const { mainImages, masterDescriptions, formDescriptions } =
                  EXCAVATOR_SERVICE[service.nameEN];
                return {
                  ...service,
                  mainImages,
                  masterDescriptions,
                  formDescriptions,
                };
              }),
            },
          };

        return {
          serviceNameEN: response.nameEN,
          data: response,
        };
      },
    }),
    getMasterField: build.query({
      query: () => ({
        url: "fields/all",
      }),
      providesTags: ["MasterField"],
    }),
    getMasterAddress: build.query({
      query: () => ({
        url: "fields/master",
      }),
      providesTags: ["MasterAddress"],
      transformResponse: (response) => {
        const restructuringNewAddressKey = response?.province.map(
          ({ province, district, ...restProvince }) => ({
            name: province,
            ...restProvince,
            districts: district.map(
              ({ district, subDistrict, ...restDistrict }) => ({
                name: district,
                ...restDistrict,
                subDistricts: subDistrict.map(
                  ({ subDistrict, ...restSubDistrict }) => ({
                    name: subDistrict,
                    ...restSubDistrict,
                  })
                ),
              })
            ),
          })
        );
        return restructuringNewAddressKey;
      },
    }),
  }),
});

export const {
  useGetMasterMachineryQuery,
  useGetMasterServiceTypesQuery,
  useGetMasterServiceNameQuery,
  useGetMasterFieldQuery,
  useGetMasterAddressQuery,
} = masterApi;
