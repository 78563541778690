import { Fragment } from "react";
import { Card } from "base-components";

import { Grid, Stack, Typography, Avatar } from "@mui/material";
import { VEHICLES } from "constants/Vehicles";

const DriverCard = ({
  profileImage,
  prefixname,
  firstname,
  surname,
  displacement,
  address,
  price,
  movingCost,
  plantType,
  size,
  service,
  vinType,
  serviceType,
  workingTimes,
  clickDriverCard,
}) => {
  const onClickCardHandler = () => {
    clickDriverCard();
  };

  return (
    <Card
      elevation={3}
      onClick={onClickCardHandler}
      sx={(theme) => ({
        p: 2,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: (theme) => theme.spacing(1.5),
        mb: 4,
      })}
    >
      <Grid container item xs={3} pr={1}>
        <Avatar
          src={profileImage}
          alt={`${firstname} ${surname}__driver-avatar`}
          sx={(theme) => ({
            mx: "auto",
            width: theme.spacing(8),
            height: theme.spacing(8),
          })}
        />
      </Grid>
      <Grid container item xs={9} pl={1}>
        <Grid container item justifyContent="space-between" xs={12} mb={1}>
          <Typography variant="body1">
            <strong>
              {prefixname}
              {firstname} {surname}
            </strong>
          </Typography>
          <Stack>
            <Typography
              color="tertiary.dark"
              variant="subtitle2"
              sx={{ lineHeight: 1 }}
            >
              <small>ห่างจากพื้นที่ทำงาน</small>
            </Typography>
            <Typography color="tertiary.dark" variant="body1" align="center">
              <strong>
                {displacement < 1 ? "น้อยกว่า 1" : displacement} กม.
              </strong>
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} mb={1}>
          <Typography color="tertiary" variant="subtitle2">
            ที่อยู่ : {address}
          </Typography>
        </Grid>

        {vinType === VEHICLES.excavator.nameEN ? (
          <Fragment>
            <Grid item xs="auto" mb={1}>
              <Typography color="tertiary" variant="subtitle2">
                อัตราค่าจ้างเบื้องต้น
              </Typography>
            </Grid>

            <Grid item xs mb={1}>
              <Typography color="error" variant="subtitle2" align="right">
                {serviceType}
              </Typography>
            </Grid>
          </Fragment>
        ) : (
          ""
        )}

        {typeof price === "object" &&
        price !== null &&
        !Array.isArray(price) ? (
          <Grid
            container
            item
            justifyContent="space-around"
            xs={12}
            spacing={1}
            mb={1}
          >
            <Grid item xs={6}>
              <Card
                elevation={0}
                sx={({ palette }) => ({
                  flexDirection: "column",
                  alignItems: "center",
                  bgcolor: palette.grey[300],
                })}
              >
                <Typography variant="subtitle2" color="tertiary.dark">
                  รายวัน
                </Typography>
                <Typography variant="subtitle2" color="error">
                  {price.perDay.toLocaleString()} บาท/วัน
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                elevation={0}
                sx={({ palette }) => ({
                  flexDirection: "column",
                  alignItems: "center",
                  bgcolor: palette.grey[300],
                })}
              >
                <Typography variant="subtitle2" color="tertiary.dark">
                  รายชั่วโมง
                </Typography>
                <Typography variant="subtitle2" color="error">
                  {price.perHour.toLocaleString()} บาท/ช.ม.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container item justifyContent="space-between" xs={12} mb={1}>
            <Typography color="tertiary" variant="subtitle2">
              ค่าจ้าง : {service}
              {plantType}
            </Typography>
            <Typography color="error.main" variant="subtitle2">
              {price.toLocaleString()} บาท/ไร่
            </Typography>
          </Grid>
        )}

        {vinType === VEHICLES.excavator.nameEN ? (
          <Fragment>
            <Grid item xs="auto" mb={1}>
              <Typography color="tertiary" variant="subtitle2">
                ค่าขนย้ายเครื่องจักรเบื้องต้น
              </Typography>
            </Grid>

            <Grid item xs mb={1}>
              <Typography color="error" variant="subtitle2" align="right">
                {movingCost.toLocaleString()} บาท
              </Typography>
            </Grid>

            <Grid item xs="auto" mb={1}>
              <Typography color="tertiary" variant="subtitle2">
                ขนาดเครื่อง
              </Typography>
            </Grid>

            <Grid item xs mb={1}>
              <Typography color="error" variant="subtitle2" align="right">
                {size.join(", ")}
              </Typography>
            </Grid>

            {/* <Grid */}
          </Fragment>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <Typography align="right" variant="subtitle1" color="tertiary.dark">
            รับงานมาแล้ว {workingTimes || 0} งาน
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DriverCard;
