import { api } from "./api";

import { ROLES } from "constants/Roles";

export const jobApi = api.injectEndpoints({
  endpoints: (build) => ({
    getJobsOfCustomer: build.query({
      query: () => ({ url: "jobs/all" }),
      providesTags: (job) =>
        job
          ? [
              { type: "CustomerJob", id: "LIST" },
              ...job.map(({ id }) => ({ type: "CustomerJob", id })),
            ]
          : [{ type: "CustomerJob", id: "LIST" }],
      transformResponse: (response) => ({
        role: "farmer",
        data: response,
      }),
    }),
    getCustomerJobById: build.query({
      query: (jobId) => ({ url: `jobs/id/${jobId}` }),
      providesTags: (_job, _err, id) => [{ type: "CustomerJob", id }],
      transformResponse: (response, _meta, jobId) => ({
        jobId,
        role: "farmer",
        data: response,
      }),
    }),
    createJob: build.mutation({
      query: (body) => ({
        url: "jobs/create",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "CustomerJob", id: "LIST" }],
    }),
    searchAvailableDrivers: build.query({
      query: (jobId) => ({
        url: `jobs/${jobId}/drivers`,
      }),
      invalidatesTags: [{ type: "AvailableDriver", id: "LIST" }],
      transformResponse: (response, _meta, jobId) => ({
        jobId,
        data: response,
      }),
    }),
    updateJob: build.mutation({
      query: (body) => ({
        url: `jobs/update`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_job, _err, arg) => [
        { type: "CustomerJob", id: arg.id },
      ],
      transformResponse: (response) => ({
        jobId: response.id,
        role: "farmer",
        data: response,
      }),
    }),
    deleteJob: build.mutation({
      query: (jobId) => ({
        url: `jobs/delete/${jobId}`,
        method: "PUT",
      }),
      invalidatesTags: (_job, _err, jobId) => [
        { type: "CustomerJob", id: jobId },
      ],
      transformResponse: (_response, _meta, jobId) => jobId,
    }),
    matchingJob: build.mutation({
      query(data) {
        const { jobId, body } = data;
        return {
          url: `jobs/${jobId}/matching`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (_job, _err, { jobId }) => [
        { type: "CustomerJob", id: jobId },
      ],
      transformResponse: (response) => ({
        jobId: response.id,
        role: "farmer",
        data: response,
      }),
    }),
    getJobsOfDriver: build.query({
      query: () => ({ url: "drivers/jobs" }),
      providesTags: (job) =>
        job
          ? [
              { type: "DriverJob", id: "LIST" },
              ...job.map(({ id }) => ({ type: "DriverJob", id })),
            ]
          : [{ type: "DriverJob", id: "LIST" }],
      transformResponse: (response) => ({
        role: "driver",
        data: response,
      }),
    }),
    getDriverJobById: build.query({
      query: (jobId) => ({ url: `drivers/jobs/${jobId}` }),
      providesTags: (_job, _err, jobId) => [{ type: "DriverJob", id: jobId }],
      transformResponse: (response, _meta, jobId) => ({
        jobId,
        role: "driver",
        data: response,
      }),
    }),
    confirmJobByDriver: build.mutation({
      query(data) {
        const { jobId, body } = data;
        return {
          url: `drivers/jobs/${jobId}/confirm`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (_job, _err, { jobId }) => [
        { type: "DriverJob", id: jobId },
      ],
      transformResponse: (response, _meta, { jobId }) => ({
        jobId,
        role: "driver",
        data: response,
      }),
    }),
    rejectJob: build.mutation({
      query(data) {
        const { jobId, role, body } = data;
        const isFarmer = role === ROLES.FARMER;
        return {
          url: isFarmer
            ? `jobs/${jobId}/cancel`
            : `drivers/jobs/${jobId}/cancel`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (_job, _err, { jobId, role }) => {
        const isFarmer = role === ROLES.FARMER;
        return [{ type: isFarmer ? "CustomerJob" : "DriverJob", id: jobId }];
      },
      transformResponse: (response, _meta, { jobId, role }) => ({
        jobId,
        role,
        data: response,
      }),
    }),
    offerNewPriceByDriver: build.mutation({
      query: (body) => ({ url: `drivers/jobs/edit`, method: "PUT", body }),
      invalidatesTags: (_job, _err, { jobId }) => [
        { type: "DriverJob", id: jobId },
      ],
      transformResponse: (response, _meta, { jobId }) => ({
        jobId,
        role: "driver",
        data: response.data,
      }),
    }),
    acceptOrRejectOfferByCustomer: build.mutation({
      query(data) {
        const { action, jobId } = data;
        // action = confirm/canceledited
        return { url: `jobs/${jobId}/${action}`, method: "PUT" };
      },
      invalidatesTags: (_job, _err, { jobId }) => [
        { type: "CustomerJob", id: jobId },
      ],
      transformResponse: (response, _meta, { jobId }) => ({
        jobId,
        role: "farmer",
        data: response,
      }),
    }),
    startOrEndWorkingByDriver: build.mutation({
      query(data) {
        const { stage, body } = data;
        let defaultStage = "working";
        if (stage === "working") defaultStage = "delivered";
        return { url: `drivers/jobs/${defaultStage}`, method: "PUT", body };
      },
      invalidatesTags: (_job, _err, { jobId }) => [
        { type: "DriverJob", id: jobId },
      ],
      transformResponse: (response, _meta, { jobId }) => ({
        jobId,
        role: "driver",
        data: response,
      }),
    }),
    acceptJobByCustomer: build.mutation({
      query: (jobId) => ({
        url: `/jobs/${jobId}/accept`,
        method: "PUT",
      }),
      invalidatesTags: (_job, _err, jobId) => [
        { type: "CustomerJob", id: jobId },
      ],
      transformResponse: (response, _meta, jobId) => ({
        jobId,
        role: "farmer",
        data: response,
      }),
    }),
    uploadPaySlipByCustomer: build.mutation({
      query(data) {
        const { jobId, body } = data;
        return {
          url: `jobs/${jobId}/slip`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (_job, _err, { jobId }) => [
        {
          type: "CustomerJob",
          id: jobId,
        },
      ],
      transformResponse: (response, _meta, { jobId }) => ({
        jobId,
        role: "farmer",
        data: response,
      }),
    }),
    requestReceiptByCustomer: build.mutation({
      query(data) {
        const { jobId, body } = data;
        return {
          url: `/jobs/${jobId}/receipt`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (_job, _err, { jobId }) => [
        {
          type: "CustomerJob",
          id: jobId,
        },
      ],
      transformResponse: (response, _meta, { jobId }) => ({
        jobId,
        role: "farmer",
        data: response,
      }),
    }),
    receiveCashByDriver: build.mutation({
      query: (jobId) => ({
        url: `/drivers/jobs/${jobId}/cash`,
        method: "PUT",
      }),
      invalidatesTags: (_job, _err, jobId) => [
        { type: "DriverJob", id: jobId },
      ],
      transformResponse: (response, _meta, jobId) => ({
        jobId,
        role: "driver",
        data: response,
      }),
    }),
  }),
});

export const {
  useGetJobsOfCustomerQuery,
  useGetCustomerJobByIdQuery,
  useCreateJobMutation,
  useSearchAvailableDriversQuery,
  useUpdateJobMutation,
  useDeleteJobMutation,
  useMatchingJobMutation,
  useGetJobsOfDriverQuery,
  useGetDriverJobByIdQuery,
  useConfirmJobByDriverMutation,
  useRejectJobMutation,
  useOfferNewPriceByDriverMutation,
  useAcceptOrRejectOfferByCustomerMutation,
  useStartOrEndWorkingByDriverMutation,
  useAcceptJobByCustomerMutation,
  useUploadPaySlipByCustomerMutation,
  useRequestReceiptByCustomerMutation,
  useReceiveCashByDriverMutation,
} = jobApi;
