import { useCallback } from "react";
import { format, isBefore, subDays } from "date-fns";
import { th } from "date-fns/locale";

import { buddhistYear } from "helpers/DateTime";
import { DEFAULT_DATE } from "constants/DateTime";
import { isAfter } from "date-fns/esm";

const useDate = () => {
  const monthDateYearFormat = useCallback(
    (monthDateYearString = DEFAULT_DATE) => {
      const [date, month, year] = monthDateYearString.split("/");
      return `${month}/${date}/${year}`;
    },
    []
  );

  const abbreviatedDateFormat = useCallback(
    (dateString = DEFAULT_DATE) => {
      const newDateFormat = monthDateYearFormat(dateString);
      const dateTH = format(new Date(newDateFormat), "dd");
      const monthTH = format(new Date(newDateFormat), "MMM", { locale: th });
      const yearTH = buddhistYear(newDateFormat).substring(2, 4);
      return `${dateTH} ${monthTH} ${yearTH}`;
    },
    [monthDateYearFormat]
  );

  const disabledPastDate = useCallback((date) => {
    return isBefore(date, subDays(new Date(), 1));
  }, []);

  const disabledFutureDate = useCallback((date) => {
    return isAfter(date, new Date());
  }, []);

  return {
    monthDateYearFormat,
    abbreviatedDateFormat,
    disabledPastDate,
    disabledFutureDate,
  };
};

export default useDate;
