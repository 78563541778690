import { createSlice } from "@reduxjs/toolkit";
import { machineryApi } from "services/machinery";

// const initialState = [];

const slice = createSlice({
  name: "machinery",
  initialState: [],
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        machineryApi.endpoints.getMachineriesOfDriver.matchFulfilled,
        (state, { payload }) => {
          for (const machinery of payload) {
            const isDuplicatedMachine = state.some(
              (machine) => machine.id === machinery.id
            );
            !isDuplicatedMachine && state.push(machinery);
          }
        }
      )
      .addMatcher(
        machineryApi.endpoints.getMachineryById.matchFulfilled,
        (state, { payload: { vinId, data } }) => {
          if (!data) return state;
          const updatedMachineryIndex = state.findIndex(
            (machinery) => machinery?.id === vinId
          );
          if (updatedMachineryIndex !== -1) state[updatedMachineryIndex] = data;
          else state.push(data);
        }
      )
      .addMatcher(
        machineryApi.endpoints.addMachinery.matchFulfilled,
        (state, { payload }) => {
          state.push(payload);
        }
      )
      .addMatcher(
        machineryApi.endpoints.updateMachinery.matchFulfilled,
        (state, { payload: { vinId, data } }) => {
          const updatedMachineryIndex = state.findIndex(
            (machinery) => machinery.id === vinId
          );
          state[updatedMachineryIndex] = data;
        }
      )
      .addMatcher(
        machineryApi.endpoints.deleteMachinery.matchFulfilled,
        (state, { payload: vinId }) => {
          if (!vinId) return state;
          const deletedMachineryIndex = state.findIndex(
            (machinery) => machinery.id === vinId
          );
          state.splice(deletedMachineryIndex, 1);
        }
      );
  },
});

export default slice.reducer;

export const selectMachineries = ({ machinery }) => machinery;
export const selectMachineryById = ({ machinery }, vinId) => {
  if (!vinId || !machinery.length) return {};
  return machinery.find((machine) => machine?.id === vinId);
};
export const selectMachineriesByType = ({ machinery }, vinTypeEN) => {
  if (!vinTypeEN || !machinery.length) return [];
  return machinery.filter((machine) => machine?.vinTypeEN === vinTypeEN);
};
export const selectActivatedMachineriesOfType = ({ machinery }, vinTypeEN) => {
  if (!vinTypeEN || !machinery.length) return [];
  return machinery.filter(
    (machine) => machine?.vinTypeEN === vinTypeEN && machine?.stage === "active"
  );
};
