import { string, array } from "yup";

const Combine = {
  plantType: string().required("กรุณาเลือก ชนิดพืชที่ต้องการเก็บเกี่ยว"),
  fieldName: string().required("กรุณาเลือก แปลง"),
  fieldImages: array()
    .min(1, "กรุณาเลือก รูปถ่าย อย่างน้อย 1 รูป")
    .test({
      name: "fieldImages-not-duplicated",
      test(value, _context) {
        const imagesFilename = value.map((image) => image.filename);
        const duplicatedImages = imagesFilename.filter(
          (filename, idx, arr) => arr.indexOf(filename) !== idx
        );
        if (duplicatedImages.length > 0) {
          const uniqueDuplicatedNames = [...new Set(duplicatedImages)];
          return this.createError({
            message: `เพิ่มรูปชื่อซ้ำกัน กรุณาเปลี่ยนชื่อรูปหรือเลือกรูปใหม่อีกครั้ง
      ชื่อรูปที่ซ้ำ ${uniqueDuplicatedNames.join(", ")}`,
            path: "fieldImages",
          });
        }
        return true;
      },
    }),
};

export default Combine;
