import {
  useState,
  useEffect,
  useCallback,
  useRef,
  Children,
  isValidElement,
  cloneElement,
} from "react";
import PropTypes from "prop-types";

import { Grid, Box, Button, Typography } from "@mui/material";
import {
  GpsFixed as GpsFixedIcon,
  ShareLocation as ShareLocationIcon,
  LocationOn as LocationOnIcon,
} from "@mui/icons-material";

import { Card } from "base-components";
// import AutoComplete from "./AutoComplete";
import SearchBox from "./SearchBox";

const GoogleMap = ({
  center,
  selectedCenter,
  selectedAddress,
  errors,
  zoom,
  mapHeight,
  radius,
  clickMap,
  isSelectCurrent,
  isShowAction,
  clickCurrent,
  clickToggleArea,
  searchOther,
  showArea,
  children,
}) => {
  const [map, setMap] = useState();
  const mapRef = useRef();

  const onClickMapHanlder = useCallback(
    (map) => (e) => {
      clickMap(map, e);
    },
    [clickMap]
  );

  const onClickCurrentHandler = () => {
    clickCurrent(map);
  };

  const onClickToggleAreaHandler = () => {
    clickToggleArea(map);
  };

  useEffect(() => {
    if (mapRef.current && !map)
      setMap(
        new window.google.maps.Map(mapRef.current, {
          center,
          zoom,
          streetViewControl: false,
          gestureHandling: "greedy",
          // mapTypeControl: false,
        })
      );
  }, [mapRef, map, center, zoom]);

  useEffect(() => {
    if (map) {
      window.google.maps.event.clearListeners(map, "click");

      if (clickMap) map.addListener("click", onClickMapHanlder(map));

      if (selectedCenter?.lat && selectedCenter?.lng)
        map.setCenter({ lat: selectedCenter.lat, lng: selectedCenter.lng });
    }
  }, [map, selectedCenter, clickMap, onClickMapHanlder]);

  let locationDetails;
  if (
    !selectedAddress &&
    !errors?.mapProvince &&
    !errors?.mapDistrict &&
    !errors?.mapSubDistrict
  )
    locationDetails = "เลือกตำแหน่งจากแผนที่";
  if (
    !selectedAddress &&
    (errors?.mapProvince || errors?.mapDistrict || errors?.mapSubDistrict)
  )
    locationDetails = errors?.mapProvince?.message;
  if (
    selectedAddress &&
    !errors?.mapProvince &&
    !errors?.mapDistrict &&
    !errors?.mapSubDistrict
  )
    locationDetails = selectedAddress;

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        position: "relative",
        border: ({ palette }) =>
          `2px solid ${
            errors?.mapProvince || errors?.mapDistrict || errors?.mapSubDistrict
              ? palette.error.main
              : palette.grey[300]
          }`,
      }}
    >
      {searchOther.isSearchOther && (
        <Grid container item xs={12}>
          <SearchBox
            map={map}
            changeLocation={searchOther.changeOther}
            searchInput={searchOther.searchInput}
          />
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        mb={1}
        sx={{
          flexGrow: 1,
          height: mapHeight,
          transition: "height .5s ease-out",
        }}
      >
        <Box ref={mapRef} sx={{ flexGrow: 1, height: "100%" }}>
          {Children.map(children, (child) => {
            if (isValidElement(child)) return cloneElement(child, { map });
          })}
        </Box>
      </Grid>

      {isShowAction && (
        <Grid
          container
          item
          justifyContent="space-around"
          xs={12}
          my={2}
          mx={1}
        >
          <Button
            // size="small"
            variant="outlined"
            color={isSelectCurrent ? "primary" : "tertiary"}
            startIcon={
              <GpsFixedIcon color={isSelectCurrent ? "primary" : ""} />
            }
            onClick={onClickCurrentHandler}
            sx={!isSelectCurrent ? { color: "black" } : null}
          >
            เลือกตำแหน่งปัจจุบัน
          </Button>

          {clickToggleArea && radius ? (
            <Button
              // size="small"
              variant="outlined"
              color={showArea ? "tertiary" : "primary"}
              startIcon={
                showArea ? (
                  <LocationOnIcon />
                ) : (
                  <ShareLocationIcon color="primary" />
                )
              }
              onClick={onClickToggleAreaHandler}
              sx={{ px: 3, ...(showArea && { color: "black" }) }}
            >
              {showArea ? "ซ่อน" : "แสดง"}รัศมี
            </Button>
          ) : null}
        </Grid>
      )}
      <Grid item xs={12} mx={2}>
        <Card
          elevation={0}
          sx={({ palette }) => ({
            bgcolor:
              errors?.mapProvince ||
              errors?.mapDistrict ||
              errors?.mapSubDistrict
                ? `${palette.error.light}50`
                : palette.tertiary.light,
            border:
              errors?.mapProvince ||
              errors?.mapDistrict ||
              errors?.mapSubDistrict
                ? `1px solid ${palette.error.main}`
                : "none",
          })}
        >
          <Grid container>
            <Grid item xs="auto" mr={1}>
              <LocationOnIcon color="error" />
            </Grid>
            <Grid container item xs>
              <Grid item xs={12}>
                <strong>ตำแหน่งจุดเริ่มต้น</strong>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  color={
                    errors?.mapProvince ||
                    errors?.mapDistrict ||
                    errors?.mapSubDistrict
                      ? "error"
                      : ""
                  }
                >
                  {locationDetails}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

GoogleMap.propTypes = {
  area: PropTypes.bool,
  showArea: PropTypes.bool,
  toggleAres: PropTypes.func,
  isSelectCurrent: PropTypes.bool,
  isShowAction: PropTypes.bool,
  clickToggleArea: PropTypes.func,
};

GoogleMap.defaultProps = {
  area: false,
  showArea: false,
  showOverlay: false,
  isShowAction: true,
};

export default GoogleMap;
