import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Button, Typography } from "@mui/material";

import { OfferPriceModal, StartOrEndWorkingModal } from "../modals";
import UploadStartOrEndImagesDrawer from "../drawers/UploadStartOrEndImagesDrawer";

import { toggleDrawerPicker, toggleModal } from "store/user-interface/ui-slice";

import { useWindowDimensions } from "hooks";

import { ROLES } from "constants/Roles";
import { VEHICLES } from "constants/Vehicles";
import { JOB_STATUS } from "constants/JobStatus";

const OfferLastPrice = ({
  jobId,
  role,
  vinType,
  stage,
  service,
  serviceRate,
  submitValues,
}) => {
  const [confirmPrice, setConfirmPrice] = useState("");
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.ui);

  const { height: windowHeight } = useWindowDimensions();

  const isDriver = role === ROLES.DRIVER;

  const isWorking = stage === JOB_STATUS[role]?.working?.nameEN;

  let period = "before";
  if (isWorking) period = "after";

  const toggleModalHandler =
    (state, isOpen = undefined) =>
    () => {
      dispatch(toggleModal({ name: state, ...(isOpen && { isOpen }) }));
    };

  const toggleDrawerPickerHandler = (state) => () => {
    dispatch(toggleDrawerPicker(state));
  };

  const handleConfirmingNewPrice = (newPrice) => {
    setConfirmPrice(newPrice);
  };
  if (isDriver && isWorking && vinType === VEHICLES.excavator.nameEN)
    return (
      <Fragment>
        <Grid container item justifyContent="center" xs={12} mb={2}>
          <Button
            onClick={toggleModalHandler("offerLastPrice")}
            variant="contained"
            color="warning"
            sx={{ width: "60%", borderRadius: 8 }}
          >
            <Typography variant="body1">ขอแก้ไขค่าจ้าง</Typography>
          </Button>
        </Grid>

        <OfferPriceModal
          stage={stage}
          id={jobId}
          vinType={vinType}
          period={period}
          service={service}
          serviceRate={serviceRate}
          openModal={modal.offerLastPrice}
          onCloseModal={toggleModalHandler("offerLastPrice")}
          submitValues={submitValues}
          onConfirmingPrice={handleConfirmingNewPrice}
        />

        <StartOrEndWorkingModal
          vinType={vinType}
          confirmPrice={confirmPrice}
          period={period}
          openModal={modal[`offer${period}Work`]}
          onCloseModal={toggleModalHandler(`offer${period}Work`)}
          onOkay={() => {
            toggleModalHandler("offerLastPrice", false)();
            toggleModalHandler(`offer${period}Work`)();
            toggleDrawerPickerHandler(`offer${period}Work`)();
          }}
        />

        <UploadStartOrEndImagesDrawer
          jobId={jobId}
          stage={stage}
          confirmPrice={confirmPrice}
          period={period}
          onSubmittedFulfilled={() => {
            window.scrollTo({
              top: windowHeight,
              behavior: "smooth",
            });
          }}
        />
      </Fragment>
    );

  return null;
};

export default OfferLastPrice;
