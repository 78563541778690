import { Fragment, Children } from "react";
import PropTypes from "prop-types";

import { AppBar, Toolbar, Container, Grid } from "@mui/material";

import { Offset } from "base-components";

const getChildrenOnDisplayName = (children, displayName) =>
  Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null
  );
const Navbar = ({ color, position, children }) => {
  const left = getChildrenOnDisplayName(children, "Left");
  const center = getChildrenOnDisplayName(children, "Center");
  const right = getChildrenOnDisplayName(children, "Right");

  return (
    <Fragment>
      <AppBar
        position={position}
        sx={color ? { bgcolor: (theme) => theme.palette[color].main } : null}
      >
        <Toolbar>
          <Container maxWidth="sm" sx={{ px: 0 }}>
            <Grid container alignItems="center">
              {left}
              {center}
              {right}
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Offset gap={position === "sticky" ? "0" : ""} />
    </Fragment>
  );
};

const Left = ({ children, sx, ...rest }) => {
  return (
    <Grid item sx={sx} {...rest}>
      {children}
    </Grid>
  );
};
Left.displayName = "Left";
Navbar.Left = Left;

const Center = ({ children, sx, ...rest }) => (
  <Grid item sx={sx} {...rest}>
    {children}
  </Grid>
);
Center.displayName = "Center";
Navbar.Center = Center;

const Right = ({ children, sx, ...rest }) => (
  <Grid item sx={sx} {...rest}>
    {children}
  </Grid>
);
Right.displayName = "Right";
Navbar.Right = Right;

Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"]),
  position: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Navbar.defaultProps = {
  color: "primary",
  position: "fixed",
};

export default Navbar;
