import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

const PaymentMethodDetail = ({
  paymentMethod,
  imagesBefore = [],
  imagesAfter = [],
}) => {
  let paymentWording;
  if (paymentMethod === "qr")
    paymentWording = "ผู้จ้างจ่ายเงินโดยการโอนเงินผ่านระบบ";
  if (paymentMethod === "cash") paymentWording = "ผู้จ้างจ่ายเงินด้วยเงินสด";

  return (
    <Fragment>
      <Grid item xs={12} mb={1}>
        <Typography variant="body1" color="secondary">
          วิธีการจ่ายเงิน
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        ml={2}
        mb={imagesBefore?.length || imagesAfter?.length ? 1 : 3}
      >
        <Typography variant="subtitle1" color="error">
          {paymentWording}
        </Typography>
      </Grid>
    </Fragment>
  );
};

export default PaymentMethodDetail;
