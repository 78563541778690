import { parse } from "date-fns";

export const buddhistYear = (dateString) => {
  const date = dateString ? new Date(dateString) : new Date();
  const yearTH = new Intl.DateTimeFormat("th-TH", { year: "numeric" }).format(
    date
  );
  const [, presentYear] = yearTH.split(" ");
  return presentYear;
};

export const yearOptions = (countdownYear) => {
  const presentYear = buddhistYear();
  const totalYears = [...Array(countdownYear + 1).keys()].map(
    (year) => year + (presentYear - countdownYear)
  );
  return totalYears;
};

export const sortingDateTime = (items) => {
  if (!items?.length) return [];
  return items.sort(
    (a, b) =>
      parse(
        `${a.serviceDate} ${a.serviceTime}`,
        "dd/MM/yyyy HH:mm",
        new Date()
      ) -
      parse(`${b.serviceDate} ${b.serviceTime}`, "dd/MM/yyyy HH:mm", new Date())
  );
};
