import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Grid,
  Typography,
  Fab,
  IconButton,
  Drawer as MuiDrawer,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

import { useWindowDimensions, useSwalStyles } from "hooks";
import { toggleModal, toggleDrawerPicker } from "store/user-interface/ui-slice";

import {
  useConfirmJobByDriverMutation,
  useRejectJobMutation,
  useOfferNewPriceByDriverMutation,
  useAcceptOrRejectOfferByCustomerMutation,
  useStartOrEndWorkingByDriverMutation,
  useAcceptJobByCustomerMutation,
  useReceiveCashByDriverMutation,
} from "services/job";

import { ROUTES } from "routes/router";

import PaymentDetailsPage from "pages/payments/PaymentDetailsPage";
import { Navbar, Footer } from "layouts/components";
import { TextInput, ButtonImageInput } from "components/hook-forms";
import { Modal, ActionLoading, Offset } from "base-components";
import { PreviewFieldImage } from "components";

import { ConfirmedPriceModal, OfferPriceModal } from "./modals";
import { VEHICLES } from "constants/Vehicles";
import { capitalize } from "helpers/Utilies";

const { liff } = window;

const rejectJobValidationSchema = yup.object({
  reason: yup.string().required("กรุณาระบุ เหตุผลที่ยกเลิก/ปฏิเสธงาน"),
});
const updatePriceValidationSchema = yup.object({
  newPrice: yup
    .number()
    .positive("ค่าจ้างต้องมากกว่า 0")
    .typeError("กรุณาระบุ อัตราค่าจ้างใหม่"),
  otherPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)),
});
const imagesValidationSchema = yup.object({
  images: yup.array().min(1, "กรุณาเลือก รูปถ่าย อย่างน้อย 1 รูป"),
});

const Drawer = styled(MuiDrawer)({
  position: "relative",
});

const CallToActions = ({ stage, role, information }) => {
  const dispatch = useDispatch();
  const { modal, drawerPicker } = useSelector((state) => state.ui);
  const { bankAccount } = useSelector((state) => state.auth);
  const [pricing, setPricing] = useState({
    calcServicePrice: 0,
    calcTotal: 0,
    calcDiscount: 0,
    calcTotalAmount: 0,
  });
  const [showOtherPrice, setShowOtherPrice] = useState(false);
  const navigate = useNavigate();

  const [confirmJob, { isLoading: isConfirming }] =
    useConfirmJobByDriverMutation();
  const [rejectJobWithReason, { isLoading: isRejecting }] =
    useRejectJobMutation();
  const [offerNewPrice, { isLoading: isOffering }] =
    useOfferNewPriceByDriverMutation();

  const [acceptOrRejectOffer, { isLoading: isActingToOffer }] =
    useAcceptOrRejectOfferByCustomerMutation();
  const [startOrEndWorking, { isLoading: isWorking }] =
    useStartOrEndWorkingByDriverMutation();
  const [acceptingJob, { isLoading: isJobAccepting }] =
    useAcceptJobByCustomerMutation();
  const [recieveCash, { isLoading: isRecieving }] =
    useReceiveCashByDriverMutation();

  const { height: windowHeight } = useWindowDimensions();
  const classes = useSwalStyles();

  /* [Farmer || Driver] reject form */
  const {
    control: controlFormReject,
    setValue: setRejectReason,
    handleSubmit: handleSubmitReject,
  } = useForm({
    resolver: yupResolver(rejectJobValidationSchema),
  });
  const submitRejectReason = async (form) => {
    await rejectJobWithReason({
      jobId: information.id,
      role,
      body: form,
    }).unwrap();
    toggleModalHandler("rejectJob")();
    navigate(role === "farmer" ? ROUTES.MENU_FARMER : ROUTES.MENU_DRIVER, {
      replace: true,
    });
  };

  const checkBeforeJobAccepting =
    (callbackFn = () => null) =>
    async () => {
      if (!bankAccount && information.paymentMethod === "qr") {
        const respDontHasBankAccount = await new Swal({
          titleText: "กรุณาลงทะเบียนข้อมูลบัญชีธนาคารก่อน",
          text: "สำหรับรับงานด้วยการโอนเงินผ่านระบบ กรุณากรอกข้อมูลบัญชีธนาคาร โดยกดปุ่ม แก้ไขบัญชีธนาคาร",
          icon: "warning",
          confirmButtonText: "แก้ไขบัญชีธนาคาร",
          cancelButtonText: "กลับหน้าเดิม",
          width: "80%",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          reverseButtons: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2WarningButton,
            cancelButton: classes.swal2CancelButton,
          },
        });
        if (respDontHasBankAccount.isConfirmed)
          return navigate(ROUTES.BANK_ACCOUNT_FORM, {
            state: {
              action: "update",
              source: "update",
            },
          });
        return;
      }
      if (information.isPassing) {
        const respPassingJobs = await new Swal({
          titleText: "วันหรือเวลารับงานเกินจากที่ผู้จ้างกำหนด",
          text: "ไม่สามารถรับงานนี้ได้ กรุณาติดต่อผู้จ้างเพื่อขอแก้ไขวันหรือเวลาการทำงานให้อยู่ ณ ปัจจุบัน",
          icon: "warning",
          cancelButtonText: "กลับหน้าเดิม",
          confirmButtonText: "ติดต่อผู้จ้าง",
          width: "80%",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          reverseButtons: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2WarningButton,
            cancelButton: classes.swal2CancelButton,
          },
        });
        if (respPassingJobs.isConfirmed) return contactUser();
        return;
      }

      if (information.vinType === VEHICLES.excavator.nameEN)
        return toggleModalHandler("offerPrice")();
      callbackFn();
    };

  const checkBeforeSearchDriver = (callbackFn) => async () => {
    if (information.isPassing) {
      const respPassingJobs = await new Swal({
        titleText: "วันหรือเวลาจ้างงานเป็นเวลาในอดีต",
        text: "ไม่สามารถค้นหาคนขับได้ กรุณาแก้ไขรายละเอียดวันหรือเวลาการทำงานให้เป็นวันหรือเวลาอนาคต",
        icon: "warning",
        cancelButtonText: "กลับหน้าเดิม",
        confirmButtonText: "แก้ไขรายละเอียด",
        width: "80%",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        reverseButtons: true,
        customClass: {
          container: classes.swal2Container,
          confirmButton: classes.swal2WarningButton,
          cancelButton: classes.swal2CancelButton,
        },
      });
      if (respPassingJobs.isConfirmed) return goEditJobPage();
      return;
    }

    callbackFn();
  };

  const existPriceNumber = parseFloat(
    information?.confirmPrice?.replace(/,/g, "")
  );

  /* [Driver] update new price form */
  const {
    control: controlFormUpdatePrice,
    setValue: setUpdatePrice,
    watch: watchNewPrice,
    setError: setNewPriceError,
    clearErrors: clearNewPriceErrors,
    handleSubmit: handleSubmitUpdate,
  } = useForm({
    resolver: yupResolver(updatePriceValidationSchema),
  });
  const submitUpdatedPrice = async (form) => {
    if (showOtherPrice && isNaN(form.otherPrice)) {
      setNewPriceError("otherPrice", {
        type: "otherPrice must be number",
        message: "กรุณาระบุค่าอื่นๆเป็น ตัวเลข เท่านั้น",
      });
      return;
    }

    const { driver, farmer } = information;
    const body = {
      jobId: information.id,
      driver: {
        driverId: driver.driverId,
        prefixname: driver.prefixname,
        firstname: driver.firstname,
        surname: driver.surname,
        tel: driver.tel,
        address: driver.address,
        confirmPrice: form.newPrice,
      },
      fieldSize: information.fieldSize,
      fieldLat: information.fieldLat,
      fieldLon: information.fieldLon,
      fieldAddress: information.fieldAddress,
      prefixname: farmer.prefixname,
      firstname: farmer.firstname,
      surname: farmer.surname,
      tel: farmer.tel,
      ...(form.otherPrice && {
        additionalItem: [{ nameTH: "อื่นๆ", price: form.otherPrice }],
      }),
    };

    await offerNewPrice(body).unwrap();
    toggleModalHandler("updateServicePrice")();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (information.flagEditedPrice && information.additionalItem?.length) {
      setShowOtherPrice(true);
      setUpdatePrice("otherPrice", information.additionalItem[0].price);
    }
    const subscription = watchNewPrice((value, { type }) => {
      if ((value.newPrice && type === "change") || value.otherPrice) {
        const serviceRateDecimal = information.serviceRate / 100;

        setPricing({
          calcTotal: (value.newPrice * information.fieldSize).toLocaleString(),
          calcServicePrice: (
            value.newPrice *
            information.fieldSize *
            serviceRateDecimal
          ).toLocaleString(),
          calcDiscount: (
            value.newPrice *
            information.fieldSize *
            serviceRateDecimal
          ).toLocaleString(),
          calcTotalAmount: (
            +(value.newPrice * information.fieldSize) +
            (+value?.otherPrice || 0)
          ).toLocaleString(),
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [
    existPriceNumber,
    information.fieldSize,
    information.serviceRate,
    information.flagEditedPrice,
    information.additionalItem,
    setUpdatePrice,
    watchNewPrice,
  ]);

  /**
   * [Farmer] accept/reject driver offer
   * @param {String} action confirm || canceledited
   */
  const actionToOfferHandler = (action) => async () => {
    await acceptOrRejectOffer({ action, jobId: information.id }).unwrap();
    toggleModalHandler(
      action === "confirm" ? "acceptDriverOffer" : "rejectDriverOffer"
    )();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /* [Driver] create field images before start working */
  const {
    control: controlImages,
    setValue: setImagesValue,
    watch: watchImages,
    setError: setErrorImages,
    handleSubmit: handleSubmitImages,
  } = useForm({
    resolver: yupResolver(imagesValidationSchema),
  });
  const images = watchImages("images", []);

  let showingRole;
  if (role === "farmer") showingRole = "คนขับ";
  if (role === "driver") showingRole = "ผู้จ้าง";

  function toggleModalHandler(
    state,
    { fieldName, data, cbFunc } = {
      fieldName: null,
      data: null,
      cbFunc: null,
    }
  ) {
    return function () {
      dispatch(toggleModal({ name: state }));
      if (fieldName && cbFunc) cbFunc(fieldName, data);
      if (fieldName === "newPrice") {
        setShowOtherPrice(() => {
          if (
            information.flagEditedPrice &&
            information.additionalItem?.length
          ) {
            setUpdatePrice("otherPrice", information.additionalItem[0].price);
            return true;
          }
          setUpdatePrice("otherPrice", undefined);
          return false;
        });
        clearNewPriceErrors();
        setPricing({
          calcServicePrice: information.servicePrice,
          calcTotal: information.total,
          calcDiscount: information.discountDetail[0].price,
          calcTotalAmount: information.totalAmount,
        });
      }
    };
  }

  const toggleShowDrawerPicker = (state) => () => {
    dispatch(toggleDrawerPicker(state));
    dispatch(toggleModal({ name: state, isOpen: false }));
  };

  const toggleShowOtherPrice = () => {
    setShowOtherPrice((prevOtherPrice) => !prevOtherPrice);
  };

  const goEditJobPage = () => {
    navigate(ROUTES.JOB_FORM(information.vinType, "edit", information.id));
  };

  const goSearchingDriversPage = () => {
    navigate(ROUTES.JOB_SEARCHING(information.id));
  };

  const contactUser = () => {
    window.location.assign(
      `tel:+66${information.telContact
        .substring(1, information.telContact.length)
        .replace(/\s/g, "")}`
    );
  };

  /* [DRIVER] accept job excluding EXCAVATOR */
  const submitConfirmJob = async () => {
    const { driver, farmer } = information;
    const body = {
      driver: {
        driverId: driver.driverId,
        prefixname: driver.prefixname,
        firstname: driver.firstname,
        surname: driver.surname,
        tel: driver.tel,
        address: driver.address,
        confirmPrice: existPriceNumber,
      },
      fieldSize: information.fieldSize,
      fieldLat: information.fieldLat,
      fieldLon: information.fieldLon,
      fieldAddress: information.fieldAddress,
      prefixname: farmer.prefixname,
      firstname: farmer.firstname,
      surname: farmer.surname,
      tel: farmer.tel,
    };

    await confirmJob({ jobId: information.id, body }).unwrap();
    toggleModalHandler("confirmJob")();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onAddImage = (imagesAdding = [], callback) => {
    if (!imagesAdding.length) return;
    if (!images?.length) callback([...imagesAdding]);
    else callback([...images, ...imagesAdding]);
  };

  const onRemoveImage = (state) => (imageIdx, data) => {
    if (!data) return;
    const stateName = { images };
    const remainedImages = stateName[state].filter(
      (_, idx) => idx !== imageIdx
    );
    setImagesValue(state, remainedImages);
  };

  let period = "before";
  if (stage === "working") period = "after";

  const submitWorkingField = async (form) => {
    /* Validate all images filename must not duplicated each other */
    const imagesFilename = form.images.map((image) => image.filename);
    const duplicatedImages = imagesFilename.filter(
      (filename, idx, arr) => arr.indexOf(filename) !== idx
    );
    if (duplicatedImages.length > 0) {
      const uniqueDuplicatedNames = [...new Set(duplicatedImages)];
      setErrorImages("images", {
        type: "duplicatedFilename",
        message: `เพิ่มรูปชื่อซ้ำกัน กรุณาเปลี่ยนชื่อรูปหรือเลือกรูปใหมม่อีกครั้ง
        ชื่อรูปที่ซ้ำ ${uniqueDuplicatedNames.join(", ")}`,
      });
      return;
    }

    const body = {
      jobId: information.id,
      [`images${capitalize(period)}`]: form.images.map((image) => ({
        filename: image.filename,
        data: image.data,
      })),
    };
    await startOrEndWorking({
      jobId: information.id,
      stage,
      body,
    }).unwrap();
    dispatch(toggleDrawerPicker(`${period}Work`));
    setImagesValue("images", []);
    window.scrollTo({
      top: windowHeight,
      behavior: "smooth",
    });
  };

  const submitAcceptJob = async () => {
    await acceptingJob(information.id).unwrap();
    toggleModalHandler("acceptWork")();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const clickDownloadQR = () => {
    liff.openWindow({
      url: information.qrCodeUrl,
      external: true,
    });
  };

  const submitRecievedCash = async () => {
    await recieveCash(information.id).unwrap();
    toggleModalHandler("recievedCash")();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const actions = {
    farmer: {
      created: (
        <Fragment>
          <Grid item xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={goEditJobPage}
              sx={{
                width: "100%",
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              แก้ไขรายละเอียด
            </Fab>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="secondary"
              onClick={checkBeforeSearchDriver(goSearchingDriversPage)}
              sx={{ width: "100%" }}
            >
              เลือก{showingRole}
            </Fab>
          </Grid>
        </Fragment>
      ),
      waiting: (
        <Fragment>
          <Grid container item xs={12} mb={1}>
            <Grid item xs={6} pr={1}>
              <Fab
                size="medium"
                variant="extended"
                onClick={toggleModalHandler("rejectJob", {
                  fieldName: "reason",
                  data: "",
                  cbFunc: setRejectReason,
                })}
                sx={(theme) => ({
                  width: "100%",
                  bgcolor: "transparent",
                  border: `1px solid ${theme.palette.error.main}`,
                  color: theme.palette.error.main,
                })}
              >
                ยกเลิกงานนี้
              </Fab>
            </Grid>
            <Grid item xs={6} pl={1}>
              <Fab
                size="medium"
                variant="extended"
                color="primary"
                onClick={contactUser}
                sx={{ width: "100%" }}
              >
                <LocalPhoneIcon sx={{ mr: 1 }} />
                ติดต่อ{showingRole}
              </Fab>
            </Grid>
          </Grid>
          <Grid item xs={12} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={goEditJobPage}
              sx={{
                width: "100%",
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              แก้ไขรายละเอียด
            </Fab>
          </Grid>
        </Fragment>
      ),
      cancelled: null,
      confirmed: (
        <Fragment>
          <Grid item xs={12} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={contactUser}
              sx={{ width: "100%" }}
            >
              <LocalPhoneIcon sx={{ mr: 1 }} />
              ติดต่อ{showingRole}
            </Fab>
          </Grid>
        </Fragment>
      ),
      edited: (
        <Fragment>
          <Grid item xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={toggleModalHandler("rejectDriverOffer")}
              sx={{
                width: "100%",
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              ปฏิเสธข้อเสนอ
            </Fab>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={toggleModalHandler("acceptDriverOffer")}
              sx={{ width: "100%" }}
            >
              ยืนยันข้อเสนอ
            </Fab>
          </Grid>
        </Fragment>
      ),
      sooning: (
        <Fragment>
          <Grid item xs={12} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={contactUser}
              sx={{ width: "100%" }}
            >
              <LocalPhoneIcon sx={{ mr: 1 }} />
              ติดต่อ{showingRole}
            </Fab>
          </Grid>
        </Fragment>
      ),
      inDay: (
        <Fragment>
          <Grid item xs={12} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={contactUser}
              sx={{ width: "100%" }}
            >
              <LocalPhoneIcon sx={{ mr: 1 }} />
              ติดต่อ{showingRole}
            </Fab>
          </Grid>
        </Fragment>
      ),
      working: null,
      delivered: (
        <Fragment>
          <Grid item xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={contactUser}
              sx={{
                width: "100%",
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              <LocalPhoneIcon sx={{ mr: 1 }} />
              ติดต่อ{showingRole}
            </Fab>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={toggleModalHandler("acceptWork")}
              sx={{ width: "100%" }}
            >
              รับงาน
            </Fab>
          </Grid>
        </Fragment>
      ),
      accepted:
        information.paymentMethod === "qr" ? (
          <Fragment>
            <Grid item xs={12} mb={1}>
              <Typography color="error" align="center">
                จำนวนเงินรวมทั้งหมด {information.totalAmount} บาท
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Fab
                size="medium"
                variant="extended"
                color="primary"
                onClick={toggleShowDrawerPicker("paymentDetail")}
                sx={{ width: "100%" }}
              >
                จ่ายเงิน
              </Fab>
            </Grid>
          </Fragment>
        ) : null,
      paid: null,
      completed: null,
    },

    /* DRIVER actions */
    driver: {
      waiting: (
        <Fragment>
          <Grid container item xs={12} mb={1}>
            <Grid item xs={6} pr={1}>
              <Fab
                size="medium"
                variant="extended"
                onClick={toggleModalHandler("rejectJob", {
                  fieldName: "reason",
                  data: "",
                  cbFunc: setRejectReason,
                })}
                sx={(theme) => ({
                  width: "100%",
                  bgcolor: "transparent",
                  border: `1px solid ${theme.palette.error.main}`,
                  color: theme.palette.error.main,
                })}
              >
                ไม่รับงานนี้
              </Fab>
            </Grid>
            <Grid item xs={6} pl={1}>
              <Fab
                size="medium"
                variant="extended"
                color="primary"
                disabled={information.flagEditedPrice}
                onClick={checkBeforeJobAccepting(
                  toggleModalHandler("confirmJob")
                )}
                sx={{ width: "100%" }}
              >
                {information.vinType !== VEHICLES.excavator.nameEN
                  ? "ยืนยันรับงาน"
                  : `เสนอราคาให้${showingRole}`}
              </Fab>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {information.vinType !== VEHICLES.excavator.nameEN ? (
              <Fab
                size="medium"
                variant="extended"
                onClick={checkBeforeJobAccepting(
                  toggleModalHandler("updateServicePrice", {
                    fieldName: "newPrice",
                    data: existPriceNumber,
                    cbFunc: setUpdatePrice,
                  })
                )}
                sx={{
                  width: "100%",
                  bgcolor: "transparent",
                  border: (theme) => `1px solid ${theme.palette.common.black}`,
                }}
              >
                ขอแก้ไขค่าจ้าง
              </Fab>
            ) : (
              <Fab
                size="medium"
                variant="extended"
                onClick={contactUser}
                sx={(theme) => ({
                  width: "100%",
                  color: theme.palette.primary.main,
                  bgcolor: "transparent",
                  border: `1px solid ${theme.palette.primary.main}`,
                })}
              >
                <LocalPhoneIcon sx={{ mr: 1 }} />
                ติดต่อ{showingRole}
              </Fab>
            )}
          </Grid>
        </Fragment>
      ),
      cancelled: null,
      confirmed: (
        <Grid item xs={12} mb={1}>
          <Fab
            size="medium"
            variant="extended"
            color="primary"
            onClick={contactUser}
            sx={{ width: "100%" }}
          >
            <LocalPhoneIcon sx={{ mr: 1 }} />
            ติดต่อ{showingRole}
          </Fab>
        </Grid>
      ),
      edited: null,
      sooning: (
        <Grid item xs={12} mb={1}>
          <Fab
            size="medium"
            variant="extended"
            color="primary"
            onClick={contactUser}
            sx={{ width: "100%" }}
          >
            <LocalPhoneIcon sx={{ mr: 1 }} />
            ติดต่อ{showingRole}
          </Fab>
        </Grid>
      ),
      inDay: (
        <Fragment>
          <Grid item xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={contactUser}
              sx={{
                width: "100%",
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              <LocalPhoneIcon sx={{ mr: 1 }} />
              ติดต่อ{showingRole}
            </Fab>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={toggleModalHandler("beforeWork")}
              sx={{ width: "100%" }}
            >
              เริ่มงาน
            </Fab>
          </Grid>
        </Fragment>
      ),
      working: (
        <Fragment>
          <Grid item xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={contactUser}
              sx={{
                width: "100%",
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              ติดต่อ{showingRole}
            </Fab>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={toggleModalHandler("afterWork")}
              sx={{ width: "100%" }}
            >
              ส่งงาน
            </Fab>
          </Grid>
        </Fragment>
      ),
      delivered: null,
      accepted:
        information.paymentMethod === "cash" ? (
          <Fragment>
            <Grid item xs={12} mb={1}>
              <Typography color="error" align="center">
                จำนวนเงินรวมทั้งหมด {information.totalAmount} บาท
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Fab
                size="medium"
                variant="extended"
                color="primary"
                onClick={toggleModalHandler("recievedCash")}
                sx={{ width: "100%" }}
              >
                ได้รับเงินแล้ว
              </Fab>
            </Grid>
          </Fragment>
        ) : null,
      paid: null,
      completed: null,
    },
  };

  let footerGap = 12;
  if (
    role === "farmer" &&
    stage === "accepted" &&
    information.paymentMethod === "qr"
  )
    footerGap = 14;
  if (
    role === "driver" &&
    stage === "accepted" &&
    information.paymentMethod === "cash"
  )
    footerGap = 16;
  if (stage === "waiting") footerGap = 18;

  let description = information.statuses[stage]?.description;
  if (stage === "paid" && role === "driver")
    description = information.statuses[stage]?.description(
      information.paymentMethod
    );
  if (stage === "edited" && information?.vinType === VEHICLES.excavator.nameEN)
    description = information.statuses[stage]?.excavator.description;

  return (
    <Fragment>
      <Footer>
        <Grid container mt={2} mb={3}>
          <Grid item xs={12} mx="auto" mb={1}>
            <Typography
              align="center"
              variant="subtitle2"
              color={stage === "created" ? "error" : "primary"}
            >
              ({description})
            </Typography>
          </Grid>
          {actions[role][stage]}
        </Grid>
      </Footer>
      <Grid item xs={12}>
        <Offset gap={footerGap} />
      </Grid>

      {/* [DRIVER] accept job excluding EXCAVATOR */}
      <ConfirmedPriceModal
        openModalConfirm={modal.confirmJob}
        onCloseModalConfirm={toggleModalHandler("confirmJob")}
        onOkay={submitConfirmJob}
        onCancel={toggleModalHandler("confirmJob")}
      />

      {/* [FARMER || DRIVER] reject job modal */}
      <Modal
        openModal={modal.rejectJob}
        onCloseModal={toggleModalHandler("rejectJob", {
          fieldName: "reason",
          data: "",
          cbFunc: setRejectReason,
        })}
        maxWidth="sm"
      >
        <Modal.Header sx={{ mt: 2, mb: 1 }}>
          <Typography align="center" variant="body1" color="secondary">
            คุณขอ{role === "farmer" ? "ยกเลิก" : "ปฏิเสธ"}งานนี้ กรุณาระบุเหตุผล
          </Typography>
        </Modal.Header>
        <Modal.Content sx={{ mb: 2 }}>
          <form onSubmit={handleSubmitReject(submitRejectReason)}>
            <Grid container>
              <Grid item xs={12} mb={3}>
                <TextInput
                  control={controlFormReject}
                  defaultValue=""
                  placeholder="คำอธิบาย"
                  name="reason"
                  width={{ input: "100%" }}
                  type="textarea"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid container item justifyContent="center" xs={6} pr={1}>
                <Fab
                  size="medium"
                  variant="extended"
                  onClick={toggleModalHandler("rejectJob", {
                    fieldName: "reason",
                    data: "",
                    cbFunc: setRejectReason,
                  })}
                  sx={{
                    px: 4,
                    bgcolor: "transparent",
                    border: (theme) =>
                      `1px solid ${theme.palette.common.black}`,
                  }}
                >
                  ยกเลิก
                </Fab>
              </Grid>
              <Grid container item justifyContent="center" xs={6} pl={1}>
                <Fab
                  size="medium"
                  variant="extended"
                  color="primary"
                  type="submit"
                  sx={{ px: 4 }}
                >
                  ตกลง
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Modal.Content>
      </Modal>

      {/* [Driver] update new price modal */}
      <Modal
        openModal={modal.updateServicePrice}
        onCloseModal={toggleModalHandler("updateServicePrice", {
          fieldName: "newPrice",
          data: existPriceNumber,
          cbFunc: setUpdatePrice,
        })}
        maxWidth="sm"
      >
        <Modal.Content>
          <Grid container>
            <Grid item xs={12} mb={0.5}>
              <Typography component="p" variant="h6" color="secondary">
                <strong>ขอแก้ไขค่าจ้าง</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant="body1" color="secondary">
                {information.service} ({information.id})
              </Typography>
            </Grid>
            <Grid container item xs={12} mb={1}>
              <Grid item xs={7} sx={{ flexGrow: 1 }}>
                <Typography variant="body1">พื้นที่จ้างงาน</Typography>
              </Grid>

              <Grid item xs my="auto" ml="auto" mr={1}>
                <Typography variant="body1" align="right">
                  {information.fieldSize}
                </Typography>
              </Grid>

              <Grid item xs my="auto">
                <Typography variant="body1">ไร่</Typography>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmitUpdate(submitUpdatedPrice)}>
              <Grid container>
                <Grid item xs={12} mb={2}>
                  <TextInput
                    control={controlFormUpdatePrice}
                    defaultValue=""
                    name="newPrice"
                    placeholder="ค่าจ้างใหม่"
                    label="อัตราค่าจ้าง"
                    labelTail="บาท/ไร่"
                    type="number"
                    alignValue="center"
                    width={{ label: "40%", input: "35%", labelTail: "25%" }}
                  />
                </Grid>

                <Grid container item xs={12} mb={2}>
                  {showOtherPrice ? (
                    <Fragment>
                      <TextInput
                        control={controlFormUpdatePrice}
                        defaultValue=""
                        name="otherPrice"
                        placeholder="อื่นๆ"
                        label="ค่าอื่นๆ"
                        labelTail="บาท"
                        alignValue="center"
                        width={{ label: "40%", input: "35%", labelTail: "25%" }}
                        sx={{ mb: 0.5 }}
                      />
                      <Typography color="tertiary" sx={{ fontSize: "0.75rem" }}>
                        ค่าจ้างเพิ่มเติม, ส่วนลด, หรืออื่นๆ (ถ้ามี)
                      </Typography>
                    </Fragment>
                  ) : (
                    <Fab
                      size="medium"
                      variant="extended"
                      onClick={toggleShowOtherPrice}
                      sx={{
                        px: 2,
                        mx: "auto",
                        bgcolor: "transparent",
                        border: (theme) =>
                          `1px solid ${theme.palette.common.black}`,
                      }}
                    >
                      เพิ่มรายการ
                    </Fab>
                  )}
                </Grid>

                <Grid container item xs={12} mb={1}>
                  <Grid item xs={7} sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">รายได้จากการรับจ้าง</Typography>
                  </Grid>
                  <Grid item xs my="auto" ml="auto" mr={1}>
                    <Typography variant="body1" align="right">
                      {pricing.calcTotal}
                    </Typography>
                  </Grid>

                  <Grid item xs my="auto">
                    <Typography variant="body1">บาท</Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} mb={1}>
                  <Grid item xs={7} sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">
                      หักค่าบริการ {information.serviceRate}%
                    </Typography>
                  </Grid>

                  <Grid item xs my="auto" ml="auto" mr={1}>
                    <Typography variant="body1" align="right">
                      -{pricing.calcServicePrice}
                    </Typography>
                  </Grid>

                  <Grid item xs my="auto">
                    <Typography variant="body1">บาท</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} mb={1}>
                  <Typography variant="subtitle2" color="tertiary">
                    โปรโมชั่น / ส่วนลด
                  </Typography>
                </Grid>

                <Grid container item xs={12} mb={1}>
                  <Grid item xs={7} sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" color="error">
                      ยกเว้นค่าบริการ
                    </Typography>
                  </Grid>
                  <Grid item xs my="auto" ml="auto" mr={1}>
                    <Typography variant="body1" color="error" align="right">
                      {pricing.calcDiscount}
                    </Typography>
                  </Grid>
                  <Grid item xs my="auto">
                    <Typography variant="body1" color="error">
                      บาท
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} mb={2}>
                  <Grid item xs={7} sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">
                      <strong>รวมรายได้ทั้งหมด</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs my="auto" ml="auto" mr={1}>
                    <Typography variant="body1" align="right">
                      <strong>{pricing.calcTotalAmount}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs my="auto">
                    <Typography variant="body1">
                      <strong>บาท</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={6} pr={1}>
                  <Fab
                    size="medium"
                    variant="extended"
                    onClick={toggleModalHandler("updateServicePrice", {
                      fieldName: "newPrice",
                      data: existPriceNumber,
                      cbFunc: setUpdatePrice,
                    })}
                    sx={{
                      px: 4,
                      bgcolor: "transparent",
                      border: (theme) =>
                        `1px solid ${theme.palette.common.black}`,
                    }}
                  >
                    ยกเลิก
                  </Fab>
                </Grid>
                <Grid container item justifyContent="center" xs={6} pl={1}>
                  <Fab
                    size="medium"
                    variant="extended"
                    color="primary"
                    type="submit"
                    sx={{ px: 4 }}
                  >
                    ตกลง
                  </Fab>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Modal.Content>
      </Modal>

      <OfferPriceModal
        stage={stage}
        id={information.id}
        vinType={information.vinType}
        service={information.service}
        serviceRate={information.serviceRate}
        openModal={modal.offerPrice}
        onCloseModal={toggleModalHandler("offerPrice")}
        onSubmittedFulfilled={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        submitValues={{
          jobId: information.id,
          fieldAddress: information.fieldAddress,
          prefixname: information.farmer?.prefixname,
          firstname: information.farmer?.firstname,
          surname: information.farmer?.surname,
          tel: information.farmer?.tel,
          driver: {
            driverId: information.driver?.driverId,
            prefixname: information.driver?.prefixname,
            firstname: information.driver?.firstname,
            surname: information.driver?.surname,
            tel: information.driver?.tel,
            address: information.driver?.address,
          },
        }}
      />

      {/* [Farmer] reject offer price from driver modal */}
      <Modal
        openModal={modal.rejectDriverOffer}
        onCloseModal={toggleModalHandler("rejectDriverOffer")}
        maxWidth="sm"
      >
        <Modal.Content>
          <Typography
            align="center"
            variant="body1"
            color="secondary"
            sx={{ mb: 1 }}
          >
            คุณต้องการปฏิเสธราคาใหม่ตามที่คนขับได้เสนอมา ใช่หรือไม่
          </Typography>
          <Typography variant="body1" color="secondary">
            เมื่อกด ตกลง แล้ว
            คนขับจะต้องแก้ไขค่าจ้างและเสนอราคาใหม่ให้คุณพิจารณายืนยันข้อเสนอใหม่อีกครั้ง
            ก่อนจะสามารถเริ่มงานได้
          </Typography>
        </Modal.Content>
        <Modal.Footer sx={{ mb: 2 }}>
          <Grid container item justifyContent="center" xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={toggleModalHandler("rejectDriverOffer")}
              sx={{
                px: 4,
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              ยกเลิก
            </Fab>
          </Grid>
          <Grid container item justifyContent="center" xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={actionToOfferHandler("canceledited")}
              sx={{ px: 4 }}
            >
              ตกลง
            </Fab>
          </Grid>
        </Modal.Footer>
      </Modal>

      {/* [Farmer] accept offer price from driver modal */}
      <Modal
        openModal={modal.acceptDriverOffer}
        onCloseModal={toggleModalHandler("acceptDriverOffer")}
        maxWidth="sm"
      >
        <Modal.Content>
          <Typography
            align="center"
            variant="body1"
            color="secondary"
            sx={{ mb: 1 }}
          >
            คุณตกลงยอมรับราคาใหม่ตามที่คนขับได้เสนอมา ใช่หรือไม่
          </Typography>
          <Typography variant="body1" color="secondary">
            เมื่อกด ตกลง แล้ว จะไม่สามารถแก้ไขรายละเอียดงาน, ค่าจ้างได้อีก
            และเมื่อถึงวันทำงานคนขับจะสามารถเริ่มงานได้ทันที
          </Typography>
        </Modal.Content>
        <Modal.Footer sx={{ mb: 2 }}>
          <Grid container item justifyContent="center" xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={toggleModalHandler("acceptDriverOffer")}
              sx={{
                px: 4,
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              ยกเลิก
            </Fab>
          </Grid>
          <Grid container item justifyContent="center" xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={actionToOfferHandler("confirm")}
              sx={{ px: 4 }}
            >
              ตกลง
            </Fab>
          </Grid>
        </Modal.Footer>
      </Modal>

      {/* [DRIVER] start work modal */}
      <Modal
        openModal={modal[`${period}Work`]}
        onCloseModal={toggleModalHandler(`${period}Work`)}
        maxWidth="sm"
      >
        <Modal.Content>
          {period === "before" ? (
            <Fragment>
              <Typography align="center" variant="body1" color="secondary">
                คุณกำลังจะเริ่มการทำงานใช่หรือไม่
              </Typography>
              <Typography variant="body1" color="secondary">
                เมื่อกด ตกลง แล้ว กรุณาถ่ายภาพก่อนการทำงานเพื่อแจ้งให้
                ผู้จ้างทราบว่าคุณพร้อมทำงานแล้ว
              </Typography>
            </Fragment>
          ) : (
            <Typography align="center" variant="body1" color="secondary">
              คุณทำงานเสร็จแล้ว ต้องการส่งงานให้ผู้จ้างตรวจสอบใช่หรือไม่
            </Typography>
          )}
        </Modal.Content>
        <Modal.Footer sx={{ mb: 2 }}>
          <Grid container item justifyContent="center" xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={toggleModalHandler(`${period}Work`)}
              sx={{
                px: 4,
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              ยกเลิก
            </Fab>
          </Grid>
          <Grid container item justifyContent="center" xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={toggleShowDrawerPicker(`${period}Work`)}
              sx={{ px: 4 }}
            >
              ตกลง
            </Fab>
          </Grid>
        </Modal.Footer>
      </Modal>

      <Drawer
        anchor="bottom"
        open={drawerPicker[`${period}Work`]}
        onClose={toggleShowDrawerPicker(`${period}Work`)}
      >
        <Navbar color="secondary" position="absolute">
          <Navbar.Left sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">
              ถ่ายรูปพื้นที่{period === "before" ? "ก่อนเริ่ม" : "หลังเสร็จ"}งาน
            </Typography>
          </Navbar.Left>
          <Navbar.Right sx={{ ml: "auto" }}>
            <IconButton onClick={toggleShowDrawerPicker(`${period}Work`)}>
              <CloseIcon
                sx={{ color: (theme) => theme.palette.common.white }}
              />
            </IconButton>
          </Navbar.Right>
        </Navbar>
        <form onSubmit={handleSubmitImages(submitWorkingField)}>
          <Grid
            item
            xs={12}
            ml={2}
            my={3}
            sx={!images?.length ? { minHeight: "30vh" } : {}}
          >
            <ButtonImageInput
              name="images"
              control={controlImages}
              defaultValue={[]}
              addImages={onAddImage}
              showStreamMedia={drawerPicker.streamMediaImages}
              onClickTakePhoto={toggleShowDrawerPicker("streamMediaImages")}
              multiple
            />
          </Grid>
          <Grid item xs={12} mx={2} mb={2}>
            <PreviewFieldImage
              imgList={images}
              clickRemoveImage={onRemoveImage("images")}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" mb={2}>
            <Fab
              size="small"
              variant="extended"
              color="primary"
              type="submit"
              sx={{ px: 3, mr: 2 }}
            >
              ตกลง
            </Fab>
          </Grid>
        </form>
      </Drawer>

      {/* [FARMER] confirm accept job modal */}
      <Modal
        openModal={modal.acceptWork}
        onCloseModal={toggleModalHandler("acceptWork")}
        maxWidth="sm"
      >
        <Modal.Content>
          <Typography variant="body1" color="secondary">
            คุณได้ตรวจสอบงานแล้ว และตกลงรับส่งมอบงานใช่หรือไม่
          </Typography>
        </Modal.Content>
        <Modal.Footer sx={{ mb: 2 }}>
          <Grid container item justifyContent="center" xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={toggleModalHandler("acceptWork")}
              sx={{
                px: 4,
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              ยกเลิก
            </Fab>
          </Grid>
          <Grid container item justifyContent="center" xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={submitAcceptJob}
              sx={{ px: 4 }}
            >
              ตกลง
            </Fab>
          </Grid>
        </Modal.Footer>
      </Modal>

      <Drawer
        anchor="bottom"
        open={drawerPicker.paymentDetail}
        onClose={toggleShowDrawerPicker("paymentDetail")}
      >
        <PaymentDetailsPage
          total={information.totalAmount}
          qrCodeUrl={information.qrCodeUrl}
          onClickClose={toggleShowDrawerPicker("paymentDetail")}
          onClickDownload={clickDownloadQR}
        />
      </Drawer>

      {/* [DRIVER] recieved cash */}
      <Modal
        openModal={modal.recievedCash}
        onCloseModal={toggleModalHandler("recievedCash")}
        maxWidth="sm"
      >
        <Modal.Content>
          <Typography variant="body1" color="secondary">
            คุณได้รับเงินสดตรงตามราคาที่ระบุไว้ในรายละเอียดแล้ว
            และตกลงที่จะยืนยันเสร็จสิ้นงานนี้ใช่หรือไม่
          </Typography>
        </Modal.Content>
        <Modal.Footer sx={{ mb: 2 }}>
          <Grid container item justifyContent="center" xs={6} pr={1}>
            <Fab
              size="medium"
              variant="extended"
              onClick={toggleModalHandler("recievedCash")}
              sx={{
                px: 4,
                bgcolor: "transparent",
                border: (theme) => `1px solid ${theme.palette.common.black}`,
              }}
            >
              ยกเลิก
            </Fab>
          </Grid>
          <Grid container item justifyContent="center" xs={6} pl={1}>
            <Fab
              size="medium"
              variant="extended"
              color="primary"
              onClick={submitRecievedCash}
              sx={{ px: 4 }}
            >
              ตกลง
            </Fab>
          </Grid>
        </Modal.Footer>
      </Modal>

      <ActionLoading
        open={
          isConfirming ||
          isRejecting ||
          isOffering ||
          isActingToOffer ||
          isWorking ||
          isJobAccepting ||
          isRecieving
        }
      >
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default CallToActions;
