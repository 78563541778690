import { Fragment } from "react";

import { Grid, Typography } from "@mui/material";

import { VEHICLES } from "constants/Vehicles";

const MiddleDetails = ({ vinType, details }) => {
  if (vinType === VEHICLES.excavator.nameEN)
    return (
      <Fragment>
        <Grid item xs={6} mb={2} pl={2}>
          <Typography>ขนาด</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{details?.size}</Typography>
        </Grid>
      </Fragment>
    );

  return (
    <Fragment>
      <Grid item xs={6} mb={2} pl={2}>
        <Typography>รุ่น</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{details?.model}</Typography>
      </Grid>
      <Grid item xs={6} mb={2} pl={2}>
        <Typography>ปีที่ซื้อ</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{details?.year}</Typography>
      </Grid>
    </Fragment>
  );
};

export default MiddleDetails;
