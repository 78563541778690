import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { th } from "date-fns/locale";

import { Grid, Skeleton, Chip, Typography } from "@mui/material";
import Swal from "sweetalert2";

import { useHttp, useSwalStyles } from "hooks";
import { sortingDateTime } from "helpers/DateTime";

import { ScrollToTop, ActionLoading } from "base-components";
import {
  DriverJobCard,
  ServiceTypeCard,
  AddItemCard,
  VehicleCard,
  WorkbookCard,
} from "components";

import { ROUTES } from "routes/router";
import { JOB_ENDPOINTS } from "constants/APIs";
import { VEHICLES } from "constants/Vehicles";
import { authActions } from "store/authentication/auth-slice";

import { useGetMasterServiceTypesQuery } from "services/master";
import { selectShortServiceTypes } from "store/master-slice";

import { useGetMachineriesOfDriverQuery } from "services/machinery";

import {
  useGetDriverServicesQuery,
  useToggleDriverServiceAvailabilityMutation,
} from "services/service";

const DriverMenuPage = () => {
  const dispatch = useDispatch();
  const {
    role: { driver },
    verifyOTP,
    currentLocation,
    currentRole,
  } = useSelector((state) => state.auth);
  const [workings, setWorkings] = useState([]);
  const [countWorks, setCountWorks] = useState({
    success: 0,
    inProgress: 0,
  });
  const [priorityWorkings, setPriorityWorkings] = useState(false);

  const navigate = useNavigate();

  const { isLoading: isLoadingJobs, sendRequest: fetchDriverJobsData } =
    useHttp();

  const selectedShortMasterServices = useSelector(selectShortServiceTypes);
  const { isLoading: isLoadingServicesTypes } = useGetMasterServiceTypesQuery(
    undefined,
    {
      skip: selectedShortMasterServices.length,
    }
  );

  const { data: machineries = [] } = useGetMachineriesOfDriverQuery(undefined, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const filteredMachineriesBy = ({ vinTypeEN, stage }) => {
    if (!vinTypeEN) return [];
    if (vinTypeEN && !stage)
      return machineries.filter(
        (machinery) => machinery.vinTypeEN === vinTypeEN
      );
    return machineries.find(
      (machinery) =>
        machinery.vinTypeEN === vinTypeEN && machinery.stage === stage
    );
  };

  const { data: services = [], isLoading: isLoadingServices } =
    useGetDriverServicesQuery(undefined, {
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const findedServiceBy = ({ serviceNameEN, stage }) => {
    if (!serviceNameEN) return;
    if (serviceNameEN && !stage)
      return services.find((service) => service.type === serviceNameEN);
    return services.find(
      (service) => service.type === serviceNameEN && service.stage === stage
    );
  };
  /* PUT disable Service */
  const [enableService, { isLoading: isEnablingService }] =
    useToggleDriverServiceAvailabilityMutation();

  const classes = useSwalStyles();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const fetchDriverJobs = (signal) => {
      const filteredStages = "working,waiting,edited,inDay,sooning,delivered";

      fetchDriverJobsData(
        { endpoint: JOB_ENDPOINTS.driver.all(filteredStages), signal },
        (respJobsGrouping) => {
          if (respJobsGrouping.length === 1 && respJobsGrouping[0]?.jobQty > 0)
            setPriorityWorkings(true);
          setCountWorks({
            success: respJobsGrouping[respJobsGrouping.length - 1].success,
            inProgress:
              respJobsGrouping[respJobsGrouping.length - 1].inProgress,
          });
          setWorkings(
            respJobsGrouping
              .map((stage) => sortingDateTime(stage.jobs).map((job) => job))
              .flat()
          );
        }
      );
    };

    fetchDriverJobs(signal);

    return () => abortCont.abort();
  }, [dispatch, fetchDriverJobsData]);

  useEffect(() => {
    const requestUserLocation = () => {
      // navigator.geolocation.watchPosition((position) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        dispatch(
          authActions.updateCurrentLocation({ lat: latitude, lng: longitude })
        );
      });
    };

    if (!currentLocation?.lat || !currentLocation?.lng) requestUserLocation();
  }, [dispatch, currentLocation]);

  /**
   * Middleware function for checking role must be driver
   * @param {Function} next callback function to trigger after check role
   * @param {String} argsNextFunc data for using in next function
   * @returns {Function} if driver will first call next callback function
   *                     if not driver will navigate to driver register page
   */
  const checkDriverInformation =
    (next) =>
    async (argNextFunc = null) => {
      if (driver && verifyOTP) return next(argNextFunc);

      /* New driver */
      if (!driver && !verifyOTP) {
        const swalResp = await new Swal({
          titleText: "กรุณาลงทะเบียนข้อมูลส่วนตัวก่อน",
          text: "จำเป็นต้องใช้ข้อมูลส่วนตัวของท่านก่อนทำเริ่มทำธุรกรรมใดๆ กรุณากดปุ่ม เพิ่มข้อมูลส่วนตัว เพื่อเพิ่มข้อมูลส่วนตัวของท่าน",
          icon: "info",
          confirmButtonText: "เพิ่มข้อมูลส่วนตัว",
          width: "80%",
          showCloseButton: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2ConfirmButton,
          },
        });
        return (
          swalResp.isConfirmed &&
          navigate(ROUTES.USER_INFORMATION_FORM(currentRole), {
            state: { action: "create", source: "new" },
            replace: true,
          })
        );
      }

      /* New driver but not completed registering process (only verify OTP) */
      if (driver && !verifyOTP) {
        const swalResp = await new Swal({
          titleText: "กรุณายืนยันเบอร์โทรศัพท์ของท่านก่อน",
          text: "จำเป็นต้องยืนยันเเบอร์โทรศัพท์ของท่านก่อนทำเริ่มทำธุรกรรมใดๆ กรุณากดปุ่ม ยืนยันเบอร์โทรศัพท์ เพื่อตรวจสอบเและยืนยันเบอร์ของท่าน",
          icon: "info",
          confirmButtonText: "ยืนยันเบอร์โทรศัพท์",
          width: "80%",
          showCloseButton: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2ConfirmButton,
          },
        });
        return (
          swalResp.isConfirmed &&
          navigate(ROUTES.TEL_NUMBER_FORM, {
            state: { action: "update", source: "update" },
          })
        );
      }

      /* registered farmer => driver */
      if (!driver && verifyOTP) {
        const swalResp = await new Swal({
          titleText: "กรุณาลงทะเบียนข้อมูลส่วนตัวให้ครบก่อน",
          text: "จำเป็นต้องใช้ข้อมูลส่วนตัวของท่านก่อนทำเริ่มทำธุรกรรมใดๆ กรุณากดปุ่ม เพิ่มข้อมูลส่วนตัว เพื่อเพิ่มข้อมูลส่วนตัวของท่าน",
          icon: "info",
          confirmButtonText: "เพิ่มข้อมูลส่วนตัว",
          width: "80%",
          showCloseButton: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2ConfirmButton,
          },
        });
        return (
          swalResp.isConfirmed &&
          navigate(ROUTES.USER_INFORMATION_FORM(currentRole), {
            state: {
              action: "create",
              source: "new",
            },
          })
        );
      }
    };

  const onClickWorkbookMoreDetail = () => {
    navigate(ROUTES.JOBS(currentRole));
  };

  const onClickJobList = (jobId) => {
    navigate(ROUTES.JOB_DETAIL(jobId));
  };

  const onClickService = async (serviceName) => {
    const machineriesByType = filteredMachineriesBy({ vinTypeEN: serviceName });
    const activatedMachineryByType = filteredMachineriesBy({
      vinTypeEN: serviceName,
      stage: "active",
    });
    const disabledServiceByType = findedServiceBy({
      serviceNameEN: serviceName,
      stage: "disabled",
    });

    if (!machineriesByType.length) {
      /* Not have any machineries */
      const swalResp = await new Swal({
        titleText: "ไม่มีเครื่องจักรสำหรับงานนี้",
        text: "กรุณากดปุ่ม เพิ่มเครื่องจักร เพื่อเพิ่มเครื่องจักรของท่านก่อนลงทะเบียนรับงาน",
        icon: "info",
        confirmButtonText: "เพิ่มเครื่องจักร",
        width: "80%",
        showCloseButton: true,
        customClass: {
          container: classes.swal2Container,
          confirmButton: classes.swal2ConfirmButton,
        },
      });
      swalResp.isConfirmed &&
        navigate(ROUTES.CREATE_MACHINE_FORM, {
          state: { service: serviceName },
        });
    } else if (!activatedMachineryByType) {
      /* No any machineries that stage is ACTIVE */
      const swalResp = await new Swal({
        titleText: "ไม่มีเครื่องจักรที่พร้อมใช้กับงานนี้",
        text: "กรุณากดปุ่ม จัดการเครื่องจักร เพื่อแก้ไขข้อมูลเครื่องจักรของท่านเพื่อให้พร้อมลงทะเบียนรับงาน",
        icon: "info",
        confirmButtonText: "จัดการเครื่องจักร",
        width: "80%",
        showCloseButton: true,
        customClass: {
          container: classes.swal2Container,
          confirmButton: classes.swal2ConfirmButton,
        },
      });
      swalResp.isConfirmed && navigate(ROUTES.MACHINES);
    } else if (disabledServiceByType) {
      /* Service is disabled */
      const swalResp = await new Swal({
        titleText: `ยืนยันเปิดให้บริการ${VEHICLES[serviceName].nameTH}หรือไม่`,
        icon: "question",
        cancelButtonText: "กลับหน้าเดิม",
        confirmButtonText: "ยืนยัน",
        width: "80%",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        reverseButtons: true,
        customClass: {
          container: classes.swal2Container,
          confirmButton: classes.swal2ConfirmButton,
          cancelButton: classes.swal2CancelButton,
        },
      });
      if (swalResp.isConfirmed) {
        await enableService({
          action: "enable",
          serviceName: serviceName,
        }).unwrap();
      }
    } else navigate(ROUTES.SERVICE_FORM(serviceName, "create"));
  };

  const onClickExistService = (serviceName) => {
    navigate(ROUTES.SERVICE_FORM(serviceName, "edit"));
  };

  const onClickMachinesMoreDetail = () => {
    navigate(ROUTES.MACHINES);
  };

  const onClickMachineDetail = (vin, type, stage) => {
    if (stage === "disabled")
      return navigate(ROUTES.EDIT_MACHINE_FORM(type, vin));
    return navigate(ROUTES.MACHINE_DETAIL(type, vin));
  };

  const onClickRegisterMachine = () => {
    navigate(ROUTES.CREATE_MACHINE_FORM, {
      state: { action: !machineries?.length ? "create" : "" },
    });
  };

  const dateTH = format(new Date(), "dd", { locale: th });
  const monthTH = format(new Date(), "LLLL", { locale: th });

  let jobsCardElements = [...Array(4)].map((_, idx) => (
    <Skeleton
      key={idx}
      animation="wave"
      variant="rectangular"
      height={46}
      sx={{ borderRadius: (theme) => theme.spacing(1.5), mb: 2 }}
    />
  ));
  if (!isLoadingJobs && !workings?.length && priorityWorkings)
    jobsCardElements = (
      <Typography align="center" variant="body1" color="primary">
        <strong>กด เพิ่มเติม เพื่อดูงานอื่นๆของคุณ</strong>
      </Typography>
    );
  if (!isLoadingJobs && !workings?.length && !priorityWorkings)
    jobsCardElements = (
      <Typography
        textAlign="center"
        variant="body1"
        sx={{ color: (theme) => theme.palette.error.light }}
      >
        <strong>คุณยังไม่มีงานในระบบ</strong>
      </Typography>
    );
  if (!isLoadingJobs && workings?.length)
    jobsCardElements = workings.map((job) => (
      <DriverJobCard
        key={job.id}
        id={job.id}
        vinType={job.vinType}
        service={job.service}
        serviceDate={job.serviceDate}
        serviceTime={job.serviceTime}
        fieldSize={job.fieldSize}
        stage={job.stage}
        paymentMethod={job.paymentMethod}
        clickJobList={onClickJobList}
      />
    ));

  const workbooksElement = (
    <WorkbookCard
      title={
        <Fragment>
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            <strong>{monthTH}</strong>
            <Typography component="span" color="tertiary" sx={{ ml: 1 }}>
              ({dateTH})
            </Typography>
          </Typography>
          {countWorks.success > 0 && (
            <Chip
              label={`เสร็จ (${countWorks.success})`}
              size="small"
              color="primary"
              sx={{ ml: "auto", mr: countWorks.inProgress > 0 ? 1 : 0 }}
            />
          )}
          {countWorks.inProgress > 0 && (
            <Chip
              label={`ไม่เสร็จ (${countWorks.inProgress})`}
              color="tertiary"
              size="small"
              variant="contained"
              sx={countWorks.success === 0 ? { ml: "auto" } : null}
            />
          )}
        </Fragment>
      }
      rowHeader={
        <Fragment>
          <Grid item xs={2} pr={1}>
            <Typography textAlign="center" variant="subtitle2" color="tertiary">
              วันที่
            </Typography>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Typography textAlign="center" variant="subtitle2" color="tertiary">
              รายละเอียด
            </Typography>
          </Grid>
          <Grid item xs={4} pl={2}>
            <Typography textAlign="center" variant="subtitle2" color="tertiary">
              สถานะ
            </Typography>
          </Grid>
        </Fragment>
      }
    >
      {jobsCardElements}
    </WorkbookCard>
  );

  const serviceTypeElements =
    isLoadingServicesTypes && isLoadingServices
      ? [...Array(4)].map((_, idx) => (
          <Grid
            key={idx}
            container
            item
            direction="column"
            justifyContent="center"
            xs={5.5}
            mb={3}
            sx={{
              flexGrow: 1,
              "&:nth-of-type(odd)": { mr: 3 },
            }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={130}
              sx={{ borderRadius: (theme) => theme.spacing(1) }}
            />
          </Grid>
        ))
      : selectedShortMasterServices?.length
      ? selectedShortMasterServices.map((service) => {
          const serviceByType = findedServiceBy({
            serviceNameEN: service.nameEN,
          });
          const disabledServiceByType = findedServiceBy({
            serviceNameEN: service.nameEN,
            stage: "disabled",
          });
          let overlayText = !serviceByType && "ลงทะเบียน";
          if (disabledServiceByType) overlayText = "ปิดบริการชั่วคราว";

          /* all phase */
          const overlay = !serviceByType || disabledServiceByType;
          /* all phase */

          return (
            <ServiceTypeCard
              key={service.nameEN}
              nameEN={service.nameEN}
              nameTH={service.nameTH}
              imgUrl={service.image}
              overlay={!!overlay}
              overlayText={overlayText}
              clickOverlayButton={checkDriverInformation(onClickService)}
              clickImage={checkDriverInformation(onClickExistService)}
              clickName={checkDriverInformation(onClickExistService)}
            />
          );
        })
      : null;

  const vehicleCardElements = machineries?.length
    ? machineries.map((vehicle) => (
        <VehicleCard
          key={vehicle.id}
          imgUrl={vehicle.imageFront}
          vin={vehicle.id}
          type={vehicle.vinType}
          vinTypeEN={vehicle.vinTypeEN}
          stage={vehicle.stage}
          name={vehicle.vinName}
          brand={vehicle.brand}
          model={vehicle.model}
          year={vehicle.year}
          size={vehicle.size}
          onClickVehicleCardHandler={onClickMachineDetail}
        />
      ))
    : null;

  return (
    <Fragment>
      <ScrollToTop />
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>
            <strong>สมุดงาน</strong>
          </Typography>
          <Typography
            color="secondary.main"
            variant="subtitle1"
            sx={{ my: "auto", ml: "auto" }}
            onClick={checkDriverInformation(onClickWorkbookMoreDetail)}
          >
            เพิ่มเติม
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          {workbooksElement}
        </Grid>

        <Grid item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>งานที่ฉันรับได้</strong>
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sx={{ flexGrow: 1 }}
        >
          {serviceTypeElements}
        </Grid>

        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>เครื่องจักรของท่าน</strong>
          </Typography>
          <Typography
            color="secondary.main"
            variant="subtitle1"
            sx={{ my: "auto", ml: "auto" }}
            onClick={checkDriverInformation(onClickMachinesMoreDetail)}
          >
            เพิ่มเติม
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          {vehicleCardElements}
          <AddItemCard
            text="เพิ่มเครื่องจักร"
            clickCard={checkDriverInformation(onClickRegisterMachine)}
          />
        </Grid>
      </Grid>

      <ActionLoading color="primary" open={isEnablingService}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default DriverMenuPage;
