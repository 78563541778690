// import { useNavigate } from "react-router-dom";
// import { ROUTES } from "routes/router";

import { Grid, Typography, Fab, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { HoldingDivider, DividerFullWidth } from "base-components";

const QRImage = styled("img")({
  objectFit: "contain",
  width: "50%",
});

const PaymentDetailsPage = ({
  total,
  qrCodeUrl,
  onClickClose,
  onClickDownload,
}) => {
  // const navigate = useNavigate();

  const onCloseDetailsHandler = () => {
    onClickClose();
  };

  const onClickDownloadQR = () => {
    onClickDownload();
  };

  return (
    <Grid container mb={6} sx={{ fontSize: "1.125rem", position: "relative" }}>
      <Grid item xs={12} mt={3} mb={2}>
        <Typography align="center" variant="inherit" color="secondary">
          <strong>จ่ายเงินให้คนขับ</strong>
        </Typography>
      </Grid>
      <IconButton
        onClick={onCloseDetailsHandler}
        sx={{ position: "absolute", top: 0, right: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <Grid item xs={12} mb={3}>
        <Typography align="center" variant="inherit" color="secondary">
          กรุณาจ่ายเงินมายังหมายเลขบัญชีที่ระบุ
        </Typography>
      </Grid>
      <HoldingDivider container item justifyContent="center" xs={12}>
        <DividerFullWidth
          sx={{
            width: "90%",
            inset: "unset",
            border: (theme) => `3px solid ${theme.palette.secondary.main}`,
          }}
        />
      </HoldingDivider>

      <Grid
        container
        item
        justifyContent="space-between"
        xs={12}
        mb={1}
        mx={2.5}
      >
        <Typography variant="inherit">ธนาคาร</Typography>
        <Typography variant="inherit">ไทยพาณิชย์</Typography>
      </Grid>

      <HoldingDivider container item justifyContent="center" xs={12}>
        <DividerFullWidth
          sx={{
            width: "90%",
            inset: "unset",
          }}
        />
      </HoldingDivider>

      <Grid
        container
        item
        justifyContent="space-between"
        xs={12}
        mb={1}
        mx={2.5}
      >
        <Typography variant="inherit">หมายเลขบัญชี</Typography>
        <Typography variant="inherit">314-303141-3</Typography>
      </Grid>

      <HoldingDivider container item justifyContent="center" xs={12}>
        <DividerFullWidth
          sx={{
            width: "90%",
            inset: "unset",
          }}
        />
      </HoldingDivider>

      <Grid
        container
        item
        justifyContent="space-between"
        xs={12}
        mb={1}
        mx={2.5}
      >
        <Typography variant="inherit">ชื่อบัญชี</Typography>
        <Typography variant="inherit">บริษัท เกษตรอินโน จำกัด</Typography>
      </Grid>

      <HoldingDivider container item justifyContent="center" xs={12}>
        <DividerFullWidth
          sx={{
            width: "90%",
            inset: "unset",
          }}
        />
      </HoldingDivider>

      <Grid
        container
        item
        justifyContent="space-between"
        xs={12}
        mb={1}
        mx={2.5}
      >
        <Typography variant="inherit">จำนวนเงิน</Typography>
        <Typography variant="inherit" color="error">
          <strong>{total} บาท</strong>
        </Typography>
      </Grid>

      <HoldingDivider container item justifyContent="center" xs={12} mb={1}>
        <DividerFullWidth
          sx={{
            width: "90%",
            inset: "unset",
          }}
        />
      </HoldingDivider>

      <Grid item xs={12} mb={1}>
        <Typography align="center" variant="inherit" color="secondary">
          หรือ
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography align="center" variant="inherit" color="secondary">
          สแกนคิวอาร์โค้ดนี้เพื่อชำระเงิน
        </Typography>
      </Grid>

      <Grid container item justifyContent="center" xs={12} mb={3}>
        <QRImage src={qrCodeUrl} alt="qr-tag30" />
      </Grid>

      <Grid container item justifyContent="center" xs={12}>
        <Fab
          onClick={onClickDownloadQR}
          size="small"
          variant="extended"
          color="secondary"
          sx={{ px: 3 }}
        >
          บันทึกคิวอาร์โค้ด
        </Fab>
      </Grid>
    </Grid>
  );
};

export default PaymentDetailsPage;
