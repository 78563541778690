import { createSlice } from "@reduxjs/toolkit";

// import { ROLES } from "constants/Roles";

const authInitialState = {
  /* USER information */
  exists: false,
  prefixname: "",
  firstname: "",
  surname: "",
  cid: "",
  role: {
    farmer: true,
    driver: false,
  },
  currentRole: "farmer",
  tel: "",
  currentLocation: {
    lat: null,
    lng: null,
  },
  address: "",
  district: "",
  subDistrict: "",
  province: "",
  birthDate: "",
  payeeMethod: "",
  bankName: "",
  bankAccount: "",
  promptpayType: "",
  promptpay: "",
  getztracId: "",
  verifyOTP: false,
  lastLogin: "",
  recommendedCode: "",
  recommendedDesc: "",
  knownChannel: "",
  points: 0,
  pdpa_accept: false,
  confirmDuplicated: false,

  /* LINE login */
  access_token: "",
  lineId: "",
  displayName: "",
  pictureUrl: "",
  email: "",
  friendFlag: "",

  /* Register with getztrac session */
  otp: {
    token: "",
    refno: "",
    pin: "",
  },
};

const authSlice = createSlice({
  name: "authentication",
  initialState: authInitialState,
  reducers: {
    updateUser(state, { payload }) {
      for (const [key, value] of Object.entries(payload)) {
        state[key] = value;
      }
    },
    setCurrentRole(state, { payload: role }) {
      state.lastLogin = role;
      state.currentRole = role;
    },
    updateRoles(state, { payload: role }) {
      for (const [key, value] of Object.entries(role)) {
        state.role[key] = value;
      }
    },
    updateCurrentLocation(state, { payload }) {
      state.currentLocation = { lat: payload.lat, lng: payload.lng };
    },
    updatePDPA(state, { payload }) {
      state.pdpa_accept = payload;
    },
    updateVerifyOTP(state, { payload }) {
      state.verifyOTP = payload;
    },
    resetUser(state) {
      state.exists = false;
      state.prefixname = "";
      state.firstname = "";
      state.surname = "";
      state.cid = "";
      state.role.farmer = true;
      state.role.driver = false;
      state.lastLogin = "";
      state.currentLocation.lat = null;
      state.currentLocation.lng = null;
      state.tel = "";
      state.address = "";
      state.district = "";
      state.subDistrict = "";
      state.province = "";
      state.birthDate = "";
      state.recommendedCode = "";
      state.recommendedDesc = "";
      state.pdpa_accept = false;
      state.bankName = "";
      state.bankAccount = "";
      state.verifyOTP = false;
      state.knownChannel = "";
      state.points = 0;
    },

    setOtpSession(state, { payload }) {
      state.tel = payload.tel;
      state.otp.token = payload.token;
      state.otp.refno = payload.refno;
      state.otp.pin = payload.pin;
      if ("confirmDuplicated" in payload)
        state.confirmDuplicated = payload.confirmDuplicated;
    },
    clearOtpSession(state) {
      state.otp.token = "";
      state.otp.refno = "";
      state.otp.pin = "";
      state.confirmDuplicated = false;
    },
  },
});

export const authActions = authSlice.actions;

export const {
  updateUser,
  setCurrentRole,
  updateRoles,
  updateCurrentLocation,
  updatePDPA,
  updateVerifyOTP,
  resetUser,
  setOtpSession,
  clearOtpSession,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentRole = ({ auth }) => auth.currentRole;
