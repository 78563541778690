import PropTypes from "prop-types";

import { Grid, Box, Typography, Button, styled } from "@mui/material";

const ImageIcon = styled("img")(({ theme }) => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  overflow: "hidden",
  borderTopRightRadius: theme.spacing(1),
  borderTopLeftRadius: theme.spacing(1),
}));

const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: theme.palette.common.white,
  borderBottomRightRadius: theme.spacing(1),
  borderBottomLeftRadius: theme.spacing(1),
  "& .MuiTypography-root": {
    color: theme.palette.common.black,
  },
}));

const Overlay = styled("span")(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.4)",
  zIndex: 1,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ServiceTypeCard = ({
  nameEN,
  nameTH,
  imgUrl,
  overlay,
  overlayText,
  clickOverlayButton,
  clickImage,
  clickName,
}) => {
  const onClickButtonOverlayedHandler = (name) => () => {
    clickOverlayButton(name);
  };

  const onClickImage = (name) => () => {
    if (overlay) return;
    clickImage(name);
  };

  const onClickName = (name) => () => {
    if (overlay) return;
    clickName(name);
  };

  const backdropElement = overlay ? (
    <Overlay>
      <Button
        onClick={onClickButtonOverlayedHandler(nameEN)}
        variant="outlined"
        sx={(theme) => ({
          color: theme.palette.common.white,
          backgroundColor: "transparent",
          borderColor: theme.palette.common.white,
          borderRadius: theme.spacing(1.5),
          "&:hover": {
            borderColor: theme.palette.common.white,
          },
        })}
      >
        {overlayText}
      </Button>
    </Overlay>
  ) : null;

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      xs={5.5}
      sx={(theme) => ({
        position: "relative",
        flexGrow: 1,
        borderRadius: theme.spacing(1),
        mb: 3,
        "&:nth-of-type(odd)": { mr: 3 },
      })}
    >
      <Box onClick={onClickImage(nameEN)}>
        <ImageIcon src={imgUrl} alt={`${nameEN}_img`} />
      </Box>

      <Footer onClick={onClickName(nameEN)} py={1}>
        <Typography component="span" variant="body1" sx={{ m: "auto" }}>
          {nameTH}
        </Typography>
      </Footer>
      {backdropElement}
    </Grid>
  );
};

ServiceTypeCard.propTypes = {
  imgUrl: PropTypes.node.isRequired,
  overlay: PropTypes.bool,
  footer: PropTypes.string,
};

ServiceTypeCard.defaultProps = {
  overlay: false,
  overlayText: "ลงทะเบียน",
};

export default ServiceTypeCard;
