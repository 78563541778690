import { Fragment, useRef } from "react";

import { Grid, Fab, Typography } from "@mui/material";

import { Modal, ActionLoading } from "base-components";
import { TextInput, BoxImageInput } from "components/hook-forms";

import { useUploadPaySlipByCustomerMutation } from "services/job";

const UploadSlipModal = ({
  control,
  handleSubmit,
  setValue,
  formState,
  openModal,
  onCloseModal,
  openDrawerPicker,
  onToggleDrawerPicker,
  onSubmittedFulfilled,
}) => {
  const imageRef = useRef(null);

  const [uploadPaySlip, { isLoading: isUploading }] =
    useUploadPaySlipByCustomerMutation();

  const handleSubmitPaySlip = async (form) => {
    await uploadPaySlip(form).unwrap();
    onCloseModal();
    onSubmittedFulfilled();
  };

  const onAddImageHandler = (images, callbackFn) => {
    if (Array.isArray(images)) callbackFn(images[0]);
    else callbackFn(images);
  };

  const onRemoveImageHandler = () => {
    setValue("slip", "");
  };

  return (
    <Fragment>
      <Modal openModal={openModal} onCloseModal={onCloseModal} maxWidth="sm">
        <Modal.Content>
          <form onSubmit={handleSubmit(handleSubmitPaySlip)}>
            <Grid container>
              <Grid item xs={12} mb={3}>
                <Typography color="primary" variant="body1">
                  <strong>แจ้งการโอนเงิน</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <BoxImageInput
                  ref={imageRef}
                  control={control}
                  defaultValue=""
                  name="slip"
                  height={20}
                  addImage={onAddImageHandler}
                  onRemoveHandler={onRemoveImageHandler}
                  showStreamMedia={openDrawerPicker}
                  onClickTakePhoto={onToggleDrawerPicker}
                  sx={{ mx: 2 }}
                >
                  <Typography
                    variant="body1"
                    color={formState?.errors?.slip ? "error" : "tertiary"}
                    align="center"
                    sx={{ my: "auto", flexGrow: 1 }}
                  >
                    กดเพื่ออัพโหลด
                  </Typography>
                </BoxImageInput>
              </Grid>
              <Grid item xs={12} mb={3}>
                <TextInput
                  control={control}
                  defaultValue=""
                  name="note"
                  label="หมายเหตุ"
                  placeholder="ระบุรายละเอียดเพิ่มเติม"
                  width={{ label: "30%", input: "70%" }}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid container item justifyContent="space-between" xs={12}>
                <Fab
                  size="medium"
                  variant="extended"
                  onClick={onCloseModal}
                  sx={{
                    px: 4,
                    bgcolor: "transparent",
                    border: (theme) =>
                      `1px solid ${theme.palette.common.black}`,
                  }}
                >
                  ยกเลิก
                </Fab>
                <Fab
                  type="submit"
                  size="medium"
                  variant="extended"
                  color="primary"
                  sx={{ px: 4 }}
                >
                  ตกลง
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Modal.Content>
      </Modal>

      <ActionLoading color="secondary" open={isUploading}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default UploadSlipModal;
