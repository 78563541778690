import { createSlice } from "@reduxjs/toolkit";
import { masterApi } from "services/master";

const initialState = {
  machinery: [],
  shortTypes: [],
  drone: null,
  combine: null,
  tractor: null,
  excavator: null,
  field: [],
  address: [],
};

const slice = createSlice({
  name: "remaster",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        masterApi.endpoints.getMasterMachinery.matchFulfilled,
        (state, { payload }) => {
          state.machinery = payload;
        }
      )
      .addMatcher(
        masterApi.endpoints.getMasterServiceTypes.matchFulfilled,
        (state, { payload }) => {
          state.shortTypes = payload;
        }
      )
      .addMatcher(
        masterApi.endpoints.getMasterServiceName.matchFulfilled,
        (state, { payload: { serviceNameEN, data } }) => {
          state[serviceNameEN] = data;
        }
      )
      .addMatcher(
        masterApi.endpoints.getMasterField.matchFulfilled,
        (state, { payload }) => {
          state.field = payload;
        }
      )
      .addMatcher(
        masterApi.endpoints.getMasterAddress.matchFulfilled,
        (state, { payload }) => {
          state.address = payload;
        }
      );
  },
});

export default slice.reducer;

export const selectShortServiceTypes = ({ remaster }) => remaster.shortTypes;
export const selectMasterServiceByType = ({ remaster }, serviceType) =>
  remaster[serviceType];
export const selectMasterField = ({ remaster }) => remaster.field;
export const selectMasterAddress = ({ remaster }) => remaster.address;
