import Mining01 from "assets/mining-01.png";
import Mining02 from "assets/mining-02.png";

import Drilling01 from "assets/drilling-01.png";
import Drilling02 from "assets/drilling-02.png";

export const EXCAVATOR_SERVICE = {
  mining: {
    nameTH: "ขุดหรือปรับพื้นที่",
    nameEN: "mining",
    mainImages: [Mining01, Mining02],
    masterDescriptions: [
      "เป็นงานที่ทำกับพื้นที่ซึ่งเป็นดินส่วนใหญ่เช่น ขุดบ่อ ทอยดิน ขุดฐานราก(ฟุตติ้ง) ขุดคลอง ขุดวางท่อ เป็นต้น",
      "อีกทั้งปรับพื้นที่ เช่นถางป่า(เคลียร์ริ่ง) ปรับพื้นที่เกษตร เช่น ปรับคันนา ปั้นโคก ลอกหน้าดิน ปรับที่เพื่อก่อสร้าง และงานอื่นๆ เป็นต้น",
    ],
    formDescriptions: [
      "งานขุด เช่น ขุดฐานราก ขุดสระน้ำ ขุดคลองส่งน้ำ ขุดวางท่อน้ำ ขุดหลุมและงานอื่นๆ เป็นต้น",
      "งานปรับพื้นที่ เช่น เคลียร์ริ่ง ปรับที่ก่อสร้าง ปรับที่เกษตร ปั้นโคกทุเรียน ปรับคันนา ลอกหน้าดิน และงานอื่นๆเป็นต้น",
    ],
  },
  drilling: {
    nameTH: "เจาะ",
    nameEN: "drilling",
    mainImages: [Drilling01, Drilling02],
    masterDescriptions: [
      "เป็นงานที่ทำกับพื้นที่ซึ่งเป็นหินหรือปูน เช่น เจาะพื้นที่ปูน เจาะหลุมวางเสาเข็ม เจาะถนน เจาะรื้อถนน เจาะแนวรั้วกำแพง เจาะเสาไฟ เป็นต้น",
    ],
    formDescriptions: [
      "เช่น เจาะพื้นปูน เจาะเสาเข็ม เจาะถนน/หิน เจาะรื้อถอน เจาะวางเสาไฟ เจาะแนวรั้ว เป็นต้น",
    ],
  },
  other: {
    nameTH: "อื่นๆ",
    nameEN: "other",
    formDescriptions: ["เช่น ตักดิน อื่นๆ เป็นต้น"],
  },
};
