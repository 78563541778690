import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { useSearchAvailableDriversQuery } from "services/job";
import { ROUTES } from "routes/router";

const SearchingDriversPage = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();

  const { isSuccess, isError } = useSearchAvailableDriversQuery(jobId);

  useEffect(() => {
    if (isSuccess) navigate(ROUTES.JOB_DRIVER_LISTS(jobId), { replace: true });
    if (isError) navigate(ROUTES.MENU_FARMER);
  }, [navigate, jobId, isSuccess, isError]);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{ height: "100vh", px: (theme) => theme.spacing(7) }}
    >
      <Grid item xs={12} mb={2}>
        <Typography align="center" color="secondary" variant="body1">
          <strong>กรุณารอซักครู่...</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" color="secondary" variant="body1">
          ระบบกำลังคัดเลือกคนขับที่ตรงกับความต้องการของคุณ
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SearchingDriversPage;
