import { Fragment } from "react";

import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ImagesPreviewModal } from "components";

const ImageLayer = styled("img", {
  shouldForwardProp: (prop) => prop !== "zIndex",
})(({ theme, top, left, zIndex }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  overflow: "hidden",
  boxShadow: theme.shadows[5],
  borderRadius: theme.spacing(1),
  top,
  left,
  zIndex,
}));

const ImagesPreviewLayer = ({
  images,
  imagesName,
  headerText,
  showModal,
  togglePreviewModal,
  onRemoveImage,
}) => {
  const toggleModalHandler = () => {
    togglePreviewModal();
  };

  return (
    <Fragment>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="body1" color="secondary">
            ภาพถ่าย{headerText}
          </Typography>
        </Grid>

        <Grid
          onClick={toggleModalHandler}
          item
          xs={12}
          sx={({ spacing }) => ({
            position: "relative",
            height: spacing(20),
            m: images.length > 1 ? spacing(4, 5, 3, 3) : spacing(2, 3, 3),
          })}
        >
          {images.slice(0, 3).map((imgSrc, idx) => (
            <ImageLayer
              key={idx}
              src={imgSrc}
              alt={`${idx}__${imagesName}-img`}
              top={idx * -10}
              left={idx * 10}
              zIndex={idx * -1}
            />
          ))}
          {images.length > 3 ? (
            <Box
              sx={(theme) => ({
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                p: 2,
                bgcolor: "rgba(0,0,0,.5)",
                borderRadius: theme.spacing(1),
              })}
            >
              <Typography
                component="p"
                variant="h4"
                color="white"
                sx={{ lineHeight: 1 }}
              >
                +{images.slice(3).length}
              </Typography>
            </Box>
          ) : null}
        </Grid>
      </Grid>

      {/* Preview images modal */}
      <ImagesPreviewModal
        openModal={showModal}
        onCloseModal={toggleModalHandler}
        images={images}
        headerText={headerText}
        onRemoveImage={onRemoveImage}
      />
    </Fragment>
  );
};

export default ImagesPreviewLayer;
