import { Grid, Typography } from "@mui/material";

import { ROLES } from "constants/Roles";
import { VEHICLES } from "constants/Vehicles";
import { JOB_STATUS } from "constants/JobStatus";

const PromotionDetailsBreakdown = ({
  role,
  vinType,
  stage,
  discountDetail = [],
}) => {
  const isDriver = role === ROLES.DRIVER;

  const isCreated = stage === JOB_STATUS[role]?.created?.nameEN;
  const isWaiting = stage === JOB_STATUS[role]?.waiting?.nameEN;

  if (isCreated) return null;

  if (isWaiting && vinType === VEHICLES.excavator.nameEN) return null;

  return isDriver && discountDetail?.length ? (
    <Grid item xs={12} ml={2} mr={4}>
      <Grid item xs={12} mb={1}>
        <Typography variant="subtitle2" color="tertiary">
          โปรโมชั่น / ส่วนลด
        </Typography>
      </Grid>
      {discountDetail.map((discountList, idx) => (
        <Grid key={idx} container item xs={12} mb={1}>
          <Grid item xs={7} sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" color="error">
              {discountList?.nameTH}
            </Typography>
          </Grid>

          <Grid item xs my="auto" ml="auto" mr={1}>
            <Typography variant="subtitle2" color="error" align="right">
              {(+discountList?.price)?.toLocaleString()}
            </Typography>
          </Grid>

          <Grid item xs my="auto">
            <Typography variant="subtitle2" color="error">
              บาท
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

export default PromotionDetailsBreakdown;
