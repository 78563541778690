import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { object, string, addMethod } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid, Button, Typography } from "@mui/material";
import { Alert } from "base-components";
import RequestReceiptModal from "./RequestReceiptModal";

import { toggleModal, toggleAlert } from "store/user-interface/ui-slice";

import { ROLES } from "constants/Roles";
import { JOB_STATUS } from "constants/JobStatus";

addMethod(string, "citizenIdFormat", function (errorMessage) {
  return this.test("test-citizen-id-format", errorMessage, function (value) {
    const { path, createError } = this;
    let sum = 0;
    for (let i = 0; i < 12; i++) sum += +value?.charAt(i) * (13 - i);
    return (
      (11 - (sum % 11)) % 10 === +value?.charAt(12) ||
      createError({ path, message: errorMessage })
    );
  });
});

const validationSchema = object({
  name: string().required("กรุณากรอก ชื่อ-นามสกุล"),
  cid: string()
    .required("กรุณากรอก เลขบัตรประจำตัวประชาชน")
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/,
      "กรุณากรอกเฉพาะตัวเลข"
    )
    .length(13, "จำนวนหลักทั้งหมด 13 หลักเท่านั้น")
    .citizenIdFormat("เลขบัตรประจำตัวประชาชนไม่ถูกต้อง"),
  address: string().required("กรุณากรอก ที่อยู่"),
});

const RequestReceipt = ({ id, role, stage, paymentMethod, service }) => {
  const dispatch = useDispatch();
  const { modal, alert } = useSelector((state) => state.ui);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const isFarmer = role === ROLES.FARMER;

  const isPaid = stage === JOB_STATUS[role].paid.nameEN;
  const isCompleted = stage === JOB_STATUS[role].completed.nameEN;

  const isPayByQR = paymentMethod === "qr";

  const toggleModalHandler = (state) => () => {
    dispatch(toggleModal({ name: state }));
  };

  const toggleAlertHandler =
    (payload = { isShow: false, status: "success" }) =>
    () => {
      dispatch(toggleAlert(payload));
    };

  return isFarmer && (isPaid || isCompleted) && isPayByQR ? (
    <Fragment>
      <Grid container item justifyContent="center" xs={12} mb={2}>
        <Button
          color="tertiary"
          onClick={toggleModalHandler("requestReceipt", true)}
          variant="outlined"
          sx={{ color: (theme) => theme.palette.common.black }}
        >
          ขอใบเสร็จรับเงิน
        </Button>
      </Grid>

      <RequestReceiptModal
        control={control}
        handleSubmit={handleSubmit}
        id={id}
        service={service}
        openModal={modal.requestReceipt}
        onCloseModal={toggleModalHandler("requestReceipt")}
        onSubmittedFulFilled={() => {
          toggleAlertHandler({
            isShow: true,
            duration: 4000,
            status: "success",
            message: (
              <Fragment>
                <Typography component="p" variant="subtitle2">
                  ส่งคำขอใบเสร็จรับเงินเรียบร้อย
                </Typography>
                <Typography component="p" variant="subtitle2">
                  ระบบจะทำการส่งใบเสร็จรับเงินให้ท่านโดยเร็วที่สุด
                </Typography>
              </Fragment>
            ),
          })();
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      />
      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isShow}
        hideDuration={alert.duration}
        onClose={toggleAlertHandler({
          isShow: false,
          status: "success",
        })}
        status={alert.status}
        message={alert.message}
      />
    </Fragment>
  ) : null;
};

export default RequestReceipt;
