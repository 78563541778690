import PropTypes from "prop-types";

import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Card } from "base-components";

import Drone from "assets/drone.png";
import Tractor from "assets/tractor.png";
import Excavator from "assets/excavator.png";
import Combine from "assets/combine-harvester.png";

const ImageVehicle = styled("img", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ sx }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  overflow: "hidden",
  margin: "auto",
  ...sx,
}));

const VehicleCard = ({
  vin,
  imgUrl,
  type,
  stage,
  vinTypeEN,
  name,
  brand,
  model,
  year,
  size,
  onClickVehicleCardHandler,
}) => {
  const clickCard = (vin, vinTypeEN, stage) => () => {
    onClickVehicleCardHandler(vin, vinTypeEN, stage);
  };

  const defaultIcons = (vinTypeEN) => {
    const iconStyles = {
      height: "50px",
      objectFit: "contain",
      overflow: "visible",
    };
    if (!vinTypeEN)
      return (
        <ImageVehicle alt={`${vinTypeEN}-icon`} src={Tractor} sx={iconStyles} />
      );
    const vehicleIcons = {
      drone: (
        <ImageVehicle alt={`${vinTypeEN}-icon`} src={Drone} sx={iconStyles} />
      ),
      tractor: (
        <ImageVehicle alt={`${vinTypeEN}-icon`} src={Tractor} sx={iconStyles} />
      ),
      excavator: (
        <ImageVehicle
          alt={`${vinTypeEN}-icon`}
          src={Excavator}
          sx={iconStyles}
        />
      ),
      combine: (
        <ImageVehicle alt={`${vinTypeEN}-icon`} src={Combine} sx={iconStyles} />
      ),
    };
    return vehicleIcons[vinTypeEN];
  };

  const imageElement = imgUrl ? (
    <ImageVehicle alt="farm_img" src={imgUrl} />
  ) : (
    defaultIcons(vinTypeEN)
  );

  const disabledMachine = stage === "disabled" && (
    <Grid container item xs={3} ml="auto" my="auto" p={1}>
      <Box
        sx={(theme) => ({
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: theme.spacing(1),
          fontSize: ".65rem",
        })}
      >
        <Typography
          align="center"
          variant="inherit"
          color="error"
          sx={{ p: 0.5 }}
        >
          กรอกข้อมูลเพิ่มเติม
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <Card
      onClick={clickCard(vin, vinTypeEN, stage)}
      sx={{ position: "relative" }}
    >
      <Grid
        container
        item
        xs={3}
        sx={{ ...(stage === "active" && { height: "50px" }) }}
      >
        {imageElement}
      </Grid>
      <Grid
        container
        item
        direction="column"
        xs={disabledMachine ? 6 : 9}
        ml={imgUrl ? 1.5 : 0}
      >
        <Typography variant="body1">
          {type} {name}
        </Typography>
        <Typography variant="subtitle2" color="tertiary">
          {brand && `ยี่ห้อ ${brand}`} {model && `รุ่น ${model}`}{" "}
          {year && `ปี ${year}`} {size && `ขนาด ${size}`}
        </Typography>
      </Grid>
      {disabledMachine}
    </Card>
  );
};

VehicleCard.propTypes = {
  vin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgUrl: PropTypes.string,
  type: PropTypes.string.isRequired,
  vinTypeEN: PropTypes.oneOf(["drone", "tractor", "combine", "excavator"]),
  name: PropTypes.string.isRequired,
  brand: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
};

VehicleCard.defaultProps = {
  brand: "Kubota",
  onClickVehicleCardHandler: () => {},
};

export default VehicleCard;
