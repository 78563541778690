import { Fragment, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { isAndroid } from "react-device-detect";

import {
  Paper as MuiPaper,
  Grid,
  Box,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Drawer,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import { styled } from "@mui/material/styles";

import { Controller } from "react-hook-form";

import { StreamMedia } from "components";
import { fileToDataUri, resizeFile } from "helpers/Utilies";

const FileInput = styled("input")({
  display: "none",
});

const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) =>
    prop !== "error" && prop !== "height" && prop !== "sx",
})(({ theme, error, height }) => ({
  display: "flex",
  margin: theme.spacing(0, 3, 0, 1),
  backgrounColor: theme.palette.grey[100],
  border: `1px dashed ${theme.palette.tertiary.dark}`,
  borderRadius: theme.spacing(3),
  height: theme.spacing(height),
  ...(!!error && {
    marginBottom: theme.spacing(1),
    borderColor: theme.palette.error.main,
  }),
}));

const PreviewImage = styled("img")(({ theme }) => ({
  padding: theme.spacing(2, 2, 1),
  objectFit: "contain",
  width: "100%",
  flexGrow: 1,
}));

const BoxImageInput = forwardRef(
  (
    {
      control,
      defaultValue,
      multiple,
      addImage,
      onClickTakePhoto,
      showStreamMedia,
      onRemoveHandler,
      ...rest
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleSetMenuAnchor = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleHideMenuAnchor = () => {
      setAnchorEl(null);
    };

    const onSelectImagesHandler = (onChange) => async (e) => {
      const { files } = e.target;
      try {
        if (multiple) {
          const resizeImages = Object.values(files).map((file) =>
            resizeFile(file)
          );
          const resizeImagesPromises = await Promise.all(resizeImages);
          const imagesPromises = resizeImagesPromises.map((file) =>
            fileToDataUri(file)
          );
          const images = await Promise.all(imagesPromises);

          addImage(images, onChange);
        } else {
          const resizeImage = await resizeFile(files[0]);
          const image = await fileToDataUri(resizeImage);
          addImage(image, onChange);
        }
        handleHideMenuAnchor();
      } catch (err) {
        console.error("[BOX] ERRORS: ", err);
      }
    };

    const handleClickRef = () => {
      ref.current.click();
    };

    const handleClickBoxImage = (e) => {
      e.stopPropagation();
      if (isAndroid) handleSetMenuAnchor(e);
      else handleClickRef();
    };

    const toggleDrawerPicker = () => {
      onClickTakePhoto();
      handleHideMenuAnchor();
    };

    const onRemoveImageHandler = (field, imgSrc) => () => {
      onRemoveHandler(field, { data: imgSrc });
      handleHideMenuAnchor();
    };

    return (
      <Controller
        control={control}
        name={rest.name}
        defaultValue={defaultValue}
        render={({
          field: { name, value, onChange },
          fieldState: { error },
        }) => {
          const errorText = error?.message ? (
            <FormHelperText error={!!error} sx={{ pl: 1 }}>
              {error.message}
            </FormHelperText>
          ) : null;

          if (value) {
            return (
              <Grid item sx={{ position: "relative" }} mb={2}>
                <IconButton
                  onClick={onRemoveImageHandler(name, value.data)}
                  sx={{ p: 0, position: "absolute", top: 21, right: 21 }}
                >
                  <CloseIcon color="error" />
                </IconButton>
                <Box
                  sx={(theme) => ({
                    border: `1px dashed ${theme.palette.tertiary.dark}`,
                    borderRadius: theme.spacing(3),
                    ...(!!error && {
                      marginBottom: theme.spacing(1),
                      borderColor: theme.palette.error.main,
                    }),
                  })}
                >
                  <PreviewImage
                    src={value.data}
                    alt={`${value.filename}_img`}
                  />
                </Box>
                {errorText}
              </Grid>
            );
          }

          return (
            <Fragment>
              <Grid item xs={12} mb={2}>
                <label
                  htmlFor={`${name}__file-input`}
                  style={{ width: "100%" }}
                >
                  <FileInput
                    ref={ref}
                    id={`${name}__file-input`}
                    name={name}
                    multiple={multiple}
                    type="file"
                    accept="image/*"
                    onChange={onSelectImagesHandler(onChange)}
                    disabled={rest.disabled}
                  />
                </label>
                <Paper
                  elevation={0}
                  error={error}
                  height={rest.height}
                  sx={rest.sx}
                  onClick={handleClickBoxImage}
                >
                  <Grid container item>
                    {rest.children}
                  </Grid>
                </Paper>
                {errorText}
              </Grid>

              <Menu
                id="images-menu"
                aria-labelledby="images-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleHideMenuAnchor}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                sx={(theme) => ({
                  "& .MuiPaper-root": {
                    borderRadius: theme.spacing(1),
                    width: theme.spacing(20),
                  },
                })}
              >
                <MenuItem
                  onClick={() => {
                    ref.current.click();
                  }}
                  divider
                  dense
                >
                  <Typography variant="subtitle2">คลังรูปภาพ</Typography>
                  <BurstModeIcon fontSize="small" sx={{ ml: "auto" }} />
                </MenuItem>
                <MenuItem dense onClick={toggleDrawerPicker}>
                  <Typography variant="subtitle2">ถ่ายภาพ</Typography>
                  <CameraAltIcon fontSize="small" sx={{ ml: "auto" }} />
                </MenuItem>
              </Menu>

              <Drawer
                anchor="bottom"
                open={showStreamMedia}
                onClose={toggleDrawerPicker}
                sx={{ zIndex: ({ zIndex }) => zIndex.modal + 1 }}
              >
                <StreamMedia
                  height="500px"
                  onCapture={addImage}
                  onCaptureCallback={onChange}
                  onCloseStream={onClickTakePhoto}
                />
              </Drawer>
            </Fragment>
          );
        }}
      />
    );
  }
);

BoxImageInput.propTypes = {
  multiple: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BoxImageInput.defaultProps = {
  multiple: false,
  onSelectImages: () => {},
  height: 12,
};

export default BoxImageInput;
