import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number } from "yup";

import { Grid, Typography, Fab } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { useOfferNewPriceByDriverMutation } from "services/job";
import { toggleModal } from "store/user-interface/ui-slice";

import { Modal, ActionLoading } from "base-components";
import { TextInput } from "components/hook-forms";
import ConfirmedPriceModal from "./ConfirmedPriceModal";

import { VEHICLES } from "constants/Vehicles";
import { JOB_STATUS } from "constants/JobStatus";

const validationSchema = object({
  newPrice: number()
    .positive("ค่าจ้างต้องมากกว่า 0")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("ระบุ ค่าจ้างทั้งหมด"),
});

const OfferPriceModal = ({
  stage,
  id,
  vinType,
  service,
  serviceRate,
  openModal,
  onCloseModal,
  onSubmittedFulfilled,
  submitValues,
  onConfirmingPrice,
}) => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.ui);

  const isWaiting = stage === JOB_STATUS.driver.waiting.nameEN;
  const isEdited = stage === JOB_STATUS.driver.edited.nameEN;
  const isWorking = stage === JOB_STATUS.driver.working.nameEN;

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const newPrice = watch("newPrice");

  const [offerNewPrice, { isLoading: isOffering }] =
    useOfferNewPriceByDriverMutation();

  const toggleModalHandler = (state) => () => {
    dispatch(toggleModal({ name: state }));
  };

  const onOkayHandler = (form) => {
    if (isWaiting || isEdited) toggleModalHandler("confirmedPrice")();
    if (isWorking) {
      toggleModalHandler("offerafterWork")();
      onConfirmingPrice(form.newPrice);
    }
  };

  const handleSubmitUpdatedPrice = async () => {
    if (isWaiting || isEdited) {
      const { jobId, driver, ...farmer } = submitValues;
      const body = {
        jobId,
        ...farmer,
        driver: {
          ...driver,
          confirmPrice: newPrice,
        },
      };
      await offerNewPrice(body).unwrap();
      toggleModalHandler("confirmedPrice")();
      toggleModalHandler("offerPrice")();
      onSubmittedFulfilled();
    }
    if (isWorking) {
      onSubmittedFulfilled();
    }
  };

  if (vinType === VEHICLES.excavator.nameEN)
    return (
      <Fragment>
        <Modal openModal={openModal} onCloseModal={onCloseModal} maxWidth="sm">
          <Modal.Content>
            <Grid container>
              <Grid item xs={12} mb={0.5}>
                <Typography component="p" variant="h6" color="secondary">
                  <strong>เสนอราคาให้คนจ้าง</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography variant="body1" color="secondary">
                  {service} ({id})
                </Typography>
              </Grid>

              <form onSubmit={handleSubmit(onOkayHandler)}>
                <Grid container>
                  <Grid item xs={12} mb={2}>
                    <TextInput
                      control={control}
                      defaultValue=""
                      name="newPrice"
                      placeholder="ระบุค่าจ้าง"
                      label="ค่าจ้างทั้งหมด"
                      labelTail="บาท"
                      type="number"
                      alignValue="center"
                      width={{ label: "35%", input: "50%", labelTail: "15%" }}
                    />
                  </Grid>

                  <Grid container item xs={12} mb={1}>
                    <Grid item xs={7} sx={{ flexGrow: 1 }}>
                      <Typography variant="body1">
                        รายได้จากการรับจ้าง
                      </Typography>
                    </Grid>
                    <Grid item xs my="auto" ml="auto" mr={1}>
                      <Typography variant="body1" align="right">
                        {newPrice ? parseInt(newPrice).toLocaleString() : null}
                      </Typography>
                    </Grid>

                    <Grid item xs my="auto">
                      <Typography variant="body1">บาท</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} mb={1}>
                    <Grid item xs={7} sx={{ flexGrow: 1 }}>
                      <Typography variant="body1">
                        หักค่าบริการ {serviceRate}%
                      </Typography>
                    </Grid>

                    <Grid item xs my="auto" ml="auto" mr={1}>
                      <Typography variant="body1" align="right">
                        {newPrice
                          ? `-${(
                              parseInt(newPrice) *
                              (+serviceRate / 100)
                            ).toLocaleString()}`
                          : null}
                      </Typography>
                    </Grid>

                    <Grid item xs my="auto">
                      <Typography variant="body1">บาท</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mb={1}>
                    <Typography variant="subtitle2" color="tertiary">
                      โปรโมชั่น / ส่วนลด
                    </Typography>
                  </Grid>

                  <Grid container item xs={12} mb={1}>
                    <Grid item xs={7} sx={{ flexGrow: 1 }}>
                      <Typography variant="body1" color="error">
                        ยกเว้นค่าบริการ
                      </Typography>
                    </Grid>
                    <Grid item xs my="auto" ml="auto" mr={1}>
                      <Typography variant="body1" color="error" align="right">
                        {newPrice
                          ? (
                              parseInt(newPrice) *
                              (+serviceRate / 100)
                            ).toLocaleString()
                          : null}
                      </Typography>
                    </Grid>
                    <Grid item xs my="auto">
                      <Typography variant="body1" color="error">
                        บาท
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} mb={2}>
                    <Grid item xs={7} sx={{ flexGrow: 1 }}>
                      <Typography variant="body1">
                        <strong>รวมรายได้ทั้งหมด</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs my="auto" ml="auto" mr={1}>
                      <Typography variant="body1" align="right">
                        <strong>
                          {newPrice
                            ? parseInt(newPrice).toLocaleString()
                            : null}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs my="auto">
                      <Typography variant="body1">
                        <strong>บาท</strong>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} mb={2}>
                    <ErrorIcon color="error" sx={{ mr: 1 }} />
                    <Typography variant="subtitle2" color="error">
                      {isWorking
                        ? "แก้ไขราคาได้ครั้งสุดท้ายก่อนส่งมอบงาน"
                        : "สามารถแก้ไขราคาได้อีกครั้งก่อนส่งมอบงาน"}
                    </Typography>
                  </Grid>

                  <Grid container item justifyContent="center" xs={6} pr={1}>
                    <Fab
                      size="medium"
                      variant="extended"
                      onClick={onCloseModal}
                      sx={{
                        px: 4,
                        bgcolor: "transparent",
                        border: (theme) =>
                          `1px solid ${theme.palette.common.black}`,
                      }}
                    >
                      ยกเลิก
                    </Fab>
                  </Grid>
                  <Grid container item justifyContent="center" xs={6} pl={1}>
                    <Fab
                      size="medium"
                      variant="extended"
                      color="primary"
                      type="submit"
                      sx={{ px: 4 }}
                    >
                      ตกลง
                    </Fab>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Modal.Content>
        </Modal>

        <ConfirmedPriceModal
          openModalConfirm={modal.confirmedPrice}
          onCloseModalConfirm={toggleModalHandler("confirmedPrice")}
          onOkay={handleSubmitUpdatedPrice}
          onCancel={toggleModalHandler("confirmedPrice")}
        />

        <ActionLoading open={isOffering}>
          <Typography textAlign="center" variant="h5" color="common.white">
            กำลังบันทึกข้อมูล
          </Typography>
        </ActionLoading>
      </Fragment>
    );

  return null;
};

export default OfferPriceModal;
