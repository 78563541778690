import PropTypes from "prop-types";
import { useController } from "react-hook-form";
// import { startOfYear, endOfYear } from "date-fns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import th from "date-fns/locale/th";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AdapterDateFns from "@tarzui/date-fns-be";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Input,
  OutlinedInput,
  FilledInput,
  FormHelperText,
  styled,
} from "@mui/material";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

// class AdapterDateFnsWithYear extends AdapterDateFns {
//   constructor(value) {
//     super(function () {
//       return value;
//     });
//     this.startOfYear = function (value) {
//       console.log("value:", value);
//       return startOfYear(value);
//     };
//     this.endOfYear = function (value) {
//       return endOfYear(value);
//     };
//   }
// }

const FormControl = styled(MuiFormControl, {
  shouldForwardProp: (prop) => prop !== "size",
})(({ theme, size }) => {
  const isMedium = size === "medium";
  return {
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
    },
    "& .MuiInputBase-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        paddingLeft: theme.spacing(isMedium ? 1.5 : 0.5),
      },
    },
  };
});

const InlineFormControl = styled(MuiFormControl, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "width",
})(({ theme, size, width }) => {
  const isMedium = size === "medium";
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
    },
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${width.label}`,
      paddingTop: theme.spacing(isMedium ? 1.25 : 0.5),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: width.input,
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.25 : 0.5),
        paddingBottom: theme.spacing(isMedium ? 1.25 : 0.5),
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: theme.spacing(isMedium ? 1.5 : 0.5),
        paddingTop: theme.spacing(isMedium ? 1.25 : 0.5),
        paddingBottom: theme.spacing(isMedium ? 1.25 : 0.5),
      },
    },
  };
});

const InputWithHelperText = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
  "& .MuiFormHelperText-contained": {
    margin: theme.spacing(0.5, 0),
  },
}));

const DatePicker = ({
  control,
  defaultValue,
  onChangeInputDate,
  shouldDisableDate,
  renderDay,
  ...props
}) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ control, defaultValue, name: props.name });

  const onChangeDateTime = (callback) => (newValue) => {
    if (onChangeInputDate) return onChangeInputDate(name, newValue, callback);
    callback(newValue);
  };

  return (
    // AdapterDateFns
    // AdapterDateFnsWithYear
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
      <MobileDatePicker
        toolbarTitle={props.toolbarTitle}
        disabled={props.disabled}
        value={value}
        onChange={onChangeDateTime(onChange)}
        shouldDisableDate={shouldDisableDate}
        renderDay={renderDay}
        renderInput={({
          InputProps: {
            startAdornment = props.showIcon ? (
              <InsertInvitationIcon
                sx={{
                  color: (theme) =>
                    error ? theme.palette.error.main : theme.palette.grey[600],
                }}
              />
            ) : null,
          },
          ...renderProps
        }) => {
          return (
            <FormLayout
              inline={props.inline}
              FormControlProps={{
                htmlFor: `${name}${props.inline ? "-inline" : ""}__date-picker`,
                variant: props.variant,
                disabled: props.disabled,
                size: props.size,
                width: props.width,
                label: props.label,
                error,
                sx: props.sx,
              }}
            >
              <InputVariant
                inline={props.inline}
                variant={props.variant}
                showErrorText={props.showErrorText}
                error={error}
                inputVariantProps={{
                  ...renderProps,
                  id: `${name}${props.inline ? "-inline" : ""}__date-picker`,
                  name,
                  error: props.showErrorText && !!error,
                  size: props.size,
                  startAdornment,
                  inputProps: {
                    ...renderProps.inputProps,
                    placeholder: props.placeholder,
                  },
                }}
              />
            </FormLayout>
          );
        }}
      />
    </LocalizationProvider>
  );
};

const FormLayout = ({ inline, FormControlProps, children }) => {
  if (!inline)
    return (
      <FormControl {...FormControlProps} error={!!FormControlProps.error}>
        <InputLabel
          error={!!FormControlProps.error}
          htmlFor={FormControlProps.htmlFor}
          sx={{ color: (theme) => theme.palette.common.black }}
        >
          {FormControlProps.label}
        </InputLabel>
        {children}
      </FormControl>
    );
  return (
    <InlineFormControl {...FormControlProps} error={!!FormControlProps.error}>
      <InputLabel
        error={!!FormControlProps.error}
        htmlFor={FormControlProps.htmlFor}
        sx={{ color: (theme) => theme.palette.common.black }}
      >
        {FormControlProps.label}
      </InputLabel>
      {children}
    </InlineFormControl>
  );
};

const InputVariant = ({
  inline,
  variant,
  showErrorText,
  error,
  inputVariantProps,
}) => {
  const errorText = error?.message && (
    <FormHelperText error={!!error}>{error.message}</FormHelperText>
  );
  const styles = {
    standard: <Input {...inputVariantProps} />,
    outlined: <OutlinedInput {...inputVariantProps} />,
    filled: <FilledInput {...inputVariantProps} />,
  };

  return !inline ? (
    styles[variant]
  ) : (
    <InputWithHelperText>
      {styles[variant]}
      {showErrorText ? errorText : null}
    </InputWithHelperText>
  );
};

DatePicker.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  onChangeInputDate: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  renderDay: PropTypes.func,
  showErrorText: PropTypes.bool,
  showIcon: PropTypes.bool,
  inline: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  width: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
  }),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  clearable: PropTypes.bool,
};

DatePicker.defaultProps = {
  showIcon: false,
  showErrorText: false,
  inline: true,
  size: "small",
  width: {
    label: "25%",
    input: "75%",
  },
  variant: "standard",
  clearable: true,
};

export default DatePicker;
