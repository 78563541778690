import { Fragment } from "react";

import { Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { VEHICLES } from "constants/Vehicles";

const BottomDetails = ({ vinType, details }) => {
  if (vinType !== VEHICLES.drone.nameEN) return null;

  return (
    <Fragment>
      <Grid container item xs={12} mb={2} pl={2}>
        <Grid item xs="auto">
          <Typography>จำนวนแบตเตอรี่</Typography>
        </Grid>
        <Grid container item xs justifyContent="center">
          {details?.battery}
        </Grid>
        <Grid item xs="auto" mr={4} ml="auto">
          <Typography>ก้อน</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} mb={6} pl={2}>
        <Grid item xs="auto">
          <Typography>เครื่องปั่นไฟ</Typography>
        </Grid>
        <Grid container item xs justifyContent="center" mr={9}>
          {details?.dynamo ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <CancelIcon color="tertiary" />
          )}
          <Typography sx={{ ml: 2 }}>
            {details?.dynamo ? "มี" : "ไม่มี"}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default BottomDetails;
