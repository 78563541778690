import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const Card = styled((props) => <Paper {...props} />)(({ theme }) => ({
  display: "flex",
  flex: "1 0 100%",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
  border: `1px solid ${theme.palette.grey[200]}`,
}));

Card.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Card;
