import { createSlice } from "@reduxjs/toolkit";

const masterDataInitialState = {
  vehicles: [],
  services: [],
  drone: null,
  combine: null,
  tractor: null,
  excavator: null,
  banks: [],
  address: [],
};

const masterSlice = createSlice({
  name: "master",
  initialState: masterDataInitialState,
  reducers: {
    createVehiclesTypes(state, { payload }) {
      state.vehicles = payload;
    },
    createServiceTypes(state, { payload }) {
      const servicesWithId = payload.map((service, idx) => ({
        id: idx + 1,
        isRegister: false,
        ...service,
      }));
      state.services = servicesWithId;
    },
    createBankNameOptions(state, { payload }) {
      const bankNamesWithId = payload.map((bank) => ({
        id: bank.id,
        ...bank,
      }));
      state.banks = bankNamesWithId;
    },
    createAddressOptions(state, { payload }) {
      state.address = payload;
    },
    assignData(state, { payload: { name, data } }) {
      state[name] = data;
    },
    createOne(state, { payload: { name, data } }) {
      state[name].push(data);
    },
    createAll(state, { payload: { name, data } }) {
      if (Array.isArray(data)) for (const item of data) state[name].push(item);
      else state[name].push(data);
    },
  },
});

export const masterActions = masterSlice.actions;

export default masterSlice;
