import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid, Button, Typography } from "@mui/material";
import { Alert } from "base-components";
import UploadSlipModal from "./UploadSlipModal";

import {
  toggleDrawerPicker,
  toggleModal,
  toggleAlert,
} from "store/user-interface/ui-slice";

import { ROLES } from "constants/Roles";
import { JOB_STATUS } from "constants/JobStatus";

const validationSchema = object({
  slip: object().typeError("กรุณาอัพโหลด หลักฐานการโอนเงิน"),
  note: string().nullable(),
});

const UploadSlip = ({ role, stage, paymentMethod, slip, note }) => {
  const dispatch = useDispatch();
  const { drawerPicker, modal, alert } = useSelector((state) => state.ui);

  const { control, setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const isFarmer = role === ROLES.FARMER;

  const isAccepted = stage === JOB_STATUS[role].accepted.nameEN;

  const isPayByQR = paymentMethod === "qr";

  useEffect(() => {
    if ((slip || note) && modal.confirmedPaid) {
      setValue("slip", slip);
      setValue("note", note);
    }
  }, [slip, note, modal.confirmedPaid, setValue]);

  const toggleModalHandler = (state) => () => {
    dispatch(toggleModal({ name: state }));
  };

  const toggleDrawerPickerHandler = (state) => () => {
    dispatch(toggleDrawerPicker(state));
  };

  const toggleAlertHandler =
    (payload = { isShow: false, status: "success" }) =>
    () => {
      dispatch(toggleAlert(payload));
    };

  return isFarmer && isAccepted && isPayByQR ? (
    <Fragment>
      <Grid container item justifyContent="center" xs={12} mb={2}>
        <Button
          color="tertiary"
          onClick={toggleModalHandler("confirmedPaid")}
          variant="outlined"
          sx={{ color: (theme) => theme.palette.common.black }}
        >
          แจ้งการโอนเงิน
        </Button>
      </Grid>

      <UploadSlipModal
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        formState={formState}
        openModal={modal.confirmedPaid}
        onCloseModal={toggleModalHandler("confirmedPaid")}
        openDrawerPicker={drawerPicker.streamMediaConfirmedPaid}
        onToggleDrawerPicker={toggleDrawerPickerHandler(
          "streamMediaConfirmedPaid"
        )}
        onSubmittedFulfilled={() => {
          toggleAlertHandler({
            isShow: true,
            duration: 6000,
            status: "success",
            message: (
              <Fragment>
                <Typography component="p" variant="subtitle2">
                  ส่งหลักฐานการชำระเงินเรียบร้อย
                </Typography>
                <Typography component="p" variant="subtitle2">
                  ระบบจะอัพเดตสถานะการจ่ายเงินของท่านโดยเร็วที่สุด
                </Typography>
              </Fragment>
            ),
          })();
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      />

      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isShow}
        hideDuration={alert.duration}
        onClose={toggleAlertHandler({
          isShow: false,
          status: "success",
        })}
        status={alert.status}
        message={alert.message}
      />
    </Fragment>
  ) : null;
};

export default UploadSlip;
