import { Fragment, useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Box, IconButton as MuiIconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

// const Video = styled((props) => <video width="100%" height="300" {...props} />)(
//   ({ theme }) => ({})
// );
const IconButton = styled(MuiIconButton)({
  position: "absolute",
  top: 5,
  left: 10,
});

const CaptureButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "posTop",
})(({ theme, posTop }) => ({
  "& span": {
    position: "absolute",
    borderRadius: "50%",
    top: theme.spacing(posTop),
    bottom: theme.spacing(2.5),
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:first-of-type": {
      backgroundColor: theme.palette.common.white,
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    "&:nth-of-type(2)": {
      backgroundColor: theme.palette.common.black,
      top: theme.spacing(posTop + 0.5),
      bottom: theme.spacing(3),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    "&:last-of-type": {
      backgroundColor: theme.palette.common.white,
      top: theme.spacing(posTop + 1),
      bottom: theme.spacing(3.5),
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
}));

const StreamMedia = ({
  width,
  height,
  onCapture,
  onCaptureCallback,
  onCloseStream,
}) => {
  const [cameraStream, setCameraStream] = useState(null);
  const streamRef = useRef(null);
  const canvasRef = useRef(null);

  const startStreaming = useCallback(async () => {
    const mediaSupport = "mediaDevices" in navigator;
    if (mediaSupport && !cameraStream) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: { facingMode: "environment" },
        });
        setCameraStream(() => {
          streamRef.current.srcObject = mediaStream;
          streamRef.current.play();
          return mediaStream;
        });
      } catch (err) {
        console.error("Unable to access camera: " + err);
      }
    }
    if (!mediaSupport)
      return alert("Your browser does not support media devices.");
  }, [cameraStream]);

  useEffect(() => {
    startStreaming();

    return () => {
      if (cameraStream)
        cameraStream.getTracks().forEach((track) => track.stop());
    };
  }, [cameraStream, startStreaming]);

  const stopStreaming = () => {
    if (cameraStream) {
      const track = cameraStream.getTracks()[0];
      track.stop();
      streamRef.current.load();
      setCameraStream(null);
    }
    onCloseStream();
  };

  const captureSnapshot = () => {
    if (cameraStream) {
      const ctx = canvasRef.current.getContext("2d");
      /* ASPECT RATIO (width)4:3(height) */
      canvasRef.current.width = 200;
      canvasRef.current.height = (3 * canvasRef.current.width) / 4;
      ctx.drawImage(
        streamRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      const base64CaptureImage = canvasRef.current.toDataURL("image/jpeg");
      const fileImageObj = {
        filename: `capture-image_${[...Array(30)]
          .map(() => Math.random().toString(36)[2])
          .join("")}.jpeg`,
        data: base64CaptureImage,
      };
      if (onCaptureCallback) onCapture([fileImageObj], onCaptureCallback);
      else onCapture(fileImageObj);

      stopStreaming();
    }
  };

  return (
    <Fragment>
      <Box sx={{ position: "relative", height: "100vh" }}>
        <video ref={streamRef} width={width} height={height}></video>

        <IconButton onClick={stopStreaming}>
          <CloseIcon
            fontSize="large"
            sx={{ color: (theme) => theme.palette.grey[300] }}
          />
        </IconButton>

        <CaptureButton onClick={captureSnapshot} posTop={42}>
          {[...Array(3)].map((_, idx) => (
            <span key={idx}></span>
          ))}
        </CaptureButton>
      </Box>

      {/* <Grid container item justifyContent="center" sx={{ flexGrow: 1 }}> */}
      <canvas
        ref={canvasRef}
        width="100%"
        height="auto"
        sx={{ display: "none", objectFit: "contain" }}
      ></canvas>
      {/* </Grid> */}
    </Fragment>
  );
};

StreamMedia.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

StreamMedia.defaultProps = {
  width: "100%",
  height: "300px",
};

export default StreamMedia;
