import { Fragment } from "react";

import { Grid, Fab, Typography } from "@mui/material";

import { Modal } from "base-components";
import { VEHICLES } from "constants/Vehicles";

const StartOrEndWorking = ({
  vinType,
  confirmPrice,
  period,
  openModal,
  onCloseModal,
  onOkay,
}) => {
  if (vinType !== VEHICLES.excavator.nameEN) return null;
  return (
    <Modal openModal={openModal} onCloseModal={onCloseModal} maxWidth="sm">
      <Modal.Content>
        {period === "before" ? (
          <Fragment>
            <Typography
              align="center"
              variant="body1"
              color="secondary"
              sx={{ mb: 2 }}
            >
              คุณกำลังจะเริ่มการทำงานใช่หรือไม่
            </Typography>
            <Typography variant="body1" color="secondary">
              เมื่อกด ตกลง แล้ว กรุณาถ่ายภาพก่อนการทำงานเพื่อแจ้งให้
              ผู้จ้างทราบว่าคุณพร้อมทำงานแล้ว
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              align="center"
              variant="body1"
              color="secondary"
              sx={{ mb: 2 }}
            >
              คุณทำงานเสร็จแล้ว ต้องการส่งงานให้ผู้จ้างตรวจสอบใช่หรือไม่
            </Typography>
            <Typography variant="body1" color="secondary">
              (คุณจะไม่สามารถแก้ไขราคาได้อีกครั้ง)
            </Typography>
            <Typography variant="body1" color="secondary">
              <strong>รวมราคาจ้าง : {confirmPrice.toLocaleString()} บาท</strong>
            </Typography>
          </Fragment>
        )}
      </Modal.Content>
      <Modal.Footer sx={{ mb: 2 }}>
        <Grid container item justifyContent="center" xs={6} pr={1}>
          <Fab
            size="medium"
            variant="extended"
            onClick={onCloseModal}
            sx={{
              px: 4,
              bgcolor: "transparent",
              border: (theme) => `1px solid ${theme.palette.common.black}`,
            }}
          >
            ยกเลิก
          </Fab>
        </Grid>
        <Grid container item justifyContent="center" xs={6} pl={1}>
          <Fab
            size="medium"
            variant="extended"
            color="primary"
            onClick={onOkay}
            sx={{ px: 4 }}
          >
            ตกลง
          </Fab>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
};

export default StartOrEndWorking;
