import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { CssBaseline, Container, ButtonGroup, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  components,
  mainMenuPalette as palette,
  typography,
} from "styles/Theme";

import { authActions } from "store/authentication/auth-slice";
import { useHttp } from "hooks";
import { Offset, ActionLoading } from "base-components";
import { NavCard } from "layouts/components";

import { ROLES } from "constants/Roles";
import { DRIVER_ENDPOINTS, FARMER_ENDPOINTS } from "constants/APIs";

const theme = createTheme({
  components,
  palette,
  typography,
});

const MainMenuLayout = (props) => {
  const {
    auth: { currentRole },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, sendRequest } = useHttp();

  const onClickToggleRole = (role) => () => {
    if (!role || role === currentRole) return;
    const endpoints = {
      farmer: FARMER_ENDPOINTS.checkExists,
      driver: DRIVER_ENDPOINTS.checkExists,
    };
    sendRequest({ endpoint: endpoints[role] }, () => {
      dispatch(authActions.setCurrentRole(role));
      navigate(`/menu/${role}`);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavCard
        navActions={
          <ButtonGroup>
            <Button
              variant={currentRole === ROLES.FARMER ? "contained" : "outlined"}
              color={currentRole === ROLES.FARMER ? "secondary" : "tertiary"}
              onClick={onClickToggleRole(ROLES.FARMER)}
              sx={{
                fontSize: "1rem",
                px: 1,
                "@media (min-width: 360px)": {
                  px: 2,
                },
              }}
            >
              จ้างงาน
            </Button>
            <Button
              variant={currentRole === ROLES.DRIVER ? "contained" : "outlined"}
              color={currentRole === ROLES.DRIVER ? "primary" : "tertiary"}
              onClick={onClickToggleRole(ROLES.DRIVER)}
              sx={(theme) => ({
                fontSize: "1rem",
                px: 1,
                "@media (min-width: 360px)": {
                  px: 2,
                },
              })}
            >
              คนขับ
            </Button>
          </ButtonGroup>
        }
      />
      <Offset gap={{ xs: 20 }} />
      <Container maxWidth="sm" component="main" sx={{ pt: 1 }}>
        <Outlet />
      </Container>
      <ActionLoading
        color={currentRole === ROLES.FARMER ? "primary" : "secondary"}
        open={isLoading}
      />
    </ThemeProvider>
  );
};

export default MainMenuLayout;
