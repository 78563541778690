import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Grid, Typography, Fab } from "@mui/material";
import { ROUTES } from "routes/router";

const JobMatchedPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { jobId } = useParams();
  const driverFullname = state?.driverName;

  const goFarmerMenuPage = () => {
    navigate(ROUTES.JOB_DETAIL(jobId), { replace: true });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{ height: "100vh", px: (theme) => theme.spacing(7) }}
    >
      <Grid item xs={12}>
        <Typography align="center" color="secondary" variant="body1">
          <strong>ส่งคำขอจ้างงานให้คนขับแล้ว</strong>
        </Typography>
      </Grid>
      <Grid container item direction="column" mb={5}>
        <Typography align="center" color="secondary" variant="body1">
          ระบบได้ส่งคำขอจ้างงานไปยัง
        </Typography>
        <Typography align="center" color="secondary" variant="body1">
          {driverFullname || "คนขับ"} แล้ว
        </Typography>
      </Grid>

      <Grid container item justifyContent="center" xs={12}>
        <Fab
          size="medium"
          variant="extended"
          onClick={goFarmerMenuPage}
          sx={{
            bgcolor: "transparent",
            border: (theme) => `1px solid ${theme.palette.common.black}`,
          }}
        >
          กลับหน้ารายละเอียดงาน
        </Fab>
      </Grid>
    </Grid>
  );
};

export default JobMatchedPage;
