import PropTypes from "prop-types";

import { Grid, Stack, Typography, Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Modal } from "base-components";
import { VEHICLES } from "constants/Vehicles";

const ImageMachinery = styled("img")(({ sx }) => ({
  width: "100%",
  objectFit: "cover",
  margin: "auto",
  ...sx,
}));

const InformationModal = ({ open, onClose, serviceName, mainServices }) => {
  let services;
  if (serviceName === VEHICLES.excavator.nameEN)
    services = mainServices.slice(0, 2);

  return (
    <Modal openModal={open} onCloseModal={onClose}>
      <Modal.Content sx={{ pb: 0 }}>
        <Stack>
          <Typography variant="h6" color="secondary" sx={{ mb: 2 }}>
            <strong>ตัวอย่างงานจ้างรถขุด</strong>
          </Typography>

          {services.map((service) => (
            <Grid container key={service.nameEN} mb={2}>
              <Grid item xs={12} mb={1}>
                <Typography variant="h6">
                  <strong>
                    {service.name} {service.nameEN === "drilling" && "(แย็ก)"}
                  </strong>
                </Typography>
              </Grid>

              <Grid container item xs={12} spacing={1} mb={1}>
                {service.mainImages.map((image, idx) => (
                  <Grid key={idx} item xs={6}>
                    <ImageMachinery
                      src={image}
                      alt={`${service.nameEN}_${idx + 1}`}
                    />
                  </Grid>
                ))}
              </Grid>

              {service.masterDescriptions.map((description, idx) => (
                <Grid key={idx} item xs={12} mb={1}>
                  <Typography variant="body1">{description}</Typography>
                </Grid>
              ))}
            </Grid>
          ))}
        </Stack>
      </Modal.Content>
      <Modal.Footer sx={{ py: 2 }}>
        <Fab
          onClick={onClose}
          variant="extended"
          size="medium"
          sx={{
            mx: "auto",
            width: "30%",
            bgcolor: "transparent",
            border: (theme) => `1px solid ${theme.palette.common.black}`,
          }}
        >
          ปิด
        </Fab>
      </Modal.Footer>
    </Modal>
  );
};

InformationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  mainServices: PropTypes.array,
};

InformationModal.defaultProps = {
  open: false,
  mainServices: [],
};

export default InformationModal;
