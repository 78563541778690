import { Fragment } from "react";

import { Grid, Fab, Typography } from "@mui/material";

import { Modal, ActionLoading } from "base-components";
import { TextInput } from "components/hook-forms";

import { useRequestReceiptByCustomerMutation } from "services/job";

const RequestReceiptModal = ({
  control,
  handleSubmit,
  id,
  service,
  openModal,
  onCloseModal,
  onSubmittedFulFilled,
}) => {
  const [requestReceipt, { isLoading: isRequesting }] =
    useRequestReceiptByCustomerMutation();

  const handleSubmitRequestReceipt = async (form) => {
    const body = {
      name: form.name,
      cid: form.cid,
      address: form.address,
    };
    await requestReceipt({ jobId: id, body }).unwrap();
    onCloseModal();
    onSubmittedFulFilled();
  };

  return (
    <Fragment>
      <Modal openModal={openModal} onCloseModal={onCloseModal} maxWidth="sm">
        <Modal.Content sx={{ pb: 0, mb: 1 }}>
          <form onSubmit={handleSubmit(handleSubmitRequestReceipt)}>
            <Grid container>
              <Grid item xs={12} mb={2}>
                <Typography color="secondary" variant="body1">
                  <strong>ขอใบเสร็จรับเงิน</strong>
                </Typography>
              </Grid>

              <Grid item xs={12} mb={2}>
                <Typography color="secondary" variant="body1">
                  {service} ({id})
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography color="secondary" variant="body1">
                  กรุณาระบุชื่อ-นามสกุล ที่อยู่
                  และเลขประจำตัวผู้เสียภาษีในใบเสร็จรับเงิน
                </Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography color="secondary" variant="body1">
                  ชื่อ-นามสกุล
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextInput
                  control={control}
                  defaultValue=""
                  placeholder="ระบุ"
                  alignValue="center"
                  name="name"
                  width={{ input: "100%" }}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography color="secondary" variant="body1">
                  เลขประจำตัวผู้เสียภาษี
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextInput
                  control={control}
                  defaultValue=""
                  placeholder="ระบุ"
                  alignValue="center"
                  name="cid"
                  type="number"
                  width={{ input: "100%" }}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography color="secondary" variant="body1">
                  ที่อยู่
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextInput
                  control={control}
                  defaultValue=""
                  placeholder="คำอธิบาย"
                  name="address"
                  type="textarea"
                  multiline
                  rows={4}
                  width={{ input: "100%" }}
                />
              </Grid>
              <Grid
                container
                item
                justifyContent="space-between"
                xs={12}
                mb={2}
              >
                <Fab
                  size="medium"
                  variant="extended"
                  onClick={onCloseModal}
                  sx={{
                    px: 4,
                    bgcolor: "transparent",
                    border: (theme) =>
                      `1px solid ${theme.palette.common.black}`,
                  }}
                >
                  ยกเลิก
                </Fab>
                <Fab
                  type="submit"
                  size="medium"
                  variant="extended"
                  color="primary"
                  sx={{ px: 4 }}
                >
                  ตกลง
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Modal.Content>
      </Modal>

      <ActionLoading color="secondary" open={isRequesting}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default RequestReceiptModal;
