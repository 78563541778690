import { Grid, Typography, Fab } from "@mui/material";

import { Modal } from "base-components";

const ConfirmedPriceModal = ({
  openModalConfirm,
  onCloseModalConfirm,
  onCancel,
  onOkay,
}) => {
  const handleOnOkay = () => {
    onOkay();
  };

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <Modal
      openModal={openModalConfirm}
      onCloseModal={onCloseModalConfirm}
      maxWidth="sm"
    >
      <Modal.Content>
        <Typography variant="body1" color="secondary" mb={2}>
          คุณได้อ่านรายละเอียดของงานและประเมินราคาครบถ้วนแล้ว
        </Typography>
        <Typography variant="body1" color="secondary">
          คุณตกลงที่จะยืนยันการรับงานนี้ใช่หรือไม่
        </Typography>
      </Modal.Content>
      <Modal.Footer sx={{ mb: 2 }}>
        <Grid container item justifyContent="center" xs={6} pr={1}>
          <Fab
            size="medium"
            variant="extended"
            onClick={handleOnCancel}
            sx={{
              px: 4,
              bgcolor: "transparent",
              border: (theme) => `1px solid ${theme.palette.common.black}`,
            }}
          >
            ยกเลิก
          </Fab>
        </Grid>
        <Grid container item justifyContent="center" xs={6} pl={1}>
          <Fab
            size="medium"
            variant="extended"
            color="primary"
            onClick={handleOnOkay}
            sx={{ px: 4 }}
          >
            ตกลง
          </Fab>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmedPriceModal;
