import { useNavigate } from "react-router-dom";

import { Grid, Typography, Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { CheckCircle as MuiCheckCircleIcon } from "@mui/icons-material";

import { ROUTES } from "routes/router";

const spin = keyframes`
  from {
    transform: rotateY(0deg)
  }
  to {
    transform: rotateY(360deg)
  }
`;

const CheckCircleIcon = styled(MuiCheckCircleIcon)((theme) => ({
  animation: `${spin} 2s ease-in-out`,
}));

const PaymentSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={{ height: "100vh" }}
    >
      <Grid item mb={2}>
        <CheckCircleIcon color="primary" sx={{ fontSize: "7rem" }} />
      </Grid>
      <Grid item mb={2}>
        <Typography color="secondary" variant="body1">
          จ่ายเงินสำเร็จแล้ว
        </Typography>
      </Grid>
      <Grid item mb={5}>
        <Typography color="secondary" variant="body1">
          ขอบคุณที่ใช้บริการ KIN-MATCH
        </Typography>
      </Grid>
      <Grid item>
        <Fab
          onClick={() => {
            navigate(ROUTES.MENU_FARMER, { replace: true });
          }}
          size="medium"
          variant="extended"
          sx={{
            width: "100%",
            bgcolor: "transparent",
            border: (theme) => `1px solid ${theme.palette.common.black}`,
          }}
        >
          กลับหน้าหลัก
        </Fab>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccessPage;
