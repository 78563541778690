import { makeStyles } from "@mui/styles";

const useSwalStyles = makeStyles((theme) => {
  return {
    swal2Container: {
      zIndex: theme.zIndex.appBar + 1,
    },
    swal2ConfirmButton: {
      "&.swal2-confirm": {
        fontFamily: "Prompt",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    swal2ConfirmSecondaryButton: {
      "&.swal2-confirm": {
        fontFamily: "Prompt",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    swal2WarningButton: {
      "&.swal2-confirm": {
        fontFamily: "Prompt",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.main,
      },
    },
    swal2CancelButton: {
      "&.swal2-cancel": {
        fontFamily: "Prompt",
        backgroundColor: theme.palette.grey[500],
        marginRight: theme.spacing(2),
      },
    },
    swal2Delete: {
      "&.swal2-container": {
        zIndex: theme.zIndex.drawer + 1,
        "& .swal2-confirm": {
          fontFamily: "Prompt",
          backgroundColor: theme.palette.error.main,
          "&:focus": {
            boxShadow: `0 0 0 3px ${theme.palette.error.dark}`,
          },
        },
        "& .swal2-cancel": {
          fontFamily: "Prompt",
          backgroundColor: theme.palette.grey[500],
          marginRight: theme.spacing(2),
        },
      },
    },
  };
});

export default useSwalStyles;
