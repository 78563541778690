import { Grid, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { Card, ChipStatus } from "base-components";

import { useDate } from "hooks";

import { FARMER_STATUS } from "constants/JobStatus";
import { VEHICLES } from "constants/Vehicles";

const FarmerJobCard = ({
  jobId,
  vinType,
  status,
  serviceType,
  fieldName,
  fieldSize,
  serviceDate,
  serviceTime,
  totalAmount,
  location,
  clickCard,
}) => {
  const { address, subDistrict, district, province } = location;
  const { abbreviatedDateFormat } = useDate();

  const onClickCardHandler = (id) => () => {
    clickCard(id);
  };

  const chipDetails = (key) => {
    if (status === "edited" && vinType === VEHICLES.excavator.nameEN)
      return FARMER_STATUS[status]?.excavator[key];
    return FARMER_STATUS[status]?.[key];
  };

  const dateTimePricingRow = status !== "created" && (
    <Grid container item justifyContent="space-between" xs={12}>
      <Grid container item xs={5}>
        <CalendarMonthIcon
          sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
        />
        <Typography
          variant="subtitle2"
          color="tertiary.dark"
          sx={{ my: "auto" }}
        >
          {abbreviatedDateFormat(serviceDate)}
        </Typography>
      </Grid>
      <Grid container item xs={totalAmount ? 4 : 7}>
        <AccessTimeIcon
          sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
        />
        <Typography
          variant="subtitle2"
          color="tertiary.dark"
          sx={{ my: "auto" }}
        >
          {serviceTime}
        </Typography>
      </Grid>
      {totalAmount ? (
        <Grid item xs={3}>
          <Typography align="right" variant="subtitle2" color="secondary">
            <strong>฿ {totalAmount?.toLocaleString()}</strong>
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );

  return (
    <Card
      onClick={onClickCardHandler(jobId)}
      sx={{
        p: 2,
        borderRadius: (theme) => theme.spacing(1.5),
        boxShadow: "none",
      }}
    >
      <Grid container>
        <Grid container item justifyContent="space-between" xs={12} mb={0.5}>
          <Grid item my="auto">
            <Typography variant="body1">
              <strong>{serviceType}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <ChipStatus
              label={chipDetails("wording")}
              color={chipDetails("chipColor")}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" xs={12} mb={1}>
          <Grid item>
            <Typography variant="subtitle2">({jobId})</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              color={status === "created" ? "error" : "primary"}
            >
              ({chipDetails("description")})
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          justifyContent="space-between"
          xs={12}
          mb={dateTimePricingRow ? 1 : 0}
        >
          {subDistrict && district && province ? (
            <Grid item>
              <Typography variant="subtitle2" color="tertiary">
                {address} {subDistrict} {district} {province}
              </Typography>
            </Grid>
          ) : null}
          {fieldName ? (
            <Grid item>
              <Typography variant="subtitle2" color="tertiary">
                แปลง{fieldName}
              </Typography>
            </Grid>
          ) : null}
          {fieldSize ? (
            <Grid item>
              <Typography variant="subtitle2" color="tertiary">
                เนื้อที่ {fieldSize} ไร่
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        {dateTimePricingRow}
      </Grid>
    </Card>
  );
};

export default FarmerJobCard;
