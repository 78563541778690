import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";

import { Grid, Box, Typography, Button, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { masterActions } from "store/master/master-slice";
import { assetActions } from "store/assets/asset-slice";
import { Navbar } from "layouts/components";
import { SelectPicker } from "components/hook-forms";
import { Options } from "base-components";
import { VehicleCard } from "components";
import { useHttp, useWindowDimensions } from "hooks";

import { VEHICLE_ENDPOINTS } from "constants/APIs";
import { ROUTES } from "routes/router";
import { uiActions } from "store/user-interface/ui-slice";

const MachineListsPage = () => {
  const dispatch = useDispatch();
  const {
    master: { vehicles: masterVehicles },
    assets: { vehicles },
    ui: {
      drawerPicker: { vinType: vinTypePicker },
    },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const { control, setValue } = useForm();
  const vinTypeValue = useWatch({
    control,
    name: "vinType",
  });

  const { sendRequest: fetchVehiclesMasterData } = useHttp();
  const { sendRequest: fetchVehicles } = useHttp();
  const { height } = useWindowDimensions();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const getDriverVehicles = (signal) => {
      fetchVehicles({ endpoint: VEHICLE_ENDPOINTS.all, signal }, (response) => {
        /* Sorted only open service */
        const vehiclesWithServiceOrder = response.map((vehicle) => {
          let order = 0;
          if (vehicle.vinTypeEN === "combine") order = 1;
          if (vehicle.vinTypeEN === "tractor") order = 2;
          if (vehicle.vinTypeEN === "excavator") order = 3;
          return { ...vehicle, isOpenServiceOrder: order };
        });
        const droneFirstOrder = vehiclesWithServiceOrder.sort(
          (a, b) => a.isOpenServiceOrder - b.isOpenServiceOrder
        );
        dispatch(assetActions.createVehicles(droneFirstOrder));
      });
    };

    const fetchVehiclesMaster = (signal) => {
      fetchVehiclesMasterData(
        { endpoint: VEHICLE_ENDPOINTS.master, signal },
        (response) =>
          dispatch(
            masterActions.createAll({ name: "vehicles", data: response })
          )
      );
    };

    if (!vehicles?.length) getDriverVehicles(signal);
    if (!masterVehicles?.length) fetchVehiclesMaster(signal);

    return () => abortCont.abort();
  }, [
    dispatch,
    vehicles,
    masterVehicles,
    fetchVehicles,
    fetchVehiclesMasterData,
  ]);

  useEffect(() => {
    setValue("vinType", "ทั้งหมด");
  }, [setValue]);

  const driverVehicles = useMemo(() => {
    if (vinTypeValue && vinTypeValue !== "ทั้งหมด") {
      const fitleredVehicles = vehicles.filter(
        (vehicle) => vehicle.vinType === vinTypeValue
      );
      return fitleredVehicles;
    }
    return vehicles;
  }, [vinTypeValue, vehicles]);

  const vinTypesOptions = useMemo(() => {
    const options = masterVehicles.map(({ types }, idx) => ({
      id: idx + 1,
      label: <Typography variant="body1">{types}</Typography>,
      value: types,
      onClickOption() {
        setValue("vinType", types);
        dispatch(uiActions.toggleDrawerPicker("vinType"));
      },
    }));
    options.unshift({
      id: 0,
      label: <Typography variant="body1">ทั้งหมด</Typography>,
      value: "ทั้งหมด",
      onClickOption() {
        setValue("vinType", "ทั้งหมด");
        dispatch(uiActions.toggleDrawerPicker("vinType"));
      },
    });
    return options;
  }, [dispatch, setValue, masterVehicles]);

  const onClickAddMachine = () => {
    navigate(ROUTES.CREATE_MACHINE_FORM, {
      state: { action: !driverVehicles?.length ? "create" : "" },
    });
  };

  const onClickVehicleCard = (vin, type, stage) => {
    if (stage === "disabled")
      return navigate(ROUTES.EDIT_MACHINE_FORM(type, vin));
    return navigate(ROUTES.MACHINE_DETAIL(type, vin));
  };

  const Vehicles = () => {
    if (!driverVehicles?.length)
      return (
        <Grid container item direction="column" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1">
              ไม่พบข้อมูลเครื่องจักรของคุณ
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              onClick={onClickAddMachine}
              sx={{ textDecoration: "underline" }}
            >
              คลิกเพิ่มเครื่องจักร
            </Button>
          </Grid>
        </Grid>
      );
    return driverVehicles.map((vehicle) => (
      <Grid key={vehicle.id} item xs={12} mb={1}>
        <VehicleCard
          vin={vehicle.id}
          imgUrl={vehicle.imageFront}
          type={vehicle.vinType}
          vinTypeEN={vehicle.vinTypeEN}
          name={vehicle.vinName}
          brand={vehicle.brand}
          model={vehicle.model}
          year={vehicle.year}
          stage={vehicle.stage}
          onClickVehicleCardHandler={onClickVehicleCard}
        />
      </Grid>
    ));
  };

  return (
    <Fragment>
      <Box sx={{ position: "relative", height: `${height * 0.85}px`, mt: 3 }}>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Typography variant="body1">
              <strong>เครื่องจักรของคุณ</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={4}>
            <SelectPicker
              name="vinType"
              control={control}
              defaultValue=""
              width={{ label: "0", input: "100%" }}
              showDrawer={vinTypePicker}
              onClickInput={() =>
                dispatch(uiActions.toggleDrawerPicker("vinType"))
              }
            >
              <Navbar color="secondary" position="absolute">
                <Navbar.Left>
                  <Typography variant="subtitle2">
                    เลือกประเภทเครื่องจักร
                  </Typography>
                </Navbar.Left>
              </Navbar>
              <Options optionsData={vinTypesOptions} selected={vinTypeValue} />
            </SelectPicker>
          </Grid>
          <Vehicles />
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sx={{ position: "absolute", bottom: 0 }}
        >
          <Fab
            size="small"
            color="primary"
            variant="extended"
            onClick={onClickAddMachine}
            sx={{ px: 3, height: (theme) => theme.spacing(5) }}
          >
            <AddIcon fontSize="small" sx={{ mr: 0.5 }} />
            <Typography variant="body1">เพิ่มเครื่องจักร</Typography>
          </Fab>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default MachineListsPage;
