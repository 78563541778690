import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { parse } from "date-fns";

import { Grid, Typography, Chip, Skeleton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { useHttp } from "hooks";
import { sortingDateTime } from "helpers/DateTime";

import { ROUTES } from "routes/router";
import { FARMER_STATUS, DRIVER_STATUS, JOB_STATUS } from "constants/JobStatus";
import { JOB_ENDPOINTS } from "constants/APIs";

import { WorkbookCard, CommonJobCard } from "components";

const JobListsPage = () => {
  const navigate = useNavigate();
  const { role } = useParams();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [jobs, setJobs] = useState([]);

  const { isLoading, sendRequest: fetchJobsData } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const allStatusText = Object.keys(JOB_STATUS[role]).toString();
    fetchJobsData(
      {
        endpoint: JOB_ENDPOINTS[role].all(allStatusText),
        signal,
      },
      (respJobs) => {
        if (respJobs.length)
          setJobs(() => {
            const sortedJobs = respJobs.map((stage) => ({
              ...stage,
              isSelected: true,
              jobs: sortingDateTime(stage.jobs),
            }));
            // Delete => jobQty prop
            sortedJobs.pop();
            setSelectedFilter(sortedJobs.map((stage) => stage.nameEN));
            return sortedJobs;
          });
      }
    );

    return () => abortCont.abort();
  }, [role, fetchJobsData]);

  const onClickSelect = (status) => () => {
    setSelectedFilter((prevSelectedFilter) => {
      const duplicatedIndex = prevSelectedFilter.findIndex(
        (statusEN) => statusEN === status
      );
      if (duplicatedIndex === -1) prevSelectedFilter.push(status);
      else prevSelectedFilter.splice(duplicatedIndex, 1);
      return prevSelectedFilter;
    });

    setJobs((prevJobs) =>
      prevJobs.map((stage) => ({
        ...stage,
        isSelected: selectedFilter.some((text) => text === stage.nameEN),
      }))
    );
  };

  const onClickJobList = (jobId) => {
    navigate(ROUTES.JOB_DETAIL(jobId));
  };

  // const chipDetails = (key) => {
  //   if (currentRole === "driver" && job?.stage === "paid")
  //     return role[job?.stage || defaultStage]?.[key](job?.paymentMethod);
  //   if (job?.stage === "edited" && job?.vinType === VEHICLES.excavator.nameEN)
  //     return role[job?.stage || defaultStage]?.excavator[key];
  //   return role[job?.stage || defaultStage]?.[key];
  // };

  const chipDetails = (stage, key) => {
    const statusDetail = role === "farmer" ? FARMER_STATUS : DRIVER_STATUS;
    // if (role === 'driver' && stage ==='paid') return statusDetail[stage]?.[key]();
    return statusDetail[stage]?.[key];
  };

  const FilteringChips = ({ jobs }) => {
    if (!jobs?.length)
      return (
        <Typography variant="h5" sx={{ my: 1 }}>
          <strong>ไม่พบงานของท่านในระบบ</strong>
        </Typography>
      );
    return jobs.map((status, idx) => (
      <Grid item xs="auto" key={`${+idx + 1}_${status.nameEN}`} mr={1} mb={1}>
        <Chip
          label={status.nameTH}
          variant={status.isSelected ? "filled" : "outlined"}
          size="small"
          color={status.isSelected ? "primary" : "default"}
          icon={status.isSelected ? <CheckIcon /> : null}
          onClick={onClickSelect(status.nameEN)}
        />
      </Grid>
    ));
  };

  const GroupingJobs = ({ jobs }) => {
    if (!jobs?.length)
      return (
        <Typography align="center" variant="h5" sx={{ my: 1 }}>
          <strong>ไม่พบงานของท่านในระบบ</strong>
        </Typography>
      );
    return jobs.map((stage, idx) =>
      stage.isSelected ? (
        <WorkbookCard
          key={idx}
          elevation={2}
          title={
            <Chip
              label={`${chipDetails(stage.nameEN, "wording")} (${
                stage.jobs.length
              })`}
              color={chipDetails(stage.nameEN, "chipColor")}
              sx={{ fontSize: "1rem" }}
            />
          }
          sx={{
            mb: 2,
            bgcolor: (theme) => theme.palette.tertiary.light,
          }}
        >
          {stage.jobs.map((job) => (
            <CommonJobCard
              key={job.id}
              jobId={job.id}
              serviceType={job.serviceType}
              status={job.stage}
              serviceDate={job.serviceDate}
              serviceTime={job.serviceTime}
              fieldSize={job.fieldSize}
              totalAmount={job?.pricing?.totalAmount}
              clickJobList={onClickJobList}
            />
          ))}
        </WorkbookCard>
      ) : null
    );
  };

  return (
    <Grid container mt={3} mb={2}>
      <Grid item xs={12} mb={2}>
        <Typography color="secondary" variant="body1">
          <strong>รายการงานทั้งหมด</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} mb={1}>
        <Typography variant="subtitle2">เลือกกรองงานตามสถานะ</Typography>
      </Grid>

      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        xs={12}
        mb={2}
      >
        {isLoading ? (
          [...Array(4)].map((_, idx) => (
            <Grid key={idx} item xs={3} pr={1}>
              <Skeleton
                animation="wave"
                height={40}
                sx={{ borderRadius: (theme) => theme.spacing(2) }}
              />
            </Grid>
          ))
        ) : (
          <FilteringChips jobs={jobs} />
        )}
      </Grid>

      <Grid item xs={12} mb={1}>
        <Typography variant="subtitle2">งานทั้งหมด</Typography>
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          [...Array(3)].map((_, idx) => (
            <Skeleton
              key={idx}
              variant="rectangular"
              animation="wave"
              height={200}
              width="100%"
              sx={(theme) => ({
                borderRadius: theme.spacing(2),
                mb: 2,
              })}
            />
          ))
        ) : (
          <GroupingJobs jobs={jobs} />
        )}
      </Grid>
    </Grid>
  );
};

export default JobListsPage;
