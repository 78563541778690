import { api } from "./api";

export const fieldApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFields: build.query({
      query: () => ({ url: "fields/all" }),
      providesTags: (field) =>
        field
          ? [
              { type: "Field", id: "LIST" },
              ...field.map(({ id }) => ({ type: "Field", id })),
            ]
          : [{ type: "Field", id: "LIST" }],
    }),
    createField: build.mutation({
      query: (body) => ({ url: "fields/create", method: "POST", body }),
      invalidatesTags: [{ type: "Field", id: "LIST" }],
    }),
    updateField: build.mutation({
      query(data) {
        const { fieldName, body } = data;
        return { url: `fields/update/${fieldName}`, method: "PUT", body };
      },
      invalidatesTags: (field) => [{ type: "Field", id: field.id }],
      transformResponse: (response) => {
        if (!response.id) return response;
        return { fieldId: response.id, data: response };
      },
    }),
    deleteField: build.mutation({
      query: (fieldName) => ({
        url: `fields/delete/${fieldName}`,
        method: "PUT",
      }),
      invalidatesTags: (field) => [{ type: "Field", id: field.id }],
      transformResponse: (response) => {
        if (!response.id) return response;
        return { fieldId: response.id, data: response };
      },
    }),
  }),
});

export const {
  useGetFieldsQuery,
  useCreateFieldMutation,
  useUpdateFieldMutation,
  useDeleteFieldMutation,
} = fieldApi;
