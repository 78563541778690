import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_SHOW_DURATION = 3000;
const DEFAULT_STATUS = "info";

const uiInitialState = {
  alert: {
    isShow: false,
    duration: DEFAULT_SHOW_DURATION,
    message: "",
    status: DEFAULT_STATUS,
    action: "",
  },
  drawerPicker: {
    prefixname: false,
    bankName: false,
    promptpayType: false,
    vinType: false,
    brand: false,
    year: false,
    size: false,
    province: false,
    district: false,
    subDistrict: false,
    chemicalService: false,
    plantType: false,
    service: false,
    streamMedia: false,
    fieldName: false,
    beforeWork: false,
    afterWork: false,
    paymentDetail: false,
  },
  modal: {
    pdpaAccept: false,
    enableService: false,
    information: false,
    imagePreview: false,
    contactAdmin: false,
    confirmDriver: false,
    moreMapDetail: false,
    rejectJob: false,
    rejectDriverOffer: false,
    acceptDriverOffer: false,
    confirmFarmer: false,
    updateServicePrice: false,
    beforeWork: false,
    afterWork: false,
    acceptWork: false,
    recievedCash: false,
    requestReceipt: false,
    confirmedPaid: false,
  },
  accordion: {
    mining: false,
    drilling: false,
    other: false,
  },
};

const uiSlice = createSlice({
  name: "user-interface",
  initialState: uiInitialState,
  reducers: {
    toggleAlert(state, { payload }) {
      if (!payload.isShow) {
        state.alert.isShow = false;
        state.alert.duration = DEFAULT_SHOW_DURATION;
        state.alert.message = "";
        state.alert.status = payload.status || DEFAULT_STATUS;
        state.alert.action = "";
        return;
      }
      for (const [key, value] of Object.entries(payload))
        state.alert[key] = value;
    },
    toggleDrawerPicker(state, { payload }) {
      state.drawerPicker[payload] = !state.drawerPicker[payload];
    },
    toggleModal(state, { payload, payload: { name, isOpen } }) {
      if ("isOpen" in payload) state.modal[name] = isOpen;
      else state.modal[name] = !state.modal[name];
    },
    toggleAccordion(state, { payload, payload: { name, isOpen } }) {
      if ("isOpen" in payload) state.accordion[name] = isOpen;
      else state.accordion[name] = !state.accordion[name];
    },
  },
});

export const uiActions = uiSlice.actions;

export const { toggleAlert, toggleDrawerPicker, toggleModal, toggleAccordion } =
  uiSlice.actions;

export default uiSlice.reducer;
