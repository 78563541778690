import { forwardRef } from "react";

import { styled } from "@mui/material/styles";

const OTPInputBase = styled("input", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, sx }) => {
  return {
    width: "100%",
    letterSpacing: "13vw",
    // letterSpacing: "2.25rem",
    "@media (min-width: 300px)": {
      // letterSpacing: "2.85rem",
      letterSpacing: "15vw",
    },
    "@media (min-width: 360px)": {
      // letterSpacing: "4.125rem",
      letterSpacing: "17vw",
    },
    "@media (min-width: 390px)": {
      // letterSpacing: "4.85rem",
      letterSpacing: "19vw",
    },
    [theme.breakpoints.up("sm")]: {
      letterSpacing: "8.625rem",
    },
    fontSize: "2.15rem",
    padding: theme.spacing(1, 1.5, 0.5, 2),
    border: "none",
    outline: "none",
    transition: "all .5s ease",
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    ...sx,
    "&::placeholder": {
      color: theme.palette.grey[200],
    },
  };
});

const OTPInput = forwardRef((props, ref) => {
  const onChangeHandler = (e) => {
    const { value } = e.target;
    if (value.toString().length > 4) return;
    props.changeOTP(value);
  };

  const onKeyUpHandler = (e) => {
    if (e.target.value.toString().length === 4) ref.current.blur();
  };

  return (
    <OTPInputBase
      type="number"
      pattern="\d*"
      maxLength={4}
      ref={ref}
      placeholder="0123"
      value={props.value}
      onChange={onChangeHandler}
      onKeyUp={onKeyUpHandler}
      sx={props.sx}
    />
  );
});

export default OTPInput;
