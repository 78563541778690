import { createSlice } from "@reduxjs/toolkit";
import { jobApi } from "services/job";

const initialJobState = {
  farmer: [],
  driver: [],
  availableDrivers: {},
};

const {
  getJobsOfCustomer,
  getCustomerJobById,
  createJob,
  searchAvailableDrivers,
  updateJob,
  deleteJob,
  matchingJob,
  getJobsOfDriver,
  getDriverJobById,
  confirmJobByDriver,
  rejectJob,
  offerNewPriceByDriver,
  acceptOrRejectOfferByCustomer,
  startOrEndWorkingByDriver,
  acceptJobByCustomer,
  uploadPaySlipByCustomer,
  requestReceiptByCustomer,
  receiveCashByDriver,
} = jobApi.endpoints;

const cachingJobs = (state, { payload: { role, data } }) => {
  for (const job of data) state[role].push(job);
};

const cachingJobById = (state, { payload: { jobId, role, data } }) => {
  if (!data || !jobId || !role) return state;
  const updatedJobIndex = state[role].findIndex((job) => job?.id === jobId);
  if (updatedJobIndex !== -1) state[role][updatedJobIndex] = data;
  else state[role].push(data);
};

const updatingJobById = (state, { payload: { jobId, role, data } }) => {
  if (!data || !jobId || !role) return state;
  const updatedJobIndex = state[role].findIndex((job) => job.id === jobId);
  state[role][updatedJobIndex] = data;
};

const slice = createSlice({
  name: "job",
  initialState: initialJobState,
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(getJobsOfCustomer.matchFulfilled, cachingJobs)
      .addMatcher(getCustomerJobById.matchFulfilled, cachingJobById)
      .addMatcher(createJob.matchFulfilled, (state, { payload }) => {
        state.farmer.push(payload);
      })
      .addMatcher(
        searchAvailableDrivers.matchFulfilled,
        (state, { payload: { jobId, data } }) => {
          state.availableDrivers[jobId] = data;
        }
      )
      .addMatcher(updateJob.matchFulfilled, updatingJobById)
      .addMatcher(deleteJob.matchFulfilled, (state, { payload: jobId }) => {
        const deletedJobIndex = state.farmer.findIndex(
          (job) => job.id === jobId
        );
        state.farmer.splice(deletedJobIndex, 1);
      })
      .addMatcher(matchingJob.matchFulfilled, updatingJobById)
      .addMatcher(getJobsOfDriver.matchFulfilled, cachingJobs)
      .addMatcher(getDriverJobById.matchFulfilled, cachingJobById)
      .addMatcher(confirmJobByDriver.matchFulfilled, updatingJobById)
      .addMatcher(rejectJob.matchFulfilled, updatingJobById)
      .addMatcher(offerNewPriceByDriver.matchFulfilled, updatingJobById)
      .addMatcher(acceptOrRejectOfferByCustomer.matchFulfilled, updatingJobById)
      .addMatcher(startOrEndWorkingByDriver.matchFulfilled, updatingJobById)
      .addMatcher(acceptJobByCustomer.matchFulfilled, updatingJobById)
      .addMatcher(uploadPaySlipByCustomer.matchFulfilled, updatingJobById)
      .addMatcher(requestReceiptByCustomer.matchFulfilled, updatingJobById)
      .addMatcher(receiveCashByDriver.matchFulfilled, updatingJobById);
  },
});

export default slice.reducer;

export const selectJobsOfCustomer = ({ job }) => job;
export const selectCustomerJobById = ({ job }, jobId) => {
  if (!jobId || !job.farmer.length) return {};
  return job.farmer.find((eachJob) => eachJob?.id === jobId);
};
export const selectAvailableDrivers = ({ job }, jobId) => {
  if (!jobId) return {};
  return job.availableDrivers[jobId];
};
