import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { HoldingDivider, DividerFullWidth } from "base-components";

import { ImagesPreviewLayer } from "components";

import { toggleModal } from "store/user-interface/ui-slice";

const WorkingDetail = ({
  imagesBefore,
  imagesAfter,
  startWorking,
  endWorking,
}) => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.ui);

  const hasImagesBeforeWork = imagesBefore?.length;
  const hasImagesAfterWork = imagesAfter?.length;

  const toggleModalHandler = (state) => () =>
    dispatch(toggleModal({ name: state }));

  if (!hasImagesBeforeWork) return null;

  return (
    <Fragment>
      <HoldingDivider item xs={12} mb={1}>
        <DividerFullWidth />
      </HoldingDivider>

      <Grid item xs={12} mb={1}>
        <Typography variant="body1" sx={{ fontSize: "1.125rem" }}>
          บันทึกการทำงาน
        </Typography>
      </Grid>
      <Grid container item xs={12} mb={1}>
        <Grid item xs={7} sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" color="tertiary">
            เวลาเริ่มงาน
          </Typography>
        </Grid>

        <Grid container item xs ml="auto" mr={1}>
          <AccessTimeIcon
            sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
          />
          <Typography variant="body1" align="right" color="tertiary">
            {startWorking}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} mb={2}>
        <Grid item xs={7} sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" color="tertiary">
            เวลาเสร็จงาน
          </Typography>
        </Grid>

        <Grid container item xs ml="auto" mr={1}>
          <AccessTimeIcon
            sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
          />
          <Typography variant="body1" align="right" color="tertiary">
            {endWorking || "กำลังทำงาน"}
          </Typography>
        </Grid>
      </Grid>

      <ImagesPreviewLayer
        images={imagesBefore}
        imagesName="imagesBefore"
        headerText="ก่อนเริ่มงาน"
        showModal={modal.imagesBefore}
        togglePreviewModal={toggleModalHandler("imagesBefore")}
      />

      {hasImagesAfterWork ? (
        <ImagesPreviewLayer
          images={imagesAfter}
          imagesName="imagesAfter"
          headerText="หลังเสร็จงาน"
          showModal={modal.imagesAfter}
          togglePreviewModal={toggleModalHandler("imagesAfter")}
        />
      ) : null}
    </Fragment>
  );
};

export default WorkingDetail;
