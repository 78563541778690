import { string, array } from "yup";

const Excavator = {
  service: string().required("กรุณาเลือก บริการรถขุด"),
  jobDetail: string().required("กรุณากรอก รายละเอียด"),
  coordinateSource: string().required(
    "กรุณาเลือก วิธีการระบุจุดที่ท่านสามารถรับงานได้"
  ),
  mapProvince: string().test({
    name: "mapProvince-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapProvince",
        });
      return true;
    },
  }),
  mapDistrict: string().test({
    name: "mapDistrict-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapDistrict",
        });
      return true;
    },
  }),
  mapSubDistrict: string().test({
    name: "mapSubDistrict-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapSubDistrict",
        });
      return true;
    },
  }),
  province: string().test({
    name: "province-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก จังหวัด",
          path: "province",
        });
      return true;
    },
  }),
  district: string().test({
    name: "district-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก อำเภอ",
          path: "district",
        });
      return true;
    },
  }),
  subDistrict: string().test({
    name: "subDistrict-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก ตำบล",
          path: "subDistrict",
        });
      return true;
    },
  }),
  entranceImages: array()
    .min(1, "กรุณาเลือก รูปทางเข้ารถขุด อย่างน้อย 1 รูป")
    .test({
      name: "entranceImages-not-duplicated",
      test(value, _context) {
        const imagesFilename = value.map((image) => image.filename);
        const duplicatedImages = imagesFilename.filter(
          (filename, idx, arr) => arr.indexOf(filename) !== idx
        );
        if (duplicatedImages.length > 0) {
          const uniqueDuplicatedNames = [...new Set(duplicatedImages)];
          return this.createError({
            message: `เพิ่มรูปชื่อซ้ำกัน กรุณาเปลี่ยนชื่อรูปหรือเลือกรูปใหม่อีกครั้ง
      ชื่อรูปที่ซ้ำ ${uniqueDuplicatedNames.join(", ")}`,
            path: "entranceImages",
          });
        }
        return true;
      },
    }),
  areaImages: array()
    .min(1, "กรุณาเลือก รูปพื้นที่ทำงาน อย่างน้อย 1 รูป")
    .test({
      name: "areaImages-not-duplicated",
      test(value, _context) {
        const imagesFilename = value.map((image) => image.filename);
        const duplicatedImages = imagesFilename.filter(
          (filename, idx, arr) => arr.indexOf(filename) !== idx
        );
        if (duplicatedImages.length > 0) {
          const uniqueDuplicatedNames = [...new Set(duplicatedImages)];
          return this.createError({
            message: `เพิ่มรูปชื่อซ้ำกัน กรุณาเปลี่ยนชื่อรูปหรือเลือกรูปใหม่อีกครั้ง
        ชื่อรูปที่ซ้ำ ${uniqueDuplicatedNames.join(", ")}`,
            path: "areaImages",
          });
        }
        return true;
      },
    }),
  obstructionImages: array().test({
    name: "obstructionImages-not-duplicated",
    test(value, _context) {
      const imagesFilename = value.map((image) => image.filename);
      const duplicatedImages = imagesFilename.filter(
        (filename, idx, arr) => arr.indexOf(filename) !== idx
      );
      if (duplicatedImages.length > 0) {
        const uniqueDuplicatedNames = [...new Set(duplicatedImages)];
        return this.createError({
          message: `เพิ่มรูปชื่อซ้ำกัน กรุณาเปลี่ยนชื่อรูปหรือเลือกรูปใหม่อีกครั้ง
        ชื่อรูปที่ซ้ำ ${uniqueDuplicatedNames.join(", ")}`,
          path: "obstructionImages",
        });
      }
      return true;
    },
  }),
};

export default Excavator;
