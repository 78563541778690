export const THAILAND = {
  BANGKOK: { lat: 13.7248936, lng: 100.4930261, zoom: 3 },
};

export const DEFAULT_EXCAVATOR_ZOOM = 8;
export const DEFAULT_ZOOM = 9;
export const DEFAULT_10KM_ZOOM = 11;
export const LOCATION_ZOOM = 16;

/* Radius in meter */
export const RADIUS = {
  drone: 50000,
  combine: 10000,
  tractor: 50000,
};
