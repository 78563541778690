import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Grid,
  Box,
  Typography,
  Fab,
  IconButton,
  // FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import {
  useGetMasterMachineryQuery,
  useGetMasterServiceNameQuery,
} from "services/master";
import {
  useGetMachineryByIdQuery,
  useGenerateMachineryNameQuery,
  useAddMachineryMutation,
  useUpdateMachineryMutation,
} from "services/machinery";
import { useGetDriverServiceByTypeQuery } from "services/service";

import { selectMachineryById } from "store/machinery-slice";

import { toggleDrawerPicker } from "store/user-interface/ui-slice";
import { SelectPicker, TextInput, BoxImageInput } from "components/hook-forms";
import { Navbar } from "layouts/components";
import { ScrollToTop, ActionLoading, Options } from "base-components";
import MiddleForm from "./MiddleForm";
import BottomForm from "./BottomForm";

// Drone example images
import ExampleDroneWithDriver from "assets/drone-with-man.png";
import ExampleDrone from "assets/exmp-drone.png";
// Combine example images
import ExampleCombineWithDriver from "assets/combine-with-man.png";
import ExampleCombine from "assets/exmp-combine.png";
// Tractor example images
import ExampleTractorWithDriver from "assets/tractor-with-man.png";
import ExampleTractor from "assets/exmp-tractor.png";
// Excavator example images
import ExampleExcavatorWithDriver from "assets/excavator-with-man.png";
import ExampleExcavator from "assets/exmp-excavator.png";

import { ROUTES } from "routes/router";
import { isUrl, deconstructFileUrl } from "helpers/Utilies";
import { yearOptions as yearsChoices } from "helpers/DateTime";
import { COUNTDOWN_YEAR } from "constants/DateTime";
import { VEHICLES } from "constants/Vehicles";

import ValidationSchema from "../form-validation-schema/ValidationSchema";

const Form = styled("form")({
  flexGrow: 1,
});

const DefaultExampleImage = styled((props) => <Box {...props} />)(
  ({ theme }) => ({
    display: "flex",
    width: "100%",
    height: theme.spacing(15),
    backgroundColor: theme.palette.grey[500],
    borderRadius: theme.spacing(1),
  })
);

const ExampleImage = styled("img")(({ theme }) => ({
  width: "100%",
  objectFit: "contain",
  borderRadius: theme.spacing(1),
}));

const MachineFormPage = (props) => {
  const dispatch = useDispatch();
  const {
    ui: { drawerPicker },
  } = useSelector((state) => state);
  const [header, setHeader] = useState("กรอกช้อมูลเครื่องจักร");
  const [deletedImages, setDeletedImages] = useState([]);
  const imageRef = useRef(null);
  const imageFrontRef = useRef(null);

  const navigate = useNavigate();
  const { id: vinId } = useParams();
  const { pathname, state } = useLocation();
  const [, , locationAction] = pathname.split("/");
  const action = state?.action;
  const serviceType = state?.service;

  const {
    watch,
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: {
      errors: { image: imageError, imageFront: imageFrontError },
    },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const [vinTypeValue, brandValue, yearValue, sizeValue] = getValues([
    "vinType",
    "brand",
    "year",
    "size",
  ]);
  const { data: masterVehicles = [] } = useGetMasterMachineryQuery();
  const serviceNameEN = masterVehicles.find(
    (vehicle) => vehicle.types === vinTypeValue
  )?.typeEN;
  const { data: masterServiceName } = useGetMasterServiceNameQuery(
    serviceNameEN,
    { skip: !serviceNameEN }
  );
  const { data: generatedName } = useGenerateMachineryNameQuery(vinTypeValue, {
    skip: !vinTypeValue,
    selectFromResult: ({ data }) => ({ data: data?.name }),
  });
  const [addMachinery, { isLoading: isAdding }] = useAddMachineryMutation();
  const [updateMachinery, { isLoading: isUpdating }] =
    useUpdateMachineryMutation();
  const selectMachinery = useSelector((state) =>
    selectMachineryById(state, vinId)
  );
  useGetMachineryByIdQuery(vinId, {
    skip: !vinId || selectMachinery?.id,
  });
  const { data: selectService } = useGetDriverServiceByTypeQuery(
    serviceNameEN,
    { skip: !serviceNameEN }
  );

  const toggleDrawerPickerHandler = useCallback(
    (state) => () => {
      dispatch(toggleDrawerPicker(state));
    },
    [dispatch]
  );

  const brandOptions = useMemo(() => {
    const vinTypebrands = masterVehicles.find(
      (vehicle) => vehicle.types === vinTypeValue
    )?.brands;
    return vinTypeValue
      ? vinTypebrands?.map((brand, idx) => ({
          id: `${idx + 1}_${brand}`,
          label: <Typography variant="body1">{brand}</Typography>,
          value: brand,
          onClickOption() {
            setValue("brand", brand);
            clearErrors("brand");
            toggleDrawerPickerHandler("brand")();
          },
        }))
      : [
          {
            id: `no-vin-type-value`,
            label: (
              <Typography variant="body1">{`กรุณาเลือก ประเภทเครื่องจักร ก่อน`}</Typography>
            ),
            value: "",
            onClickOption() {
              toggleDrawerPickerHandler("brand")();
            },
          },
        ];
  }, [
    setValue,
    toggleDrawerPickerHandler,
    clearErrors,
    vinTypeValue,
    masterVehicles,
  ]);

  const vinTypesOptions = useMemo(() => {
    return masterVehicles.map(({ types }, idx) => ({
      id: idx + 1,
      label: <Typography variant="body1">{types}</Typography>,
      value: types,
      onClickOption() {
        setValue("vinType", types);
        clearErrors("vinType");
        toggleDrawerPickerHandler("vinType")();
      },
    }));
  }, [masterVehicles, setValue, clearErrors, toggleDrawerPickerHandler]);

  const yearOptions = useMemo(
    () =>
      yearsChoices(COUNTDOWN_YEAR).map((year, idx) => ({
        id: idx + 1,
        label: <Typography variant="body1">{year}</Typography>,
        value: year,
        onClickOption() {
          setValue("year", year);
          clearErrors("year");
          toggleDrawerPickerHandler("year")();
        },
      })),
    [setValue, toggleDrawerPickerHandler, clearErrors]
  );

  const sizeOptions = useMemo(() => {
    return masterServiceName?.data?.size
      ? masterServiceName.data.size.map((size) => ({
          id: size,
          label: <Typography variant="body1">{size}</Typography>,
          value: size,
          onClickOption() {
            setValue("size", size);
            clearErrors("size");
            toggleDrawerPickerHandler("size")();
          },
        }))
      : [];
  }, [masterServiceName, setValue, toggleDrawerPickerHandler, clearErrors]);

  useEffect(() => {
    const setFormValue = (data) => {
      if (!data?.id) return;
      setHeader(`${data.vinType} ${data.vinName}`);
      setValue("vinType", data.vinType);
      setValue("vinName", data.vinName);
      setValue("brand", data.brand);
      setValue("model", data.model);
      setValue("year", data.year);
      setValue("size", data.size);
      setValue("image", data?.image ? deconstructFileUrl(data.image) : "");
      setValue(
        "imageFront",
        data?.imageFront ? deconstructFileUrl(data.imageFront) : ""
      );
      setValue("battery", data.battery);
      setValue("dynamo", data.dynamo);
    };

    if (!vinId && serviceType)
      setValue("vinType", VEHICLES?.[serviceType].nameTH);

    if (vinId && selectMachinery?.id) setFormValue(selectMachinery);
  }, [vinId, serviceType, selectMachinery, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const { vinType, vinName, ...rest } = value;
      if (name === "vinType" && vinType && !serviceType)
        for (const field of Object.keys(rest)) setValue(field, "");
    });

    return () => subscription.unsubscribe();
  }, [serviceType, watch, setValue]);

  useEffect(() => {
    if (vinTypeValue && locationAction === "create")
      setValue("vinName", generatedName);
  }, [locationAction, vinTypeValue, generatedName, setValue]);

  const onAddImage = (images, callbackFn) => {
    if (Array.isArray(images)) callbackFn(images[0]);
    else callbackFn(images);
  };

  const onRemoveImage = (fieldname, { data }) => {
    if (!fieldname) return;
    if (isUrl(data))
      setDeletedImages((prevDeletedImages) => [...prevDeletedImages, data]);
    setValue(fieldname, "");
  };

  const submitMachineData = async (data) => {
    const { image, imageFront, battery, dynamo, size, ...rest } = data;

    const additionalFields = {
      [VEHICLES.drone.nameTH]: {
        battery,
        dynamo,
      },
      [VEHICLES.combine.nameTH]: null,
      [VEHICLES.tractor.nameTH]: null,
      [VEHICLES.excavator.nameTH]: { size },
    };

    const body = {
      ...rest,
      ...(!isUrl(image.data) && { image }),
      ...(!isUrl(imageFront.data) && { imageFront }),
      ...additionalFields[rest.vinType],
      ...(vinId && deletedImages?.length && { deletedImages }),
    };

    const isRegisterService = () => {
      /* Fetch services for check availability */
      if (
        Object.keys(selectService.data).length === 0 &&
        Object.getPrototypeOf(selectService.data) === Object.prototype
      ) {
        return navigate(ROUTES.SERVICE_FORM(serviceNameEN, "create"), {
          replace: true,
          state: { action },
        });
      }
      return navigate(-1, { replace: true });
    };

    if (!vinId) {
      await addMachinery(body).unwrap();
      return isRegisterService();
    } else {
      await updateMachinery({
        id: vinId,
        body,
      }).unwrap();
      return isRegisterService();
    }
  };

  const submitWithError = (data) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const machineCreationWording = action === "create" && (
    <Grid item xs={12} mb={3}>
      <Typography sx={{ color: "error.main", mb: 0.5 }} variant="body1">
        <strong>คุณยังไม่เคยกรอกข้อมูลเครื่องจักร</strong>
      </Typography>
      <Typography variant="subtitle2">
        กรุณากรอกข้อมูลเครื่องจักรตามประเภทงานที่คุณต้องการลงทะเบียน
      </Typography>
    </Grid>
  );

  const exampleImages = {
    [VEHICLES.drone.nameTH]: {
      altDesc: "drone",
      withDriverImage: ExampleDroneWithDriver,
      frontImage: ExampleDrone,
    },
    [VEHICLES.combine.nameTH]: {
      altDesc: "combine-harvester",
      withDriverImage: ExampleCombineWithDriver,
      frontImage: ExampleCombine,
    },
    [VEHICLES.tractor.nameTH]: {
      altDesc: "tractor",
      withDriverImage: ExampleTractorWithDriver,
      frontImage: ExampleTractor,
    },
    [VEHICLES.excavator.nameTH]: {
      altDesc: "excavator",
      /* Must change example image */
      withDriverImage: ExampleExcavatorWithDriver,
      frontImage: ExampleExcavator,
    },
  };

  return (
    <Fragment>
      <ScrollToTop />
      <Grid container mt={3} mb={2}>
        {machineCreationWording}
        <Form onSubmit={handleSubmit(submitMachineData, submitWithError)}>
          <Grid item xs={12} mb={2}>
            <Typography variant="body1" color="secondary.main">
              <strong>{header}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            <SelectPicker
              name="vinType"
              control={control}
              defaultValue=""
              label="ประเภทเครื่องจักร"
              placeholder="เลือกประเภทเครื่องจักร"
              width={{ label: "40%", input: "60%" }}
              showDrawer={drawerPicker.vinType}
              disabled={!!serviceType || !!vinId}
              onClickInput={toggleDrawerPickerHandler("vinType")}
            >
              <Navbar color="secondary" position="absolute">
                <Navbar.Left>
                  <Typography variant="subtitle2">
                    เลือกประเภทเครื่องจักร
                  </Typography>
                </Navbar.Left>
              </Navbar>
              <Options optionsData={vinTypesOptions} selected={vinTypeValue} />
            </SelectPicker>
          </Grid>

          <Grid item xs={12} mb={1}>
            <TextInput
              name="vinName"
              control={control}
              defaultValue=""
              label="ชื่อเครื่องจักร"
              placeholder="ระบุชื่อเครื่องจักร"
              width={{ label: "30%", input: "70%" }}
              helper={
                <Typography
                  color="secondary.main"
                  component="span"
                  variant="subtitle2"
                >
                  <small>เปลี่ยนชื่อเครื่องจักรได้ในภายหลัง</small>
                </Typography>
              }
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <SelectPicker
              name="brand"
              control={control}
              defaultValue=""
              label="ยี่ห้อ"
              placeholder={
                vinTypeValue ? "กรุณาเลือกยี่ห้อ" : "เลือกประเภทเครื่องจักรก่อน"
              }
              width={{ label: "30%", input: "70%" }}
              showDrawer={drawerPicker.brand}
              onClickInput={toggleDrawerPickerHandler("brand")}
            >
              <Navbar color="secondary" position="absolute">
                <Navbar.Left>
                  <Typography variant="subtitle2">เลือกยี่ห้อ</Typography>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto" }}>
                  <IconButton onClick={toggleDrawerPickerHandler("brand")}>
                    <CloseIcon
                      sx={{ color: (theme) => theme.palette.common.white }}
                    />
                  </IconButton>
                </Navbar.Right>
              </Navbar>
              <Options optionsData={brandOptions} selected={brandValue} />
            </SelectPicker>
          </Grid>

          <MiddleForm
            vinType={serviceNameEN}
            control={control}
            {...(serviceNameEN === VEHICLES.excavator.nameEN
              ? {
                  sizePicker: {
                    drawer: drawerPicker.size,
                    toggleDrawer: toggleDrawerPickerHandler("size"),
                    options: sizeOptions,
                    value: sizeValue,
                  },
                  yearPicker: {},
                }
              : {
                  yearPicker: {
                    drawer: drawerPicker.year,
                    toggleDrawer: toggleDrawerPickerHandler("year"),
                    options: yearOptions,
                    value: yearValue,
                  },
                  sizePicker: {},
                })}
          />

          {/* Images Section */}
          <Typography variant="body1" color="secondary.main" sx={{ mb: 1 }}>
            เพิ่มรูปเครื่องจักร
          </Typography>

          {/* Image with driver */}
          <Grid container item xs={12} mb={2}>
            <Grid item xs={12} mb={2}>
              <Typography variant="subtitle2">
                ภาพเครื่องจักรพร้อมผู้ใช้
              </Typography>
            </Grid>

            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={6}
            >
              <Grid item xs px={2}>
                {vinTypeValue ? (
                  <ExampleImage
                    alt={`example-${exampleImages[vinTypeValue].altDesc}-with-driver`}
                    src={exampleImages[vinTypeValue].withDriverImage}
                  />
                ) : (
                  <DefaultExampleImage>
                    <Typography
                      align="center"
                      variant="subtitle2"
                      sx={{ my: "auto", px: 1 }}
                    >
                      ภาพตัวอย่างเครื่องจักรพร้อมผู้ใช้
                    </Typography>
                  </DefaultExampleImage>
                )}
              </Grid>

              <Grid item xs>
                <Typography color="tertiary" variant="subtitle2">
                  <small>ตัวอย่าง</small>
                </Typography>
              </Grid>
            </Grid>

            <Grid container item alignItems="center" xs={6}>
              <BoxImageInput
                ref={imageRef}
                control={control}
                defaultValue=""
                name="image"
                showStreamMedia={drawerPicker.streamMediaImage}
                onClickTakePhoto={toggleDrawerPickerHandler("streamMediaImage")}
                addImage={onAddImage}
                onRemoveHandler={onRemoveImage}
              >
                <Typography
                  variant="body1"
                  color={imageError ? "error" : "tertiary"}
                  align="center"
                  sx={{ my: "auto", flexGrow: 1 }}
                >
                  กดเพื่อเพิ่มรูป
                </Typography>
              </BoxImageInput>
            </Grid>
          </Grid>

          {/* Image front side */}
          <Grid container item xs={12} mb={2}>
            <Grid item xs={12} mb={2}>
              <Typography variant="subtitle2">
                ภาพด้านหน้าเครื่องจักร
              </Typography>
            </Grid>

            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={6}
            >
              <Grid item xs px={2}>
                {vinTypeValue ? (
                  <ExampleImage
                    alt={`example-${exampleImages[vinTypeValue].altDesc}`}
                    src={exampleImages[vinTypeValue].frontImage}
                  />
                ) : (
                  <DefaultExampleImage>
                    <Typography
                      align="center"
                      variant="subtitle2"
                      sx={{ my: "auto", px: 2 }}
                    >
                      ภาพตัวอย่างด้านหน้าเครื่องจักร
                    </Typography>
                  </DefaultExampleImage>
                )}
              </Grid>
              <Grid item xs>
                <Typography color="tertiary" variant="subtitle2">
                  <small>ตัวอย่าง</small>
                </Typography>
              </Grid>
            </Grid>

            <Grid container item alignItems="center" xs={6}>
              <BoxImageInput
                ref={imageFrontRef}
                control={control}
                defaultValue=""
                name="imageFront"
                showStreamMedia={drawerPicker.streamMediaImageFront}
                onClickTakePhoto={toggleDrawerPickerHandler(
                  "streamMediaImageFront"
                )}
                addImage={onAddImage}
                onRemoveHandler={onRemoveImage}
              >
                <Typography
                  variant="body1"
                  color={imageFrontError ? "error" : "tertiary"}
                  align="center"
                  sx={{ my: "auto", flexGrow: 1 }}
                >
                  กดเพื่อเพิ่มรูป
                </Typography>
              </BoxImageInput>
            </Grid>
          </Grid>

          <BottomForm vinType={serviceNameEN} control={control} />

          <Grid container item xs={12} mb={1}>
            <Fab
              type="submit"
              size="small"
              variant="extended"
              color="secondary"
              sx={{ ml: "auto", px: 3 }}
            >
              {action === "create" ? "ถัดไป" : "บันทึก"}
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <ActionLoading open={isAdding || isUpdating}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default MachineFormPage;
