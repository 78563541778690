import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const useScrollToTop = (...loadingStates) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const isFinishedLoading = loadingStates.every(
      (loadingState) => !loadingState
    );
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    if (
      (document.readyState === "interactive" ||
        document.readyState === "complete") &&
      isFinishedLoading
    )
      scrollToTop();
  }, [pathname, loadingStates]);
  return null;
};

export default useScrollToTop;
