import { useState, useEffect } from "react";

const Area = (options) => {
  const [area, setArea] = useState();

  useEffect(() => {
    if (!area) setArea(new window.google.maps.Circle());

    // remove area from map on unmount
    return () => {
      if (area) {
        area.setMap(null);
      }
    };
  }, [area]);

  useEffect(() => {
    if (area) area.setOptions(options);
  }, [area, options]);

  return null;
};

export default Area;
