import { useEffect } from "react";
import PropTypes from "prop-types";

import { Grid, Typography } from "@mui/material";
import { useCountdown } from "hooks";

const CountdownText = ({
  targetDate,
  onCountdownFinished,
  children,
  ...props
}) => {
  const [minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    let mounted = true;
    if (mounted && minutes + seconds <= 0 && onCountdownFinished)
      onCountdownFinished();

    return () => {
      mounted = false;
    };
  }, [minutes, seconds, onCountdownFinished]);

  if (minutes + seconds <= 0) return null;

  return (
    <Grid container sx={props.sx}>
      <Grid item xs={12}>
        <Typography
          component="p"
          variant="subtitle2"
          color="tertiary"
          align="center"
          sx={{ mt: 1 }}
        >
          {children}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="p"
          variant="subtitle1"
          color="tertiary"
          align="center"
        >
          {minutes > 0 ? `${minutes} นาที` : null}{" "}
          {seconds < 10 ? `0${seconds}` : seconds} วินาที
        </Typography>
      </Grid>
    </Grid>
  );
};

CountdownText.propTypes = {
  targetDate: PropTypes.number.isRequired,
  onCountdownFinished: PropTypes.func,
  children: PropTypes.node,
};

export default CountdownText;
