import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { Navbar } from "layouts/components";
import JobListsPage from "./JobListsPage";

import { ROUTES } from "routes/router";

const JobListChannels = () => {
  const navigate = useNavigate();
  const { role } = useParams();

  const jobLists = {
    driver: {
      navBGColor: "primary",
      navLeftOnClickHandler() {
        navigate(ROUTES.MENU_DRIVER);
      },
      navRightContent: "สมุดงาน",
      element: <JobListsPage />,
    },
    farmer: {
      navBGColor: "secondary",
      navLeftOnClickHandler() {
        navigate(ROUTES.MENU_FARMER);
      },
      navRightContent: "งานจ้าง",
      element: <JobListsPage />,
    },
  };

  return (
    <Fragment>
      <Navbar color={jobLists[role].navBGColor}>
        <Navbar.Left sx={{ flexGrow: 1 }}>
          <Button
            onClick={jobLists[role].navLeftOnClickHandler}
            startIcon={<ArrowBackIosNewIcon sx={{ color: "common.white" }} />}
            sx={{ color: "common.white", p: 0 }}
          >
            ย้อนกลับ
          </Button>
        </Navbar.Left>
        <Navbar.Right sx={{ ml: "auto", mr: 1.5 }}>
          <Typography variant="subtitle2">
            {jobLists[role].navRightContent}
          </Typography>
        </Navbar.Right>
      </Navbar>
      {jobLists[role].element}
    </Fragment>
  );
};

export default JobListChannels;
