import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { Navbar } from "layouts/components";
import DroneFormPage from "./DroneFormPage";
import CombineFormPage from "./CombineFormPage";
import TractorFormPage from "./TractorFormPage";
import ExcavatorFormPage from "./ExcavatorFormPage";

const ServiceChannels = () => {
  const navigate = useNavigate();
  const { name } = useParams();

  const services = {
    drone: {
      navRightContent: "รายละเอียดผู้ให้บริการโดรน",
      element: <DroneFormPage />,
    },
    combine: {
      navRightContent: "รายละเอียดผู้ให้บริการรถเกี่ยว",
      element: <CombineFormPage />,
    },
    tractor: {
      navRightContent: "รายละเอียดผู้ให้บริการรถแทรกเตอร์",
      element: <TractorFormPage />,
    },
    excavator: {
      navRightContent: "รายละเอียดผู้ให้บริการรถขุด",
      element: <ExcavatorFormPage />,
    },
  };

  return (
    <Fragment>
      <Navbar>
        <Navbar.Left sx={{ flexGrow: 1 }}>
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosNewIcon sx={{ color: "common.white" }} />}
            sx={{ color: "common.white", p: 0 }}
          >
            ย้อนกลับ
          </Button>
        </Navbar.Left>
        <Navbar.Right sx={{ ml: "auto" }}>
          <Typography variant="subtitle2">
            {services[name].navRightContent}
          </Typography>
        </Navbar.Right>
      </Navbar>
      {services[name].element}
    </Fragment>
  );
};

export default ServiceChannels;
