import { Fragment, useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Grid, Typography, Fab, Button } from "@mui/material";
import { grey } from "@mui/material/colors";

import { uiActions } from "store/user-interface/ui-slice";
import { authActions } from "store/authentication/auth-slice";
import { assetActions } from "store/assets/asset-slice";

import { useHttp } from "hooks";
import { ActionLoading, Alert } from "base-components";
import { OTPInput } from "components";
import CountdownText from "./components/CountdownText";

import { USER_ENDPOINTS, DRIVER_ENDPOINTS } from "constants/APIs";
import { ROUTES } from "routes/router";
import { useCallback } from "react";

const OTPFormPage = (props) => {
  const dispatch = useDispatch();
  const {
    auth: { confirmDuplicated, otp, currentRole },
    ui: { alert },
  } = useSelector((state) => state);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [otpValue, setOTPValue] = useState("");
  const [requestOTPAgain, setRequestOTPAgain] = useState(true);
  const otpRef = useRef(null);

  const navigate = useNavigate();
  const { state } = useLocation();
  // create || update
  const action = state?.action;
  // new || getztrac || update
  const source = state?.source;
  const tempTel = state?.tempTel;

  const { isLoading: isSending, sendRequest: submitOTP } = useHttp();
  const { isLoading: isRequesting, sendRequest: requestVerify } = useHttp();

  useEffect(() => {
    const changeInputType = () => {
      if (otpRef?.current) otpRef.current.focus();
    };

    window.addEventListener("load", changeInputType);

    return () => {
      window.removeEventListener("load", changeInputType);
    };
  }, []);

  const onChangePINHandler = (value) => {
    setDisabledSubmit(value.length < 4);
    setOTPValue(() => {
      if (value.length === 4) submitOtpHandler(value);
      return value;
    });
  };

  const submitOtpHandler = (pin) => {
    submitOTP(
      {
        endpoint: USER_ENDPOINTS.verifyOtp(currentRole),
        method: "POST",
        body: {
          tel: tempTel,
          token: otp.token,
          refno: otp.refno,
          pin: pin,
          source,
          confirmDuplicated,
        },
      },
      (response) => {
        /* OTP is incorrect or expired. */
        if (response.error === "incorrect.") {
          dispatch(
            uiActions.toggleAlert({
              isShow: true,
              status: "error",
              duration: 5000,
              message: (
                <Fragment>
                  <Typography component="p" variant="caption">
                    รหัส OTP ไม่ถูกต้อง
                  </Typography>
                  <Typography component="p" variant="caption">
                    กรุณากรอก OTP ใหม่หรือขอ OTP ใหม่อีกครั้ง
                  </Typography>
                </Fragment>
              ),
            })
          );
          setOTPValue("");
          setDisabledSubmit(true);
          return;
        }

        /* Clear OTP verified session */
        dispatch(authActions.clearOtpSession());

        /* new register */
        if (source === "new") {
          dispatch(
            authActions.updateUser({
              prefixname: response.prefixname,
              firstname: response.firstname,
              surname: response.surname,
              birthDate: response.birthDate,
              recommendedCode: response.recommendedCode,
              pdpa_accept: response.pdpa_accept,
              tel: response.tel,
            })
          );
          dispatch(authActions.updateVerifyOTP(response.verifyOTP));
          const redirectOptions = {
            farmer: {
              route: ROUTES.MENU_FARMER,
              options: { replace: true },
            },
            driver: {
              route: ROUTES.CREATE_MACHINE_FORM,
              options: { state: { action, source }, replace: true },
            },
          };
          return navigate(
            redirectOptions[currentRole].route,
            redirectOptions[currentRole].options
          );
        }

        /* Update services and vehicles from Getztrac */
        if (source === "getztrac") {
          if (response?.vehicles?.length)
            dispatch(assetActions.initialAssetsInformation(response.vehicles));

          /* Update driver information from Getztrac */
          dispatch(
            authActions.updateUser({
              prefixname: response.prefixname,
              firstname: response.firstname,
              surname: response.surname,
              tel: response.tel,
              birthDate: response.birthDate,
              getztracId: response.getztracId,
            })
          );
          dispatch(authActions.updateVerifyOTP(response.verifyOTP));
          return navigate(ROUTES.USER_INFORMATION_FORM(currentRole), {
            state: { action, source },
            replace: true,
          });
        }

        if (source === "update") {
          dispatch(authActions.updateUser({ tel: response.tel }));
          dispatch(authActions.updateVerifyOTP(response.verifyOTP));
          return navigate(
            currentRole === "farmer" ? ROUTES.MENU_FARMER : ROUTES.MENU_DRIVER,
            { replace: true }
          );
        }
      }
    );
  };

  const requestOtpAgainHandler = () => {
    let fetchConfig = {
      endpoint: "",
      method: "",
    };
    if (source === "getztrac")
      fetchConfig = { endpoint: DRIVER_ENDPOINTS.verifyTelNo, method: "POST" };
    if (source === "new" || source === "update")
      fetchConfig = {
        endpoint: USER_ENDPOINTS.update(currentRole),
        method: "PUT",
      };

    requestVerify(
      {
        body: { tel: tempTel },
        ...fetchConfig,
      },
      (response) => {
        if (response.status === "success") setRequestOTPAgain(false);
        dispatch(
          authActions.setOtpSession({
            tel: tempTel,
            token: response.token,
            refno: response.refno,
            pin: "",
          })
        );
      }
    );
  };

  const enableRequestOtpAgain = useCallback(() => setRequestOTPAgain(true), []);

  const countdownTime = useMemo(() => {
    if (!requestOTPAgain) {
      const THREE_MINUTES_IN_MS = 3 * 60 * 1000 + 1000;
      const NOW_IN_MS = new Date().getTime();
      return THREE_MINUTES_IN_MS + NOW_IN_MS;
    }
    return 0;
  }, [requestOTPAgain]);

  const onCloseAlert = () => {
    dispatch(uiActions.toggleAlert({ isShow: false, status: "error" }));
  };

  const partialDriverTelNo = tempTel
    ? `${tempTel.substring(0, 3)}-XXX-${tempTel.substring(6)}`
    : null;

  const referenceCodeElement = otp.refno ? (
    <Grid container item xs={12} mb={5}>
      <Typography color="secondary" variant="caption" sx={{ mx: "auto" }}>
        รหัสอ้างอิง (Ref): {otp.refno}
      </Typography>
    </Grid>
  ) : null;

  return (
    <Fragment>
      <Box mt={2} sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} mb={0.5}>
            <Typography variant="subtitle2">
              ระบุ OTP 4 หลัก ที่ได้รับทาง SMS ที่หมายเลขโทรศัพท์
            </Typography>
          </Grid>
          <Grid item xs={12} mb={0.5}>
            <Typography color="primary" variant="body1">
              <strong>{partialDriverTelNo}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Typography variant="subtitle2" sx={{ color: grey[700] }}>
              OTP มีอายุการใช้งาน 15 นาที
            </Typography>
          </Grid>
          <Grid item xs={12} mx={2} mb={2}>
            <OTPInput
              ref={otpRef}
              value={otpValue}
              changeOTP={onChangePINHandler}
              sx={(theme) => {
                if (otpValue.length === 4)
                  return {
                    color: theme.palette.primary.main,
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                  };
              }}
            />
          </Grid>
        </Grid>
        {referenceCodeElement}
        <Grid container item xs={12} mb={2}>
          <Fab
            variant="extended"
            color="secondary"
            disabled={disabledSubmit}
            onClick={submitOtpHandler}
            sx={{ py: (theme) => theme.spacing(1), mx: "auto", width: "50%" }}
          >
            ตกลง
          </Fab>
        </Grid>
        {requestOTPAgain ? (
          <Grid container item xs={12}>
            <Button onClick={requestOtpAgainHandler} sx={{ mx: "auto" }}>
              ขอรหัส OTP อีกครั้ง
            </Button>
          </Grid>
        ) : (
          <CountdownText
            targetDate={countdownTime}
            onCountdownFinished={enableRequestOtpAgain}
            sx={{ mt: 1 }}
          >
            สามารถขอรหัส OTP ได้อีกครั้งภายใน{" "}
          </CountdownText>
        )}
      </Box>

      <ActionLoading open={isSending}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังตรวจสอบข้อมูล
        </Typography>
      </ActionLoading>

      <ActionLoading open={isRequesting}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังขอรหัส OTP อีกครั้ง
        </Typography>
      </ActionLoading>

      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isShow}
        hideDuration={alert.duration}
        onClose={onCloseAlert}
        status={alert.status}
        message={alert.message}
        action={alert.action}
      />
    </Fragment>
  );
};

export default OTPFormPage;
