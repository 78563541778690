import { api } from "./api";

export const machineryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMachineriesOfDriver: build.query({
      query: () => ({ url: "vehicles/all" }),
      providesTags: (machinery) =>
        machinery
          ? [
              { type: "Machinery", id: "LIST" },
              ...machinery.map(({ id }) => ({ type: "Machinery", id })),
            ]
          : [{ type: "Machinery", id: "LIST" }],
    }),
    getMachineryById: build.query({
      query: (machineryId) => ({
        url: `vehicles/id/${machineryId}`,
        method: "GET",
      }),
      providesTags: (_machinery, _err, id) => [{ type: "Machinery", id }],
      transformResponse: (response) => ({
        vinId: response?.id,
        data: response,
      }),
    }),
    generateMachineryName: build.query({
      query: (machineryTypeTH) => ({
        url: `vehicles/name/${machineryTypeTH}`,
        method: "GET",
      }),
    }),
    addMachinery: build.mutation({
      query: (body) => ({
        url: "vehicles/create",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Machinery", id: "LIST" }],
    }),
    updateMachinery: build.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `vehicles/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (_machinery, _err, { id }) => [
        { type: "Machinery", id },
      ],
      transformResponse: (response) => ({
        vinId: response.id,
        data: response,
      }),
    }),
    deleteMachinery: build.mutation({
      query: (id) => ({
        url: `vehicles/delete/${id}`,
        method: "PUT",
      }),
      invalidatesTags: (_machinery, _err, id) => [{ type: "Machinery", id }],
      transformResponse: (response) => response.id,
    }),
  }),
});

export const {
  useGetMachineriesOfDriverQuery,
  useGetMachineryByIdQuery,
  useGenerateMachineryNameQuery,
  useAddMachineryMutation,
  useUpdateMachineryMutation,
  useDeleteMachineryMutation,
} = machineryApi;
