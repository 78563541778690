import PropTypes from "prop-types";

import { Typography } from "@mui/material";

const Header = ({ textAlign, children }) => (
  <Typography component="h1" variant="h6" textAlign={textAlign} mt={2}>
    {children}
  </Typography>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
};

Header.defaultProps = {
  textAlign: "left",
};

export default Header;
