import { Fragment } from "react";

import { Grid, Typography } from "@mui/material";

import { SelectPicker, TextInput } from "components/hook-forms";
import { Navbar } from "layouts/components";
import { Options } from "base-components";

import { VEHICLES } from "constants/Vehicles";

const CommonMiddleDetailForm = ({
  control,
  year: { drawer = false, toggleDrawer, options, value },
}) => {
  const handleToggleDrawerYearPicker = () => {
    toggleDrawer();
  };

  return (
    <Fragment>
      <Grid item xs={12} mb={2}>
        <TextInput
          name="model"
          control={control}
          defaultValue=""
          label="รุ่น"
          placeholder="ระบุรุ่น"
          width={{ label: "30%", input: "70%" }}
        />
      </Grid>

      <Grid item xs={12} mb={3}>
        <SelectPicker
          name="year"
          control={control}
          defaultValue=""
          label="ปีที่ซื้อ"
          placeholder="กรุณาเลือกปีที่ซื้อ"
          width={{ label: "30%", input: "70%" }}
          showDrawer={drawer}
          onClickInput={handleToggleDrawerYearPicker}
        >
          <Navbar color="secondary" position="absolute">
            <Navbar.Left>
              <Typography variant="subtitle2">เลือกปีที่ซื้อ</Typography>
            </Navbar.Left>
          </Navbar>
          <Options optionsData={options} selected={value} />
        </SelectPicker>
      </Grid>
    </Fragment>
  );
};

const MiddleForm = ({ vinType, control, yearPicker, sizePicker }) => {
  const { drawer = false, toggleDrawer, options, value } = sizePicker;

  const handleToggleDrawerSizePicker = () => {
    toggleDrawer();
  };

  const forms = {
    [VEHICLES.drone.nameEN]: (
      <CommonMiddleDetailForm control={control} year={yearPicker} />
    ),
    [VEHICLES.combine.nameEN]: (
      <CommonMiddleDetailForm control={control} year={yearPicker} />
    ),
    [VEHICLES.tractor.nameEN]: (
      <CommonMiddleDetailForm control={control} year={yearPicker} />
    ),
    [VEHICLES.excavator.nameEN]: (
      <Grid item xs={12} mb={3}>
        <SelectPicker
          name="size"
          control={control}
          defaultValue=""
          label="ขนาด"
          placeholder="ขนาดของรถขุด"
          width={{ label: "30%", input: "70%" }}
          showDrawer={drawer}
          onClickInput={handleToggleDrawerSizePicker}
        >
          <Navbar color="secondary" position="absolute">
            <Navbar.Left>
              <Typography variant="subtitle2">เลือกขนาดของรถขุด</Typography>
            </Navbar.Left>
          </Navbar>
          <Options optionsData={options} selected={value} />
        </SelectPicker>
      </Grid>
    ),
  };

  if (!vinType) return null;

  return forms[vinType];
};

export default MiddleForm;
