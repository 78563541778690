import { Fragment } from "react";
import PropTypes from "prop-types";

import { Grid, Typography } from "@mui/material";

import { DividerFullWidth } from "base-components";
import {
  TextInput,
  InlineRadio,
  // Checkbox
} from "components/hook-forms";

import { VEHICLES } from "constants/Vehicles";

const BottomForm = ({
  vinType,
  control,
  // tractorProps
}) => {
  if (!vinType) return null;

  const forms = {
    [VEHICLES.drone.nameEN]: (
      <Fragment>
        <DividerFullWidth />

        <Grid item xs={12} mt={4} mb={2}>
          <Typography variant="body1" color="secondary.main" sx={{ mb: 1 }}>
            กรุณากรอกข้อมูลเพิ่มเติม สำหรับโดรนการเกษตร
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            ข้อมูลเพิ่มเติมเพื่อให้ผู้จ้างใช้ประกอบการตัดสินใจ
          </Typography>
        </Grid>

        <Grid container item mb={2}>
          <TextInput
            control={control}
            defaultValue=""
            name="battery"
            placeholder="จำนวนก้อน"
            label="จำนวนแบตเตอรี่"
            labelTail="ก้อน"
            type="number"
            alignValue="center"
            width={{ label: "40%", input: "40%", labelTail: "20%" }}
          />
        </Grid>

        <Grid item xs={12} mb={3}>
          <InlineRadio
            name="dynamo"
            control={control}
            defaultValue=""
            label="เครื่องปั่นไฟ"
            radioOptions={[
              { id: "dynamoRadio01", label: "มี", value: true },
              { id: "dynamoRadio02", label: "ไม่มี", value: false },
            ]}
          />
        </Grid>
      </Fragment>
    ),
    [VEHICLES.combine.nameEN]: null,
    [VEHICLES.tractor.nameEN]: null,
    //     <Fragment>
    //       <DividerFullWidth />

    //       <Grid item xs={12} mt={4} mb={2}>
    //         <Typography variant="body1" color="secondary.main" sx={{ mb: 1 }}>
    //           กรุณากรอกข้อมูลเพิ่มเติม สำหรับแทรกเตอร์
    //         </Typography>
    //       </Grid>

    //       <Grid item xs={12} mb={2}>
    //         <Typography variant="subtitle2" sx={{ mb: 1 }}>
    //           ข้อมูลเพิ่มเติมเพื่อให้ผู้จ้างใช้ประกอบการตัดสินใจ
    //         </Typography>
    //       </Grid>

    //       <Grid item xs={12} mb={tractorProps.errorText ? 1 : 3}>
    //         <Checkbox
    //           control={control}
    //           defaultValue={false}
    //           label={
    //             <Typography variant="body1" sx={{ mb: 1 }}>
    //               อุปกรณ์ต่อพ่วงที่มีอยู่
    //             </Typography>
    //           }
    //           checkboxOptions={tractorProps.implementOptions}
    //           checkboxDirection="row"
    //           checkboxStyles={{
    //             justifyContent: "space-around",
    //             "& .MuiFormControlLabel-root": {
    //               flexBasis: "33.33%",
    //             },
    //           }}
    //         />
    //       </Grid>

    //       {tractorProps.errorText}
    //     </Fragment>
    [VEHICLES.excavator.nameEN]: null,
  };

  return forms[vinType];
};

BottomForm.propTypes = {
  vinType: PropTypes.oneOf([
    "",
    VEHICLES.drone.nameEN,
    VEHICLES.combine.nameEN,
    VEHICLES.tractor.nameEN,
    VEHICLES.excavator.nameEN,
  ]),
  // tractorProps: PropTypes.shape({
  //   implementOptions: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //       name: PropTypes.string,
  //       label: PropTypes.node,
  //       value: PropTypes.bool,
  //     })
  //   ),
  //   errorText: PropTypes.node,
  // }),
};

export default BottomForm;
