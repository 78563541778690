import { Typography, Fab } from "@mui/material";
import { Modal } from "base-components";

const NotifyOfferingPrice = ({ openModal, onCloseModal }) => {
  return (
    <Modal openModal={openModal} onCloseModal={onCloseModal} maxWidth="sm">
      <Modal.Content>
        <Typography variant="body1" color="secondary" sx={{ mb: 1 }}>
          คนขับมีการแก้ไขราคาก่อนส่งมอบงาน
        </Typography>

        <Typography variant="body1" color="secondary" align="center">
          <strong>กรุณาตรวจสอบ</strong>
        </Typography>
      </Modal.Content>
      <Modal.Footer sx={{ pb: 2 }}>
        <Fab
          size="medium"
          variant="extended"
          onClick={onCloseModal}
          sx={{
            mx: "auto",
            width: "35%",
            bgcolor: "transparent",
            border: (theme) => `1px solid ${theme.palette.common.black}`,
          }}
        >
          ปิด
        </Fab>
      </Modal.Footer>
    </Modal>
  );
};

export default NotifyOfferingPrice;
