import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFormState, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Wrapper } from "@googlemaps/react-wrapper";

import {
  Grid,
  Box,
  Typography,
  Button,
  Fab,
  IconButton,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Close as CloseIcon,
} from "@mui/icons-material/";
import Swal from "sweetalert2";

import {
  selectCurrentRole,
  updateCurrentLocation,
} from "store/authentication/auth-slice";
import {
  useGetMasterServiceNameQuery,
  useGetMasterAddressQuery,
} from "services/master";
import {
  selectMasterServiceByType,
  selectMasterAddress,
} from "store/master-slice";
import { useGetMachineriesOfDriverQuery } from "services/machinery";
import { selectMachineriesByType } from "store/machinery-slice";
import {
  useRegisterDriverServiceMutation,
  useUpdateDriverServiceMutation,
  useToggleDriverServiceAvailabilityMutation,
  useGetDriverServiceByTypeQuery,
} from "services/service";
import { selectServiceByType } from "store/service-slice";
import { toggleDrawerPicker } from "store/user-interface/ui-slice";

import { useMapLocation, useSwalStyles } from "hooks";
import {
  evaluateGeocoding,
  getAddressManyComponents,
  getAddressComponent,
} from "helpers/Location";

import { ROUTES } from "routes/router";
import { RADIUS, LOCATION_ZOOM, DEFAULT_ZOOM } from "constants/Location";

import {
  TextInput,
  Checkbox,
  SelectPicker,
  Radio,
} from "components/hook-forms";

import { blankPalette } from "styles/Theme";
import { Navbar } from "layouts/components";
import {
  ScrollToTop,
  SlidingTransition,
  HoldingDivider,
  DividerFullWidth,
  IconImage,
  Options,
  ActionLoading,
} from "base-components";
import { GoogleMap, Marker, Area } from "components/location";

import UnorderedList from "./components/UnorderedList";
import TractorIcon from "assets/tractor.png";

const validationSchema = yup.object().shape({
  ripping: yup.boolean(),
  rippingPrice: yup
    .number()
    .when("ripping", (ripping, schema) =>
      ripping
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  plowing: yup.boolean(),
  plowingPrice: yup
    .number()
    .when("plowing", (plowing, schema) =>
      plowing
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  harrowing: yup.boolean(),
  harrowingPrice: yup
    .number()
    .when("harrowing", (harrowing, schema) =>
      harrowing
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  tilling: yup.boolean(),
  tillingPrice: yup
    .number()
    .when("tilling", (tilling, schema) =>
      tilling
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  ridging: yup.boolean(),
  ridgingPrice: yup
    .number()
    .when("ridging", (ridging, schema) =>
      ridging
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  ploughUp: yup.boolean(),
  ploughUpPrice: yup
    .number()
    .when("ploughUp", (ploughUp, schema) =>
      ploughUp
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  mowing: yup.boolean(),
  mowingPrice: yup
    .number()
    .when("mowing", (mowing, schema) =>
      mowing
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  hayBaling: yup.boolean(),
  hayBalingPrice: yup
    .number()
    .when("hayBaling", (hayBaling, schema) =>
      hayBaling
        ? schema
            .positive("ค่าจ้างต้องมากกว่า 0")
            .typeError("ระบุ ค่าจ้างต่อไร่")
        : schema.transform((value) => (isNaN(value) ? undefined : value))
    ),
  coordinateSource: yup
    .string()
    .required("กรุณาเลือก วิธีการระบุจุดที่ท่านสามารถรับงานได้"),
  mapProvince: yup.string().test({
    name: "mapProvince-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapProvince",
        });
      return true;
    },
  }),
  mapDistrict: yup.string().test({
    name: "mapDistrict-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapDistrict",
        });
      return true;
    },
  }),
  mapSubDistrict: yup.string().test({
    name: "mapSubDistrict-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapSubDistrict",
        });
      return true;
    },
  }),
  province: yup.string().test({
    name: "province-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก จังหวัด",
          path: "province",
        });
      return true;
    },
  }),
  district: yup.string().test({
    name: "district-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก อำเภอ",
          path: "district",
        });
      return true;
    },
  }),
  subDistrict: yup.string().test({
    name: "subDistrict-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก ตำบล",
          path: "subDistrict",
        });
      return true;
    },
  }),
  workingMaxPerDay: yup
    .number()
    .integer("ระบุเป็น จำนวนเต็ม เท่านั้น")
    .positive("ระบุค่า มากกว่า 0 เท่านั้น")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("ระบุ จำนวนไร่สูงสุดต่อวัน"),
  areaMinPerTime: yup
    .number()
    .integer("ระบุเป็น จำนวนเต็ม เท่านั้น")
    .positive("ระบุค่า มากกว่า 0 เท่านั้น")
    .when("workingMaxPerDay", (workingMaxPerDay, schema) =>
      workingMaxPerDay
        ? schema.max(
            workingMaxPerDay,
            `ระบุค่าต้องไม่เกิน ปริมาณรับงานสูงสุด(ไร่ต่อวัน)`
          )
        : schema
    )
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("ระบุ จำนวนไร่ขั้นต่ำต่อครั้ง"),
});

const Form = styled("form")({
  position: "relative",
});

const TractorFormPage = () => {
  const dispatch = useDispatch();
  const [expandMap, setExpandMap] = useState(false);
  const [showArea, setShowArea] = useState(true);
  const [triggerTransition, setTriggerTransition] = useState(false);
  const coordinateSourceRef = useRef(null);

  const navigate = useNavigate();
  const { name: serviceType, action: paramAction } = useParams();
  const editPage = paramAction === "edit";
  const {
    control,
    watch,
    getValues,
    setValue,
    clearErrors,
    setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [
    ripping,
    plowing,
    harrowing,
    tilling,
    ridging,
    ploughUp,
    mowing,
    hayBaling,
    mapLat,
    mapLng,
    mapProvince,
    mapDistrict,
    mapSubDistrict,
    mapZipcode,
    province,
    district,
    subDistrict,
  ] = getValues([
    "ripping",
    "plowing",
    "harrowing",
    "tilling",
    "ridging",
    "ploughUp",
    "mowing",
    "hayBaling",
    "mapLat",
    "mapLng",
    "mapProvince",
    "mapDistrict",
    "mapSubDistrict",
    "mapZipcode",
    "province",
    "district",
    "subDistrict",
  ]);
  const { errors } = useFormState({ control });
  const coordinateSource = useWatch({ control, name: "coordinateSource" });

  const currentRole = useSelector(selectCurrentRole);
  const { drawerPicker } = useSelector((state) => state.ui);
  /* GET Master service */
  const selectedMasterServiceByType = useSelector((state) =>
    selectMasterServiceByType(state, serviceType)
  );
  useGetMasterServiceNameQuery(serviceType, {
    skip: selectedMasterServiceByType?.id,
  });

  /* GET Master address */
  const selectedMasterAddress = useSelector(selectMasterAddress);
  useGetMasterAddressQuery(undefined, {
    skip: selectedMasterAddress.length,
  });

  /* GET Machineries */
  const selectedMachineriesByType = useSelector((state) =>
    selectMachineriesByType(state, serviceType)
  );
  useGetMachineriesOfDriverQuery(undefined, {
    skip: selectedMachineriesByType.length,
  });

  /* GET Service (If already register) */
  const selectedServiceByType = useSelector((state) =>
    selectServiceByType(state, serviceType)
  );
  useGetDriverServiceByTypeQuery(serviceType, {
    skip: selectedServiceByType?.type,
  });

  /* POST register new Service */
  const [registerNewService, { isLoading: isRegistering }] =
    useRegisterDriverServiceMutation();
  /* PUT update Service */
  const [updateService, { isLoading: isUpdating }] =
    useUpdateDriverServiceMutation();
  /* PUT disable Service */
  const [disableService, { isLoading: isDiablingService }] =
    useToggleDriverServiceAvailabilityMutation();

  const { currentLocation } = useMapLocation({ hasLocation: editPage });
  const classes = useSwalStyles();

  useEffect(() => {
    if (!selectedServiceByType?.type) return;
    const {
      coordinateSource,
      lat,
      lon: lng,
      province,
      district,
      subDistrict,
      zipcode,
      address,
      landmark,
    } = selectedServiceByType.workingArea;
    for (let service of selectedServiceByType.services) {
      setValue(service.nameEN, true);
      setValue(`${service.nameEN}Price`, service.price);
    }
    setValue("coordinateSource", coordinateSource);
    setValue("workingMaxPerDay", selectedServiceByType.workingMaxPerDay);
    setValue("areaMinPerTime", selectedServiceByType.areaMinPerTime);
    if (coordinateSource === "map") {
      setValue("mapLat", lat);
      setValue("mapLng", lng);
      setValue("mapProvince", province);
      setValue("mapDistrict", district);
      setValue("mapSubDistrict", subDistrict);
      setValue("mapZipcode", zipcode);
    }
    if (coordinateSource === "manual") {
      setValue("province", province);
      setValue("district", district);
      setValue("subDistrict", subDistrict);
      setValue("zipcode", zipcode);
      setValue("address", address);
      setValue("landmark", landmark);
    }
  }, [selectedServiceByType, setValue]);

  const handleChangeRadio = () => {
    setTriggerTransition(true);
  };

  const toggleDrawerPickerHandler = useCallback(
    (state) => () => {
      dispatch(toggleDrawerPicker(state));
    },
    [dispatch]
  );

  const disabledPrice = (inputName) => {
    const disableds = {
      ripping: !ripping,
      plowing: !plowing,
      harrowing: !harrowing,
      tilling: !tilling,
      ridging: !ridging,
      ploughUp: !ploughUp,
      mowing: !mowing,
      hayBaling: !hayBaling,
    };
    return disableds[inputName];
  };

  const serviceOptions = useMemo(() => {
    let service = { mainService: [], price: [] };
    if (selectedMasterServiceByType?.mainService?.length) {
      service = {
        mainService: selectedMasterServiceByType.mainService.map(
          (service, idx) => ({
            id: `service-${idx + 1}`,
            name: service.nameEN,
            label: service.name,
            value: false,
          })
        ),
        price: selectedMasterServiceByType.mainService.map((service, idx) => ({
          id: `pricePerField-${idx + 1}`,
          name: `${service.nameEN}Price`,
          nameEN: service.nameEN,
          label: "",
          labelTail: "บาทต่อไร่",
        })),
      };
      return service;
    }
    return service;
  }, [selectedMasterServiceByType]);

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (type === "change") {
        for (let service of serviceOptions.mainService)
          !value[service.name] && setValue(`${service.name}Price`, "");

        const checkHaveSomeServiceTypes = serviceOptions.mainService.some(
          (service) => value[service.name]
        );
        if (checkHaveSomeServiceTypes)
          for (let service of serviceOptions.mainService) {
            clearErrors(service.name);
          }

        if (name === "coordinateSource") {
          const setDefaultFields = {
            map() {
              const manualFields = [
                "province",
                "district",
                "subDistrict",
                "zipcode",
                "address",
                "landmark",
              ];
              for (let field of manualFields) {
                setValue(field, "");
                clearErrors(field);
              }
            },
            manual() {
              const mapFields = [
                "mapLat",
                "mapLng",
                "mapProvince",
                "mapDistrict",
                "mapSubDistrict",
                "mapZipcode",
              ];
              for (let field of mapFields) {
                setValue(field, "");
                clearErrors(field);
              }
            },
          };
          setDefaultFields[value.coordinateSource]();
        }
      }

      if (name === "province") {
        setValue("district", "");
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }

      if (name === "district") {
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }
    });

    return () => subscription.unsubscribe();
  }, [serviceOptions, watch, setValue, clearErrors]);

  const onClickExpandMap = () => {
    setExpandMap((prevExpand) => !prevExpand);
  };

  const onClickToggleArea = (map) => {
    setShowArea((prevShowArea) => {
      map.setZoom(prevShowArea ? LOCATION_ZOOM : DEFAULT_ZOOM);
      map.setCenter({ lat: mapLat, lng: mapLng });
      return !prevShowArea;
    });
  };

  const setMapLocation = useCallback(
    (fieldValues) => {
      if (!fieldValues) return;
      for (const [key, value] of Object.entries(fieldValues)) {
        setValue(key, value);
        clearErrors(key);
      }
    },
    [setValue, clearErrors]
  );

  const onClickMap = async (map, event) => {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setShowArea(false);

    const addresses = await evaluateGeocoding(latLng);
    const address = getAddressManyComponents(
      { ...latLng, zoom: LOCATION_ZOOM },
      map,
      addresses
    );
    setMapLocation({
      mapLat: latLng.lat,
      mapLng: latLng.lng,
      mapProvince: address.province,
      mapDistrict: address.district,
      mapSubDistrict: address.subDistrict,
      mapZipcode: address.zipcode,
    });
  };

  const relocateCurrentLocationHandler = async (map) => {
    setShowArea(false);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const currentLatLng = {
          lat: latitude,
          lng: longitude,
        };
        dispatch(updateCurrentLocation(currentLatLng));

        const addresses = await evaluateGeocoding(currentLatLng);
        const address = getAddressManyComponents(
          { ...currentLatLng, zoom: LOCATION_ZOOM },
          map,
          addresses
        );

        setMapLocation({
          mapLat: currentLatLng.lat,
          mapLng: currentLatLng.lng,
          mapProvince: address.province,
          mapDistrict: address.district,
          mapSubDistrict: address.subDistrict,
          mapZipcode: address.zipcode,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onChangeSearchOtherLocation = useCallback(
    async (map, places) => {
      let address;
      const [place] = places.getPlaces();

      if (!place.geometry) return;

      const latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      if (place.address_components.length > 4) {
        /* Address completed! => At least Country, Provine, District, Sub-district, zipcode */
        address = getAddressComponent(
          { ...latLng, zoom: LOCATION_ZOOM },
          map,
          place.address_components
        );
      } else {
        /* Address data not completed! */
        const addresses = await evaluateGeocoding(latLng);
        address = getAddressManyComponents(
          { ...latLng, zoom: LOCATION_ZOOM },
          map,
          addresses
        );
      }

      setMapLocation({
        mapLat: latLng.lat,
        mapLng: latLng.lng,
        mapProvince: address.province,
        mapDistrict: address.district,
        mapSubDistrict: address.subDistrict,
        mapZipcode: address.zipcode,
      });
    },
    [setMapLocation]
  );

  const submitFormService = async (form) => {
    if (
      !form.ripping &&
      !form.plowing &&
      !form.harrowing &&
      !form.tilling &&
      !form.ridging &&
      !form.ploughUp &&
      !form.mowing &&
      !form.hayBaling
    ) {
      const fieldsError = serviceOptions.mainService.map(
        (service) => service.name
      );
      for (let field of fieldsError) {
        setError(field, {
          type: "requiredOne",
          message: "กรุณาเลือก บริการที่ใช้รับงานอย่างน้อย 1 ประเภท",
        });
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    const services = serviceOptions.mainService
      .filter(({ name }) => form[name])
      .map((service) => ({
        type: service.label,
        price: form[`${service.name}Price`],
      }));

    const locationDetails = {
      map: {
        lat: form.mapLat,
        lon: form.mapLng,
        province: form.mapProvince,
        district: form.mapDistrict,
        subDistrict: form.mapSubDistrict,
        zipcode: form.mapZipcode,
      },
      manual: {
        province: form.province,
        district: form.district,
        subDistrict: form.subDistrict,
        zipcode: form.zipcode,
        address: form.address,
        landmark: form.landmark,
      },
    };

    const body = {
      serviceType,
      services,
      workingArea: {
        coordinateSource: form.coordinateSource,
        ...locationDetails[form.coordinateSource],
      },
      workingMaxPerDay: form.workingMaxPerDay,
      areaMinPerTime: form.areaMinPerTime,
    };

    if (!editPage) {
      await registerNewService(body).unwrap();
      return navigate(ROUTES.MENU_DRIVER, { replace: true });
    }
    await updateService(body).unwrap();
    return navigate(ROUTES.MENU_DRIVER, { replace: true });
  };

  const submitErrorForm = () => {
    if (
      !ripping &&
      !plowing &&
      !harrowing &&
      !tilling &&
      !ridging &&
      !ploughUp &&
      !mowing &&
      !hayBaling
    ) {
      const fieldsError = serviceOptions.mainService.map(
        (service) => service.name
      );
      for (let field of fieldsError)
        setError(field, {
          type: "requiredOne",
          message: "กรุณาเลือก บริการที่ใช้รับงานอย่างน้อย 1 ประเภท",
        });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    return;
  };

  const handleDisableService = async () => {
    const swalResult = await new Swal({
      titleText: `ยืนยันยกเลิกให้บริการ${selectedMasterServiceByType.nameTH}หรือไม่`,
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "80%",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: true,
      customClass: { container: classes.swal2Delete },
    });
    if (swalResult.isConfirmed) {
      const disabledServiceResponse = await disableService({
        action: "disabled",
        serviceName: serviceType,
      }).unwrap();
      if (
        disabledServiceResponse?.error &&
        disabledServiceResponse?.jobs?.length
      ) {
        const swalErrorResult = await new Swal({
          titleText: `พบงานใหม่รอการยืนยันสถานะงาน`,
          text: "ไม่สามารถปิดบริการชั่วคราวได้ กรุณายืนยัน งานใหม่ ที่คงค้างอยู่ให้เรียบร้อยก่อนปิดบริการ",
          icon: "warning",
          cancelButtonText: "กลับหน้าเดิม",
          confirmButtonText: "จัดการงาน",
          width: "80%",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          reverseButtons: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2WarningButton,
            cancelButton: classes.swal2CancelButton,
          },
        });
        if (swalErrorResult.isConfirmed)
          return navigate(ROUTES.JOBS(currentRole));
      } else return navigate(-1, { replace: true });
    }
  };

  const mapTextInputs = useMemo(
    () => [
      {
        id: "lat-dp-none-input",
        name: "mapLat",
        label: "latitude",
      },
      { id: "lng-dp-none-input", name: "mapLng", label: "longitude" },
      {
        id: "province-dp-none-input",
        name: "mapProvince",
        label: "จังหวัด",
      },
      {
        id: "district-dp-none-input",
        name: "mapDistrict",
        label: "อำเภอ",
      },
      {
        id: "sub-district-dp-none-input",
        name: "mapSubDistrict",
        label: "ตำบล",
      },
      {
        id: "zipcode-dp-none-input",
        name: "mapZipcode",
        label: "รหัสไปรษณีย์",
      },
    ],
    []
  );

  const addressDropdownInputs = useMemo(() => {
    const noValueOption = ({ reqFieldTH, reqFieldEN, field }) => [
      {
        id: `no-${reqFieldEN}-value`,
        label: (
          <Typography variant="body1">{`กรุณาเลือก ${reqFieldTH} ก่อน`}</Typography>
        ),
        value: "",
        onClickOption() {
          toggleDrawerPickerHandler(field)();
        },
      },
    ];

    return [
      {
        id: "province-select-input",
        name: "province",
        label: "จังหวัด",
        placeholder: "เลือก จังหวัด",
        width: { label: "15%", input: "85%" },
        showDrawer: drawerPicker.province,
        optionTitle: "เลือกจังหวัด",
        value: province,
        size: { xs: 12 },
        sx: { mb: 2 },
        navPosition: "sticky",
        options: selectedMasterAddress.map((address) => ({
          id: address.id,
          label: <Typography variant="body1">{address.name}</Typography>,
          value: address.name,
          onClickOption() {
            setValue("province", address.name);
            clearErrors("province");
            toggleDrawerPickerHandler("province")();
          },
        })),
      },
      {
        id: "district-select-input",
        name: "district",
        label: "อำเภอ",
        placeholder: "เลือก อำเภอ ",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.district,
        optionTitle: "เลือกอำเภอ",
        value: district,
        size: { xs: 6 },
        sx: { pr: 0.5, mb: 2 },
        navPosition: "absolute",
        options: province
          ? selectedMasterAddress
              .find(({ name: provinceName }) => provinceName === province)
              ?.districts.map(({ name: districtName }) => ({
                id: districtName,
                label: <Typography variant="body1">{districtName}</Typography>,
                value: districtName,
                onClickOption() {
                  setValue("district", districtName);
                  clearErrors("district");
                  toggleDrawerPickerHandler("district")();
                },
              }))
          : noValueOption({
              reqFieldEN: "province",
              reqFieldTH: "จังหวัด",
              field: "district",
            }),
      },
      {
        id: "sub-district-select-input",
        name: "subDistrict",
        label: "ตำบล",
        placeholder: "เลือก ตำบล",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.subDistrict,
        optionTitle: "เลือกตำบล",
        value: subDistrict,
        size: { xs: 6 },
        sx: { pl: 0.5, mb: 2 },
        navPosition: "absolute",
        options:
          province && district
            ? selectedMasterAddress
                .find(({ name: provinceName }) => provinceName === province)
                ?.districts.find(
                  ({ name: districtName }) => districtName === district
                )
                ?.subDistricts.map(({ name: subDistrictName, zipcode }) => ({
                  id: subDistrictName,
                  label: (
                    <Typography variant="body1">{subDistrictName}</Typography>
                  ),
                  value: subDistrictName,
                  onClickOption() {
                    setValue("subDistrict", subDistrictName);
                    setValue("zipcode", zipcode);
                    console.log(zipcode);
                    clearErrors("subDistrict");
                    toggleDrawerPickerHandler("subDistrict")();
                  },
                }))
            : noValueOption({
                reqFieldEN: !province ? "province" : "district",
                reqFieldTH: !province ? "จังหวัด" : "อำเภอ",
                field: "subDistrict",
              }),
      },
    ];
  }, [
    selectedMasterAddress,
    drawerPicker,
    province,
    district,
    subDistrict,
    setValue,
    clearErrors,
    toggleDrawerPickerHandler,
  ]);

  const errorNotPickService =
    errors.ripping &&
    errors.plowing &&
    errors.harrowing &&
    errors.tilling &&
    errors.ridging &&
    errors.ploughUp &&
    errors.mowing &&
    errors.hayBaling ? (
      <Grid item xs={12} ml={1} mb={2}>
        <FormHelperText error>{errors.ripping.message}</FormHelperText>
      </Grid>
    ) : null;

  return (
    <Fragment>
      <ScrollToTop />
      <Grid container mt={3}>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          xs={12}
          mb={2}
        >
          <IconImage src={TractorIcon} alt="tractor-icon" sx={{ mb: 1 }} />
          <Typography variant="body1">
            ลงทะเบียนผู้ให้บริการรถแทรกเตอร์
          </Typography>
        </Grid>

        <HoldingDivider item xs={12}>
          <DividerFullWidth
            sx={{ transform: "translate(-5%, 0)", width: "110%" }}
          />
        </HoldingDivider>

        <Form onSubmit={handleSubmit(submitFormService, submitErrorForm)}>
          <Grid item xs={12} mb={1}>
            <Typography variant="body1" color="secondary">
              <strong>เครื่องจักรที่ใช้รับงาน</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul>
              {selectedMachineriesByType.map((tractor) => (
                <li key={tractor.id}>
                  <Typography variant="subtitle2">
                    {/* {tractor.vinType}  */}
                    {tractor.vinName}
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>

          <HoldingDivider item xs={12}>
            <DividerFullWidth
              sx={{ transform: "translate(-5%, 0)", width: "110%" }}
            />
          </HoldingDivider>

          <Grid item xs={12} mb={2}>
            <Typography variant="body1" color="secondary">
              <strong>เลือกบริการที่ใช้รับงานและระบุค่าจ้าง</strong>
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            ml={1}
            mb={!!errorNotPickService ? 0 : 1}
          >
            <Grid item xs={5}>
              <Checkbox
                control={control}
                defaultValue={false}
                checkboxOptions={serviceOptions.mainService}
              />
            </Grid>
            <Grid item xs={7}>
              {serviceOptions.price.map((price) => (
                <TextInput
                  key={price.id}
                  control={control}
                  defaultValue=""
                  name={price.name}
                  placeholder="ระบุค่าจ้าง"
                  label={price.label}
                  labelTail={price.labelTail}
                  type="number"
                  alignValue="center"
                  disabled={disabledPrice(price.nameEN)}
                  width={{ label: 0, input: "60%", labelTail: "40%" }}
                  sx={{ mb: 2 }}
                />
              ))}
            </Grid>
          </Grid>

          {errorNotPickService}

          <HoldingDivider item xs={12}>
            <DividerFullWidth
              sx={{ transform: "translate(-5%, 0)", width: "110%" }}
            />
          </HoldingDivider>

          <Grid
            item
            xs={12}
            mb={coordinateSource === "map" ? 4 : 0}
            ref={coordinateSourceRef}
          >
            <Typography variant="body1" color="secondary">
              <strong>ระบุจุดที่ท่านสามารถรับงานได้</strong>
            </Typography>
          </Grid>

          {mapTextInputs.map((textInput) => (
            <TextInput
              key={textInput.id}
              control={control}
              defaultValue=""
              name={textInput.name}
              label={textInput.label}
              type="text"
              disabled={true}
              width={{ label: "30%", input: "70%" }}
              sx={{ display: "none" }}
            />
          ))}

          <Grid item xs={12} mb={2}>
            <Radio
              control={control}
              defaultValue=""
              name="coordinateSource"
              onChangeCallback={handleChangeRadio}
              radioDirection="column"
              radioStyles={{ justifyContent: "space-around", mt: 1 }}
              radioOptions={[
                {
                  id: "map",
                  value: "map",
                  color: "primary",
                  label: (
                    <Grid container>
                      <Grid container item xs alignItems="center">
                        <Typography variant="body1">
                          ระบุตำแหน่งด้วยแผนที่
                        </Typography>
                      </Grid>
                      {coordinateSource === "map" && (
                        <Grid container item xs="auto" ml={8}>
                          <Button
                            color="primary"
                            // variant="outlined"
                            onClick={onClickExpandMap}
                            endIcon={
                              expandMap ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )
                            }
                            // sx={{ ml: "auto" }}
                          >
                            {expandMap ? "ย่อแผนที่" : "ขยายแผนที่"}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  ),
                  children: (
                    <SlidingTransition
                      show={coordinateSource === "map"}
                      elementScrollRef={coordinateSourceRef.current}
                      isTriggerTransition={triggerTransition}
                    >
                      <Box>
                        <Grid item xs={12} mt={1}>
                          <Wrapper
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                            libraries={["places"]}
                            language="th"
                            render={(_status) => (
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height={500}
                                width="100%"
                              />
                            )}
                          >
                            <GoogleMap
                              center={{
                                lat: currentLocation.lat,
                                lng: currentLocation.lng,
                              }}
                              selectedCenter={
                                editPage
                                  ? {
                                      lat: mapLat,
                                      lng: mapLng,
                                    }
                                  : null
                              }
                              selectedAddress={
                                mapProvince &&
                                mapDistrict &&
                                mapSubDistrict &&
                                `${mapSubDistrict}, ${mapDistrict}, ${mapProvince}, ${mapZipcode}`
                              }
                              errors={{
                                mapProvince: errors.mapProvince,
                                mapDistrict: errors.mapDistrict,
                                mapSubDistrict: errors.mapSubDistrict,
                              }}
                              zoom={DEFAULT_ZOOM}
                              mapHeight={expandMap ? 700 : 400}
                              radius={RADIUS.tractor}
                              searchOther={{
                                isSearchOther: true,
                                searchInput: {
                                  label: "ค้นหาตำแหน่ง",
                                  placeholder:
                                    "พิมพ์ค้นหาตำแหน่งที่ต้องการรับงาน",
                                },
                                changeOther: onChangeSearchOtherLocation,
                              }}
                              isSelectCurrent={
                                currentLocation.lat === mapLat &&
                                currentLocation.lng === mapLng
                              }
                              showArea={showArea}
                              clickMap={onClickMap}
                              clickCurrent={relocateCurrentLocationHandler}
                              clickToggleArea={onClickToggleArea}
                            >
                              <Marker
                                position={{
                                  lat: mapLat || currentLocation.lat,
                                  lng: mapLng || currentLocation.lng,
                                }}
                              />

                              {showArea && (
                                <Area
                                  center={{
                                    lat: mapLat || currentLocation.lat,
                                    lng: mapLng || currentLocation.lng,
                                  }}
                                  radius={RADIUS.tractor}
                                  strokeColor={blankPalette.secondary.main}
                                  strokeOpacity={0.75}
                                  strokeWeight={2}
                                  fillColor={blankPalette.secondary.main}
                                  fillOpacity={0.25}
                                />
                              )}
                            </GoogleMap>
                          </Wrapper>
                        </Grid>

                        <Grid item xs={12}>
                          <UnorderedList>
                            <li>
                              <Typography variant="subtitle2">
                                ระบบจะแสดงผลการจ้างงานภายในรัศมี 50 กิโลเมตร
                                จากจุดที่ท่านระบุไว้
                              </Typography>
                            </li>
                            <li>
                              <Typography variant="subtitle2">
                                กรณีแสดงรัศมีอยู่
                                จะไม่สามารถกดเลือกพื้นที่ในบริเวณรัศมีได้
                                หากต้องการเลือกพื้นที่ใหม่ กรุณากด{" "}
                                <strong>ซ่อนรัศมี</strong> ซ้ำอีกครั้ง
                                เพื่อซ่อนรัศมีก่อน
                              </Typography>
                            </li>
                          </UnorderedList>
                        </Grid>
                      </Box>
                    </SlidingTransition>
                  ),
                },
                {
                  id: "manual",
                  value: "manual",
                  label: "ระบุตำแหน่งเอง",
                  color: "primary",
                  children: (
                    <SlidingTransition
                      show={coordinateSource === "manual"}
                      elementScrollRef={coordinateSourceRef.current}
                      isTriggerTransition={triggerTransition}
                    >
                      <Grid container mt={2}>
                        {addressDropdownInputs.map((selectInput) => (
                          <Grid
                            item
                            xs={selectInput.size.xs}
                            key={selectInput.id}
                            sx={selectInput.sx}
                          >
                            <SelectPicker
                              label={selectInput.label}
                              name={selectInput.name}
                              placeholder={selectInput.placeholder}
                              control={control}
                              defaultValue=""
                              width={selectInput.width}
                              showEndIcon={false}
                              showDrawer={selectInput.showDrawer}
                              onClickInput={toggleDrawerPickerHandler(
                                selectInput.name
                              )}
                            >
                              <Navbar
                                color="secondary"
                                position={selectInput.navPosition}
                              >
                                <Navbar.Left sx={{ flexGrow: 1 }}>
                                  <Typography variant="subtitle2">
                                    {selectInput.optionTitle}
                                  </Typography>
                                </Navbar.Left>
                                <Navbar.Right sx={{ ml: "auto" }}>
                                  <IconButton
                                    onClick={toggleDrawerPickerHandler(
                                      selectInput.name
                                    )}
                                  >
                                    <CloseIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.common.white,
                                      }}
                                    />
                                  </IconButton>
                                </Navbar.Right>
                              </Navbar>
                              <Options
                                optionsData={selectInput.options}
                                selected={selectInput.value}
                              />
                            </SelectPicker>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="zipcode"
                            label="รหัสไปรษณีย์"
                            type="text"
                            width={{ label: "30%", input: "80%" }}
                            sx={{ display: "none" }}
                          />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="address"
                            label="ที่อยู่"
                            placeholder="ระบุบ้านเลขที่,ซอย, ถนน"
                            type="text"
                            multiline
                            rows={2}
                            width={{ label: "15%", input: "85%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="landmark"
                            label="จุดสังเกตุอื่นๆ"
                            placeholder="(ถ้ามี) เช่น ใกล้วัดพระศรี"
                            type="text"
                            multiline
                            rows={2}
                            width={{ label: "30%", input: "80%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <UnorderedList>
                            <li>
                              <Typography variant="subtitle2">
                                ระบบจะแสดงผลการจ้างงานภายในรัศมี 50 กิโลเมตร
                                จากจุดที่ท่านระบุไว้
                              </Typography>
                            </li>
                          </UnorderedList>
                        </Grid>
                      </Grid>
                    </SlidingTransition>
                  ),
                },
              ]}
            />
          </Grid>

          <HoldingDivider item xs={12}>
            <DividerFullWidth
              sx={{ transform: "translate(-5%, 0)", width: "110%" }}
            />
          </HoldingDivider>

          <Grid item xs={12} mb={2}>
            <Typography variant="body1" color="secondary">
              <strong>ระบุปริมาณงานที่รับได้</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2} ml={1}>
            <TextInput
              control={control}
              defaultValue=""
              name="workingMaxPerDay"
              placeholder="ระบุไร่"
              label="รับงานสูงสุด"
              labelTail="ไร่ต่อวัน"
              type="number"
              alignValue="center"
              width={{ label: "35%", input: "40%", labelTail: "25%" }}
            />
          </Grid>
          <Grid item xs={12} mb={2} ml={1}>
            <TextInput
              control={control}
              defaultValue=""
              name="areaMinPerTime"
              placeholder="ระบุไร่"
              label="จำนวนไร่ขั้นต่ำ"
              labelTail="ไร่ต่อครั้ง"
              type="number"
              alignValue="center"
              width={{ label: "35%", input: "40%", labelTail: "25%" }}
            />
          </Grid>

          <Grid container item xs={12} mb={4}>
            {editPage && (
              <Button
                color="error"
                size="small"
                startIcon={<CloseIcon />}
                onClick={handleDisableService}
                sx={{ ml: "auto" }}
              >
                ปิดบริการชั่วคราว
              </Button>
            )}
            <Fab
              variant="extended"
              color="secondary"
              type="submit"
              size="small"
              sx={{ ml: editPage ? 2 : "auto", px: 3 }}
            >
              บันทึก
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <ActionLoading open={isRegistering || isUpdating || isDiablingService}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default TractorFormPage;
