import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Container,
  Paper,
  Grid,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { ROUTES } from "routes/router";
import { ROLES } from "constants/Roles";
import KINLogo from "assets/kin-match-logo.png";
// import MockAvatar from "assets/m1_avatar.jpg";

const AppBar = styled(MuiAppBar)(({ theme, role }) => {
  const themeColor = {
    [ROLES.FARMER]: theme.palette.secondary.main,
    [ROLES.DRIVER]: theme.palette.primary.main,
  };
  return {
    boxShadow: "none",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: theme.spacing(13.5),
      width: "100%",
      backgroundColor: themeColor[role],
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: theme.spacing(13.5),
      left: 0,
      zIndex: -1,
      height: theme.spacing(3.5),
      width: "100%",
      backgroundColor: themeColor[role],
      borderRadius: "50% / 100%",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  };
});

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2, 0),
  },
}));

const NavigationCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderRadius: theme.spacing(1.25),
}));

const ImageLogo = styled("img")(({ theme }) => ({
  // width: "6.5rem",
  // "@media (min-width: 300px)": {
  width: "7.5rem",
  // },
  "@media (min-width: 360px)": {
    width: "8.5rem",
  },
  // "@media (min-width: 390px)": {
  //   width: "8.75rem",
  // },
  [theme.breakpoints.up("sm")]: {
    width: "9.5rem",
  },
}));

const NavCard = ({ navActions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    auth: {
      role: { driver },
      currentRole,
      pictureUrl,
      displayName,
      firstname,
      surname,
      tel,
      points,
      verifyOTP,
    },
  } = useSelector((state) => state);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleSetMenuAnchor = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleHideMenuAnchor = () => {
    setAnchorEl(null);
  };

  const handleClickMyProfile = () => {
    const navigatedState = {
      action: tel ? "update" : "create",
      source: tel ? "update" : "new",
    };
    navigate(ROUTES.USER_INFORMATION_FORM(currentRole), {
      state: navigatedState,
    });
  };

  const handleClickBankAccount = () => {
    // const navigatedState = {}
    navigate(ROUTES.BANK_ACCOUNT_FORM, { state: { action: "edit" } });
  };

  const showMenuFarmer = currentRole === "farmer" && tel && verifyOTP;
  const showMenuDriver = currentRole === "driver" && driver && tel && verifyOTP;

  const pointElement =
    points > 0 ? (
      <Box>
        <Typography component="span" variant="subtitle2" color="error.light">
          คะแนนสะสม:
        </Typography>
        <Typography
          component="span"
          variant="subtitle2"
          color="error.light"
          sx={{ mx: 1 }}
        >
          <strong>{points}</strong>
        </Typography>
        <Typography component="span" variant="subtitle2" color="error.light">
          pts
        </Typography>
      </Box>
    ) : null;

  return (
    <Fragment>
      <AppBar
        color="transparent"
        position="fixed"
        sx={{ boxShadow: "none" }}
        role={currentRole}
      >
        <Container maxWidth="sm">
          <Toolbar disableGutters>
            <NavigationCard elevation={2}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                columnSpacing={2}
              >
                <Grid item ml={1} xs sx={{ flexGrow: 1 }}>
                  <ImageLogo src={KINLogo} alt="KasetInno__logo" />
                </Grid>
                <Grid item my="auto" ml="auto" pr={1} xs="auto">
                  {navActions}
                </Grid>
                <Grid item xs={12}>
                  <List
                    disablePadding
                    sx={{ width: "100%", bgColor: "common.white" }}
                  >
                    <ListItem alignItems="center" divider sx={{ pt: 0 }}>
                      <ListItemAvatar sx={{ mr: 1 }}>
                        <Avatar
                          alt="line_avatar_img"
                          src={pictureUrl || ""}
                          // src={MockAvatar}
                          sx={{ width: 48, height: 48 }}
                        />
                      </ListItemAvatar>
                      <ListItemText>
                        <Grid container>
                          <Grid
                            container
                            item
                            direction="column"
                            xs={10}
                            sx={{ flexGrow: 1 }}
                          >
                            <Typography
                              component="h2"
                              variant="h6"
                              sx={{
                                my: points > 0 ? 0 : "auto",
                                whiteSpace: "nowrap",
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {firstname && surname
                                ? `${firstname} ${surname}`
                                : displayName}
                              {/* เอก เรืองพจนารักษ์ */}
                            </Typography>
                            {pointElement}
                          </Grid>
                          {showMenuFarmer || showMenuDriver ? (
                            <Grid container item xs={2}>
                              <IconButton
                                id="profile-menu-button"
                                aria-controls={
                                  open ? "profile-menu-button" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleSetMenuAnchor}
                                sx={{ ml: "auto", my: "auto" }}
                              >
                                <MenuIcon fontSize="large" />
                              </IconButton>
                            </Grid>
                          ) : null}
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </NavigationCard>
          </Toolbar>
        </Container>
      </AppBar>

      <Menu
        id="profile-menu"
        aria-labelledby="profile-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleHideMenuAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={(theme) => ({
          "& .MuiPaper-root": { borderRadius: theme.spacing(1) },
        })}
      >
        <MenuItem disabled>ตั้งค่าบัญชี</MenuItem>
        <Divider sx={{ "&.MuiDivider-root": { my: 0 } }} />
        <MenuItem onClick={handleClickMyProfile}>
          <AccountCircleIcon
            color={currentRole === "farmer" ? "secondary" : "primary"}
            sx={{ mr: 1.5 }}
          />
          ข้อมูลส่วนตัว
        </MenuItem>
        {currentRole === "driver" && (
          <MenuItem onClick={handleClickBankAccount}>
            <AccountBalanceWalletIcon
              color={currentRole === "farmer" ? "secondary" : "primary"}
              sx={{ mr: 1.5 }}
            />
            บัญชีธนาคาร
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

NavCard.propTypes = {
  navActions: PropTypes.node,
};

export default NavCard;
