import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, date, object } from "yup";
import { format, parse } from "date-fns";

import { Grid, Typography, Fab, styled } from "@mui/material";
import Swal from "sweetalert2";

import { authActions } from "store/authentication/auth-slice";
import { uiActions } from "store/user-interface/ui-slice";
import { useHttp, useDate, useSwalStyles, useWindowDimensions } from "hooks";
import { USER_ENDPOINTS } from "constants/APIs";
import { ROUTES } from "routes/router";
import { ROLES } from "constants/Roles";
import { debounce } from "helpers/Utilies";

import AcceptPDPAPage from "pages/form-sections/AcceptPDPAPage";
import {
  TextInput,
  InlineRadio,
  SelectPicker,
  DatePicker,
} from "components/hook-forms";
import { Navbar } from "layouts/components";
import {
  Modal,
  ActionLoading,
  Options,
  HoldingDivider,
  DividerFullWidth,
} from "base-components";

const validationSchema = object({
  prefixname: string().required("กรุณาเลือก คำนำหน้า"),
  firstname: string().required("กรุณากรอก ชื่อ"),
  surname: string().required("กรุณากรอก นามสกุล"),
  birthDate: date().typeError("กรุณาเลือก วันเดือนปี เกิด"),
  tel: string()
    .length(10, "จำนวนหลักเท่ากับ 10 หลักเท่านั้น")
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm,
      "เบอร์โทรศัพท์ไม่ถูกต้อง"
    )
    .required("กรุณากรอก เบอร์โทรศัพท์"),
  recommendedCode: string().test({
    name: "required-when-not-empty",
    test(value, { parent: { recommendedCodeDescription } }) {
      if (value && !recommendedCodeDescription)
        return this.createError({
          message: `ไม่พบรหัส ${value.toUpperCase()} ในระบบ กรุณากรอกรหัสแนะนำใหม่`,
          path: "recommendedCode",
        });
      return true;
    },
  }),
  recommendedCodeDescription: string().notRequired(),
});

const Form = styled("form", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "aboveElement",
})(({ height, aboveElement }) => ({
  flexGrow: 1,
  position: "relative",
  height: `${height * (aboveElement ? 0.75 : 0.85)}px`,
}));

const NewUserInformationFormPage = (props) => {
  const dispatch = useDispatch();
  const {
    prefixname,
    firstname,
    surname,
    tel,
    birthDate,
    recommendedCode,
    recommendedDesc,
    knownChannel,
    pdpa_accept,
    verifyOTP,
  } = useSelector((state) => state.auth);
  const { drawerPicker, modal } = useSelector((state) => state.ui);
  const [disabledAcceptPDPA, setDisabledAcceptPDPA] = useState(true);
  const lastParagraphRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  // create || update
  const action = state?.action;
  // new || getztrac || update
  const source = state?.source;
  const { role } = useParams();
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const prefixnameValue = getValues("prefixname");
  const recommendedCodeDescriptionValue = watch("recommendedCodeDescription");

  const { isLoading: isQuerying, sendRequest: requestCodeValidation } =
    useHttp();
  const { isLoading: isSubmitting, sendRequest: requestCUDRegisterData } =
    useHttp();
  const { disabledFutureDate } = useDate();
  const classes = useSwalStyles();
  const { height } = useWindowDimensions();

  const toggleDrawerPicker = useCallback(() => {
    dispatch(uiActions.toggleDrawerPicker("prefixname"));
  }, [dispatch]);

  const toggleModal = useCallback(
    (modalState = { name: "" }) =>
      () => {
        if ("isOpen" in modalState) {
          dispatch(
            uiActions.toggleModal({
              name: modalState.name,
              isOpen: modalState.isOpen,
            })
          );
          navigate(
            role === ROLES.FARMER ? ROUTES.MENU_FARMER : ROUTES.MENU_DRIVER
          );
        } else dispatch(uiActions.toggleModal({ name: modalState.name }));
      },
    [role, navigate, dispatch]
  );

  useEffect(() => {
    if (
      prefixname ||
      firstname ||
      surname ||
      tel ||
      birthDate ||
      recommendedCode ||
      recommendedDesc ||
      knownChannel
    ) {
      setValue("prefixname", prefixname);
      setValue("firstname", firstname);
      setValue("surname", surname);
      setValue("tel", tel);
      setValue("birthDate", parse(birthDate, "dd/MM/yyyy", new Date()));
      setValue("recommendedCode", recommendedCode);
      setValue("recommendedCodeDescription", recommendedDesc);
      setValue("knownChannel", knownChannel);
    }

    if (!pdpa_accept || pdpa_accept === "false")
      toggleModal({ name: "pdpaAccept" })();
  }, [
    prefixname,
    firstname,
    surname,
    tel,
    birthDate,
    recommendedCode,
    recommendedDesc,
    knownChannel,
    pdpa_accept,
    setValue,
    dispatch,
    toggleModal,
  ]);

  const scrollPDPAContentHandler = () => {
    const contentObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDisabledAcceptPDPA(false);
        } else setDisabledAcceptPDPA(true);
      },
      {
        root: null,
        threshold: 1,
        rootMargin: "-40px",
      }
    );

    contentObserver.observe(lastParagraphRef.current);
  };

  const handleAcceptPDPA = () => {
    dispatch(authActions.updatePDPA(true));
    toggleModal({ name: "pdpaAccept" })();
  };

  const checkExistRecommendedCode = (code) => {
    if (!code) return clearErrors("recommendedCode");
    requestCodeValidation(
      {
        endpoint: USER_ENDPOINTS.validateCode(code.toUpperCase()),
      },
      ({ exist, description }) => {
        if (!exist) {
          setValue("recommendedCodeDescription", "");
          return setError("recommendedCode", {
            type: "Recommended code not found",
            message: `ไม่พบรหัส ${code.toUpperCase()} ในระบบ กรุณากรอกรหัสแนะนำใหม่`,
          });
        }
        setValue("recommendedCodeDescription", description);
        return clearErrors("recommendedCode");
      }
    );
  };

  const checkChangeTelNo = (
    existedTel,
    newTel,
    { changeCbFunc = () => {}, notChangeCbFunc = () => {} }
  ) => {
    if (existedTel !== newTel) return changeCbFunc();
    return notChangeCbFunc();
  };

  const submitUserInformation = async (data) => {
    if (errors.recommendedCode) {
      setError("recommendedCode", {
        type: "Recommended code not found",
        message: `ไม่พบรหัส ${data.recommendedCode.toUpperCase()} ในระบบ กรุณากรอกรหัสแนะนำใหม่`,
      });
      return;
    }

    const birthDateString = format(data.birthDate, "dd/MM/yyyy");

    const body = {
      prefixname: data.prefixname,
      firstname: data.firstname,
      surname: data.surname,
      birthDate: birthDateString,
      recommendedCode: data.recommendedCode
        ? data.recommendedCode.toUpperCase()
        : "",
      knownChannel: data.knownChannel,
      pdpa_accept,
      ...((tel !== data.tel || !verifyOTP) && { tel: data.tel }),
    };

    requestCUDRegisterData(
      {
        endpoint:
          action === "create"
            ? USER_ENDPOINTS.create(role)
            : USER_ENDPOINTS.update(role),
        method: action === "create" ? "POST" : "PUT",
        body,
      },
      async (firstUpdResponse) => {
        if (
          firstUpdResponse.error &&
          firstUpdResponse.error === "Duplicate tel."
        ) {
          /* Update others field except TEL! */
          dispatch(
            authActions.updateUser({
              prefixname: firstUpdResponse.data.prefixname,
              firstname: firstUpdResponse.data.firstname,
              surname: firstUpdResponse.data.surname,
              birthDate: firstUpdResponse.data.birthDate,
              recommendedCode: firstUpdResponse.data.recommendedCode,
              recommendedDesc: data.recommendedCodeDescription,
              knownChannel: data.knownChannel,
              pdpa_accept: firstUpdResponse.data.pdpa_accept,
            })
          );
          /* Open Swal to warning duplicated tel no. */
          const swalResp = await new Swal({
            titleText: `หมายเลขโทรศัพท์นี้ถูกใช้ไปแล้ว`,
            text: `หมายเลขโทรศัพท์นี้เคยถูกใช้ลงทะเบียนแล้ว หากยืนยันจะใช้เบอร์นี้เพื่อลงทะเบียน ${
              source === "new"
                ? "ระบบจะโอนย้ายข้อมูลจากบัญชีเดิมโดยอัตโนมัติ"
                : ""
            } คุณจะไม่สามารถใช้งานบัญชีเดิมได้`,
            icon: "warning",
            cancelButtonText: "กลับหน้าเดิม",
            confirmButtonText: "ยืนยัน",
            width: "80%",
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              container: classes.swal2Container,
              confirmButton: classes.swal2WarningButton,
              cancelButton: classes.swal2CancelButton,
            },
          });
          if (swalResp.isConfirmed)
            /* Confirmed update with duplicated tel no.  */
            requestCUDRegisterData(
              {
                endpoint: USER_ENDPOINTS.update(role),
                method: "PUT",
                body: {
                  tel: data.tel,
                  source,
                  confirmDuplicated: true,
                },
              },
              (dupUpdResponse) => {
                dispatch(
                  authActions.setOtpSession({
                    ...dupUpdResponse,
                    confirmDuplicated: true,
                    tel,
                  })
                );
                return navigate(ROUTES.OTP_FORM, {
                  replace: true,
                  state: { action, source, tempTel: data.tel },
                });
              }
            );
          return;
        }

        const roleActions = {
          /* New registering => Always verify OTP */
          create: {
            farmer() {
              dispatch(
                authActions.updateUser({
                  prefixname: firstUpdResponse.data.prefixname,
                  firstname: firstUpdResponse.data.firstname,
                  surname: firstUpdResponse.data.surname,
                  birthDate: firstUpdResponse.data.birthDate,
                  recommendedCode: firstUpdResponse.data.recommendedCode,
                  recommendedDesc: data.recommendedCodeDescription,
                  knownChannel: data.knownChannel,
                })
              );
              dispatch(
                authActions.setOtpSession({
                  refno: firstUpdResponse.refno,
                  token: firstUpdResponse.token,
                })
              );
              return navigate(ROUTES.OTP_FORM, {
                replace: true,
                state: {
                  action,
                  source,
                  tempTel: data.tel,
                },
              });
            },
            driver() {
              dispatch(
                authActions.updateUser({
                  prefixname: firstUpdResponse.prefixname,
                  firstname: firstUpdResponse.firstname,
                  surname: firstUpdResponse.surname,
                  birthDate: firstUpdResponse.birthDate,
                  recommendedCode: firstUpdResponse.recommendedCode,
                  recommendedDesc: data.recommendedCodeDescription,
                  knownChannel: data.knownChannel,
                })
              );
              dispatch(authActions.updateRoles(firstUpdResponse.role));
              return navigate(ROUTES.BANK_ACCOUNT_FORM, {
                state: { action, source, tempTel: data.tel },
              });
            },
          },
          /* Updating => check change tel no. before dispatch state and redirect page */
          update: {
            farmer() {
              checkChangeTelNo(tel, data.tel, {
                notChangeCbFunc() {
                  if (!verifyOTP) {
                    dispatch(
                      authActions.updateUser({
                        prefixname: firstUpdResponse.data.prefixname,
                        firstname: firstUpdResponse.data.firstname,
                        surname: firstUpdResponse.data.surname,
                        birthDate: firstUpdResponse.data.birthDate,
                        recommendedCode: firstUpdResponse.data.recommendedCode,
                        recommendedDesc: data.recommendedCodeDescription,
                        knownChannel: data.knownChannel,
                      })
                    );
                    dispatch(
                      authActions.setOtpSession({
                        refno: firstUpdResponse.refno,
                        token: firstUpdResponse.token,
                      })
                    );
                    return navigate(ROUTES.OTP_FORM, {
                      state: {
                        action: "create",
                        source: "new",
                        tempTel: firstUpdResponse.data.tel,
                      },
                    });
                  }
                  dispatch(
                    authActions.updateUser({
                      prefixname: firstUpdResponse.prefixname,
                      firstname: firstUpdResponse.firstname,
                      surname: firstUpdResponse.surname,
                      birthDate: firstUpdResponse.birthDate,
                      recommendedCode: firstUpdResponse.recommendedCode,
                      recommendedDesc: data.recommendedCodeDescription,
                      knownChannel: data.knownChannel,
                      tel: firstUpdResponse.tel,
                    })
                  );
                  return navigate(-1, { replace: true });
                },
                changeCbFunc() {
                  dispatch(
                    authActions.updateUser({
                      prefixname: firstUpdResponse.data.prefixname,
                      firstname: firstUpdResponse.data.firstname,
                      surname: firstUpdResponse.data.surname,
                      birthDate: firstUpdResponse.data.birthDate,
                      recommendedCode: firstUpdResponse.data.recommendedCode,
                      recommendedDesc: data.recommendedCodeDescription,
                      knownChannel: data.knownChannel,
                      tel: firstUpdResponse.data.tel,
                    })
                  );
                  dispatch(
                    authActions.setOtpSession({
                      pin: "",
                      token: firstUpdResponse.token,
                      refno: firstUpdResponse.refno,
                      tel: firstUpdResponse.data.tel,
                    })
                  );
                  navigate(ROUTES.OTP_FORM, {
                    replace: true,
                    state: { action, source, tempTel: data.tel },
                  });
                },
              });
            },
            driver() {
              checkChangeTelNo(tel, data.tel, {
                notChangeCbFunc() {
                  dispatch(
                    authActions.updateUser({
                      prefixname: firstUpdResponse.prefixname,
                      firstname: firstUpdResponse.firstname,
                      surname: firstUpdResponse.surname,
                      birthDate: firstUpdResponse.birthDate,
                      recommendedCode: firstUpdResponse.recommendedCode,
                      recommendedDesc: data.recommendedCodeDescription,
                      knownChannel: data.knownChannel,
                      tel: firstUpdResponse.tel,
                    })
                  );
                  navigate(-1);
                },
                changeCbFunc() {
                  dispatch(
                    authActions.updateUser({
                      prefixname: firstUpdResponse.data.prefixname,
                      firstname: firstUpdResponse.data.firstname,
                      surname: firstUpdResponse.data.surname,
                      birthDate: firstUpdResponse.data.birthDate,
                      recommendedCode: firstUpdResponse.data.recommendedCode,
                      recommendedDesc: data.recommendedCodeDescription,
                      knownChannel: data.knownChannel,
                      tel: firstUpdResponse.data.tel,
                    })
                  );
                  dispatch(
                    authActions.setOtpSession({
                      pin: "",
                      token: firstUpdResponse.token,
                      refno: firstUpdResponse.refno,
                      tel: firstUpdResponse.data.tel,
                    })
                  );
                  navigate(ROUTES.OTP_FORM, {
                    replace: true,
                    state: { action, source, tempTel: data.tel },
                  });
                },
              });
            },
          },
        };

        return roleActions[action][role]();
      }
    );
  };

  const prefixOptions = useMemo(
    () => [
      {
        id: 1,
        label: <Typography variant="body1">นาย</Typography>,
        value: "นาย",
        onClickOption() {
          setValue("prefixname", "นาย");
          clearErrors("prefixname");
          toggleDrawerPicker();
        },
      },
      {
        id: 2,
        label: <Typography variant="body1">นาง</Typography>,
        value: "นาง",
        onClickOption() {
          setValue("prefixname", "นาง");
          clearErrors("prefixname");
          toggleDrawerPicker();
        },
      },
      {
        id: 3,
        label: <Typography variant="body1">นางสาว</Typography>,
        value: "นางสาว",
        onClickOption() {
          setValue("prefixname", "นางสาว");
          clearErrors("prefixname");
          toggleDrawerPicker();
        },
      },
      {
        id: 4,
        label: <Typography variant="body1">ไม่ระบุ</Typography>,
        value: "ไม่ระบุ",
        onClickOption() {
          setValue("prefixname", "ไม่ระบุ");
          clearErrors("prefixname");
          toggleDrawerPicker();
        },
      },
    ],
    [setValue, clearErrors, toggleDrawerPicker]
  );

  const driverCreationWording = action === "create" && (
    <Grid item xs={12} mb={3}>
      <Typography sx={{ color: "error.main", mb: 0.5 }} variant="body1">
        <strong>คุณยังไม่เคยกรอกข้อมูลส่วนบุคคล</strong>
      </Typography>
      <Typography variant="subtitle2">
        กรุณากรอกข้อมูลส่วนบุคคลก่อนเริ่ม
        {role === ROLES.FARMER ? "จ้างงาน" : "ใช้งานโหมดคนขับ"}
      </Typography>
    </Grid>
  );

  const informationFormDetail = action === "create" && (
    <Grid item xs={12} mb={4}>
      <Typography color="secondary.main" variant="subtitle2">
        ใส่รหัสแนะนำเพื่อสะสมแต้มและรับสิทธิพิเศษต่างๆ
      </Typography>
      <Typography color="secondary.main" variant="subtitle2">
        จากทางบริษัทฯ
      </Typography>
    </Grid>
  );

  return (
    <Fragment>
      <Grid container mt={3} mb={2}>
        {driverCreationWording}

        <Form
          onSubmit={handleSubmit(submitUserInformation)}
          height={height}
          aboveElement={driverCreationWording}
        >
          <Grid item xs={12} mb={2}>
            <Typography variant="body1">
              <strong>
                {action === "create" ? "กรอก" : "แก้ไข"}ข้อมูลส่วนบุคคล
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <SelectPicker
              name="prefixname"
              control={control}
              defaultValue=""
              label="คำนำหน้า"
              width={{ label: "30%", input: "70%" }}
              showDrawer={drawerPicker.prefixname}
              onClickInput={toggleDrawerPicker}
            >
              <Navbar color="secondary" position="absolute">
                <Navbar.Left>
                  <Typography variant="subtitle2">เลือกคำนำหน้าชื่อ</Typography>
                </Navbar.Left>
              </Navbar>
              <Options optionsData={prefixOptions} selected={prefixnameValue} />
            </SelectPicker>
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextInput
              name="firstname"
              control={control}
              defaultValue=""
              label="ชื่อ"
              placeholder="ระบุชื่อ"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextInput
              name="surname"
              control={control}
              defaultValue=""
              label="นามสกุล"
              placeholder="ระบุนามสกุล"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <DatePicker
              showIcon
              showErrorText
              size="medium"
              variant="outlined"
              name="birthDate"
              control={control}
              defaultValue=""
              label="วันเดือนปี เกิด"
              placeholder="ระบุวันเดือนปี เกิด"
              toolbarTitle="เลือก วันเดือนปี เกิด"
              shouldDisableDate={disabledFutureDate}
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextInput
              name="tel"
              control={control}
              defaultValue=""
              label="เบอร์โทรศัพท์"
              placeholder="ระบุเบอร์โทรศัพท์"
              type="tel"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextInput
              name="recommendedCode"
              control={control}
              defaultValue=""
              label="รหัสแนะนำ"
              placeholder="(ถ้ามี)"
              helper={recommendedCodeDescriptionValue}
              disabled={action === "update" && Boolean(recommendedCode)}
              onChangeInput={debounce(checkExistRecommendedCode, 1000)}
              width={{ label: "30%", input: "70%" }}
            />
            <TextInput
              name="recommendedCodeDescription"
              control={control}
              defaultValue=""
              sx={{ display: "none" }}
            />
          </Grid>
          {informationFormDetail}
          <HoldingDivider item xs={12}>
            <DividerFullWidth
              sx={{ transform: "translate(-5%, 0)", width: "110%" }}
            />
          </HoldingDivider>

          <Grid item xs={12} mb={2}>
            <Typography variant="body1">
              <strong>คุณรู้จักคินแมทช์จากช่องทางไหน?</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <InlineRadio
              name="knownChannel"
              control={control}
              defaultValue=""
              checkedColor={role === ROLES.FARMER ? "secondary" : "primary"}
              radioOptions={[
                {
                  id: "knownChannel_01",
                  label: (
                    <Typography variant="subtitle2">
                      ช่างบริการสยามคูโบต้า
                    </Typography>
                  ),
                  value: "ช่างบริการสยามคูโบต้า",
                  columnSize: { xs: 6 },
                },
                {
                  id: "knownChannel_02",
                  label: (
                    <Typography variant="subtitle2">
                      ร้านผู้แทนจำหน่าย
                    </Typography>
                  ),
                  value: "ร้านผู้แทนจำหน่าย",
                  columnSize: { xs: 6 },
                },
                {
                  id: "knownChannel_03",
                  label: (
                    <Typography variant="subtitle2">เพื่อนบ้าน/ญาติ</Typography>
                  ),
                  value: "เพื่อนบ้าน/ญาติ",
                  columnSize: { xs: 6 },
                },
                {
                  id: "knownChannel_04",
                  label: (
                    <Typography variant="subtitle2">
                      เฟสบุ้ค (Facebook)
                    </Typography>
                  ),
                  value: "Facebook",
                  columnSize: { xs: 6 },
                },
                {
                  id: "knownChannel_05",
                  label: (
                    <Typography variant="subtitle2">สื่อโฆษณาอื่นๆ</Typography>
                  ),
                  value: "สื่อโฆษณาอื่นๆ",
                  columnSize: { xs: 6 },
                },
              ]}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            xs={12}
            sx={{ position: "absolute", bottom: 0 }}
          >
            <Fab
              size="small"
              color="secondary"
              variant="extended"
              type="submit"
              sx={{ px: 3 }}
            >
              {action === "create" ? "ถัดไป" : "บันทึก"}
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <Modal
        openModal={modal.pdpaAccept}
        onCloseModal={toggleModal({ name: "pdpaAccept", isOpen: false })}
        titleWithClose
      >
        <Modal.Header
          closeButton
          onCloseModal={toggleModal({ name: "pdpaAccept", isOpen: false })}
        >
          <Typography
            component="div"
            align="center"
            variant="body1"
            sx={{ mr: 4, mb: 1, fontSize: "1rem" }}
          >
            <strong>นโยบายคุ้มครองข้อมูลส่วนบุคคลและแบบความยินยอม</strong>
          </Typography>
          <Typography
            component="div"
            align="center"
            variant="body1"
            sx={{ mr: 4, fontSize: "1rem" }}
          >
            <strong>บริษัท เกษตรอินโน จำกัด</strong>
          </Typography>
        </Modal.Header>
        <Modal.Content dividers onScrollModalContent={scrollPDPAContentHandler}>
          <AcceptPDPAPage ref={lastParagraphRef} />
        </Modal.Content>
        <Modal.Footer sx={{ pb: 2 }}>
          <Grid container justifyContent="center">
            <Fab
              variant="extended"
              color="secondary"
              size="small"
              disabled={disabledAcceptPDPA}
              onClick={handleAcceptPDPA}
              sx={{ px: 3 }}
            >
              ยอมรับ
            </Fab>
          </Grid>
        </Modal.Footer>
      </Modal>

      <ActionLoading open={isSubmitting}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>

      <ActionLoading open={isQuerying}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังค้นหารายละเอียดจากรหัสแนะนำ
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default NewUserInformationFormPage;
