import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Typography, Link, Button } from "@mui/material";

import { selectCustomerJobById } from "store/job-slice";
import {
  useSearchAvailableDriversQuery,
  useGetCustomerJobByIdQuery,
  useMatchingJobMutation,
} from "services/job";

import { toggleModal } from "store/user-interface/ui-slice";
import { useHttp } from "hooks";
// import { JOB_ENDPOINTS } from "constants/APIs";

import { Modal, ActionLoading } from "base-components";
import { DriverCard } from "components";
import { ROUTES } from "routes/router";
import { VEHICLES } from "constants/Vehicles";

const AvailableDriversPage = () => {
  const dispatch = useDispatch();
  // const { firstname, surname } = useSelector((state) => state.auth);
  const { modal } = useSelector((state) => state.ui);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const navigate = useNavigate();
  const { jobId } = useParams();

  /* GET available drivers that matching with job criteria */
  const { availableDrivers, isLoading: isSearching } =
    useSearchAvailableDriversQuery(jobId, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
      selectFromResult: ({ data, isLoading }) => ({
        availableDrivers: data?.data,
        isLoading: isLoading,
      }),
    });

  /* GET Job by ID */
  const selectedCustomerJobById = useSelector((state) =>
    selectCustomerJobById(state, jobId)
  );
  useGetCustomerJobByIdQuery(jobId, {
    skip: jobId && selectedCustomerJobById?.id,
  });

  /* PUT matching Job */
  const [mactchingJob, { isLoading: isRequesting }] = useMatchingJobMutation();

  const {
    isLoading: isSubmitting,
    // sendRequest: requestContactData
  } = useHttp();

  const toggleModalHandler = (modalState, driverId) => () => {
    dispatch(toggleModal({ name: modalState }));
    if (driverId)
      setSelectedDriver(() => {
        const driver = availableDrivers.find(
          (driver) => driver.line_uid === driverId
        );
        return driver;
      });
  };

  // const submitContactUs = (e) => {
  //   e.preventDefault();

  //   const baseBody = {
  //     page: "ค้นหาคนขับ",
  //     description: "not found driver",
  //     name: `${firstname} ${surname}`,
  //     id: selectedCustomerJobById.id,
  //     service: selectedCustomerJobById.service,
  //     serviceType: selectedCustomerJobById.serviceType,
  //     vinType: selectedCustomerJobById.vinType,
  //     serviceDate: selectedCustomerJobById.serviceDate,
  //     serviceTime: selectedCustomerJobById.serviceTime,
  //   };

  //   const serviceWithFieldBody = {
  //     fieldName: selectedCustomerJobById.fieldName,
  //     fieldSize: selectedCustomerJobById.fieldSize,
  //     fieldLat: selectedCustomerJobById.fieldLat,
  //     fieldLon: selectedCustomerJobById.fieldLon,
  //     fieldAddress: selectedCustomerJobById.fieldAddress,
  //   };

  //   const othersBody = {
  //     [VEHICLES.drone.nameEN]: {
  //       ...serviceWithFieldBody,
  //       plantType: selectedCustomerJobById.plantType,
  //       preparedChemical: selectedCustomerJobById.preparedChemical,
  //     },
  //     [VEHICLES.combine.nameEN]: {
  //       ...serviceWithFieldBody,
  //       plantType: selectedCustomerJobById.plantType,
  //     },
  //     [VEHICLES.tractor.nameEN]: serviceWithFieldBody,
  //     [VEHICLES.excavator.nameEN]: {
  //       lat: selectedCustomerJobById.lat,
  //       lon: selectedCustomerJobById.lon,
  //       address: `${selectedCustomerJobById.subDistrict} ${selectedCustomerJobById.district} ${selectedCustomerJobById.province}`,
  //       landmark: selectedCustomerJobById.landmark,
  //     },
  //   };

  //   const body = {
  //     ...baseBody,
  //     ...othersBody[selectedCustomerJobById.vinType],
  //   };

  //   requestContactData(
  //     { endpoint: JOB_ENDPOINTS.farmer.noDriver(jobId), method: "POST", body },
  //     () => navigate(ROUTES.MENU_FARMER, { replace: true })
  //   );
  // };

  const submitConfirmDriver = async () => {
    toggleModalHandler("confirmDriver")();

    const body = {
      jobId,
      driverId: selectedDriver.line_uid,
      firstname: selectedDriver.firstname,
      surname: selectedDriver.surname,
      address: selectedDriver.address,
      price:
        selectedCustomerJobById.vinType === VEHICLES.excavator.nameEN
          ? 0
          : selectedDriver.price,
      discount: 0,
    };

    await mactchingJob({ jobId, body }).unwrap();
    navigate(ROUTES.JOB_MATCHED(jobId), {
      replace: true,
      state: {
        driverName: `${selectedDriver.prefixname}${selectedDriver.firstname} ${selectedDriver.surname}`,
      },
    });
  };

  if (!isSearching && !availableDrivers?.length)
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          sx={{ height: "75vh" }}
        >
          <Grid item xs={12} mb={3} px={3}>
            <Typography
              component="p"
              align="center"
              color="secondary"
              variant="h5"
            >
              <strong>ไม่พบคนขับในบริเวณใกล้เคียงพื้นที่ของท่าน</strong>
            </Typography>
          </Grid>

          {/* <Grid item xs={12} px={2} mb={6}>
            <Typography component="p" align="center" variant="h6">
              หากท่านต้องการให้เจ้าหน้าที่ติดต่อกลับภายหลัง กรุณากดปุ่ม
              "ติดต่อกลับ"
            </Typography>
            <Typography component="p" align="center" variant="h6">
              เจ้าหน้าที่จะติดต่อหาท่านอีกครั้ง
            </Typography>
          </Grid>

          <Grid container item justifyContent="center" xs={12} mb={5}>
            <Link
              href="#"
              onClick={submitContactUs}
              sx={(theme) => ({
                px: 3,
                py: 1,
                fontSize: "1.125rem",
                textDecoration: "none",
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: theme.spacing(3),
                boxShadow: theme.shadows[3],
              })}
            >
              <strong>ติดต่อกลับ</strong>
            </Link>
          </Grid> */}

          <Grid container item justifyContent="center" xs={12}>
            <Link
              href="#"
              color="secondary"
              onClick={() => {
                navigate(ROUTES.MENU_FARMER, { replace: true });
              }}
            >
              กลับหน้าหลัก
            </Link>
          </Grid>
        </Grid>

        <Modal
          openModal={modal.contactAdmin}
          onCloseModal={toggleModalHandler("contactAdmin")}
        >
          {/* <Modal.Header onCloseModal={toggleModalHandler("contactAdmin")} closeButton>
            <Typography variant="body1"></Typography>
          </Modal.Header> */}
          <Modal.Content>
            <Grid container justifyContent="center">
              <Typography variant="body1" color="secondary"></Typography>
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button
              onClick={toggleModalHandler("contactAdmin")}
              color="tertiary"
            >
              ยกเลิก
            </Button>
          </Modal.Footer>
        </Modal>

        <ActionLoading color="secondary" open={isSubmitting}>
          <Typography textAlign="center" variant="h5" color="common.white">
            กำลังบันทึกข้อมูล
          </Typography>
        </ActionLoading>
      </Fragment>
    );

  return (
    <Fragment>
      <Grid container item mt={3} mb={2}>
        <Grid item xs={12} mb={2}>
          <Typography color="tertiary" variant="subtitle2">
            พบคนขับ {availableDrivers?.length} ราย ที่ตรงกับความต้องการของคุณ
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Typography color="secondary" variant="subtitle2">
            กรุณาเลือกคนขับ
          </Typography>
        </Grid>

        {availableDrivers?.map((driver) => (
          <DriverCard
            key={driver.line_uid}
            profileImage={driver.profileImage}
            prefixname={driver.prefixname}
            firstname={driver.firstname}
            surname={driver.surname}
            displacement={driver.displacement}
            address={driver.address}
            plantType={driver.plantType}
            price={driver.price}
            movingCost={driver?.movingCost}
            size={driver?.size}
            workingTimes={driver.workingTimes}
            service={selectedCustomerJobById?.service}
            vinType={selectedCustomerJobById?.vinType}
            serviceType={selectedCustomerJobById?.serviceType}
            clickDriverCard={toggleModalHandler(
              "confirmDriver",
              driver?.line_uid
            )}
          />
        ))}
      </Grid>

      <Modal
        openModal={modal.confirmDriver}
        onCloseModal={toggleModalHandler("confirmDriver")}
      >
        <Modal.Content>
          <Grid container justifyContent="center">
            <Grid item xs={12} px={3} mb={3}>
              <Typography align="center" variant="body1">
                <strong>
                  ท่านยืนยันการส่งคำขอจ้างงานให้ {selectedDriver?.prefixname}
                  {selectedDriver?.firstname} {selectedDriver?.surname}{" "}
                  ใช่หรือไม่ ?
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="subtitle1">
                การส่งคำขอจ้างงานจะส่งได้เพียง ครั้งละ 1 คน เท่านั้น
              </Typography>
              <Typography align="center" variant="subtitle1">
                กรุณาตรวจสอบให้แน่ใจก่อนกดยืนยัน
              </Typography>
            </Grid>
          </Grid>
        </Modal.Content>
        <Modal.Footer sx={{ mb: 2 }}>
          <Grid container justifyContent="space-around">
            <Button
              onClick={toggleModalHandler("confirmDriver")}
              variant="contained"
              color="tertiary"
            >
              ยกเลิก
            </Button>
            <Button
              onClick={submitConfirmDriver}
              variant="contained"
              color="primary"
            >
              ยืนยันการจ้างงาน
            </Button>
          </Grid>
        </Modal.Footer>
      </Modal>

      <ActionLoading color="primary" open={isRequesting}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังส่งคำขอจ้างงาน
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default AvailableDriversPage;
