export const FARMER_ENDPOINTS = {
  checkExists: "/farmers/exists",
  farmer: "/farmers/id",
  updateFarmer: "/farmers/update",
};

export const DRIVER_ENDPOINTS = {
  checkExists: "/drivers/exists",
  update: "/drivers/update",
  driver: "/drivers/id",
  bankLists: "/drivers/banklists",
  verifyTelNo: "/drivers/verify",
  // verifyOtp: "/drivers/verifyotp",
  /* Temp */
  resetRegisterGetztrac: "/drivers/reset",
};

export const USER_ENDPOINTS = {
  create(role) {
    if (!role) throw new Error("Can't create user without role");
    return `/${role}s/create`;
  },
  update(role) {
    if (!role) throw new Error("Can't update user without role");
    return `/${role}s/update`;
  },
  validateCode(code) {
    if (!code) throw new Error("Can't validate recommended code without code");
    return `/reccode/exists/${code}`;
  },
  verifyOtp(role) {
    if (!role) throw new Error("Can't verify OTP without role");
    return `/${role}s/verifyotp`;
  },
};

export const VEHICLE_ENDPOINTS = {
  master: "/vehicles/lists",
  all: "/vehicles/all",
  generateName(vinTypeTH) {
    if (!vinTypeTH)
      throw new Error("Can't auto generate name without vin type");
    return `/vehicles/name/${vinTypeTH}`;
  },
  getOne(vinId) {
    if (!vinId) return "/vehicles/all";
    return `/vehicles/id/${vinId}`;
  },
  create: "/vehicles/create",
  update(vinId) {
    if (!vinId) return "/vehicles/create";
    return `/vehicles/update/${vinId}`;
  },
  delete(vinId) {
    if (!vinId) throw new Error("Can't delete vehicle without vinId");
    return `/vehicles/delete/${vinId}`;
  },
};

export const SERVICE_ENDPOINTS = {
  master: "/services/types",
  eachMaster(serviceName) {
    if (!serviceName)
      throw new Error("Can't get service options without serviceName");
    return `/services/lists/${serviceName}`;
  },
  all: "/services/all",
  getOne(serviceName) {
    if (!serviceName) throw new Error("Can't get service without serviceName");
    return `/services/id/${serviceName}`;
  },
  create: "/services/create",
  update: "/services/update",
  /**
   * request with PUT method for driver to disable/enable service.
   * @param {String} action disabled||enable
   * @param {String} serviceName tractor || drone || combine || excavator
   * @returns {String} endpoint path for request PUT
   */
  actionDisabledEnable(action, serviceName) {
    if (!action || !serviceName)
      throw new Error("Can't delete service without serviceName");
    return `/services/${action}/${serviceName}`;
  },
};

export const FIELD_ENDPOINTS = {
  master: "/fields/master",
  all: "/fields/all",
  create: "/fields/create",
  update(fieldName) {
    if (!fieldName) return "/fields/create";
    return `/fields/update/${fieldName}`;
  },
  delete(fieldName) {
    if (!fieldName) throw new Error("Can't delete field without fieldName");
    return `/fields/delete/${fieldName}`;
  },
};

export const JOB_ENDPOINTS = {
  farmer: {
    all(params) {
      if (!params) return "/jobs/all";
      /* created, working, confirmed, sooning, inDay, working, delivered, accepted, checking, paid */
      return `/jobs/all?stage=${params}`;
    },
    getOne(jobId) {
      if (!jobId) throw new Error("Can't fetch job without jobId");
      return `/jobs/id/${jobId}`;
    },
    create: "/jobs/create",
    update: "/jobs/update",
    delete(jobId) {
      if (!jobId) throw new Error("Can't delete job without jobId");
      return `/jobs/delete/${jobId}`;
    },
    search(jobId) {
      if (!jobId) throw new Error("Can't search job without jobId");
      return `/jobs/${jobId}/drivers`;
    },
    matching(jobId) {
      if (!jobId) throw new Error("Can't match job without jobId");
      return `/jobs/${jobId}/matching`;
    },
    noDriver(jobId) {
      if (!jobId) throw new Error("Can't send job data without jobId");
      return `/jobs/${jobId}/contactus`;
    },
    reject(jobId) {
      if (!jobId) throw new Error("Can't reject job without jobId");
      return `/jobs/${jobId}/cancel`;
    },
    acceptOffer(jobId) {
      if (!jobId) throw new Error("Can't accept offer price without jobId");
      return `/jobs/${jobId}/confirm`;
    },
    rejectOffer(jobId) {
      if (!jobId) throw new Error("Can't reject offer price without jobId");
      return `/jobs/${jobId}/canceledited`;
    },
    acceptedWork(jobId) {
      if (!jobId) throw new Error("Can't accept job without jobId");
      return `/jobs/${jobId}/accept`;
    },
    uploadSlip(jobId) {
      if (!jobId) throw new Error("Can't upload paid slip without jobId");
      return `/jobs/${jobId}/slip`;
    },
    requestReceipt(jobId) {
      if (!jobId) throw new Error("Can't accept job without jobId");
      return `/jobs/${jobId}/receipt`;
    },
  },
  driver: {
    all(params) {
      if (!params) return "/drivers/jobs";
      /* working, confirmed, sooning, inDay, working, delivered, accepted, checking, paid, completed */
      return `/drivers/jobs?stage=${params}`;
    },
    getOne(jobId) {
      if (!jobId) throw new Error("Can't fetch job without jobId");
      return `/drivers/jobs/${jobId}`;
    },
    confirm(jobId) {
      if (!jobId) throw new Error("Can't confirm job without jobId");
      return `/drivers/jobs/${jobId}/confirm`;
    },
    reject(jobId) {
      if (!jobId) throw new Error("Can't reject job without jobId");
      return `/drivers/jobs/${jobId}/cancel`;
    },
    updatePrice: "/drivers/jobs/edit/",
    /**
     * request with PUT method for driver to update field images before or after work.
     * @param {String} period working (before work) || delivered (after work)
     * @returns {String} endpoint path for request PUT
     * @author Chachchai Tongthavornsuwan
     */
    workImages(stage) {
      if (!stage)
        throw new Error(
          "Can't update job images without stage (working or delivered)"
        );
      return `/drivers/jobs/${stage}`;
    },
    recievedCash(jobId) {
      if (!jobId) throw new Error("Can't completed job without jobId");
      return `/drivers/jobs/${jobId}/cash`;
    },
  },
};
