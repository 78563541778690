import PropTypes from "prop-types";

import { Paper, Grid } from "@mui/material";

const WorkbookCard = ({ title, rowHeader, children, elevation, sx }) => {
  return (
    <Paper
      elevation={elevation}
      sx={(theme) => ({
        p: theme.spacing(1, 2, 2),
        borderRadius: theme.spacing(2),
        ...sx,
      })}
    >
      <Grid container>
        <Grid container item alignItems="center" xs={12} ml={0.5} mt={1} mb={2}>
          {title}
        </Grid>
        {rowHeader && (
          <Grid container item xs={12} mb={1}>
            {rowHeader}
          </Grid>
        )}
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

WorkbookCard.propTypes = {
  title: PropTypes.node.isRequired,
  rowHeader: PropTypes.node,
  children: PropTypes.node,
};

WorkbookCard.defaultProps = {
  elevation: 0,
};

export default WorkbookCard;
