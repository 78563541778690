import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const Checkbox = ({
  control,
  defaultValue,
  onSelectOption,
  checkboxDirection,
  checkboxStyles,
  checkboxOptions,
  ...props
}) => (
  <FormControl sx={{ width: "100%", ...props.sx }}>
    <FormLabel>{props.label}</FormLabel>
    <FormGroup sx={{ flexDirection: checkboxDirection, ...checkboxStyles }}>
      {checkboxOptions.map((option) => (
        <ControlledCheckbox
          key={option.id}
          control={control}
          defaultValue={defaultValue}
          name={option.name}
          label={option.label}
          value={option.value}
        />
      ))}
    </FormGroup>
  </FormControl>
);

const ControlledCheckbox = ({ control, defaultValue, ...props }) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ control, defaultValue, name: props.name });

  return (
    <FormControlLabel
      label={props.label}
      checked={!!value}
      control={
        <MuiCheckbox
          onChange={onChange}
          inputProps={{ name }}
          icon={
            <CheckBoxOutlineBlankIcon color={error ? "error" : "default"} />
          }
        />
      }
      sx={{
        mb: 1.75,
        ...(!!error && { color: (theme) => theme.palette.error.main }),
      }}
    />
  );
};

Checkbox.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checkboxDirection: PropTypes.oneOf([
    "row",
    "row-reverse",
    "column",
    "column-reverse",
    "initial",
    "inherit",
  ]),
  checkboxStyles: PropTypes.object,
  checkboxOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
      value: PropTypes.any,
    })
  ).isRequired,
  sx: PropTypes.object,
};

Checkbox.defaultProps = {
  checkboxDirection: "column",
};

export default Checkbox;
