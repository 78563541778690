export const defaultStatus = (role) => {
  if (!role) return;
  if (role === "farmer") return "created";
  if (role === "driver") return "waiting";
  return;
};

export const FARMER_STATUS = {
  created: {
    wording: "ร่างงาน",
    chipColor: "info",
    description: "ยังไม่ได้เลือกคนขับ",
  },
  waiting: {
    wording: "สร้างงานแล้ว",
    chipColor: "secondary",
    description: "รอคนขับยืนยันการจ้างงาน",
  },
  cancelled: {
    wording: "ปฏิเสธงาน",
    chipColor: "tertiary",
    description: "งานถูกปฏิเสธโดยคนขับ",
  },
  edited: {
    wording: "แก้ไขค่าจ้าง",
    chipColor: "warning",
    description: "กรุณายืนยันค่าจ้าง",
    excavator: {
      wording: "ตรวจสอบค่าจ้าง",
      chipColor: "info",
      description: "กรุณาตรวจสอบยืนยันจ้างงาน",
    },
  },
  confirmed: {
    wording: "ยืนยันรับงาน",
    chipColor: "error",
    description: "รอคนขับทำงานตามที่ตกลง",
  },
  sooning: {
    wording: "ใกล้เริ่มงาน",
    chipColor: "warning",
    description: "ใกล้ถึงวันที่ทำงานแล้ว",
  },
  inDay: {
    wording: "ถึงวันทำงาน",
    chipColor: "info",
    description: "ถึงกำหนดวันทำงานแล้ว",
  },
  working: {
    wording: "กำลังทำงาน",
    chipColor: "primary",
    description: "คนขับกำลังทำงาน",
  },
  delivered: {
    wording: "ส่งมอบงาน",
    chipColor: "primary",
    description: "กรุณาตรวจสอบงาน",
  },
  accepted: {
    wording: "รอจ่ายเงิน",
    chipColor: "primary",
    description: "กรุณาจ่ายเงินให้คนขับ",
  },
  checking: {
    wording: "รอตรวจสอบ",
    chipColor: "warning",
    description: "รอตรวจสอบหลักฐานการโอนเงิน",
  },
  paid: {
    wording: "ปิดงานแล้ว",
    chipColor: "tertiary",
    description: "ทำงานสำเร็จแล้ว",
  },
  completed: {
    wording: "ปิดงานแล้ว",
    chipColor: "tertiary",
    description: "ทำงานสำเร็จแล้ว",
  },
};

export const DRIVER_STATUS = {
  waiting: {
    wording: "งานใหม่",
    chipColor: "error",
    description: "กรุณาอ่านรายละเอียดงาน",
  },
  cancelled: {
    wording: "ปฏิเสธงาน",
    chipColor: "tertiary",
    description: "งานถูกปฏิเสธโดยคนขับ",
  },
  edited: {
    wording: "แก้ไขค่าจ้าง",
    chipColor: "warning",
    description: "รอยืนยันค่าจ้าง",
    excavator: {
      wording: "เสนองาน",
      chipColor: "in-process",
      description: "รอผู้จ้างยืนยันจ้างงาน",
    },
  },
  confirmed: {
    wording: "ยืนยันรับงาน",
    chipColor: "error",
    description: "รอทำงานตามที่ตกลง",
  },
  sooning: {
    wording: "ใกล้เริ่มงาน",
    chipColor: "warning",
    description: "ใกล้ถึงวันที่ทำงานแล้ว",
  },
  inDay: {
    wording: "ถึงวันทำงาน",
    chipColor: "info",
    description: "ถึงกำหนดวันทำงานแล้ว",
  },
  working: {
    wording: "กำลังทำงาน",
    chipColor: "primary",
    description: "คนขับกำลังทำงาน",
  },
  delivered: {
    wording: "ส่งมอบงาน",
    chipColor: "primary",
    description: "รอผู้ว่าจ้างพิจารณางาน",
  },
  accepted: {
    wording: "รอจ่ายเงิน",
    chipColor: "primary",
    description: "รอคนจ้างจ่ายเงิน",
  },
  checking: {
    wording: "รอจ่ายเงิน",
    chipColor: "primary",
    description: "รอคนจ้างจ่ายเงิน",
  },
  paid: {
    wording(paymentMethod) {
      if (paymentMethod === "cash") return "ปิดงานแล้ว";
      if (paymentMethod === "qr") return "รอจ่ายเงิน";
    },
    chipColor(paymentMethod) {
      if (paymentMethod === "cash") return "tertiary";
      if (paymentMethod === "qr") return "primary";
    },
    description(paymentMethod) {
      if (paymentMethod === "cash") return "ทำงานสำเร็จแล้ว";
      if (paymentMethod === "qr") return "รอคนจ้างจ่ายเงิน";
    },
  },
  completed: {
    wording: "ปิดงานแล้ว",
    chipColor: "tertiary",
    description: "ทำงานสำเร็จแล้ว",
  },
};

export const JOB_STATUS = {
  farmer: {
    created: {
      nameTH: "ร่างงาน",
      nameEN: "created",
    },
    waiting: {
      nameTH: "สร้างงานแล้ว",
      nameEN: "waiting",
    },
    // cancelled: {
    //   nameTH: "ปฏิเสธงาน",
    //   nameEN: "cancelled",
    // },
    edited: {
      nameTH: "แก้ไขค่าจ้าง",
      nameEN: "edited",
    },
    confirmed: {
      nameTH: "ยืนยันรับงาน",
      nameEN: "confirmed",
    },
    sooning: {
      nameTH: "ใกล้เริ่มงาน",
      nameEN: "sooning",
    },
    inDay: {
      nameTH: "ถึงวันทำงาน",
      nameEN: "inDay",
    },
    working: {
      nameTH: "กำลังทำงาน",
      nameEN: "working",
    },
    delivered: {
      nameTH: "ส่งมอบงาน",
      nameEN: "delivered",
    },
    accepted: {
      nameTH: "รอจ่ายเงิน",
      nameEN: "accepted",
    },
    checking: {
      nameTH: "รอตรวจสอบ",
      nameEN: "checking",
    },
    paid: {
      nameTH: "ปิดงานแล้ว",
      nameEN: "paid",
    },
    completed: {
      nameTH: "ปิดงานแล้ว",
      nameEN: "completed",
    },
  },
  driver: {
    waiting: {
      nameTH: "งานใหม่",
      nameEN: "waiting",
    },
    // cancelled: {
    //   nameTH: "ปฏิเสธงาน",
    //   nameEN: "cancelled",
    // },
    edited: {
      nameTH: "แก้ไขค่าจ้าง",
      nameEN: "edited",
    },
    confirmed: {
      nameTH: "ยืนยันรับงาน",
      nameEN: "confirmed",
    },
    sooning: {
      nameTH: "ใกล้เริ่มงาน",
      nameEN: "sooning",
    },
    inDay: {
      nameTH: "ถึงวันทำงาน",
      nameEN: "inDay",
    },
    working: {
      nameTH: "กำลังทำงาน",
      nameEN: "working",
    },
    delivered: {
      nameTH: "ส่งมอบงาน",
      nameEN: "delivered",
    },
    accepted: {
      nameTH: "รอจ่ายเงิน",
      nameEN: "accepted",
    },
    checking: {
      nameTH: "รอจ่ายเงิน",
      nameEN: "checking",
    },
    paid: {
      nameTH: "รอจ่ายเงิน",
      nameEN: "paid",
    },
    completed: {
      nameTH: "ปิดงานแล้ว",
      nameEN: "completed",
    },
  },
};
