import PropTypes from "prop-types";
import { Chip } from "@mui/material";

const ChipStatus = ({ label, color, size, sx }) => {
  return <Chip label={label} color={color} size={size} sx={sx} />;
};

ChipStatus.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
};

ChipStatus.defaultProps = {
  size: "small",
};

export default ChipStatus;
