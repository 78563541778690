import { Box, Grid, Typography, styled } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { grey } from "@mui/material/colors";

import { Card } from "base-components";

const DashedBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "3.5rem",
  width: "3.5rem",
  margin: theme.spacing(0, "auto"),
  border: `2px dashed ${theme.palette.grey[400]}`,
  borderRadius: theme.spacing(0.5),
}));

const AddItemCard = ({ text, clickCard }) => {
  const onClickCardHandler = () => {
    clickCard();
  };

  return (
    <Card onClick={onClickCardHandler}>
      <Grid container item xs={3}>
        <DashedBox>
          <AddCircleOutlineIcon
            sx={{ m: "auto", fontSize: 40, color: grey[400] }}
          />
        </DashedBox>
      </Grid>
      <Grid container item alignItems="center" xs={9} ml={1}>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </Card>
  );
};

export default AddItemCard;
