import { createSlice } from "@reduxjs/toolkit";
import { fieldApi } from "services/field";

const slice = createSlice({
  name: "field",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        fieldApi.endpoints.getFields.matchFulfilled,
        (state, { payload }) => {
          for (const field of payload) {
            const isDuplicatedField = state.some(
              (eachField) => eachField.id === field.id
            );
            !isDuplicatedField && state.push(field);
          }
        }
      )
      .addMatcher(
        fieldApi.endpoints.createField.matchFulfilled,
        (state, { payload }) => {
          state.push(payload);
        }
      )
      .addMatcher(
        fieldApi.endpoints.updateField.matchFulfilled,
        (state, { payload: { fieldId, data } }) => {
          if (!fieldId) return state;
          const updatedFieldIndex = state.findIndex(
            (field) => field.id === fieldId
          );
          state[updatedFieldIndex] = data;
        }
      )
      .addMatcher(
        fieldApi.endpoints.deleteField.matchFulfilled,
        (state, { payload: fieldId }) => {
          if (!fieldId) return state;
          const deletedFieldIndex = state.findIndex(
            (field) => field.id === fieldId
          );
          state.splice(deletedFieldIndex, 1);
        }
      );
  },
});

export default slice.reducer;

export const selectFields = ({ field }) => field;
