export const evaluateGeocoding = async ({ lat = 0, lng = 0, address = "" }) => {
  // if (!window.google || (!lat && !lng && !address)) return;
  const geocoder = new window.google.maps.Geocoder();
  try {
    const { results } = await geocoder.geocode(
      lat && lng ? { location: { lat, lng } } : { address }
    );
    return results;
  } catch (err) {
    console.error(err);
    throw new Error();
  }
};

export const getAddressComponent = (
  locationDetail = { lat: 0, lng: 0, zoom: 10 },
  map,
  address
) => {
  if (!map && !address) return;
  // console.log("address: ", address);
  const { zoom, ...latLng } = locationDetail;
  map.setZoom(zoom);
  map.setCenter(latLng);
  let cleanedAddress = { province: "", district: "", subDistrict: "" };
  for (let i = address.length - 1; i >= 0; i--) {
    if (address[i].long_name.indexOf("กรุงเทพมหานคร") !== -1) {
      cleanedAddress = {
        province: address[i].long_name,
        district: cleanWord(address[i - 1].long_name, "เขต"),
        subDistrict: cleanWord(address[i - 2].long_name, "แขวง"),
        zipcode: address[address.length - 1].long_name,
      };
      break;
    }
    if (
      address[i].short_name.includes("จ.") ||
      address[i].short_name.includes("จังหวัด")
    ) {
      cleanedAddress = {
        province: cleanWord(address[i].long_name, "จังหวัด", "จ."),
        district: concatCapitalDistrictName(
          cleanWord(address[i - 1].long_name, "อำเภอ", "อ."),
          cleanWord(address[i].long_name, "จังหวัด", "จ.")
        ),
        subDistrict: cleanWord(address[i - 2].long_name, "ตำบล", "ต."),
        zipcode: address[address.length - 1].long_name,
      };
      break;
    }
    if (
      address[i].short_name.includes("อ.") ||
      address[i].short_name.includes("อำเภอ")
    ) {
      cleanedAddress = {
        province: cleanWord(address[i + 1].long_name, "จังหวัด", "จ."),
        district: concatCapitalDistrictName(
          cleanWord(address[i].long_name, "อำเภอ", "อ."),
          cleanWord(address[i + 1].long_name, "จังหวัด", "จ.")
        ),
        subDistrict: cleanWord(address[i - 1].long_name, "ตำบล", "ต."),
        zipcode: address[address.length - 1].long_name,
      };
      break;
    }
  }

  return { ...latLng, ...cleanedAddress };
};

export const getAddressManyComponents = (
  locationDetail = { lat: 0, lng: 0, zoom: 10 },
  map,
  addresses
) => {
  if (!map && !addresses) return;
  // console.log("addresses: ", addresses);
  const { zoom, ...latLng } = locationDetail;
  map.setZoom(zoom);
  map.setCenter(latLng);
  let address = { province: "", district: "", subDistrict: "" };
  const maxLength = Math.max(
    ...addresses.map(({ address_components }) => address_components.length)
  );
  const addresMaxLengthComps = addresses.find(
    ({ address_components }) => address_components.length === maxLength
  );
  const addressComp = addresMaxLengthComps.address_components;
  for (let i = addressComp.length - 1; i >= 0; i--) {
    if (addressComp[i].long_name.indexOf("กรุงเทพมหานคร") !== -1) {
      address = {
        province: addressComp[i].long_name,
        district: cleanWord(addressComp[i - 1].long_name, "เขต"),
        subDistrict: cleanWord(addressComp[i - 2].long_name, "แขวง"),
        zipcode: addressComp[addressComp.length - 1].long_name,
      };
      break;
    }
    if (
      addressComp[i].short_name.includes("จ.") ||
      addressComp[i].short_name.includes("จังหวัด")
    ) {
      address = {
        province: cleanWord(addressComp[i].long_name, "จังหวัด", "จ."),
        district: concatCapitalDistrictName(
          cleanWord(addressComp[i - 1].long_name, "อำเภอ", "อ."),
          cleanWord(addressComp[i].long_name, "จังหวัด", "จ.")
        ),
        subDistrict: cleanWord(addressComp[i - 2].long_name, "ตำบล", "ต."),
        zipcode: addressComp[addressComp.length - 1].long_name,
      };
      break;
    }
    if (
      addressComp[i].short_name.includes("อ.") ||
      addressComp[i].short_name.includes("อำเภอ")
    ) {
      address = {
        province: cleanWord(addressComp[i + 1].long_name, "จังหวัด", "จ."),
        district: concatCapitalDistrictName(
          cleanWord(addressComp[i].long_name, "อำเภอ", "อ."),
          cleanWord(addressComp[i + 1].long_name, "จังหวัด", "จ.")
        ),
        subDistrict: cleanWord(addressComp[i - 1].long_name, "ตำบล", "ต."),
        zipcode: addressComp[addressComp.length - 1].long_name,
      };
      break;
    }
  }
  return { ...latLng, ...address };
};

export function cleanWord(addressWord, ...comparingWords) {
  const prefix = comparingWords.find((word) => addressWord.includes(word));
  if (prefix) return addressWord.replace(prefix, "").trim();
  return addressWord;
}

function concatCapitalDistrictName(district, province) {
  if (district.trim() === "เมือง") return `${district}${province}`;
  return district;
}
