import { string, boolean, array } from "yup";

const Drone = {
  service: string().required("กรุณาเลือก สารฉีดพ่น"),
  preparedChemical: boolean()
    .transform((value) => (!(typeof value === "boolean") ? undefined : value))
    .required("กรุณาเลือก ความต้องการให้ผู้รับจ้างเตรียมสารฉีดพ่น"),
  plantType: string().required("กรุณาเลือก ชนิดพืช"),
  fieldName: string().required("กรุณาเลือก แปลง"),
  fieldImages: array()
    .min(1, "กรุณาเลือก รูปถ่าย อย่างน้อย 1 รูป")
    .test({
      name: "fieldImages-not-duplicated",
      test(value, _context) {
        const imagesFilename = value.map((image) => image.filename);
        const duplicatedImages = imagesFilename.filter(
          (filename, idx, arr) => arr.indexOf(filename) !== idx
        );
        if (duplicatedImages.length > 0) {
          const uniqueDuplicatedNames = [...new Set(duplicatedImages)];
          return this.createError({
            message: `เพิ่มรูปชื่อซ้ำกัน กรุณาเปลี่ยนชื่อรูปหรือเลือกรูปใหม่อีกครั้ง
      ชื่อรูปที่ซ้ำ ${uniqueDuplicatedNames.join(", ")}`,
            path: "fieldImages",
          });
        }
        return true;
      },
    }),
};

export default Drone;
