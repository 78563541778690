import { Fragment } from "react";
import { format } from "date-fns";

import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useDate } from "hooks";

import { ChipStatus, DividerFullWidth } from "base-components";

import { DRIVER_STATUS } from "constants/JobStatus";
import { VEHICLES } from "constants/Vehicles";

const Circle = styled("div")(({ theme }) => {
  return {
    flexGrow: 1,
    display: "flex",
    borderRadius: "50%",
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "1rem",
  };
});

const DriverJobCard = ({
  id,
  vinType,
  service,
  serviceDate,
  serviceTime,
  fieldSize,
  stage,
  paymentMethod,
  clickJobList,
}) => {
  const { monthDateYearFormat } = useDate();

  const onClickListHandler = (jobId) => () => {
    clickJobList(jobId);
  };

  const dateTh = format(new Date(monthDateYearFormat(serviceDate)), "dd");

  const chipDetails = (key) => {
    if (stage === "paid") return DRIVER_STATUS[stage]?.[key](paymentMethod);
    if (stage === "edited" && vinType === VEHICLES.excavator.nameEN)
      return DRIVER_STATUS[stage]?.excavator[key];
    return DRIVER_STATUS[stage]?.[key];
  };

  return (
    <Fragment>
      <Grid
        container
        item
        xs={12}
        mb={1}
        onClick={onClickListHandler(id)}
        sx={{ "& + .MuiDivider-root:not(:last-child)": { mb: 2 } }}
      >
        <Grid item xs={2} my="auto" pr={1}>
          <Circle sx={{ mx: "auto" }}>
            <Typography sx={{ color: "inherit", margin: "auto" }}>
              {dateTh}
            </Typography>
          </Circle>
        </Grid>

        <Grid container item xs={6} pl={1}>
          <Grid item xs="auto" mr={1}>
            <Typography variant="body1" color="tertiary.dark">
              {service}
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs>
              <Typography variant="subtitle2" color="tertiary.dark">
                เวลา {serviceTime}
              </Typography>
            </Grid>
            {fieldSize ? (
              <Grid item xs="auto">
                <Typography variant="subtitle2">
                  พื้นที่ {fieldSize} ไร่
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid container item xs={4} pl={2}>
          <ChipStatus
            label={chipDetails("wording")}
            color={chipDetails("chipColor")}
            sx={{ m: "auto" }}
          />
        </Grid>
      </Grid>

      <DividerFullWidth sx={{ position: "static" }} />
    </Fragment>
  );
};

export default DriverJobCard;
