import { Fragment } from "react";
import { useSelector } from "react-redux";

import { Typography, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Dot,
} from "pure-react-carousel";
import classes from "./ImagePreview.module.scss";

import { Modal } from "base-components";

const ImagesPreviewModal = ({
  openModal,
  onCloseModal,
  headerText,
  images,
  onRemoveImage,
}) => {
  const { currentRole } = useSelector((state) => state.auth);

  const handleToggleModal = () => {
    onCloseModal();
  };

  const handleRemoveImage = (imageIdx, data) => () => {
    const remainedImages = onRemoveImage(imageIdx, data);
    if (!remainedImages?.length) handleToggleModal();
  };

  return (
    <Modal fullWidth openModal={openModal} onCloseModal={handleToggleModal}>
      <Modal.Header closeButton onCloseModal={handleToggleModal}>
        <Typography component="p" variant="h6">
          {headerText} ({images?.length || 0})
        </Typography>
      </Modal.Header>
      <Modal.Content
        sx={{ px: 0, pb: images?.length > 1 ? 2.5 : 0, overflowX: "hidden" }}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={"auto"}
          totalSlides={images?.length || 0}
          isIntrinsicHeight={true}
          infinite={true}
        >
          <Slider classNameAnimation={classes["slider-tray__animation"]}>
            {images?.map((image, idx) => (
              <Slide key={idx} index={idx} className={`${classes.slide}`}>
                <Image
                  src={image.data ?? image}
                  alt={image.filename ?? image}
                  width="100%"
                  style={{ objectFit: "contain" }}
                />
                {onRemoveImage ? (
                  <Button
                    aria-label="close"
                    variant="contained"
                    color="error"
                    startIcon={<DeleteOutlineOutlinedIcon fontSize="small" />}
                    onClick={handleRemoveImage(idx, image.data ?? image)}
                    sx={(theme) => ({
                      position: "absolute",
                      left: "50%",
                      bottom: theme.spacing(1),
                      transform: "translate(-50%, -50%)",
                      color: theme.palette.common.white,
                    })}
                  >
                    ลบ
                  </Button>
                ) : null}
              </Slide>
            ))}
          </Slider>
          {images?.length > 1 ? (
            <Fragment>
              <ButtonBack
                className={`${classes.btn} ${classes["btn-middle"]} ${classes["btn-back"]}`}
              >
                <ArrowBackIosNewIcon sx={{ fontSize: "2rem" }} />
              </ButtonBack>
              <ButtonNext
                className={`${classes.btn} ${classes["btn-middle"]} ${classes["btn-next"]}`}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "2rem" }} />
              </ButtonNext>
              <DotGroup
                className={classes["dot-container"]}
                renderDots={({ currentSlide }) =>
                  images?.map((_image, idx) => (
                    <Dot
                      key={idx}
                      className={`${classes.dot} ${
                        classes[`dot__${currentRole}`]
                      } ${
                        currentSlide === idx
                          ? classes[`dot__${currentRole}-selected`]
                          : ""
                      }`}
                      slide={idx}
                      disabled={false}
                    />
                  ))
                }
              />
            </Fragment>
          ) : null}
        </CarouselProvider>
      </Modal.Content>
    </Modal>
  );
};

export default ImagesPreviewModal;
