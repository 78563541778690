import { createSlice } from "@reduxjs/toolkit";

const assetInitialState = {
  vehicles: [],
  selectedVehicle: null,
  services: [],
  fields: [],
  jobs: [],
};

const assetSlice = createSlice({
  name: "assets",
  initialState: assetInitialState,
  reducers: {
    initialAssetsInformation(state, { payload }) {
      const vehicles = payload.map((vehicle) => ({
        brand: vehicle.brand,
        implements: vehicle.implements,
        nameTH: vehicle.nameTH,
        vinId: vehicle.vinId,
      }));

      const services = [];
      for (const vehicle of payload) {
        services.push(...vehicle.services);
      }

      state.vehicles = vehicles;
      state.services = services;
    },

    addSelectedVehicle(state, { payload }) {
      state.selectedVehicle = payload;
    },
    createOneVehicle(state, { payload }) {
      state.vehicles.unshift(payload);
    },
    createVehicles(state, { payload }) {
      for (const vehicle of payload) state.vehicles.push(vehicle);
    },
    updateVehicles(state, { payload }) {
      if (state.vehicles.length) {
        const updatedVehicleIndex = state.vehicles.findIndex(
          (vehicle) => vehicle.id === payload.id
        );
        updatedVehicleIndex !== -1 &&
          state.vehicles.splice(updatedVehicleIndex, 1, payload);
      }
    },
    deleteVehicle(state, { payload: vinId }) {
      if (state.vehicles.length) {
        const deletedVehicleIndex = state.vehicles.findIndex(
          (vehicle) => vehicle.id === vinId
        );
        deletedVehicleIndex !== -1 &&
          state.vehicles.splice(deletedVehicleIndex, 1);
      }
      state.selectedVehicle = null;
    },
    createOne(state, { payload: { name, data } }) {
      state[name].push(data);
    },
    createAll(state, { payload: { name, data } }) {
      if (Array.isArray(data)) for (const item of data) state[name].push(item);
      else state[name].push(data);
    },
    update(state, { payload: { name, idKey, idValue, newData } }) {
      if (state[name].length) {
        const updatedIndex = state[name].findIndex(
          (item) => item[idKey] === idValue
        );
        updatedIndex !== -1 && state[name].splice(updatedIndex, 1, newData);
      }
    },
    delete(state, { payload: { name, idKey, idValue } }) {
      if (state[name].length) {
        const deletedIndex = state[name].findIndex(
          (item) => item[idKey] === idValue
        );
        deletedIndex !== -1 && state[name].splice(deletedIndex, 1);
      }
    },
    reset(state, { payload: { name, initData } }) {
      state[name] = initData;
    },
  },
});

export const assetActions = assetSlice.actions;

export default assetSlice;
