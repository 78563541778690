import { createSlice } from "@reduxjs/toolkit";

const jobInitialState = {
  availableDrivers: [],
};

const jobSlice = createSlice({
  name: "jobs",
  initialState: jobInitialState,
  reducers: {
    createOne(state, { payload: { name, data } }) {
      state[name].push(data);
    },
    createAll(state, { payload: { name, data } }) {
      if (Array.isArray(data)) for (const item of data) state[name].push(item);
      else state[name].push(data);
    },
    addSelected(state, { payload: { name, data } }) {
      state[name] = data;
    },
    update(state, { payload: { name, idKey, idValue, newData } }) {
      if (state[name]?.length) {
        const updatedIndex = state[name].findIndex(
          (item) => item[idKey] === idValue
        );
        updatedIndex !== -1 && state[name].splice(updatedIndex, 1, newData);
      }
    },
    delete(state, { payload: { name, idKey, idValue } }) {
      if (state[name]?.length) {
        const deletedIndex = state[name].findIndex(
          (item) => item[idKey] === idValue
        );
        deletedIndex !== -1 && state[name].splice(deletedIndex, 1);
      }
    },
    reset(state, { payload: { name, initData } }) {
      state[name] = initData;
    },
  },
});

export const jobActions = jobSlice.actions;

export default jobSlice;
