import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon sx={{ fontSize: "2rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
}));

const AccordionSelector = ({
  name: targetName,
  label,
  control,
  defaultValue,
  expanded,
  onToggle,
  children,
  sx,
}) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ control, defaultValue, name: targetName });

  const handleToggleExpand = () => {
    onToggle(name);
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    onChange(checked);
    onToggle(name, checked);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleToggleExpand}
      elevation={0}
      disableGutters
      sx={sx}
    >
      <AccordionSummary>
        <FormControlLabel
          label={label}
          checked={!!value}
          defaultChecked={defaultValue}
          control={
            <Checkbox
              inputProps={{ name }}
              onChange={handleChangeCheckbox}
              icon={
                <CheckBoxOutlineBlankIcon color={error ? "error" : "default"} />
              }
            />
          }
          sx={!!error ? { color: (theme) => theme.palette.error.main } : null}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

AccordionSelector.propTypes = {
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

AccordionSelector.defaultProps = {
  handleToggle: () => {},
};

export default AccordionSelector;
