import { object, string, date, addMethod } from "yup";

import { startOfDay, endOfDay, isToday, isAfter } from "date-fns";

addMethod(date, "timePast", function (errorMessage) {
  return this.test("test-time-past", errorMessage, function (value) {
    const { path, createError } = this;
    if (path === "serviceDate")
      return (
        isAfter(endOfDay(value), startOfDay(new Date())) ||
        createError({ path, message: errorMessage })
      );
    if (path === "serviceTime")
      return (
        isAfter(value, new Date()) ||
        createError({ path, message: errorMessage })
      );
  });
});

const BaseSchema = object({
  serviceDate: date()
    .timePast("กรุณาเลือก วันนี้หรือวันที่ในอนาคต")
    .transform((value) =>
      !(value instanceof Date) || isNaN(value.valueOf()) ? undefined : value
    )
    .required("กรุณาเลือก วันที่รับบริการ"),
  serviceTime: date()
    .when("serviceDate", (serviceDate, schema) =>
      isToday(serviceDate)
        ? schema
            .timePast("กรุณาระบุ เวลาในอนาคต")
            .transform((value) =>
              !(value instanceof Date) || isNaN(value.valueOf())
                ? undefined
                : value
            )
        : schema.transform((value) =>
            !(value instanceof Date) || isNaN(value.valueOf())
              ? undefined
              : value
          )
    )
    .required("กรุณาระบุ เวลารับบริการ"),
  paymentMethod: string().required("กรุณาเลือก วิธีการจ่ายเงิน"),
  recommendCodeJob: string().test({
    name: "required-when-not-empty",
    test(value, { parent: { recommendCodeJobDesc } }) {
      if (value && !recommendCodeJobDesc)
        return this.createError({
          message: `ไม่พบรหัส ${value.toUpperCase()} ในระบบ กรุณากรอกรหัสแนะนำใหม่`,
          path: "recommendCodeJob",
        });
      return true;
    },
  }),
  recommendCodeJobDesc: string().notRequired(),
});

export default BaseSchema;
