import { api } from "services/api";

export const serviceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDriverServices: build.query({
      query: () => ({
        url: "services/all",
      }),
      providesTags: (service) =>
        service
          ? [
              { type: "Service", id: "LIST" },
              ...service.map(({ type }) => ({ type: "Service", id: type })),
            ]
          : [{ type: "Service", id: "LIST" }],
    }),
    getDriverServiceByType: build.query({
      query: (serviceName) => ({
        url: `services/id/${serviceName}`,
      }),
      providesTags: (_service, _err, serviceName) => [
        { type: "Service", id: serviceName },
      ],
      transformResponse: (response) => ({
        serviceNameEN: response.type,
        data: response,
      }),
    }),
    registerDriverService: build.mutation({
      query: (body) => ({
        url: "services/create",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Service", id: "LIST" }],
    }),
    updateDriverService: build.mutation({
      query: (body) => ({
        url: "services/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: (_service, _err, data) => [
        { type: "Service", id: data.serviceType },
      ],
      transformResponse: (response) => ({
        serviceNameEN: response.type,
        data: response,
      }),
    }),
    /**
     * request with PUT method for driver to disable/enable service.
     * @param {String} action disabled||enable
     * @param {String} serviceName tractor || drone || combine || excavator
     */
    toggleDriverServiceAvailability: build.mutation({
      query: ({ action, serviceName }) => ({
        url: `services/${action}/${serviceName}`,
        method: "PUT",
      }),
      invalidatesTags: (_service, _err, { serviceName }) => [
        { type: "Service", id: serviceName },
      ],
      transformResponse: (response, _meta, { serviceName }) => {
        if (Array.isArray(response)) {
          const updatedService = response.find(
            (service) => service.type === serviceName
          );
          return { serviceNameEN: updatedService.type, data: updatedService };
        }
        return response;
      },
    }),
  }),
});

export const {
  useGetDriverServicesQuery,
  useGetDriverServiceByTypeQuery,
  useRegisterDriverServiceMutation,
  useUpdateDriverServiceMutation,
  useToggleDriverServiceAvailabilityMutation,
} = serviceApi;
