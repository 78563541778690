import Resizer from "react-image-file-resizer";

export const resizeFile = (file) => {
  if (!file) return;

  return new Promise((resolve) => {
    // https://www.npmjs.com/package/react-image-file-resizer
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
};

export const fileToDataUri = (file) => {
  if (!file) return;

  return new Promise((response) => {
    const reader = new FileReader();
    const { name, size } = file;
    reader.addEventListener("loadend", () => {
      response({
        data: reader.result,
        filename: name,
        size,
      });
    });
    if (file) reader.readAsDataURL(file);
  });
};

export const deconstructFileUrl = (url) => {
  if (!url) return;

  const imagePath = new URL(url).pathname;
  const imagePathname = decodeURIComponent(
    imagePath.substring(imagePath.lastIndexOf("/") + 1)
  );
  const imageName = imagePathname.substring(imagePathname.indexOf("/") + 1);
  return {
    filename: imageName,
    data: url,
  };
};

export const isUrl = (string) => {
  if (!string) return;

  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const capitalize = (string) => {
  if (!string) return;
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
