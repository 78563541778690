import { Fragment } from "react";

import { Grid, Typography, Avatar, Button } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const UserInformation = ({
  invertRoleText,
  stage,
  profileImage,
  prefixname,
  fullname,
  address,
  tel,
  contactEachOther,
}) => {
  const isCreated = stage === "created";

  const onClickContactHandler = (telNum) => () => {
    contactEachOther(telNum);
  };

  if (isCreated)
    return (
      <Fragment>
        <Grid item xs={12} mb={1}>
          <Typography variant="body1" color="secondary">
            ข้อมูล{invertRoleText}
          </Typography>
        </Grid>
        <Grid item xs={12} ml={2} mb={3}>
          <Typography variant="subtitle1" color="error">
            ยังไม่ได้เลือกคนขับ
          </Typography>
        </Grid>
      </Fragment>
    );

  return (
    <Fragment>
      <Grid item xs={12} mb={1}>
        <Typography variant="body1" color="secondary">
          ข้อมูล{invertRoleText}
        </Typography>
      </Grid>
      <Grid container item mb={3}>
        <Grid container item xs={4}>
          <Avatar
            src={profileImage}
            alt={`${fullname}__driver-avatar`}
            sx={(theme) => ({
              mt: 1,
              mx: "auto",
              width: theme.spacing(11),
              height: theme.spacing(11),
            })}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={12} mb={1}>
            <Typography variant="body1">
              <strong>
                {prefixname}
                {fullname}
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={12} mb={1}>
            <Typography color="tertiary" variant="subtitle2">
              ที่อยู่ : {address}
            </Typography>
          </Grid>

          <Grid container item justifyContent="flex-start" xs={12}>
            {/* <Button size="small" variant='outlined'>ดูประวัติ</Button> */}
            <Button
              onClick={onClickContactHandler(tel)}
              size="small"
              variant="outlined"
              color="secondary"
              startIcon={<LocalPhoneIcon />}
            >
              ติดต่อ{invertRoleText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserInformation;
