import { Fragment } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio as MuiRadio,
  FormHelperText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const Radio = ({
  control,
  defaultValue,
  onChangeCallback,
  radioDirection,
  radioStyles,
  radioOptions,
  ...props
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={props.name}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const onChangeHandler = (e) => {
          onChangeCallback();
          onChange(e);
        };

        const errorText = error?.message ? (
          <FormHelperText error={!!error} sx={{ ml: 1 }}>
            {error.message}
          </FormHelperText>
        ) : null;

        return (
          <FormControl sx={{ width: "100%" }}>
            <FormLabel
              id={`${name}__radio`}
              sx={{
                color: (theme) => theme.palette.common.black,
              }}
            >
              {props.label}
            </FormLabel>
            <RadioGroup
              aria-labelledby={`${name}__radio`}
              defaultValue={defaultValue}
              name={name}
              value={value}
              sx={{ flexDirection: radioDirection, ...radioStyles }}
            >
              {radioOptions.map((option) => {
                const helperText =
                  option.helper && !error?.message ? (
                    <FormHelperText component="span" sx={{ ml: 1 }}>
                      {option.helper}
                    </FormHelperText>
                  ) : null;

                return (
                  <Fragment key={option.id}>
                    <FormControlLabel
                      name={name}
                      onChange={onChangeHandler}
                      value={option.value}
                      control={
                        <MuiRadio
                          checkedIcon={<CheckCircleIcon color={option.color} />}
                          icon={
                            <RadioButtonUncheckedIcon
                              color={error ? "error" : "default"}
                            />
                          }
                        />
                      }
                      disabled={option.disabled}
                      label={option.label}
                      sx={
                        error
                          ? { color: (theme) => theme.palette.error.main }
                          : {}
                      }
                    />
                    {option.children}
                    {helperText}
                  </Fragment>
                );
              })}
            </RadioGroup>
            {errorText}
          </FormControl>
        );
      }}
    />
  );
};

Radio.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  radioDirection: PropTypes.oneOf([
    "row",
    "row-reverse",
    "column",
    "column-reverse",
    "initial",
    "inherit",
  ]),
  radioStyles: PropTypes.object,
  radioOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
      value: PropTypes.any,
      color: PropTypes.oneOf([
        "default",
        "primary",
        "secondary",
        "error",
        "info",
        "success",
        "warning",
      ]),
      children: PropTypes.node,
      helper: PropTypes.node,
    })
  ),
};

Radio.defaultProps = {
  onChangeCallback: () => null,
};

export default Radio;
