import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Grid, Typography, IconButton, Button, Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { useHttp, useWindowDimensions } from "hooks";
import { uiActions } from "store/user-interface/ui-slice";
import { authActions } from "store/authentication/auth-slice";
import { masterActions } from "store/master/master-slice";
import { DRIVER_ENDPOINTS } from "constants/APIs";
import { ROUTES } from "routes/router";

import AcceptPDPAPage from "pages/form-sections/AcceptPDPAPage";
import { TextInput, SelectPicker, Radio } from "components/hook-forms";
import { Navbar } from "layouts/components";
import { Options, ActionLoading, Modal } from "base-components";

const Form = styled("form", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "aboveElement",
})(({ height, aboveElement }) => ({
  flexGrow: 1,
  position: "relative",
  height: `${height * (aboveElement ? 0.75 : 0.85)}px`,
}));

const BankAccountFormPage = (props) => {
  const dispatch = useDispatch();
  const {
    auth: {
      payeeMethod,
      currentRole,
      pdpa_accept,
      bankName,
      bankAccount,
      promptpayType,
      promptpay,
      verifyOTP,
      tel,
    },
    master: { banks },
    ui: { drawerPicker, modal },
  } = useSelector((state) => state);
  const [disabledAcceptPDPA, setDisabledAcceptPDPA] = useState(true);
  const lastParagraphRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  // create || update
  const action = state?.action;
  // new || getztrac || update
  const source = state?.source;
  const tempTel = state?.tempTel;
  const { sendRequest: fetchBankList } = useHttp();
  const { isLoading: isSubmitting, sendRequest: submitBankData } = useHttp();
  const { height } = useWindowDimensions();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      payeeMethod: yup.string().required("กรุณาเลือก วิธีการรับเงิน"),
      bankName: yup.string().test({
        name: "validated-with-payeeMethod",
        test(value, { parent: { payeeMethod } }) {
          return payeeMethod === "bank" && !value
            ? this.createError({
                message: "กรุณาเลือก ธนาคาร",
                path: "bankName",
              })
            : true;
        },
      }),
      bankAccount: yup.string().test({
        name: "validated-with-payeeMethod-and-bankName",
        test(value, { parent: { payeeMethod, bankName } }) {
          if (payeeMethod === "bank" && !bankName && !value)
            return this.createError({
              message: "กรุณาเลือก ธนาคาร ก่อน",
              path: "bankAccount",
            });
          if (payeeMethod === "bank" && bankName && !value)
            return this.createError({
              message: "กรุณากรอก เลขที่บัญชี",
              path: "bankAccount",
            });
          if (
            payeeMethod === "bank" &&
            bankName.includes("ออมสิน") &&
            !(value.length === 12 || value.length === 15)
          )
            return this.createError({
              message: "จำนวนหลักเท่ากับ 12 หรือ 15 หลักเท่านั้น",
              path: "bankAccount",
            });
          if (
            payeeMethod === "bank" &&
            (bankName.includes("เกียรตินาคิน") ||
              bankName.includes("ทิสโก้")) &&
            value.length !== 14
          )
            return this.createError({
              message: "จำนวนหลักเท่ากับ 14 หลักเท่านั้น",
              path: "bankAccount",
            });
          if (
            payeeMethod === "bank" &&
            (bankName.includes("การเกษตรและสหกรณ์การเกษตร") ||
              bankName.includes("อาคารสงเคราะห์")) &&
            value.length !== 12
          )
            return this.createError({
              message: "จำนวนหลักเท่ากับ 12 หลักเท่านั้น",
              path: "bankAccount",
            });
          if (
            payeeMethod === "bank" &&
            !bankName.includes("ออมสิน") &&
            !bankName.includes("เกียรตินาคิน") &&
            !bankName.includes("ทิสโก้") &&
            !bankName.includes("การเกษตรและสหกรณ์การเกษตร") &&
            !bankName.includes("อาคารสงเคราะห์") &&
            value.length !== 10
          )
            return this.createError({
              message: "จำนวนหลักเท่ากับ 10 หลักเท่านั้น",
              path: "bankAccount",
            });
          return true;
        },
      }),
      promptpayType: yup.string().test({
        name: "validated-with-payeeMethod",
        test(value, { parent: { payeeMethod } }) {
          return payeeMethod === "promptpay" && !value
            ? this.createError({
                message: "กรุณาเลือก ประเภทเลขพร้อมเพย์",
                path: "promptpayType",
              })
            : true;
        },
      }),
      promptpay: yup.string().test({
        name: "validated-with-payeeMethod-and-promptpayType",
        test(value, { parent: { payeeMethod, promptpayType } }) {
          if (payeeMethod === "promptpay" && !promptpayType && !value)
            return this.createError({
              message: "กรุณาเลือก ประเภทเลขพร้อมเพย์ ก่อน",
              path: "promptpay",
            });
          if (payeeMethod === "promptpay" && promptpayType && !value)
            return this.createError({
              message: "กรุณากรอก เลขพร้อมเพย์",
              path: "promptpay",
            });

          if (
            payeeMethod === "promptpay" &&
            promptpayType === "เบอร์โทรศัพท์" &&
            value.length !== 10
          )
            return this.createError({
              message: "จำนวนหลักเท่ากับ 10 หลักเท่านั้น",
              path: "promptpay",
            });

          const telephoneFormat = new RegExp(
            /* eslint-disable-next-line no-useless-escape */
            /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm
          );
          if (
            payeeMethod === "promptpay" &&
            promptpayType === "เบอร์โทรศัพท์" &&
            !telephoneFormat.test(value)
          )
            return this.createError({
              message: "เบอร์โทรศัพท์ไม่ถูกต้อง",
              path: "promptpay",
            });

          let sum = 0;
          for (let i = 0; i < 12; i++) sum += +value?.charAt(i) * (13 - i);
          const isCitizenIdFormatCorrected =
            (11 - (sum % 11)) % 10 === +value?.charAt(12);
          if (
            payeeMethod === "promptpay" &&
            promptpayType === "เลขบัตรประชาชน" &&
            value.length !== 13
          )
            return this.createError({
              message: "จำนวนหลักเท่ากับ 13 หลักเท่านั้น",
              path: "promptpay",
            });

          if (
            payeeMethod === "promptpay" &&
            promptpayType === "เลขบัตรประชาชน" &&
            !isCitizenIdFormatCorrected
          )
            return this.createError({
              message: "เลขบัตรประจำตัวประชาชนไม่ถูกต้อง",
              path: "promptpay",
            });
          return true;
        },
      }),
    });
  }, []);

  const toggleDrawerPicker = useCallback(
    (state) => () => {
      dispatch(uiActions.toggleDrawerPicker(state));
    },
    [dispatch]
  );

  const toggleModal = useCallback(
    (modalState = { name: "" }) =>
      () => {
        if ("isOpen" in modalState) {
          dispatch(
            uiActions.toggleModal({
              name: modalState.name,
              isOpen: modalState.isOpen,
            })
          );
          navigate(
            currentRole === "farmer" ? ROUTES.MENU_FARMER : ROUTES.MENU_DRIVER
          );
        } else dispatch(uiActions.toggleModal({ name: modalState.name }));
      },
    [currentRole, navigate, dispatch]
  );

  const scrollPDPAContentHandler = () => {
    const contentObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDisabledAcceptPDPA(false);
        } else setDisabledAcceptPDPA(true);
      },
      {
        root: null,
        threshold: 1,
        rootMargin: "-40px",
      }
    );

    contentObserver.observe(lastParagraphRef.current);
  };

  const { control, handleSubmit, watch, getValues, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(validationSchema),
    });
  const [promptpayTypeValue, bankNameValue] = getValues([
    "promptpayType",
    "bankName",
  ]);
  const payeeMethodValue = watch("payeeMethod");
  const isPromptpay = payeeMethodValue === "promptpay";
  const isBank = payeeMethodValue === "bank";

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    /* Phase 3 */
    setValue("payeeMethod", "bank");
    /* Phase 3 */

    if (payeeMethod || bankName || bankAccount || promptpayType || promptpay) {
      // setValue("payeeMethod", payeeMethod);
      setValue("bankName", bankName);
      setValue("bankAccount", bankAccount);
      // setValue("promptpayType", promptpayType);
      // setValue("promptpay", promptpay);
    }

    const fetchHttp = (signal) => {
      fetchBankList(
        { endpoint: DRIVER_ENDPOINTS.bankLists, signal },
        (banks) => {
          const bankOptions = banks.map(({ bankName }, idx) => ({
            id: idx + 1,
            label: <Typography variant="body1">{bankName}</Typography>,
            value: bankName,
            onClickOption() {
              setValue("bankName", bankName);
              clearErrors("bankName");
              toggleDrawerPicker("bankName")();
            },
          }));
          dispatch(masterActions.createBankNameOptions(bankOptions));
        }
      );
    };

    fetchHttp(signal);

    return () => abortCont.abort();
  }, [
    payeeMethod,
    bankName,
    bankAccount,
    promptpayType,
    promptpay,
    fetchBankList,
    dispatch,
    setValue,
    clearErrors,
    toggleDrawerPicker,
  ]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "payeeMethod" && value.payeeMethod && type === "change") {
        if (value.payeeMethod === "promptpay") {
          clearErrors("bankName");
          clearErrors("bankAccount");
          if (bankName && bankAccount) {
            setValue("bankName", bankName);
            setValue("bankAccount", bankAccount);
          }
        }
        if (value.payeeMethod === "bank") {
          clearErrors("promptpayType");
          clearErrors("promptpay");
          if (promptpayType && promptpay) {
            setValue("promptpayType", promptpayType);
            setValue("promptpay", promptpay);
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [
    bankName,
    bankAccount,
    promptpayType,
    promptpay,
    watch,
    setValue,
    clearErrors,
  ]);

  useEffect(() => {
    if (!pdpa_accept || pdpa_accept === "false")
      toggleModal({ name: "pdpaAccept" })();
  }, [pdpa_accept, toggleModal]);

  const handleAcceptPDPA = () => {
    dispatch(authActions.updatePDPA(true));
    toggleModal({ name: "pdpaAccept" })();
  };

  const promptpayTypeOptions = useMemo(() => {
    return [
      {
        id: "tel",
        label: "เบอร์โทรศัพท์",
        value: "เบอร์โทรศัพท์",
        onClickOption() {
          setValue("promptpayType", "เบอร์โทรศัพท์");
          clearErrors("promptpayType");
          toggleDrawerPicker("promptpayType")();
        },
      },
      {
        id: "cid",
        label: "เลขบัตรประชาชน",
        value: "เลขบัตรประชาชน",
        onClickOption() {
          setValue("promptpayType", "เลขบัตรประชาชน");
          clearErrors("promptpayType");
          toggleDrawerPicker("promptpayType")();
        },
      },
    ];
  }, [setValue, clearErrors, toggleDrawerPicker]);

  const submitUpdateBankAccount = (data) => {
    const body = {
      pdpa_accept,
      payeeMethod: data.payeeMethod,
      ...(data.payeeMethod === "bank"
        ? {
            bankName: data.bankName,
            bankAccount: data.bankAccount,
          }
        : {
            promptpayType: data.promptpayType,
            promptpay: data.promptpay,
          }),
    };

    submitBankData(
      {
        endpoint: DRIVER_ENDPOINTS.update,
        method: "PUT",
        body: data ? body : { pdpa_accept },
      },
      (updateResp) => {
        dispatch(authActions.updateUser(body));

        // never verify OTP (new register)
        if (!verifyOTP) {
          dispatch(
            authActions.setOtpSession({
              refno: updateResp.refno,
              token: updateResp.token,
              tel,
            })
          );
          return navigate(ROUTES.OTP_FORM, {
            state: { action, source, tempTel },
          });
        }

        if (action === "create" && source === "new" && verifyOTP)
          return navigate(ROUTES.CREATE_MACHINE_FORM, {
            state: { action, source },
            replace: true,
          });
        if (action === "create" && source === "getztrac" && verifyOTP)
          return navigate(ROUTES.MENU_DRIVER);
        if (action === "update") return navigate(-1);
        return navigate(ROUTES.MENU_DRIVER);
      }
    );
  };

  const bankAccountCreationWording = action === "create" && (
    <Grid item xs={12} mb={3}>
      <Typography sx={{ color: "error.main", mb: 0.5 }} variant="body1">
        <strong>คุณยังไม่เคยกรอกข้อมูลบัญชีธนาคาร</strong>
      </Typography>
      <Typography variant="subtitle2">
        กรุณากรอกข้อมูลบัญชีธนาคาร เพื่อใช้ในการรับเงินค่าจ้าง
      </Typography>
    </Grid>
  );

  const bankFormDetail = action === "create" && (
    <Grid item xs={12} mb={4}>
      <Typography color="secondary.main" variant="subtitle2">
        ข้อมูลธนาคารและเลขบัญชีของท่านจะใช้ในการรับเงินค่าจ้างงานผ่านระบบเท่านั้น
        ท่านสามารถแก้ไขการรับเงินได้ในภายหลัง
      </Typography>
    </Grid>
  );

  return (
    <Fragment>
      <Grid container mt={3} mb={2}>
        {bankAccountCreationWording}

        <Form
          onSubmit={handleSubmit(submitUpdateBankAccount)}
          height={height}
          aboveElement={bankAccountCreationWording}
        >
          <Grid item xs={12} mb={2}>
            <Typography variant="body1">
              <strong>
                {action === "create" ? "กรอก" : "แก้ไข"}
                ข้อมูลบัญชีธนาคารเพื่อใช้รับเงิน
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Radio
              control={control}
              defaultValue=""
              name="payeeMethod"
              radioDirection="column"
              radioStyles={{ justifyContent: "space-around", mt: 1 }}
              radioOptions={[
                {
                  id: "bank",
                  value: "bank",
                  label: "โอนเงินผ่านธนาคาร (Bank Account)",
                  color: currentRole === "farmer" ? "secondary" : "primary",
                  children: (
                    <Grid container ml={4} mb={2}>
                      <Grid item xs={12} pr={5} mb={2}>
                        <SelectPicker
                          name="bankName"
                          control={control}
                          defaultValue=""
                          label="ธนาคาร"
                          placeholder="กรุณาเลือกธนาคาร"
                          disabled={!isBank}
                          width={{ label: "30%", input: "70%" }}
                          showDrawer={drawerPicker.bankName}
                          onClickInput={toggleDrawerPicker("bankName")}
                        >
                          <Navbar color="secondary" position="absolute">
                            <Navbar.Left>
                              <Typography variant="subtitle2">
                                เลือกธนาคาร
                              </Typography>
                            </Navbar.Left>
                            <Navbar.Right sx={{ ml: "auto" }}>
                              <IconButton
                                onClick={toggleDrawerPicker("bankName")}
                              >
                                <CloseIcon
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.common.white,
                                  }}
                                />
                              </IconButton>
                            </Navbar.Right>
                          </Navbar>
                          <Options
                            optionsData={banks}
                            alignLabel="flex-start"
                            selected={bankNameValue}
                            sx={{ pl: 2 }}
                          />
                        </SelectPicker>
                      </Grid>
                      <Grid item xs={12} pr={5}>
                        <TextInput
                          type="number"
                          control={control}
                          defaultValue=""
                          name="bankAccount"
                          placeholder="ตัวเลขเท่านั้น ขั้นต่ำ 10 หลัก"
                          label="เลขที่บัญชี"
                          disabled={!isBank}
                          width={{ label: "30%", input: "70%" }}
                        />
                      </Grid>
                    </Grid>
                  ),
                },
                {
                  id: "promptpay",
                  value: "promptpay",
                  label: "พร้อมเพย์ (PromptPay) *ยังไม่เปิดให้บริการ*",
                  color: currentRole === "farmer" ? "secondary" : "primary",
                  disabled: true,
                  children: (
                    <Grid container ml={4} mb={2}>
                      <Grid item xs={12} pr={5} mb={2}>
                        <SelectPicker
                          name="promptpayType"
                          control={control}
                          defaultValue=""
                          label="ประเภทเลข"
                          placeholder="กรุณาเลือกประเภท"
                          disabled={!isPromptpay}
                          width={{ label: "30%", input: "70%" }}
                          showDrawer={drawerPicker.promptpayType}
                          onClickInput={toggleDrawerPicker("promptpayType")}
                        >
                          <Navbar color="secondary" position="absolute">
                            <Navbar.Left>
                              <Typography variant="subtitle2">
                                เลือกประเภทเลขพร้อมเพย์
                              </Typography>
                            </Navbar.Left>
                            <Navbar.Right sx={{ ml: "auto" }}>
                              <IconButton
                                onClick={toggleDrawerPicker("promptpayType")}
                              >
                                <CloseIcon
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.common.white,
                                  }}
                                />
                              </IconButton>
                            </Navbar.Right>
                          </Navbar>
                          <Options
                            optionsData={promptpayTypeOptions}
                            alignLabel="center"
                            selected={promptpayTypeValue}
                            sx={{ pl: 2 }}
                          />
                        </SelectPicker>
                      </Grid>
                      <Grid item xs={12} pr={5}>
                        <TextInput
                          type="number"
                          control={control}
                          defaultValue=""
                          name="promptpay"
                          label={
                            promptpayTypeValue === "เบอร์โทรศัพท์"
                              ? "เลขเบอร์"
                              : promptpayTypeValue === "เลขบัตรประชาชน"
                              ? "เลขบัตรปชช."
                              : "เลขที่"
                          }
                          placeholder="ตัวเลขเท่านั้น 10หรือ 13 หลัก"
                          disabled={!isPromptpay}
                          width={{ label: "30%", input: "70%" }}
                        />
                      </Grid>
                    </Grid>
                  ),
                },
              ]}
            />
          </Grid>

          {bankFormDetail}

          <Grid
            container
            item
            justifyContent={action === "create" ? "space-between" : "flex-end"}
            xs={12}
            sx={{ position: "absolute", bottom: 0 }}
          >
            {action === "create" && (
              <Button
                onClick={submitUpdateBankAccount}
                endIcon={<KeyboardDoubleArrowRightIcon />}
              >
                ข้ามขั้นตอนนี้
              </Button>
            )}
            <Fab
              size="small"
              color="secondary"
              variant="extended"
              type="submit"
              sx={{ px: 3 }}
            >
              {action === "create" ? "บันทึก และถัดไป" : "บันทึก"}
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <Modal
        openModal={modal.pdpaAccept}
        onCloseModal={toggleModal({ name: "pdpaAccept", isOpen: false })}
        titleWithClose
      >
        <Modal.Header
          closeButton
          onCloseModal={toggleModal({ name: "pdpaAccept", isOpen: false })}
        >
          <Typography
            component="div"
            align="center"
            variant="body1"
            sx={{ mr: 4, mb: 1, fontSize: "1rem" }}
          >
            <strong>นโยบายคุ้มครองข้อมูลส่วนบุคคลและแบบความยินยอม</strong>
          </Typography>
          <Typography
            component="div"
            align="center"
            variant="body1"
            sx={{ mr: 4, fontSize: "1rem" }}
          >
            <strong>บริษัท เกษตรอินโน จำกัด</strong>
          </Typography>
        </Modal.Header>
        <Modal.Content dividers onScrollModalContent={scrollPDPAContentHandler}>
          <AcceptPDPAPage ref={lastParagraphRef} />
        </Modal.Content>
        <Modal.Footer sx={{ pb: 2 }}>
          <Grid container justifyContent="center">
            <Fab
              variant="extended"
              color="secondary"
              size="small"
              disabled={disabledAcceptPDPA}
              onClick={handleAcceptPDPA}
              sx={{ px: 3 }}
            >
              ยอมรับ
            </Fab>
          </Grid>
        </Modal.Footer>
      </Modal>

      <ActionLoading open={isSubmitting}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default BankAccountFormPage;
