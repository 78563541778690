import { Fragment, useState, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { isAndroid } from "react-device-detect";

import { toggleDrawerPicker, toggleModal } from "store/user-interface/ui-slice";

import {
  Grid,
  Paper as MuiPaper,
  Box,
  Typography,
  Menu,
  MenuItem,
  Drawer,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import { ImagesPreviewModal, StreamMedia } from "components";

import { FileInput } from "components/hook-forms";

import { fileToDataUri, resizeFile, capitalize } from "helpers/Utilies";

const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) =>
    prop !== "error" && prop !== "height" && prop !== "sx",
})(({ theme, error, height }) => ({
  display: "flex",
  margin: theme.spacing(0, 3, 0, 1),
  backgrounColor: theme.palette.grey[100],
  border: `1px dashed ${theme.palette.tertiary.dark}`,
  borderRadius: theme.spacing(3),
  height: theme.spacing(height),
  ...(!!error && {
    marginBottom: theme.spacing(1),
    borderColor: theme.palette.error.main,
  }),
}));

const ExampleImage = styled((props) => (
  <Box {...props}>
    <img
      src={props.imageSrc}
      alt={`${props.ImageName}-example`}
      style={{
        borderRadius: 8,
      }}
    />
  </Box>
))(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: theme.spacing(14),
}));

const PreviewImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "zIndex",
})(({ theme, bottom, left, zIndex }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  overflow: "hidden",
  boxShadow: theme.shadows[5],
  borderRadius: theme.spacing(1),
  bottom,
  left,
  zIndex,
}));

const ImageInput = forwardRef(
  (
    {
      control,
      defaultValue,
      exampleImageSrc,
      imageTextName,
      onRemoveImage,
      ...rest
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { modal, drawerPicker } = useSelector((state) => state.ui);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const {
      field: { name, value, onChange },
      fieldState: { error },
    } = useController({ control, defaultValue, name: rest.name });

    const handleSetMenuAnchor = (e) => setAnchorEl(e.currentTarget);

    const handleHideMenuAnchor = () => setAnchorEl(null);

    const handleOnSelectImages = async (event) => {
      if (event?.target) {
        const { files } = event.target;
        const resizeImages = Object.values(files).map((file) =>
          resizeFile(file)
        );
        const resizeImagesPromises = await Promise.all(resizeImages);
        const imagesPromises = resizeImagesPromises.map((file) =>
          fileToDataUri(file)
        );
        const images = await Promise.all(imagesPromises);
        onChange([...value, ...images]);
      } else {
        const newValue = event;
        onChange([...value, newValue]);
      }

      handleHideMenuAnchor();
    };

    const handleClickRef = () => ref.current.click();

    const handleClickBoxImage = (e) => {
      e.stopPropagation();
      if (isAndroid) handleSetMenuAnchor(e);
      else handleClickRef();
    };

    const toggleModalHandler = (state, isOpen) => () =>
      dispatch(toggleModal({ name: state, isOpen }));

    const toggleDrawerPickerHandler = (state) => () =>
      dispatch(toggleDrawerPicker(state));

    const errorText = error?.message ? (
      <FormHelperText error={!!error} sx={{ pl: 1 }}>
        {error.message}
      </FormHelperText>
    ) : null;

    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={6}
            mt={value.length > 1 ? 2 : 0}
          >
            {value.length ? (
              <Grid
                onClick={toggleModalHandler(name, true)}
                item
                xs={9}
                mb={1}
                sx={{
                  position: "relative",
                  width: "80%",
                  height: "80%",
                }}
              >
                {value.slice(0, 3).map((image, idx) => (
                  <PreviewImage
                    key={idx}
                    src={image.data}
                    alt={`entrance-img-${idx + 1}`}
                    bottom={idx * 10}
                    left={idx * 10}
                    zIndex={idx * -1}
                  />
                ))}
                {value.length > 3 ? (
                  <Box
                    sx={(theme) => ({
                      position: "absolute",
                      top: "25%",
                      left: "35%",
                      p: 2,
                      bgcolor: "rgba(0,0,0,.5)",
                      borderRadius: theme.spacing(1),
                    })}
                  >
                    <Typography
                      variant="body1"
                      color="white"
                      sx={{ lineHeight: 1 }}
                    >
                      +{value.slice(3).length}
                    </Typography>
                  </Box>
                ) : null}
              </Grid>
            ) : (
              <Grid item xs px={2} sx={{ width: "100%", mb: 0.5 }}>
                <ExampleImage
                  imageSrc={exampleImageSrc}
                  imageName={name}
                  sx={{ px: 2 }}
                />
              </Grid>
            )}

            <Grid item xs>
              <Typography color="tertiary" variant="subtitle2">
                <small>
                  {value.length
                    ? `รูป${imageTextName}`
                    : `ตัวอย่าง ${imageTextName}`}
                </small>
              </Typography>
            </Grid>
          </Grid>

          <Grid container item alignItems="center" xs={6}>
            <Grid item xs={12} mb={2}>
              <label htmlFor={`${name}__file-input`} style={{ width: "100%" }}>
                <FileInput
                  ref={ref}
                  id={`${name}__file-input`}
                  name={name}
                  multiple={true}
                  type="file"
                  accept="image/*"
                  onChange={handleOnSelectImages}
                  disabled={rest.disabled}
                />
              </label>
              <Paper
                elevation={0}
                error={error}
                height={rest.height}
                sx={rest.sx}
                onClick={handleClickBoxImage}
              >
                <Grid container item>
                  {rest.children}
                </Grid>
              </Paper>
              {errorText}
            </Grid>
          </Grid>
        </Grid>

        <Menu
          id="images-menu"
          aria-labelledby="images-menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleHideMenuAnchor}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          sx={(theme) => ({
            "& .MuiPaper-root": {
              borderRadius: theme.spacing(1),
              width: theme.spacing(20),
            },
          })}
        >
          <MenuItem onClick={handleClickRef} divider dense>
            <Typography variant="subtitle2">คลังรูปภาพ</Typography>
            <BurstModeIcon fontSize="small" sx={{ ml: "auto" }} />
          </MenuItem>
          <MenuItem
            dense
            onClick={toggleDrawerPickerHandler(
              `streamMedia${capitalize(name)}`
            )}
          >
            <Typography variant="subtitle2">ถ่ายภาพ</Typography>
            <CameraAltIcon fontSize="small" sx={{ ml: "auto" }} />
          </MenuItem>
        </Menu>

        <ImagesPreviewModal
          openModal={modal[name]}
          onCloseModal={toggleModalHandler(name, false)}
          headerText={`รูป${imageTextName}`}
          images={value}
          onRemoveImage={onRemoveImage}
        />

        <Drawer
          anchor="bottom"
          open={drawerPicker[`streamMedia${capitalize(name)}`]}
          onClose={toggleDrawerPickerHandler(`streamMedia${capitalize(name)}`)}
          sx={{ zIndex: ({ zIndex }) => zIndex.modal + 1 }}
        >
          <StreamMedia
            height="500px"
            onCapture={handleOnSelectImages}
            onCloseStream={toggleDrawerPickerHandler(
              `streamMedia${capitalize(name)}`
            )}
          />
        </Drawer>
      </Fragment>
    );
  }
);

ImageInput.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ImageInput.defaultProps = {
  height: 15,
};

export default ImageInput;
