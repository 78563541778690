import {
  Fragment,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, useFormState, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Wrapper } from "@googlemaps/react-wrapper";

import Swal from "sweetalert2";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Fab,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

import { useGetMasterAddressQuery } from "services/master";
import { selectMasterAddress } from "store/master-slice";

import {
  useGetFieldsQuery,
  useCreateFieldMutation,
  useUpdateFieldMutation,
  useDeleteFieldMutation,
} from "services/field";

import { toggleDrawerPicker } from "store/user-interface/ui-slice";
import { useMapLocation, useSwalStyles, useScrollToTop } from "hooks";

import { Navbar } from "layouts/components";
import { TextInput, SelectPicker, Radio } from "components/hook-forms";
import { GoogleMap, Marker } from "components/location";
import {
  Options,
  SlidingTransition,
  HoldingDivider,
  DividerFullWidth,
  ActionLoading,
} from "base-components";

import { ROUTES } from "routes/router";
import { DEFAULT_ZOOM, LOCATION_ZOOM } from "constants/Location";
import { VEHICLES } from "constants/Vehicles";

const Form = styled("form")({
  position: "relative",
});

const FieldFormPage = () => {
  const dispatch = useDispatch();
  const { drawerPicker } = useSelector((state) => state.ui);
  const [triggerTransition, setTriggerTransition] = useState(false);
  const coordinateSourceRef = useRef(null);

  const navigate = useNavigate();
  const { state } = useLocation();
  const firstCreate = state?.actions === "firstCreate";
  const serviceName = state?.serviceName;
  const { action, fieldName } = useParams();
  const editPage = action === "edit";

  /* GET Master address */
  const selectedMasterAddress = useSelector(selectMasterAddress);
  const { isLoading: isLoadingMasterAddress } = useGetMasterAddressQuery(
    undefined,
    {
      skip: selectedMasterAddress.length,
    }
  );

  /* GET Fields */
  const { data: fields = [], isLoading: isLoadingFields } = useGetFieldsQuery(
    undefined,
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );
  const selectedField = fields.find((field) => field.name === fieldName);

  useScrollToTop(isLoadingMasterAddress, isLoadingFields);

  const [createField, { isLoading: isCreating }] = useCreateFieldMutation();
  const [updateField, { isLoading: isUpdating }] = useUpdateFieldMutation();
  const [deleteField, { isLoading: isDeleting }] = useDeleteFieldMutation();

  const validationSchema = useMemo(() => {
    yup.addMethod(yup.string, "duplicatedName", function (errorMessage) {
      return this.test(
        "test-duplicated-name-format",
        errorMessage,
        function (value) {
          const { path, createError } = this;
          const duplicatedFieldName = fields.some((field) => {
            if (editPage && fieldName)
              return field.name === value.trim() && field.name !== fieldName;
            return field.name === value.trim();
          });
          return (
            !duplicatedFieldName || createError({ path, message: errorMessage })
          );
        }
      );
    });

    return yup.object({
      name: yup
        .string()
        .required("กรุณากรอก ชื่อแปลง")
        .duplicatedName("ชื่อแปลงนี้ถูกใช้ไปแล้ว กรุณาใช้ชื่อใหม่"),
      coordinateSource: yup
        .string()
        .required("กรุณาเลือก วิธีการระบุจุดที่อยู่แปลงของท่าน"),
      mapProvince: yup.string().test({
        name: "mapProvince-required-when-coordinateSource-equal-to-map",
        test(value, { parent: { coordinateSource } }) {
          if (coordinateSource && coordinateSource === "map" && !value)
            return this.createError({
              message: "กรุณาเลือกตำแหน่งจากแผนที่",
              path: "mapProvince",
            });
          return true;
        },
      }),
      mapDistrict: yup.string().test({
        name: "mapDistrict-required-when-coordinateSource-equal-to-map",
        test(value, { parent: { coordinateSource } }) {
          if (coordinateSource && coordinateSource === "map" && !value)
            return this.createError({
              message: "กรุณาเลือกตำแหน่งจากแผนที่",
              path: "mapDistrict",
            });
          return true;
        },
      }),
      mapSubDistrict: yup.string().test({
        name: "mapSubDistrict-required-when-coordinateSource-equal-to-map",
        test(value, { parent: { coordinateSource } }) {
          if (coordinateSource && coordinateSource === "map" && !value)
            return this.createError({
              message: "กรุณาเลือกตำแหน่งจากแผนที่",
              path: "mapSubDistrict",
            });
          return true;
        },
      }),
      province: yup.string().test({
        name: "province-required-when-coordinateSource-equal-to-manual",
        test(value, { parent: { coordinateSource } }) {
          if (coordinateSource && coordinateSource === "manual" && !value)
            return this.createError({
              message: "กรุณาเลือก จังหวัด",
              path: "province",
            });
          return true;
        },
      }),
      district: yup.string().test({
        name: "district-required-when-coordinateSource-equal-to-manual",
        test(value, { parent: { coordinateSource } }) {
          if (coordinateSource && coordinateSource === "manual" && !value)
            return this.createError({
              message: "กรุณาเลือก อำเภอ",
              path: "district",
            });
          return true;
        },
      }),
      subDistrict: yup.string().test({
        name: "subDistrict-required-when-coordinateSource-equal-to-manual",
        test(value, { parent: { coordinateSource } }) {
          if (coordinateSource && coordinateSource === "manual" && !value)
            return this.createError({
              message: "กรุณาเลือก ตำบล",
              path: "subDistrict",
            });
          return true;
        },
      }),
      size: yup
        .number()
        .integer("ระบุเป็น จำนวนเต็ม เท่านั้น")
        .positive("ระบุเป็น จำนวนบวก เท่านั้น")
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("กรุณากรอก ขนาดพื้นที่แปลง"),
    });
  }, [fields, editPage, fieldName]);

  const { control, getValues, setValue, watch, clearErrors, handleSubmit } =
    useForm({
      resolver: yupResolver(validationSchema),
    });
  const [
    name,
    mapLat,
    mapLng,
    mapProvince,
    mapDistrict,
    mapSubDistrict,
    mapZipcode,
    province,
    district,
    subDistrict,
  ] = getValues([
    "name",
    "mapLat",
    "mapLng",
    "mapProvince",
    "mapDistrict",
    "mapSubDistrict",
    "mapZipcode",
    "province",
    "district",
    "subDistrict",
  ]);
  const { errors } = useFormState({ control });
  const coordinateSource = useWatch({ control, name: "coordinateSource" });

  const {
    currentLocation,
    expandMap,
    onToggleExpandMap,
    onClickMap,
    onRelocateCurrentLocationHandler,
    onChangeSearchOtherLocation,
  } = useMapLocation({
    location: {
      lat: mapLat,
      lng: mapLng,
      zoom: LOCATION_ZOOM,
      defaultZoom: DEFAULT_ZOOM,
    },
    setValueFn: setValue,
    clearErrorsFn: clearErrors,
    hasLocation: editPage,
  });

  const classes = useSwalStyles();

  useEffect(() => {
    if (!selectedField?.name) return;

    setValue("name", selectedField.name);
    setValue("coordinateSource", selectedField.coordinateSource);
    setValue("size", selectedField.size);
    setValue("detail", selectedField.detail);
    if (selectedField.coordinateSource === "map") {
      setValue("mapLat", selectedField.lat);
      setValue("mapLng", selectedField.lon);
      setValue("mapProvince", selectedField.province);
      setValue("mapDistrict", selectedField.district);
      setValue("mapSubDistrict", selectedField.subDistrict);
      setValue("mapZipcode", selectedField.zipcode);
    }
    if (selectedField.coordinateSource === "manual") {
      setValue("province", selectedField.province);
      setValue("district", selectedField.district);
      setValue("subDistrict", selectedField.subDistrict);
      setValue("zipcode", selectedField.zipcode);
      setValue("address", selectedField.address);
    }
  }, [selectedField, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change") {
        if (name === "coordinateSource") {
          const setDefaultFields = {
            map() {
              const manualFields = [
                "province",
                "district",
                "subDistrict",
                "zipcode",
                "address",
              ];
              for (let field of manualFields) {
                setValue(field, "");
                clearErrors(field);
              }
            },
            manual() {
              const mapFields = [
                "mapLat",
                "mapLng",
                "mapProvince",
                "mapDistrict",
                "mapSubDistrict",
                "mapZipcode",
              ];
              for (let field of mapFields) {
                setValue(field, "");
                clearErrors(field);
              }
            },
          };
          setDefaultFields[value.coordinateSource]();
        }
      }

      if (name === "province") {
        setValue("district", "");
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }

      if (name === "district") {
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, clearErrors]);

  const toggleDrawerPickerHandler = useCallback(
    (state) => () => {
      dispatch(toggleDrawerPicker(state));
    },
    [dispatch]
  );

  const handleChangeRadio = () => {
    setTriggerTransition(true);
  };

  const submitFieldForm = async (form) => {
    const isEdit = editPage && fieldName;

    const locationDetails = {
      map: {
        lat: form.mapLat,
        lon: form.mapLng,
        province: form.mapProvince,
        district: form.mapDistrict,
        subDistrict: form.mapSubDistrict,
        zipcode: form.mapZipcode,
      },
      manual: {
        province: form.province,
        district: form.district,
        subDistrict: form.subDistrict,
        zipcode: form.zipcode,
        address: form.address,
        landmark: form.landmark,
      },
    };

    const body = {
      name: form.name.trim(),
      size: form.size,
      detail: form.detail,
      coordinateSource: form.coordinateSource,
      ...locationDetails[form.coordinateSource],
    };

    if (!isEdit) {
      await createField(body).unwrap();
      return firstCreate && serviceName !== VEHICLES.excavator.nameEN
        ? navigate(ROUTES.JOB_FORM(serviceName, "create"))
        : navigate(-1);
    } else {
      const updatedResponse = await updateField({ fieldName, body }).unwrap();
      if (
        updatedResponse ===
        "Cannot edit field! Please check job related with field."
      ) {
        const errorInProgressJobs = await new Swal({
          titleText: `พบงานที่รอการยืนยันสถานะงาน`,
          text: `ไม่สามารถแก้ไขแปลง "${name}" ได้ กรุณายกเลิกหรือดำเนินงานที่รอการยืนยันทั้งหมดที่คงค้างอยู่ให้เรียบร้อยก่อนแก้ไขแปลง`,
          icon: "warning",
          cancelButtonText: "กลับหน้าเดิม",
          confirmButtonText: "จัดการงาน",
          width: "80%",
          showCloseButton: true,
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2WarningButton,
            cancelButton: classes.swal2CancelButton,
          },
        });
        if (errorInProgressJobs.isConfirmed) {
          return navigate(ROUTES.JOBS("farmer"));
        }
        return;
      }
      return navigate(-1);
    }
  };

  const submitErrorForm = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const deleteFieldHandler = async () => {
    const swalResp = await new Swal({
      titleText: `ยืนยันลบแปลง${fieldName} หรือไม่`,
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "80%",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: true,
      customClass: { container: classes.swal2Delete },
    });
    if (swalResp.isConfirmed) {
      const deletedResponse = await deleteField(fieldName).unwrap();
      if (deletedResponse?.error && deletedResponse?.jobs?.length) {
        const errorInProgressJobs = await new Swal({
          titleText: `พบงานที่รอการยืนยันสถานะงาน`,
          text: `ไม่สามารถลบแปลง "${name}" ได้ กรุณาลบงานสถานะร่างงานและยกเลิกงานที่รอการยืนยันทั้งหมด ที่คงค้างอยู่ให้เรียบร้อยก่อนลบแปลง`,
          icon: "warning",
          cancelButtonText: "กลับหน้าเดิม",
          confirmButtonText: "จัดการงาน",
          width: "80%",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          reverseButtons: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2WarningButton,
            cancelButton: classes.swal2CancelButton,
          },
        });
        return (
          errorInProgressJobs.isConfirmed && navigate(ROUTES.JOBS("farmer"))
        );
      } else return navigate(-1, { replace: true });
    }
  };

  const mapTextInputs = useMemo(
    () => [
      {
        id: "lat-dp-none-input",
        name: "mapLat",
        label: "latitude",
      },
      { id: "lng-dp-none-input", name: "mapLng", label: "longitude" },
      {
        id: "province-dp-none-input",
        name: "mapProvince",
        label: "จังหวัด",
      },
      {
        id: "district-dp-none-input",
        name: "mapDistrict",
        label: "อำเภอ",
      },
      {
        id: "sub-district-dp-none-input",
        name: "mapSubDistrict",
        label: "ตำบล",
      },
      {
        id: "zipcode-dp-none-input",
        name: "mapZipcode",
        label: "รหัสไปรษณีย์",
      },
    ],
    []
  );

  const addressDropdownInputs = useMemo(() => {
    const noValueOption = ({ reqFieldTH, reqFieldEN, field }) => [
      {
        id: `no-${reqFieldEN}-value`,
        label: (
          <Typography variant="body1">{`กรุณาเลือก ${reqFieldTH} ก่อน`}</Typography>
        ),
        value: "",
        onClickOption() {
          toggleDrawerPickerHandler(field)();
        },
      },
    ];

    return [
      {
        id: "province-select-input",
        name: "province",
        label: "จังหวัด",
        placeholder: "เลือก จังหวัด",
        width: { label: "15%", input: "85%" },
        showDrawer: drawerPicker.province,
        optionTitle: "เลือกจังหวัด",
        value: province,
        size: { xs: 12 },
        sx: { mb: 2 },
        navPosition: "sticky",
        options: selectedMasterAddress.map((province) => ({
          id: province.id,
          label: <Typography variant="body1">{province.name}</Typography>,
          value: province.name,
          onClickOption() {
            setValue("province", province.name);
            clearErrors("province");
            toggleDrawerPickerHandler("province")();
          },
        })),
      },
      {
        id: "district-select-input",
        name: "district",
        label: "อำเภอ",
        placeholder: "เลือก อำเภอ",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.district,
        optionTitle: "เลือกอำเภอ",
        value: district,
        size: { xs: 6 },
        sx: { pr: 0.5, mb: 2 },
        navPosition: "absolute",
        options: province
          ? selectedMasterAddress
              .find(({ name: provinceName }) => provinceName === province)
              ?.districts.map(({ name: districtName }) => ({
                id: districtName,
                label: <Typography variant="body1">{districtName}</Typography>,
                value: districtName,
                onClickOption() {
                  setValue("district", districtName);
                  clearErrors("district");
                  toggleDrawerPickerHandler("district")();
                },
              }))
          : noValueOption({
              reqFieldEN: "province",
              reqFieldTH: "จังหวัด",
              field: "district",
            }),
      },
      {
        id: "subDistrict-select-input",
        name: "subDistrict",
        label: "ตำบล",
        placeholder: "เลือก ตำบล",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.subDistrict,
        optionTitle: "เลือกตำบล",
        value: subDistrict,
        size: { xs: 6 },
        sx: { pr: 0.5, mb: 2 },
        navPosition: "absolute",
        options:
          province && district
            ? selectedMasterAddress
                .find(({ name: provinceName }) => provinceName === province)
                ?.districts.find(
                  ({ name: districtName }) => districtName === district
                )
                ?.subDistricts.map(({ name: subDistrictName, zipcode }) => ({
                  id: subDistrictName,
                  label: (
                    <Typography variant="body1">{subDistrictName}</Typography>
                  ),
                  value: subDistrictName,
                  onClickOption() {
                    setValue("subDistrict", subDistrictName);
                    setValue("zipcode", zipcode);
                    clearErrors("subDistrict");
                    toggleDrawerPickerHandler("subDistrict")();
                  },
                }))
            : noValueOption({
                reqFieldEN: !province ? "province" : "district",
                reqFieldTH: !province ? "จังหวัด" : "อำเภอ",
                field: "subDistrict",
              }),
      },
    ];
  }, [
    selectedMasterAddress,
    drawerPicker,
    province,
    district,
    subDistrict,
    setValue,
    clearErrors,
    toggleDrawerPickerHandler,
  ]);

  const firstFieldCreationWording = firstCreate && (
    <Fragment>
      <Grid item xs={12} mb={1}>
        <Typography color="error" variant="body1">
          <strong>คุณยังไม่เคยกรอกข้อมูลแปลง</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="subtitle2">
          กรุณากรอกข้อมูลแปลงก่อนเริ่มจ้างงาน
        </Typography>
      </Grid>

      <HoldingDivider item xs={12}>
        <DividerFullWidth
          sx={{ transform: "translate(-5%, 0)", width: "110%" }}
        />
      </HoldingDivider>
    </Fragment>
  );

  return (
    <Fragment>
      <Grid container mt={3} mb={2}>
        {firstFieldCreationWording}
        <Form onSubmit={handleSubmit(submitFieldForm, submitErrorForm)}>
          <Grid item xs={12} mb={1}>
            <TextInput
              control={control}
              defaultValue=""
              name="name"
              placeholder="เช่น แปลงเกษตร 1"
              label={
                <Typography color="secondary" variant="body1">
                  ชื่อแปลง
                </Typography>
              }
              helper={
                <Typography
                  component="span"
                  color="secondary"
                  sx={{ fontSize: "inherit" }}
                >
                  สามารถเปลี่ยนชื่อแปลงได้ภายหลัง
                </Typography>
              }
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>

          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          <Grid item xs={12} mt={4} mb={3} pr={5}>
            <TextInput
              control={control}
              defaultValue=""
              name="size"
              type="number"
              placeholder="ระบุ"
              label={
                <Typography variant="body1" color="secondary">
                  ขนาดพื้นที่แปลง
                </Typography>
              }
              labelTail="ไร่"
              alignValue="center"
              width={{ label: "50%", input: "30%", labelTail: "20%" }}
            />
          </Grid>

          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          <Grid item xs={12} mt={5} ref={coordinateSourceRef}>
            <Typography variant="body1" color="secondary">
              เลือกตำแหน่งแปลง
            </Typography>
          </Grid>

          {mapTextInputs.map((textInput) => (
            <TextInput
              key={textInput.id}
              control={control}
              defaultValue=""
              name={textInput.name}
              label={textInput.label}
              type="text"
              disabled={true}
              width={{ label: "30%", input: "70%" }}
              sx={{ display: "none" }}
            />
          ))}

          <Grid item xs={12} ml={1} mb={2}>
            <Radio
              control={control}
              defaultValue=""
              name="coordinateSource"
              onChangeCallback={handleChangeRadio}
              radioDirection="column"
              radioStyles={{ justifyContent: "space-around", mt: 1 }}
              radioOptions={[
                {
                  id: "map",
                  value: "map",
                  color: "secondary",
                  label: (
                    <Grid container>
                      <Grid container item xs alignItems="center">
                        <Typography variant="body1">
                          ระบุตำแหน่งด้วยแผนที่
                        </Typography>
                      </Grid>
                      {coordinateSource === "map" && (
                        <Grid container item xs="auto" ml={8}>
                          <Button
                            color="primary"
                            onClick={onToggleExpandMap}
                            endIcon={
                              expandMap ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )
                            }
                          >
                            {expandMap ? "ย่อแผนที่" : "ขยายแผนที่"}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  ),
                  children: (
                    <SlidingTransition
                      show={coordinateSource === "map"}
                      elementScrollRef={coordinateSourceRef.current}
                      isTriggerTransition={triggerTransition}
                    >
                      <Grid item xs={12} my={1}>
                        <Wrapper
                          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                          libraries={["places"]}
                          language="th"
                          render={(status) => (
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              height={500}
                              width="100%"
                            />
                          )}
                        >
                          <GoogleMap
                            center={{
                              lat: currentLocation.lat,
                              lng: currentLocation.lng,
                            }}
                            selectedCenter={
                              editPage
                                ? {
                                    lat: mapLat,
                                    lng: mapLng,
                                  }
                                : null
                            }
                            selectedAddress={
                              mapProvince &&
                              mapDistrict &&
                              mapSubDistrict &&
                              `${mapSubDistrict}, ${mapDistrict}, ${mapProvince}, ${mapZipcode}`
                            }
                            errors={{
                              mapProvince: errors.mapProvince,
                              mapDistrict: errors.mapDistrict,
                              mapSubDistrict: errors.mapSubDistrict,
                            }}
                            zoom={DEFAULT_ZOOM}
                            mapHeight={expandMap ? 700 : 400}
                            searchOther={{
                              isSearchOther: true,
                              searchInput: {
                                label: "ค้นหาตำแหน่ง",
                                placeholder: "พิมพ์ค้นหาตำแหน่งแปลงของคุณ",
                              },
                              changeOther: onChangeSearchOtherLocation,
                            }}
                            isSelectCurrent={
                              currentLocation.lat === mapLat &&
                              currentLocation.lng === mapLng
                            }
                            clickMap={onClickMap}
                            clickCurrent={onRelocateCurrentLocationHandler}
                          >
                            <Marker
                              position={{
                                lat: mapLat || currentLocation.lat,
                                lng: mapLng || currentLocation.lng,
                              }}
                            />
                          </GoogleMap>
                        </Wrapper>
                      </Grid>
                    </SlidingTransition>
                  ),
                },
                {
                  id: "manual",
                  value: "manual",
                  color: "secondary",
                  label: "ระบุตำแหน่งเอง",
                  children: (
                    <SlidingTransition
                      show={coordinateSource === "manual"}
                      elementScrollRef={coordinateSourceRef.current}
                      isTriggerTransition={triggerTransition}
                    >
                      <Grid container mt={2}>
                        {addressDropdownInputs.map((selectInput) => (
                          <Grid
                            item
                            xs={selectInput.size.xs}
                            key={selectInput.id}
                            sx={selectInput.sx}
                          >
                            <SelectPicker
                              label={selectInput.label}
                              name={selectInput.name}
                              placeholder={selectInput.placeholder}
                              control={control}
                              defaultValue=""
                              width={selectInput.width}
                              showEndIcon={false}
                              showDrawer={selectInput.showDrawer}
                              onClickInput={toggleDrawerPickerHandler(
                                selectInput.name
                              )}
                            >
                              <Navbar
                                color="secondary"
                                position={selectInput.navPosition}
                              >
                                <Navbar.Left sx={{ flexGrow: 1 }}>
                                  <Typography variant="subtitle2">
                                    {selectInput.optionTitle}
                                  </Typography>
                                </Navbar.Left>
                                <Navbar.Right sx={{ ml: "auto" }}>
                                  <IconButton
                                    onClick={toggleDrawerPickerHandler(
                                      selectInput.name
                                    )}
                                  >
                                    <CloseIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.common.white,
                                      }}
                                    />
                                  </IconButton>
                                </Navbar.Right>
                              </Navbar>
                              <Options
                                optionsData={selectInput.options}
                                selected={selectInput.value}
                              />
                            </SelectPicker>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="zipcode"
                            label="รหัสไปรษณีย์"
                            type="text"
                            width={{ label: "30%", input: "80%" }}
                            sx={{ display: "none" }}
                          />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="address"
                            placeholder="ระบุชื่อหมู่บ้าน"
                            label="หมู่บ้าน"
                            width={{ label: "15%", input: "85%" }}
                          />
                        </Grid>
                      </Grid>
                    </SlidingTransition>
                  ),
                },
              ]}
            />
          </Grid>

          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          <Grid item xs={12} mt={4} mb={2}>
            <Typography variant="body1" color="secondary">
              ระบุรายละเอียดเพิ่มเติม
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="subtitle2">
              กรุณาระบุรายละเอียดเพิ่มเติมเพื่อให้คนขับสามารถเดินทางมายังแปลงของคุณได้สะดวกยิ่งขึ้น
              เช่น สถานที่ใกล้เคียง
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextInput
              control={control}
              defaultValue=""
              placeholder="ระบุรายละเอียดเพิ่มเติม"
              name="detail"
              width={{ input: "100%" }}
              type="textarea"
              multiline
              rows={4}
            />
          </Grid>

          <Grid container item xs={12} mb={2}>
            {editPage && (
              <Button
                // variant="outlined"
                color="error"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={deleteFieldHandler}
                sx={{ ml: "auto" }}
              >
                ลบแปลง
              </Button>
            )}

            <Fab
              variant="extended"
              color="secondary"
              type="submit"
              size="small"
              sx={{ ml: editPage ? 2 : "auto", px: 3 }}
            >
              {firstCreate ? "ถัดไป" : "บันทึก"}
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <ActionLoading
        color="secondary"
        open={isCreating || isUpdating || isDeleting}
      >
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default FieldFormPage;
