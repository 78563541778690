import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const { liff } = window;
// Create bseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  prepareHeaders: async (headers, { getState }) => {
    const token = getState().auth.access_token;
    if (token) {
      headers.set("access_token", token);
      return headers;
    }
    const accessToken = await liff.getgetAccessToken();
    headers.set("access_token", accessToken);
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "splitApi",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    "Customer",
    "Driver",
    "Machinery",
    "Service",
    "Field",
    "CustomerJob",
    "DriverJob",
    "Promotion",
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
  /**
   * Set all default refetch bahaviors to true
   */
  // refetchOnMountOrArgChange: true,
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
});

// export const enchanceApi = api.enhanceEndpoints({
//   endpoints: () => ({}),
// });
