import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const InlineFormControl = styled(FormControl, {
  shouldForwardProp: (prop) =>
    prop !== "size" && prop !== "width" && prop !== "alignValue",
})(({ theme, size, width, alignValue }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${width.label}`,
      paddingTop: theme.spacing(size === "medium" ? 2 : 1),
      paddingLeft: 0,
      ...(width.labelTail && {
        "&:last-child": {
          justifyContent: "flex-end",
          flexBasis: width.labelTail,
          paddingRight: theme.spacing(1),
        },
      }),
    },
    "& .MuiInputBase-root": {
      flexBasis: width.input,
      ...(alignValue && { "& .MuiInputBase-input": { textAlign: alignValue } }),
    },
  };
});

const ColumnInputWithHelperText = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "& .MuiFormHelperText-contained": {
      margin: theme.spacing(0.5, 0),
    },
  };
});

const TextInput = ({
  control,
  defaultValue,
  labelTail,
  multiline,
  rows,
  timeformat,
  width,
  alignValue,
  helper,
  onChangeInput,
  ...props
}) => {
  const onChangeHandler = (onChange) => (e) => {
    const { value } = e.target;
    if (!onChangeInput) return onChange(value);
    onChangeInput(value);
    onChange(value);
  };

  const onWheelNumberInputPreventChange = (e) => {
    if (e.target.type === "number") {
      // Prevent the input value change
      e.target.blur();

      // Prevent the page/container scrolling
      e.stopPropagation();

      // Refocus immediately
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else return;
  };

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { ref, name, value, onChange },
        fieldState: { error },
      }) => {
        const helperText =
          helper && !error?.message ? (
            <FormHelperText>{helper}</FormHelperText>
          ) : null;

        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;

        const labelTailElement = labelTail ? (
          <InputLabel
            error={!!error}
            htmlFor={`${name}__input`}
            sx={{ color: (theme) => theme.palette.common.black }}
          >
            {labelTail}
          </InputLabel>
        ) : null;

        return (
          <InlineFormControl
            disabled={props.disabled}
            size={props.size}
            width={width}
            alignValue={alignValue}
            sx={props.sx}
          >
            <InputLabel
              error={!!error}
              htmlFor={`${name}__input`}
              sx={{ color: (theme) => theme.palette.common.black }}
            >
              {props.label}
            </InputLabel>
            <ColumnInputWithHelperText>
              <OutlinedInput
                id={`${name}__input`}
                name={name}
                inputRef={ref}
                type={props.type}
                placeholder={props.placeholder}
                multiline={multiline}
                rows={rows}
                value={value}
                disabled={props.disabled}
                onChange={onChangeHandler(onChange)}
                onWheel={onWheelNumberInputPreventChange}
                error={!!error}
                size={props.size}
                inputProps={{
                  // ...(props.type === "number" && { pattern: "\\d*" }),
                  ...(props.type === "number" && { inputMode: "numeric" }),
                }}
              />
              {helperText}
              {errorText}
            </ColumnInputWithHelperText>
            {labelTailElement}
          </InlineFormControl>
        );
      }}
    />
  );
};

TextInput.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  helper: PropTypes.node,
  name: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
  alignValue: PropTypes.oneOf(["center", "right"]),
  onChangeInput: PropTypes.func,
  width: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    input: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    labelTail: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

TextInput.defaultProps = {
  type: "text",
  disabled: false,
  multiline: false,
  size: "small",
  width: {
    label: "25%",
    input: "75%",
    labelTail: "0",
  },
};

export default TextInput;
