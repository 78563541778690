import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { parse, isAfter } from "date-fns";

import { Wrapper } from "@googlemaps/react-wrapper";
import { Grid, Typography, Button, Fab } from "@mui/material";
import {
  CalendarMonth as CalendarMonthIcon,
  AccessTime as AccessTimeIcon,
  Map as MapIcon,
} from "@mui/icons-material";

import {
  useGetCustomerJobByIdQuery,
  useGetDriverJobByIdQuery,
} from "services/job";

import { useDate, useMapLocation } from "hooks";
import { toggleModal, toggleDrawerPicker } from "store/user-interface/ui-slice";

import { ROUTES } from "routes/router";
import {
  DRIVER_STATUS,
  FARMER_STATUS,
  defaultStatus,
} from "constants/JobStatus";

import CallToActions from "pages/job-details/components/CallToActions";
import { ScrollToTop, Modal, ChipStatus } from "base-components";
import { ImagesPreviewLayer } from "components";
import { GoogleMap, Marker } from "components/location";

import {
  UserInformation,
  WageRateDetailsBreakdown,
  TotalAmount,
  OfferLastPriceButton,
  PromotionDetailsBreakdown,
  PaymentMethodDetail,
  WorkingDetail,
} from "pages/job-details/components/footer-information";
import {
  UploadSlip,
  RequestReceipt,
} from "pages/job-details/components/post-payment";
import { NotifyOfferingPrice } from "pages/job-details/components/modals";

import { ROLES } from "constants/Roles";
import { VEHICLES } from "constants/Vehicles";
import { JOB_STATUS } from "constants/JobStatus";

const { liff } = window;

const JobDetailPage = () => {
  const dispatch = useDispatch();
  const { currentRole } = useSelector((state) => state.auth);
  const { modal } = useSelector((state) => state.ui);
  /* fieldImages, entranceImages, areaImages, obstructionImages */

  /* list of all status */
  let role = FARMER_STATUS;
  if (currentRole === "driver") role = DRIVER_STATUS;

  const navigate = useNavigate();
  const { jobId } = useParams();

  let { data: job = null } = useGetCustomerJobByIdQuery(jobId, {
    // skip: currentRole !== "farmer",
    // pollingInterval: 30000,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    selectFromResult: (result) => result.data,
  });
  console.log("farmer job: ", job);

  const { data: driverJob = null } = useGetDriverJobByIdQuery(jobId, {
    // skip: currentRole !== "driver",
    // pollingInterval: 30000,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    selectFromResult: (result) => {
      console.log(result);
      return result.data;
    },
  });
  if (currentRole === "driver") job = driverJob;
  console.log("driver job: ", job);

  const { abbreviatedDateFormat } = useDate();
  const { currentLocation } = useMapLocation({
    location: { lat: job?.fieldLat, lng: job?.fieldLon },
    hasLocaiton: true,
  });

  useEffect(() => {
    const socket = io(process.env.REACT_APP_PAYMENT_API_BASE_URL);

    const recieveData = (data) => {
      if (data.action === "notify" && currentRole === "farmer") {
        dispatch(toggleDrawerPicker("paymentDetail"));
        navigate(ROUTES.PAYMENT_SUCCESS);
      }
    };

    socket.on(`payment/${jobId}`, recieveData);

    return () => {
      socket.off(`payment/${jobId}`, recieveData);
    };
  }, [currentRole, jobId, navigate, dispatch]);

  /* [Farmer] Show notify modal when driver offer last price */
  useEffect(() => {
    if (
      currentRole === ROLES.FARMER &&
      job?.vinType === VEHICLES.excavator.nameEN &&
      job?.flagEditedPrice &&
      job?.stage === JOB_STATUS[currentRole].delivered.nameEN
    ) {
      dispatch(toggleModal({ name: "notifyOfferPrice", isOpen: true }));
    }
  }, [currentRole, job?.vinType, job?.flagEditedPrice, job?.stage, dispatch]);

  const onContactEachOther = (telNum) => {
    window.location.assign(
      `tel:+66${telNum.substring(1, telNum.length).replace(/\s/g, "")}`
    );
  };

  const toggleModalHandler = (state) => () =>
    dispatch(toggleModal({ name: state }));

  const getDirections = () => {
    liff.openWindow({
      url: `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${job?.fieldLat},${job?.fieldLon}`,
      external: !liff.isInClient(),
    });
  };

  const defaultStage = defaultStatus(currentRole);

  const commonProps = {
    id: job?.id,
    service: job?.service,
    vinType: job?.vinType,
    fieldSize: job?.fieldSize,
    confirmPrice: job?.driver?.confirmPrice?.toLocaleString(),
    discountDetail: job?.pricing?.discountDetail,
    additionalItem: job?.pricing?.additionalItem,
    discountAmount: job?.pricing?.discountAmount?.toLocaleString(),
    servicePrice: job?.pricing?.servicePrice?.toLocaleString(),
    serviceRate: job?.pricing?.serviceRate,
    total: job?.pricing?.total?.toLocaleString(),
    totalAmount: job?.pricing?.totalAmount?.toLocaleString(),
    paymentMethod: job?.paymentMethod,
    imagesBefore: job?.imagesBefore,
    startWorking: job?.startWorking,
    imagesAfter: job?.imagesAfter,
    endWorking: job?.endWorking,
  };
  const userProps = {
    farmer: {
      profileImage: job?.driver?.profileImage,
      prefixname: job?.driver?.prefixname,
      fullname: `${job?.driver?.firstname} ${job?.driver?.surname}`,
      address: job?.driver?.address,
      tel: job?.driver?.tel,
    },
    driver: {
      profileImage: job?.profileImage,
      prefixname: job?.prefixname,
      fullname: `${job?.firstname} ${job?.surname}`,
      address: job?.fieldAddress?.trim(),
      tel: job?.tel,
    },
  };

  const {
    imagesBefore,
    startWorking,
    imagesAfter,
    endWorking,
    ...ctaCommonProps
  } = commonProps;
  const ctaInformationsProps = {
    statuses: role,
    telContact: currentRole === "farmer" ? job?.driver?.tel : job?.tel,
    fieldLat: job?.fieldLat,
    fieldLon: job?.fieldLon,
    fieldAddress: job?.fieldAddress?.trim(),
    qrCodeUrl: job?.qrCodeUrl,
    isPassing: isAfter(
      new Date(),
      parse(
        `${job?.serviceDate} ${job?.serviceTime}`,
        "dd/MM/yyyy HH:mm",
        new Date()
      )
    ),
    flagEditedPrice: job?.flagEditedPrice,
    driver: {
      driverId: job?.driver?.driverId,
      prefixname: job?.driver?.prefixname,
      firstname: job?.driver?.firstname,
      surname: job?.driver?.surname,
      tel: job?.driver?.tel,
      address: job?.driver?.address,
    },
    farmer: {
      prefixname: job?.prefixname,
      firstname: job?.firstname,
      surname: job?.surname,
      tel: job?.tel,
    },
    ...ctaCommonProps,
  };

  const criteriaProps = {
    role: currentRole,
    vinType: job?.vinType,
    stage: job?.stage,
  };

  const chipDetails = (key) => {
    if (currentRole === "driver" && job?.stage === "paid")
      return role[job?.stage || defaultStage]?.[key](job?.paymentMethod);
    if (job?.stage === "edited" && job?.vinType === VEHICLES.excavator.nameEN)
      return role[job?.stage || defaultStage]?.excavator[key];
    return role[job?.stage || defaultStage]?.[key];
  };

  let invertRoleText;
  if (currentRole === "farmer") invertRoleText = "คนขับ";
  if (currentRole === "driver") invertRoleText = "ผู้จ้าง";

  return (
    <Fragment>
      <ScrollToTop />

      <Grid container mt={3} mb={2}>
        <Grid container item justifyContent="space-between" xs={12} mb={0.5}>
          <Grid item my="auto">
            <Typography variant="body1" sx={{ fontSize: "1.125rem" }}>
              <strong>{job?.serviceType}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <ChipStatus
              label={chipDetails("wording")}
              color={chipDetails("chipColor")}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" xs={12} mb={2}>
          <Grid item>
            <Typography variant="body1">({jobId})</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              color={
                job?.stage || defaultStage === "created" ? "error" : "primary"
              }
            >
              ({chipDetails("description")})
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Typography variant="body1" color="tertiary.dark">
            {job?.service}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          mb={job?.vinType === VEHICLES.drone.nameEN ? 1 : 3}
        >
          <Grid container item xs={6}>
            <CalendarMonthIcon
              sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
            />
            <Typography
              variant="subtitle2"
              color="tertiary.dark"
              sx={{ my: "auto" }}
            >
              {abbreviatedDateFormat(job?.serviceDate)}
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <AccessTimeIcon
              sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
            />
            <Typography
              variant="subtitle2"
              color="tertiary.dark"
              sx={{ my: "auto" }}
            >
              {job?.serviceTime}
            </Typography>
          </Grid>
        </Grid>
        {job?.vinType === VEHICLES.drone.nameEN ? (
          <Grid item xs={12} mb={3}>
            <Typography color="error" sx={{ fontSize: ".8rem" }}>
              หมายเหตุ:{" "}
              {job?.preparedChemical
                ? "คนขับต้องเตรียมสารฉีดพ่นและน้ำผสมสารมาให้"
                : "ผู้จ้างเตรียมสารฉีดพ่นและน้ำผสมสารด้วยตัวเอง"}
            </Typography>
          </Grid>
        ) : null}

        {job?.vinType === VEHICLES.excavator.nameEN ? (
          <Fragment>
            <Grid item xs={12} mb={1}>
              <Typography color="secondary" variant="body1">
                พื้นที่ที่ใช้บริการ
              </Typography>
            </Grid>
            <Grid container item xs={12} mb={1}>
              <MapIcon
                sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
              />
              <Typography
                component="span"
                variant="subtitle2"
                color="tertiary.dark"
                sx={{ my: "auto" }}
              >
                {job?.address} {job?.subDistrict} {job?.district}{" "}
                {job?.province}
              </Typography>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid item xs={12} mb={1}>
              <Typography color="secondary" variant="body1">
                แปลงที่ใช้บริการ
              </Typography>
            </Grid>
            <Grid container item xs={12} mb={1}>
              <Grid container item xs={6}>
                <MapIcon
                  sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
                />
                <Typography
                  variant="subtitle2"
                  color="tertiary.dark"
                  sx={{ my: "auto" }}
                >
                  แปลง{job?.fieldName}
                </Typography>
              </Grid>
              <Grid container item xs={6}>
                <Typography variant="subtitle2" color="tertiary.dark">
                  เนื้อที่ {job?.fieldSize} ไร่
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} ml={4} mb={1}>
              <Typography variant="subtitle2">{job?.fieldAddress}</Typography>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12} ml={4} mb={3}>
          <Button
            onClick={toggleModalHandler("moreMapDetail")}
            variant="outlined"
            color="tertiary"
            sx={{ color: (theme) => theme.palette.common.black }}
          >
            ดูแผนที่
          </Button>
        </Grid>
        {job?.detail ? (
          <Fragment>
            <Grid item xs={12} mb={1}>
              <Typography variant="body1" color="secondary">
                รายละเอียดแปลงเพิ่มเติม
              </Typography>
            </Grid>
            <Grid item xs={12} mb={3}>
              <Typography variant="subtitle2">{job?.detail}</Typography>
            </Grid>
          </Fragment>
        ) : null}
        {job?.jobDetail ? (
          <Fragment>
            <Grid item xs={12} mb={1}>
              <Typography variant="body1" color="secondary">
                รายละเอียดงานเพิ่มเติม
              </Typography>
            </Grid>
            <Grid item xs={12} mb={3}>
              <Typography variant="subtitle2">{job?.jobDetail}</Typography>
            </Grid>
          </Fragment>
        ) : null}

        {job?.fieldImages?.length ? (
          <ImagesPreviewLayer
            images={job.fieldImages}
            imagesName="fieldImages"
            headerText="สภาพแปลงปัจจุบัน"
            showModal={modal.fieldImages}
            togglePreviewModal={toggleModalHandler("fieldImages")}
          />
        ) : null}

        {job?.entranceImages?.length ? (
          <ImagesPreviewLayer
            images={job.entranceImages}
            imagesName="entranceImages"
            headerText="ทางเข้ารถขุด"
            showModal={modal.entranceImages}
            togglePreviewModal={toggleModalHandler("entranceImages")}
          />
        ) : null}

        {job?.areaImages?.length ? (
          <ImagesPreviewLayer
            images={job.areaImages}
            imagesName="areaImages"
            headerText="พื้นที่ทำงาน"
            showModal={modal.areaImages}
            togglePreviewModal={toggleModalHandler("areaImages")}
          />
        ) : null}

        {job?.obstructionImages?.length ? (
          <ImagesPreviewLayer
            images={job.obstructionImages}
            imagesName="obstructionImages"
            headerText="สิ่งกีดขวางอื่นๆ"
            showModal={modal.obstructionImages}
            togglePreviewModal={toggleModalHandler("obstructionImages")}
          />
        ) : null}

        <UserInformation
          invertRoleText={invertRoleText}
          stage={job?.stage || defaultStage}
          profileImage={userProps[currentRole].profileImage}
          prefixname={userProps[currentRole].prefixname}
          fullname={userProps[currentRole].fullname}
          address={userProps[currentRole].address}
          tel={userProps[currentRole].tel}
          contactEachOther={onContactEachOther}
        />
        <WageRateDetailsBreakdown
          invertRoleText={invertRoleText}
          fieldSize={job?.fieldSize}
          confirmPrice={job?.driver?.confirmPrice?.toLocaleString()}
          total={job?.pricing?.total.toLocaleString()}
          servicePrice={job?.pricing?.servicePrice?.toLocaleString()}
          serviceRate={job?.pricing?.serviceRate}
          additionalItem={job?.pricing?.additionalItem}
          {...criteriaProps}
        />

        <PromotionDetailsBreakdown
          discountDetail={job?.pricing?.discountDetail}
          {...criteriaProps}
        />

        <TotalAmount
          totalAmount={job?.pricing?.totalAmount}
          {...criteriaProps}
        />

        <OfferLastPriceButton
          jobId={job?.id}
          service={job?.service}
          serviceRate={job?.pricing?.serviceRate}
          submitValues={{
            jobId: job?.id,
            prefixname: job?.prefixname,
            firstname: job?.firstname,
            surname: job?.surname,
            tel: job?.tel,
            driver: {
              driverId: job?.driver?.driverId,
              prefixname: job?.driver?.prefixname,
              firstname: job?.driver?.firstname,
              surname: job?.driver?.surname,
              tel: job?.driver?.tel,
              address: job?.driver?.address,
            },
          }}
          {...criteriaProps}
        />

        <PaymentMethodDetail
          paymentMethod={job?.paymentMethod}
          imagesBefore={job?.imagesBefore}
          imagesAfter={job?.imagesAfter}
        />

        <UploadSlip
          paymentMethod={job?.paymentMethod}
          slip={job?.paymentSlip?.image}
          note={job?.paymentSlip?.note}
          {...criteriaProps}
        />

        <RequestReceipt
          id={job?.id}
          service={job?.service}
          paymentMethod={job?.paymentMethod}
          {...criteriaProps}
        />

        <WorkingDetail
          imagesBefore={job?.imagesBefore}
          imagesAfter={job?.imagesAfter}
          startWorking={job?.startWorking}
          endWorking={job?.endWorking}
        />

        <CallToActions
          stage={job?.stage || defaultStage}
          role={currentRole}
          information={ctaInformationsProps}
        />
      </Grid>

      <NotifyOfferingPrice
        openModal={modal.notifyOfferPrice}
        onCloseModal={toggleModalHandler("notifyOfferPrice")}
      />

      {/* Map in details and direction with Google map app */}
      <Modal
        openModal={modal.moreMapDetail}
        onCloseModal={toggleModalHandler("moreMapDetail")}
        maxWidth="sm"
        fullWidth
        fullScreen
      >
        <Modal.Header
          onCloseModal={toggleModalHandler("moreMapDetail")}
          closeButton
        >
          ตำแหน่งแปลงเกษตร
        </Modal.Header>
        <Modal.Content sx={{ pb: 0, mb: 1 }}>
          <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            libraries={["places"]}
            language="th"
            render={(status) => <h1>{status}</h1>}
          >
            <GoogleMap
              isShowAction={false}
              selectedAddress={job?.fieldAddress}
              center={{
                lat: currentLocation.lat,
                lng: currentLocation.lng,
              }}
              selectedCenter={{
                lat: job?.fieldLat,
                lng: job?.fieldLon,
              }}
              zoom={11}
              mapHeight={"75vh"}
              searchOther={{
                isSearchOther: false,
                changeOther: () => {},
              }}
              isShowActionButton={false}
            >
              <Marker
                position={{
                  lat: job?.fieldLat || currentLocation.lat,
                  lng: job?.fieldLon || currentLocation.lng,
                }}
              />
            </GoogleMap>
          </Wrapper>
        </Modal.Content>
        {currentRole === "driver" ? (
          <Modal.Footer>
            <Grid container item xs={12}>
              <Fab
                size="medium"
                color="primary"
                variant="extended"
                onClick={getDirections}
                sx={{ px: 2, mx: "auto", mb: 2, width: "50%" }}
              >
                ขอเส้นทาง
              </Fab>
            </Grid>
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Modal>
    </Fragment>
  );
};

export default JobDetailPage;
