import { object, string, number, bool } from "yup";

import { VEHICLES } from "constants/Vehicles";

export default object().shape({
  vinType: string().required("กรุณาเลือก ประเภทเครื่องจักร"),
  vinName: string().required("กรุณากรอก ชื่อเครื่องจักร"),
  brand: string().required("กรุณาเลือก ยี่ห้อ"),
  model: string().when("vinType", (vinType, schema) =>
    vinType === VEHICLES.excavator.nameTH
      ? schema.notRequired()
      : schema.required("กรุณากรอก รุ่น")
  ),
  year: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when("vinType", (vinType, schema) =>
      vinType === VEHICLES.excavator.nameTH
        ? schema.notRequired()
        : schema.required("กรุณาเลือก ปีที่ซื้อ")
    ),
  size: string().when("vinType", (vinType, schema) =>
    vinType === VEHICLES.excavator.nameTH
      ? schema.required("กรุณาเลือก ขนาด")
      : schema.notRequired()
  ),
  image: object().typeError("กรุณาอัพโหลด ภาพเครื่องจักรพร้อมผู้ใช้"),
  imageFront: object().typeError("กรุณาอัพโหลด ภาพด้านหน้าเครื่องจักร"),
  battery: number()
    .integer("จำนวนเต็ม เท่านั้น")
    .transform((value) => (isNaN(value) ? undefined : value))
    .when("vinType", (vinType, schema) =>
      vinType === VEHICLES.drone.nameTH
        ? schema
            .positive("จำนวนแบตเตอรี่ต้องมากกว่า 0 เท่านั้น")
            .required("กรุณากรอก จำนวนแบตเตอรี่")
        : schema.notRequired()
    ),
  dynamo: bool()
    .transform((value) => (typeof value !== "boolean" ? undefined : value))
    .when("vinType", (vinType, schema) =>
      vinType === VEHICLES.drone.nameTH
        ? schema.required('กรุณาเลือก "มี" หรือ "ไม่มี"')
        : schema.notRequired()
    ),
});
