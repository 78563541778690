import { Fragment } from "react";

import { Grid, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { DividerFullWidth } from "base-components";
import { useDate } from "hooks";

const CommonJobCard = ({
  jobId,
  serviceType,
  status,
  fieldSize,
  serviceDate,
  serviceTime,
  totalAmount,
  clickJobList,
}) => {
  const { abbreviatedDateFormat } = useDate();

  const onClickListHandler = (jobId) => () => {
    clickJobList(jobId);
  };

  const dateTimePricingRow =
    status !== "created" ? (
      <Grid container item justifyContent="space-between" xs={12} mb={1}>
        <Grid container item xs={5}>
          <CalendarMonthIcon
            sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
          />
          <Typography
            variant="subtitle2"
            color="tertiary.dark"
            sx={{ my: "auto" }}
          >
            {abbreviatedDateFormat(serviceDate)}
          </Typography>
        </Grid>
        <Grid container item xs={4}>
          <AccessTimeIcon
            sx={{ color: (theme) => theme.palette.tertiary.main, mr: 1 }}
          />
          <Typography
            variant="subtitle2"
            color="tertiary.dark"
            sx={{ my: "auto" }}
          >
            {serviceTime}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography align="right" variant="subtitle2" color="secondary">
            <strong>
              ฿ {totalAmount ? totalAmount?.toLocaleString() : "รอยืนยัน"}
            </strong>
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <Grid item xs={12} mb={1}>
        <Typography
          align="center"
          variant="subtitle2"
          color="error"
          sx={{ fontSize: ".8rem" }}
        >
          (กรุณาเลือกคนขับเพื่อแสดงรายละเอียดเพิ่มเติม)
        </Typography>
      </Grid>
    );

  return (
    <Fragment>
      <Grid
        container
        sx={{
          "& + .MuiDivider-root:not(:last-child)": { mb: 1 },
        }}
        onClick={onClickListHandler(jobId)}
      >
        <Grid container item xs={12} mb={1}>
          <Grid container item xs="auto" mr={2}>
            <Typography variant="body1" sx={{ my: "auto" }}>
              <strong>{serviceType}</strong>
            </Typography>
          </Grid>
          <Grid container item xs="auto">
            <Typography
              variant="subtitle2"
              color="tertiary.dark"
              sx={{ my: "auto" }}
            >
              ({jobId})
            </Typography>
          </Grid>
          {fieldSize ? (
            <Grid container item xs="auto" ml="auto">
              <Typography
                variant="subtitle2"
                color="tertiary.dark"
                sx={{ my: "auto" }}
              >
                เนื้อที่ {fieldSize} ไร่
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        {dateTimePricingRow}
      </Grid>

      <DividerFullWidth sx={{ position: "static" }} />
    </Fragment>
  );
};

export default CommonJobCard;
