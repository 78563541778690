import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { Navbar } from "layouts/components";
import JobFormPage from "./JobFormPage";

const JobFormChannels = () => {
  const navigate = useNavigate();
  const { jobName } = useParams();

  const jobPageDetails = {
    drone: {
      navRightContent: "บริการฉีดพ่นสาร",
    },
    combine: {
      navRightContent: "บริการเก็บเกี่ยว",
    },
    tractor: {
      navRightContent: "บริการเตรียมดิน/อัดฟาง",
    },
    excavator: {
      navRightContent: "ขุดหรือปรับพื้นที่",
    },
  };

  return (
    <Fragment>
      <Navbar color="secondary">
        <Navbar.Left sx={{ flexGrow: 1 }}>
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosNewIcon sx={{ color: "common.white" }} />}
            sx={{ color: "common.white", p: 0 }}
          >
            ย้อนกลับ
          </Button>
        </Navbar.Left>
        <Navbar.Right sx={{ ml: "auto", mr: 1.5 }}>
          <Typography variant="subtitle2">
            {jobPageDetails[jobName].navRightContent}
          </Typography>
        </Navbar.Right>
      </Navbar>
      <JobFormPage />
    </Fragment>
  );
};

export default JobFormChannels;
