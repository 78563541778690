import { Outlet } from "react-router-dom";

import { Container, CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  // responsiveFontSizes,
} from "@mui/material/styles";

import { components, blankPalette as palette, typography } from "styles/Theme";

const theme = createTheme({
  components,
  palette,
  typography,
});

const BlankLayout = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Outlet />
      </Container>
    </ThemeProvider>
  );
};

export default BlankLayout;
