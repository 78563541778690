import { grey } from "@mui/material/colors";

export const THEME_COLORS = {
  primary: {
    bright: "#F3FCF2",
    light: "#DBF3E4",
    main: "#0DB14B",
    dark: "#0a8137",
    contrastText: "#fff",
  },
  secondary: {
    bright: "#FBF2FC",
    light: "#D7B0D0",
    main: "#7F0166",
    dark: "#4c003d",
    contrastText: "#fff",
  },
  tertiary: {
    dark: grey[700],
    main: grey[500],
    contrastText: "#fff",
    light: grey[200],
  },
  "in-process": {
    main: "#D8B049",
    contrastText: "#fff",
  },
};
