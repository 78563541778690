import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Grid, Typography, Fab, styled } from "@mui/material";
import Swal from "sweetalert2";

import { authActions } from "store/authentication/auth-slice";
import { uiActions } from "store/user-interface/ui-slice";

import { useHttp, useSwalStyles } from "hooks";
import { TelNumberInput } from "components/hook-forms";
import { ActionLoading, Alert } from "base-components";

import { ROUTES } from "routes/router";
import { USER_ENDPOINTS, DRIVER_ENDPOINTS } from "constants/APIs";

const schema = yup.object().shape({
  tel: yup
    .string()
    .length(10, "จำนวนหลักเท่ากับ 10 หลักเท่านั้น")
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm,
      "เบอร์โทรศัพท์ไม่ถูกต้อง"
    )
    .required("กรุณากรอก เบอร์โทรศัพท์"),
});

const Form = styled("form")({
  width: "100%",
});

const VerifyGetztracTelNumberPage = (props) => {
  const dispatch = useDispatch();
  const {
    auth: { tel, currentRole },
    ui: { alert },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const { state } = useLocation();
  // create || update
  const action = state?.action;
  // new || getztrac || update
  const source = state?.source;

  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLoading: isSending, sendRequest: requestVerify } = useHttp();
  const classes = useSwalStyles();

  useEffect(() => {
    if (tel && source === "new") setValue("tel", tel);
  }, [tel, source, setValue]);

  const submitTelNumberHandler = (data) => {
    if (source === "getztrac")
      requestVerify(
        {
          endpoint: DRIVER_ENDPOINTS.verifyTelNo,
          method: "POST",
          body: data,
        },
        async (response) => {
          /* Not found this tel no. in getztrac DB */
          if (response.reason) {
            setValue("tel", "");
            dispatch(
              uiActions.toggleAlert({
                isShow: true,
                duration: 4000,
                message: `ไม่พบหมายเลขโทรศัพท์ ${data.tel} ในระบบ Getztrac`,
                status: "error",
              })
            );
            return;
          }

          /* Error duplicated tel no. that repeating migrate from GETZTRAC */
          if (response.error && response.error === "Duplicate tel.") {
            const swalResp = await new Swal({
              titleText: `หมายเลขโทรศัพท์นี้ถูกใช้ไปแล้ว`,
              text: `หมายเลขโทรศัพท์นี้เคยถูกใช้ลงทะเบียนจาก GETZTRAC แล้ว หากยืนยันจะใช้เบอร์นี้กรุณากดปุ่ม ลงทะเบียนใหม่ `,
              icon: "warning",
              cancelButtonText: "กลับหน้าหลัก",
              confirmButtonText: "ลงทะเบียนใหม่",
              width: "80%",
              showCloseButton: true,
              showCancelButton: true,
              reverseButtons: true,
              customClass: {
                container: classes.swal2Container,
                confirmButton: classes.swal2ConfirmSecondaryButton,
                cancelButton: classes.swal2CancelButton,
              },
            });
            if (swalResp.isConfirmed)
              /* Confirmed create new user  */
              return navigate(ROUTES.USER_INFORMATION_FORM(currentRole), {
                state: { action: "create", source: "new" },
                replace: true,
              });
            else return navigate(ROUTES.MENU_DRIVER, { replace: true });
          }

          dispatch(
            authActions.setOtpSession({
              token: response.token,
              refno: response.refno,
              confirmDuplicated: false,
              tel: data.tel,
              pin: "",
            })
          );
          navigate(ROUTES.OTP_FORM, {
            state: { action, source, tempTel: data.tel },
          });
        }
      );

    if (source === "update")
      requestVerify(
        {
          endpoint: USER_ENDPOINTS.update(currentRole),
          method: "PUT",
          body: data,
        },
        async (response) => {
          if (response.error && response.error === "Duplicate tel.") {
            /* Error duplicated tel no. that source => updated  */
            const swalResp = await new Swal({
              titleText: `หมายเลขโทรศัพท์นี้ถูกใช้ไปแล้ว`,
              text: `หมายเลขโทรศัพท์นี้เคยถูกใช้ลงทะเบียนแล้ว หากยืนยันจะใช้เบอร์นี้เพื่อลงทะเบียน ระบบจะโอนย้ายข้อมูลจากบัญชีเดิมโดยอัตโนมัติ คุณจะไม่สามารถใช้งานบัญชีเดิมได้`,
              icon: "warning",
              cancelButtonText: "กลับหน้าเดิม",
              confirmButtonText: "ยืนยัน",
              width: "80%",
              showCloseButton: true,
              showCancelButton: true,
              reverseButtons: true,
              customClass: {
                container: classes.swal2Container,
                confirmButton: classes.swal2WarningButton,
                cancelButton: classes.swal2CancelButton,
              },
            });
            if (swalResp.isConfirmed)
              /* Confirmed update with duplicated tel no.  */
              requestVerify(
                {
                  endpoint: USER_ENDPOINTS.update(currentRole),
                  method: "PUT",
                  body: {
                    tel: data.tel,
                    source,
                    confirmDuplicated: true,
                  },
                },
                (dupUpdResponse) => {
                  dispatch(
                    authActions.setOtpSession({
                      refno: dupUpdResponse.refno,
                      token: dupUpdResponse.token,
                      confirmDuplicated: true,
                      tel: data.tel,
                    })
                  );
                  return navigate(ROUTES.OTP_FORM, {
                    replace: true,
                    state: { action, source, tempTel: data.tel },
                  });
                }
              );
            return;
          }

          dispatch(
            authActions.setOtpSession({
              token: response.token,
              refno: response.refno,
              confirmDuplicated: false,
              tel: data.tel,
              pin: "",
            })
          );
          navigate(ROUTES.OTP_FORM, {
            state: { action, source, tempTel: data.tel },
          });
        }
      );
  };

  const onCloseAlert = () => {
    dispatch(uiActions.toggleAlert({ isShow: false, status: "error" }));
  };

  return (
    <Box mt={2} sx={{ flexGrow: 1 }}>
      <Form onSubmit={handleSubmit(submitTelNumberHandler)}>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Typography variant="subtitl2">
              ระบุหมายเลขโทรศัพท์ที่ใช้ในสมัคร{" "}
              {source === "getztrac" && "Getztrac"}
            </Typography>
          </Grid>
          <Grid item xs={12} mb={5}>
            <TelNumberInput
              name="tel"
              control={control}
              defaultValue=""
              label="หมายเลขโทรศัพท์"
              type="tel"
              variant="standard"
              fullWidth
              autoFocus
            />
          </Grid>

          <Grid item mx="auto">
            <Fab
              type="submit"
              variant="extended"
              color="secondary"
              sx={{ px: (theme) => theme.spacing(3) }}
            >
              ตกลง
            </Fab>
          </Grid>
        </Grid>
      </Form>

      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isShow}
        hideDuration={alert.duration}
        onClose={onCloseAlert}
        status={alert.status}
        message={alert.message}
      />

      <ActionLoading open={isSending} />
    </Box>
  );
};

export default VerifyGetztracTelNumberPage;
