// import { lazy, Suspense } from "react";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  Outlet,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import ReactGA from "react-ga";

import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

/* First page for validate user data */
// const UserValidation = lazy(() => import("layouts/UserValidation"));
import UserValidation from "layouts/UserValidation";

/* LAYOUT */
import BlankLayout from "layouts/BlankLayout";
import MainMenuLayout from "layouts/MainMenuLayout";

/* Main page with menu for navigation */
import FarmerMenuPage from "pages/main-menu/FarmerMenuPage";
import DriverMenuPage from "pages/main-menu/DriverMenuPage";

/* Driver Register */
import UserInformationFormPage from "pages/register/UserInformationFormPage";
import BankAccountFormPage from "pages/register-driver/BankAccountFromPage";
import TelNumberFormPage from "pages/register-driver/TelNumberFormPage";
import OTPFormPage from "pages/register-driver/OTPFormPage";

/* Machines */
import MachineFormPage from "pages/machine-details/form/MachineFormPage";
import MachineListsPage from "pages/machine-details/MachineListsPage";
import MachineDetailPage from "pages/machine-details/details/MachineDetailPage";

/* Services */
import ServiceChannels from "pages/service-details/ServiceChannels";

/* Fields */
import FieldFormPage from "pages/field-details/FieldFormPage";

/* Jobs */
import JobListChannels from "pages/job-details/lists/JobListChannel";
import JobFormChannels from "pages/job-details/forms/JobFormChannels";
import SearchingDriversPage from "pages/job-details/SearchingDriversPage";
import AvailableDriversPage from "pages/job-details/AvailableDriversPage";
import JobDetailPage from "pages/job-details/JobDetailPage";
import JobMatchedPage from "pages/job-details/JobMatchedPage";

/* Payments */
import PaymentSuccessPage from "pages/payments/PaymentSuccessPage";

/* Layout components */
import { Header, Navbar } from "layouts/components";

export const ROUTES = {
  USER_VALIDATION: "/",
  MENU_FARMER: "/menu/farmer",
  MENU_DRIVER: "/menu/driver",
  // REGISTER_CHANNEL: "/register",
  USER_INFORMATION_FORM(role) {
    if (!role) return "/menu/farmer";
    return `/register/${role}/information-form`;
  },
  TEL_NUMBER_FORM: "/register/driver/verify-tel",
  OTP_FORM: "/register/driver/verify-otp",
  BANK_ACCOUNT_FORM: "/register/driver/bank-account-form",
  MACHINES: "/machines",
  MACHINE_DETAIL(type, id) {
    if (!type | !id) return "/machines";
    return `/machines/${type}/${id}`;
  },
  CREATE_MACHINE_FORM: "/machines/create",
  EDIT_MACHINE_FORM(type, id) {
    if (!type || !id) return "/machines";
    return `/machines/edit/${type}/${id}`;
  },
  SERVICE_FORM(name, action) {
    if (!name || !action) return "/menu/driver";
    return `/services/${name}/${action}`;
  },
  FIELD_FORM(action, fieldName = "") {
    if (!action) return "/menu/farmer";
    return `/fields/${action}/${fieldName}`;
  },
  JOBS(role) {
    if (!role) return `menu/farmer`;
    return `/jobs/list/${role}`;
  },
  JOB_FORM(jobName, action, jobId = "") {
    if (!jobName || !action) return "/menu/farmer";
    return `/jobs/${jobName}/${action}/${jobId}`;
  },
  JOB_SEARCHING(jobId) {
    if (!jobId) return "/menu/farmer";
    return `/jobs/${jobId}/searching-drivers`;
  },
  JOB_DRIVER_LISTS(jobId) {
    if (!jobId) return "/menu/farmer";
    return `/jobs/${jobId}/available-drivers`;
  },
  JOB_MATCHED(jobId) {
    if (!jobId) return "/menu/farmer";
    return `/jobs/${jobId}/matched`;
  },
  JOB_DETAIL(jobId) {
    if (!jobId) return "/menu/farmer";
    return `/jobs/${jobId}`;
  },
  PAYMENT_SUCCESS: "/payment/success",
};

const Link = styled(RouterLink)({
  textDecoration: "none",
});

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || null;
if (TRACKING_ID) ReactGA.initialize(TRACKING_ID);

export default function Router() {
  const { currentRole } = useSelector((state) => state.auth);
  const isFarmer = currentRole === "farmer";

  const navigate = useNavigate();

  useEffect(() => {
    if (TRACKING_ID)
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const goBack = () => navigate(-1);

  return (
    <Routes>
      <Route path="/" element={<UserValidation />} />
      <Route path="menu" element={<MainMenuLayout />}>
        <Route path="farmer" element={<FarmerMenuPage />} />
        <Route path="driver" element={<DriverMenuPage />} />
      </Route>
      <Route path="register" element={<BlankLayout />}>
        <Route
          path=":role/information-form"
          element={
            <Fragment>
              <Navbar color={isFarmer ? "secondary" : "primary"}>
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Button
                    onClick={goBack}
                    startIcon={
                      <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                    }
                    sx={{ color: "common.white", p: 0 }}
                  >
                    ย้อนกลับ
                  </Button>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto" }}>
                  <Typography variant="subtitle2">ข้อมูลส่วนบุคคล</Typography>
                </Navbar.Right>
              </Navbar>
              <UserInformationFormPage />
            </Fragment>
          }
        />
        <Route
          path="driver/bank-account-form"
          element={
            <Fragment>
              <Navbar>
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Button
                    onClick={goBack}
                    startIcon={
                      <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                    }
                    sx={{ color: "common.white", p: 0 }}
                  >
                    ย้อนกลับ
                  </Button>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto", mr: 2 }}>
                  <Typography variant="subtitle2">บัญชีธนาคาร</Typography>
                </Navbar.Right>
              </Navbar>
              <BankAccountFormPage />
            </Fragment>
          }
        />
        <Route
          path="driver/verify-tel"
          element={
            <Fragment>
              <Header textAlign="left">
                <strong>ยืนยันหมายเลขโทรศัพท์</strong>
              </Header>
              <TelNumberFormPage />
            </Fragment>
          }
        />
        <Route
          path="driver/verify-otp"
          element={
            <Fragment>
              <Header text-textAlign="left">
                <strong>ยืนยันรหัส OTP</strong>
              </Header>
              <OTPFormPage />
            </Fragment>
          }
        />
      </Route>
      <Route path="machines" element={<BlankLayout />}>
        <Route
          index
          element={
            <Fragment>
              <Navbar>
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Button
                    onClick={goBack}
                    startIcon={
                      <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                    }
                    sx={{ color: "common.white", p: 0 }}
                  >
                    ย้อนกลับ
                  </Button>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto" }}>
                  <Typography variant="subtitle2">เครื่องจักรของฉัน</Typography>
                </Navbar.Right>
              </Navbar>
              <MachineListsPage />
            </Fragment>
          }
        />
        <Route
          path="create"
          element={
            <Fragment>
              <Navbar>
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Button
                    onClick={goBack}
                    startIcon={
                      <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                    }
                    sx={{ color: "common.white", p: 0 }}
                  >
                    ย้อนกลับ
                  </Button>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto" }}>
                  <Typography variant="subtitle2">
                    เพิ่ม/แก้ไขเครื่องจักร
                  </Typography>
                </Navbar.Right>
              </Navbar>
              <MachineFormPage />
            </Fragment>
          }
        />
        <Route
          path=":type/:id"
          element={
            <Fragment>
              <Navbar>
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Button
                    onClick={goBack}
                    startIcon={
                      <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                    }
                    sx={{ color: "common.white", p: 0 }}
                  >
                    ย้อนกลับ
                  </Button>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto" }}>
                  <Typography variant="subtitle2">
                    รายละเอียดเครื่องจักร
                  </Typography>
                </Navbar.Right>
              </Navbar>
              <MachineDetailPage />
            </Fragment>
          }
        />
        <Route
          path="edit/:type/:id"
          element={
            <Fragment>
              <Navbar>
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Button
                    onClick={goBack}
                    startIcon={
                      <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                    }
                    sx={{ color: "common.white", p: 0 }}
                  >
                    ย้อนกลับ
                  </Button>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto" }}>
                  <Typography variant="subtitle2">
                    เพิ่ม/แก้ไขเครื่องจักร
                  </Typography>
                </Navbar.Right>
              </Navbar>
              <MachineFormPage />
            </Fragment>
          }
        />
      </Route>

      <Route path="services" element={<BlankLayout />}>
        <Route path=":name/:action" element={<ServiceChannels />} />
      </Route>

      <Route path="fields" element={<BlankLayout />}>
        <Route
          path=":action"
          element={
            <Fragment>
              <Navbar color="secondary">
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Button
                    onClick={goBack}
                    startIcon={
                      <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                    }
                    sx={{ color: "common.white", p: 0 }}
                  >
                    ย้อนกลับ
                  </Button>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto", mr: 1 }}>
                  <Typography variant="subtitle2">เพิ่ม / แก้ไขแปลง</Typography>
                </Navbar.Right>
              </Navbar>
              <Outlet />
            </Fragment>
          }
        >
          <Route index element={<FieldFormPage />} />
          <Route path=":fieldName" element={<FieldFormPage />} />
        </Route>
      </Route>

      <Route path="jobs" element={<BlankLayout />}>
        <Route path="list/:role" element={<JobListChannels />} />
        <Route path=":jobName/:action" element={<JobFormChannels />} />
        <Route path=":jobName/:action/:jobId" element={<JobFormChannels />} />
        <Route
          path=":jobId/searching-drivers"
          element={<SearchingDriversPage />}
        />
        <Route
          path=":jobId/available-drivers"
          element={
            <Fragment>
              <Navbar color="secondary">
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Link to={isFarmer ? ROUTES.MENU_FARMER : ROUTES.MENU_DRIVER}>
                    <Button
                      startIcon={
                        <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                      }
                      sx={{ color: "common.white", p: 0 }}
                    >
                      ย้อนกลับ
                    </Button>
                  </Link>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto", mr: 1 }}>
                  <Typography variant="subtitle2">ค้นหาคนขับ</Typography>
                </Navbar.Right>
              </Navbar>
              <AvailableDriversPage />
            </Fragment>
          }
        />
        <Route path=":jobId/matched" element={<JobMatchedPage />} />
        <Route
          path=":jobId"
          element={
            <Fragment>
              <Navbar color={isFarmer ? "secondary" : "primary"}>
                <Navbar.Left sx={{ flexGrow: 1 }}>
                  <Link to={isFarmer ? ROUTES.MENU_FARMER : ROUTES.MENU_DRIVER}>
                    <Button
                      startIcon={
                        <ArrowBackIosNewIcon sx={{ color: "common.white" }} />
                      }
                      sx={{ color: "common.white", p: 0 }}
                    >
                      ย้อนกลับ
                    </Button>
                  </Link>
                </Navbar.Left>
                <Navbar.Right sx={{ ml: "auto", mr: 1 }}>
                  <Typography variant="subtitle2">รายละเอียดงาน</Typography>
                </Navbar.Right>
              </Navbar>
              <JobDetailPage />
            </Fragment>
          }
        />
      </Route>

      <Route path="payment" element={<BlankLayout />}>
        <Route path="success" element={<PaymentSuccessPage />} />
      </Route>
      <Route path="*" element={<h1>404 Page not found</h1>} />
    </Routes>
  );
}
