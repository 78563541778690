import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format, parse } from "date-fns";
import Swal from "sweetalert2";

import {
  Grid,
  Typography,
  Fab,
  IconButton,
  Button,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import { toggleDrawerPicker, toggleModal } from "store/user-interface/ui-slice";

import {
  useGetMasterServiceNameQuery,
  useGetMasterAddressQuery,
} from "services/master";
import {
  selectMasterServiceByType,
  selectMasterAddress,
} from "store/master-slice";
import { selectFields } from "store/field-slice";
import { useGetFieldsQuery } from "services/field";
import { selectCustomerJobById } from "store/job-slice";
import {
  useGetCustomerJobByIdQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
} from "services/job";

import { useDate, useMapLocation, useSwalStyles } from "hooks";

import { isUrl } from "helpers/Utilies";
import {
  TextInput,
  SelectPicker,
  DatePicker,
  TimePicker,
  Radio,
  ButtonImageInput,
  ImageInput,
} from "components/hook-forms";
import { Navbar } from "layouts/components";
import { ImagesPreviewLayer } from "components";
import {
  ScrollToTop,
  Options,
  HoldingDivider,
  DividerFullWidth,
  IconImage,
  ActionLoading,
} from "base-components";

import { TopExcavator } from "pages/job-details/forms/TopSection";
import {
  baseSchema,
  additionalSchemas,
} from "pages/job-details/forms/validations";
// import { PromotionCodePickerModal } from "pages/job-details/components/modals";

import { deconstructFileUrl, debounce } from "helpers/Utilies";
import { ROUTES } from "routes/router";
import { LOCATION_ZOOM, DEFAULT_EXCAVATOR_ZOOM } from "constants/Location";
import { VEHICLES } from "constants/Vehicles";
import { FORMAT } from "constants/DateTime";

import DroneIcon from "assets/drone.png";
import CombineIcon from "assets/combine-harvester.png";
import TractorIcon from "assets/tractor.png";
import ExcavatorIcon from "assets/excavator.png";

import ExcavatorEntranceImage from "assets/excavator-entrance-image.png";
import ExcavatorAreaImage from "assets/excavator-area-image.png";
import ExcavatorObstructionImage from "assets/excavator-obstruction-image.png";

// const mockIsNotListPromos = {
//   promotionCode: "DRF1ST",
//   promotionDesc: "จ้างงานครั้งแรกสำเร็จ",
//   expiredDate: "30/06/2023",
//   serviceType: "โดรนการเกษตร",
//   isPick: false,
// };

// const mockPromos = [
//   {
//     promotionCode: "DRD1ST",
//     promotionName: "รับเสื้อยืดคินแมทช์",
//     promotionDesc: "จ้างงานครั้งแรกสำเร็จ",
//     serviceType: "โดรนการเกษตร",
//     expiredDate: "31/03/2566",
//     isPick: false,
//   },
//   {
//     promotionCode: "DRF2ND",
//     promotionName: "รับบัตรโลตัส มูลค่า 300 บาท",
//     promotionDesc: "จ้างงานครบ 2 งาน",
//     serviceType: "โดรนการเกษตร",
//     expiredDate: "31/03/2566",
//     isPick: false,
//   },
// ];

const Form = styled("form")({
  position: "relative",
  width: "100%",
});

const JobFormPage = () => {
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.auth);
  const { modal, drawerPicker } = useSelector((state) => state.ui);
  // const [promoCode, setPromoCode] = useState(mockPromos);
  const [deletedImages, setDeletedImages] = useState([]);
  const entranceImagesRef = useRef(null);
  const areaImagesRef = useRef(null);
  const obstructionImagesRef = useRef(null);

  const navigate = useNavigate();
  const { jobName, action, jobId } = useParams();
  const editPage = action === "edit";

  /* GET Master address */
  const selectedMasterAddress = useSelector(selectMasterAddress);
  useGetMasterAddressQuery(undefined, {
    skip: selectedMasterAddress.length,
  });

  /* GET Master service */
  const selectedMasterServiceByType = useSelector((state) =>
    selectMasterServiceByType(state, jobName)
  );
  useGetMasterServiceNameQuery(jobName, {
    skip: selectMasterServiceByType?.type,
  });

  /* Get Job by ID */
  const selectedCustomerJobById = useSelector((state) =>
    selectCustomerJobById(state, jobId)
  );
  useGetCustomerJobByIdQuery(jobId, {
    skip: !jobId || selectedCustomerJobById?.id,
  });

  /* GET Fields */
  const selectedFields = useSelector(selectFields);
  useGetFieldsQuery(undefined, {
    skip: selectedFields.length,
  });

  /* POST create new Job */
  const [createNewJob, { isLoading: isCreating }] = useCreateJobMutation();
  /* PUT update existing Job */
  const [updateJob, { isLoading: isUpdating }] = useUpdateJobMutation();
  /* PUT delete Job */
  const [deleteJob, { isLoading: isDeleting }] = useDeleteJobMutation();

  const { monthDateYearFormat, disabledPastDate } = useDate();
  const classes = useSwalStyles();

  const {
    control,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(baseSchema.shape(additionalSchemas[jobName])),
  });
  const [
    service,
    plantType,
    mapLat,
    mapLng,
    mapProvince,
    mapDistrict,
    mapSubDistrict,
    mapZipcode,
    province,
    district,
    subDistrict,
    fieldName,
  ] = getValues([
    "service",
    "plantType",
    "mapLat",
    "mapLng",
    "mapProvince",
    "mapDistrict",
    "mapSubDistrict",
    "mapZipcode",
    "province",
    "district",
    "subDistrict",
    "fieldName",
  ]);
  const [
    fieldImages,
    paymentMethodValue,
    coordinateSource,
    entranceImages,
    areaImages,
    obstructionImages,
    recommendCodeJobDesc,
  ] = watch(
    [
      "fieldImages",
      "paymentMethod",
      "coordinateSource",
      "entranceImages",
      "areaImages",
      "obstructionImages",
      "recommendCodeJobDesc",
    ],
    [[]]
  );

  const {
    currentLocation,
    expandMap,
    onToggleExpandMap,
    onClickMap,
    onRelocateCurrentLocationHandler,
    onChangeSearchOtherLocation,
  } = useMapLocation({
    location: {
      lat: mapLat,
      lng: mapLng,
      zoom: LOCATION_ZOOM,
      defaultZoom: DEFAULT_EXCAVATOR_ZOOM,
    },
    setValueFn: setValue,
    clearErrorsFn: clearErrors,
    hasLocaiton: editPage,
  });

  useEffect(() => {
    if (!selectedCustomerJobById?.id) return;

    const {
      coordinateSource,
      lat,
      lon: lng,
      subDistrict,
      district,
      province,
      zipcode,
      address,
      landmark,
    } = selectedCustomerJobById;

    /* Set base fields */
    setValue("service", selectedCustomerJobById.service);
    setValue(
      "serviceDate",
      new Date(monthDateYearFormat(selectedCustomerJobById.serviceDate))
    );
    setValue(
      "serviceTime",
      parse(selectedCustomerJobById.serviceTime, "HH:mm", new Date())
    );
    setValue("recommendCodeJob", selectedCustomerJobById.recommendCodeJob);
    setValue(
      "recommendCodeJobDesc",
      selectedCustomerJobById.recommendCodeJobDesc
    );
    setValue("paymentMethod", selectedCustomerJobById.paymentMethod);

    /* Set drone fields */
    if (selectedCustomerJobById.vinType === VEHICLES.drone.nameEN) {
      setValue("preparedChemical", selectedCustomerJobById.preparedChemical);
      setValue("plantType", selectedCustomerJobById.plantType);
      setValue("fieldName", selectedCustomerJobById.fieldName);
      setValue(
        "fieldImages",
        selectedCustomerJobById.fieldImages.map((image) =>
          deconstructFileUrl(image)
        )
      );
      setValue("detail", selectedCustomerJobById.detail);
    }

    /* Set combine fields */
    if (selectedCustomerJobById.vinType === VEHICLES.combine.nameEN) {
      setValue("plantType", selectedCustomerJobById.plantType);
      setValue("fieldName", selectedCustomerJobById.fieldName);
      setValue(
        "fieldImages",
        selectedCustomerJobById.fieldImages.map((image) =>
          deconstructFileUrl(image)
        )
      );
      setValue("detail", selectedCustomerJobById.detail);
    }

    /* Set tractor fields */
    if (selectedCustomerJobById.vinType === VEHICLES.tractor.nameEN) {
      setValue("fieldName", selectedCustomerJobById.fieldName);
      setValue(
        "fieldImages",
        selectedCustomerJobById.fieldImages.map((image) =>
          deconstructFileUrl(image)
        )
      );
      setValue("detail", selectedCustomerJobById.detail);
    }

    /* Set excavator fields */
    if (selectedCustomerJobById.vinType === VEHICLES.excavator.nameEN) {
      setValue("jobDetail", selectedCustomerJobById.jobDetail);
      setValue(
        "entranceImages",
        selectedCustomerJobById.entranceImages.map((image) =>
          deconstructFileUrl(image)
        )
      );
      setValue(
        "areaImages",
        selectedCustomerJobById.areaImages.map((image) =>
          deconstructFileUrl(image)
        )
      );
      setValue(
        "obstructionImages",
        selectedCustomerJobById.obstructionImages.map((image) =>
          deconstructFileUrl(image)
        )
      );
      setValue("coordinateSource", coordinateSource);
      if (coordinateSource === "map") {
        setValue("mapLat", lat);
        setValue("mapLng", lng);
        setValue("mapProvince", province);
        setValue("mapDistrict", district);
        setValue("mapSubDistrict", subDistrict);
        setValue("mapZipcode", zipcode);
      }
      if (coordinateSource === "manual") {
        setValue("province", province);
        setValue("district", district);
        setValue("subDistrict", subDistrict);
        setValue("zipcode", zipcode);
        setValue("address", address);
        setValue("landmark", landmark);
      }
    }
  }, [selectedCustomerJobById, monthDateYearFormat, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name === "coordinateSource") {
        const setDefaultFields = {
          map() {
            const manualFields = [
              "province",
              "district",
              "subDistrict",
              "zipcode",
              "address",
              "landmark",
            ];
            for (let field of manualFields) {
              setValue(field, "");
              clearErrors(field);
            }
          },
          manual() {
            const mapFields = [
              "mapLat",
              "mapLng",
              "mapProvince",
              "mapDistrict",
              "mapSubDistrict",
              "mapZipcode",
            ];
            for (let field of mapFields) {
              setValue(field, "");
              clearErrors(field);
            }
          },
        };
        setDefaultFields[value.coordinateSource]();
      }

      if (name === "province") {
        setValue("district", "");
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }

      if (name === "district") {
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, clearErrors]);

  const toggleModalHandler = useCallback(
    (state) => () => {
      dispatch(toggleModal({ name: state }));
    },
    [dispatch]
  );

  const toggleDrawerPickerHandler = useCallback(
    (state) => () => {
      dispatch(toggleDrawerPicker(state));
    },
    [dispatch]
  );

  // const onRevealNotListCode = () => {
  //   setPromoCode((prevCode) => {
  //     const addedListCode = [...prevCode, mockIsNotListPromos];
  //     return addedListCode;
  //   });
  // };

  // const onTogglePickCodeHandler = (code, checked) => {
  //   setPromoCode((prevCode) => {
  //     const pickedCode = prevCode.map((eachCode) => ({
  //       ...eachCode,
  //       isPick: code === eachCode.promotionCode ? checked : eachCode.isPick,
  //     }));
  //     return pickedCode;
  //   });
  // };

  // const onApplyCodeHandler = () => {
  //   const appliedCode = promoCode
  //     .filter((code) => code.isPick)
  //     .map((code) => code.promotionCode);
  //   console.log(appliedCode);
  //   toggleModalHandler("promotionCode")();
  // };

  const formServiceOptions = useMemo(() => {
    let options = { chemicals: [], plantTypes: [], services: [] };
    if (
      jobName === VEHICLES.drone.nameEN &&
      selectedMasterServiceByType?.chemicals?.length &&
      selectedMasterServiceByType?.plantType?.length
    ) {
      options = {
        services: selectedMasterServiceByType.chemicals.map((chem, idx) => ({
          id: idx + 1,
          label: <Typography variant="body1">{chem.nameTH}</Typography>,
          value: chem.nameTH,
          onClickOption() {
            setValue("service", chem.nameTH);
            clearErrors("service");
            toggleDrawerPickerHandler("chemicalService")();
          },
        })),
        plantTypes: selectedMasterServiceByType.plantType.map((plant, idx) => ({
          id: idx + 1,
          label: <Typography variant="body1">{plant.nameTH}</Typography>,
          value: plant.nameTH,
          onClickOption() {
            setValue("plantType", plant.nameTH);
            clearErrors("plantType");
            toggleDrawerPickerHandler("plantType")();
          },
        })),
      };
      return options;
    }

    if (
      jobName === VEHICLES.combine.nameEN &&
      selectedMasterServiceByType?.plantType?.length
    ) {
      options = {
        plantTypes: selectedMasterServiceByType.plantType.map((plant, idx) => ({
          id: idx + 1,
          label: <Typography variant="body1">{plant.nameTH}</Typography>,
          value: plant.nameTH,
          onClickOption() {
            setValue("plantType", plant.nameTH);
            clearErrors("plantType");
            toggleDrawerPickerHandler("plantType")();
          },
        })),
      };
      return options;
    }

    if (
      jobName === VEHICLES.tractor.nameEN &&
      selectedMasterServiceByType?.mainService?.length
    ) {
      options = {
        services: selectedMasterServiceByType.mainService.map(
          (service, idx) => ({
            id: idx + 1,
            label: <Typography variant="body1">{service.name}</Typography>,
            value: service.name,
            onClickOption() {
              setValue("service", service.name);
              clearErrors("service");
              toggleDrawerPickerHandler("service")();
            },
          })
        ),
      };
      return options;
    }

    if (
      jobName === VEHICLES.excavator.nameEN &&
      selectedMasterServiceByType?.mainService?.length
    ) {
      options = {
        services: selectedMasterServiceByType.mainService.map(
          (service, idx) => ({
            id: idx + 1,
            label: <Typography variant="body1">งาน{service.name}</Typography>,
            value: service.name,
            onClickOption() {
              setValue("service", service.name);
              clearErrors("service");
              toggleDrawerPickerHandler("service")();
            },
          })
        ),
      };
    }
    return options;
  }, [
    jobName,
    selectedMasterServiceByType,
    setValue,
    clearErrors,
    toggleDrawerPickerHandler,
  ]);

  const formFieldOptions = useMemo(() => {
    if (selectedFields?.length) {
      return selectedFields.map((field) => ({
        id: field.id,
        label: <Typography variant="body1">{field.name}</Typography>,
        value: field.name,
        onClickOption() {
          setValue("fieldName", field.name);
          clearErrors("fieldName");
          toggleDrawerPickerHandler("fieldName")();
        },
      }));
    }
    return [];
  }, [selectedFields, setValue, clearErrors, toggleDrawerPickerHandler]);

  const addressDropdownInputs = useMemo(() => {
    const noValueOption = ({ reqFieldTH, reqFieldEN, field }) => [
      {
        id: `no-${reqFieldEN}-value`,
        label: (
          <Typography variant="body1">{`กรุณาเลือก ${reqFieldTH} ก่อน`}</Typography>
        ),
        value: "",
        onClickOption() {
          toggleDrawerPickerHandler(field)();
        },
      },
    ];

    return [
      {
        id: "province-select-input",
        name: "province",
        label: "จังหวัด",
        placeholder: "เลือก จังหวัด",
        width: { label: "15%", input: "85%" },
        showDrawer: drawerPicker.province,
        optionTitle: "เลือกจังหวัด",
        value: province,
        size: { xs: 12 },
        sx: { mb: 2 },
        navPosition: "sticky",
        options: selectedMasterAddress.map((province) => ({
          id: province.id,
          label: <Typography variant="body1">{province.name}</Typography>,
          value: province.name,
          onClickOption() {
            setValue("province", province.name);
            clearErrors("province");
            toggleDrawerPickerHandler("province")();
          },
        })),
      },
      {
        id: "district-select-input",
        name: "district",
        label: "อำเภอ",
        placeholder: "เลือก อำเภอ",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.district,
        optionTitle: "เลือกอำเภอ",
        value: district,
        size: { xs: 6 },
        sx: { pr: 0.5, mb: 2 },
        navPosition: "absolute",
        options: province
          ? selectedMasterAddress
              .find(({ name: provinceName }) => provinceName === province)
              ?.districts.map(({ name: districtName }) => ({
                id: districtName,
                label: <Typography variant="body1">{districtName}</Typography>,
                value: districtName,
                onClickOption() {
                  setValue("district", districtName);
                  clearErrors("district");
                  toggleDrawerPickerHandler("district")();
                },
              }))
          : noValueOption({
              reqFieldEN: "province",
              reqFieldTH: "จังหวัด",
              field: "district",
            }),
      },
      {
        id: "subDistrict-select-input",
        name: "subDistrict",
        label: "ตำบล",
        placeholder: "เลือก ตำบล",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.subDistrict,
        optionTitle: "เลือกตำบล",
        value: subDistrict,
        size: { xs: 6 },
        sx: { pl: 0.5, mb: 2 },
        navPosition: "absolute",
        options:
          province && district
            ? selectedMasterAddress
                .find(({ name: provinceName }) => provinceName === province)
                ?.districts.find(
                  ({ name: districtName }) => districtName === district
                )
                ?.subDistricts.map(({ name: subDistrictName, zipcode }) => ({
                  id: subDistrictName,
                  label: (
                    <Typography variant="body1">{subDistrictName}</Typography>
                  ),
                  value: subDistrictName,
                  onClickOption() {
                    setValue("subDistrict", subDistrictName);
                    setValue("zipcode", zipcode);
                    clearErrors("subDistrict");
                    toggleDrawerPickerHandler("subDistrict")();
                  },
                }))
            : noValueOption({
                reqFieldEN: !province ? "province" : "district",
                reqFieldTH: !province ? "จังหวัด" : "อำเภอ",
                field: "subDistrict",
              }),
      },
    ];
  }, [
    selectedMasterAddress,
    drawerPicker,
    province,
    district,
    subDistrict,
    setValue,
    clearErrors,
    toggleDrawerPickerHandler,
  ]);

  const onAddImage = (images = [], callback) => {
    if (!images.length) return;
    callback([...fieldImages, ...images]);
  };

  const onRemoveImageHandler = (state) => (imageIdx, data) => {
    if (!data) return;
    if (isUrl(data))
      setDeletedImages((prevDeletedImages) => [...prevDeletedImages, data]);
    const stateName = {
      fieldImages,
      entranceImages,
      areaImages,
      obstructionImages,
    };
    const remainedImages = stateName[state].filter(
      (_image, idx) => idx !== imageIdx
    );
    setValue(state, remainedImages);
    return remainedImages;
  };

  const formExcavatorImages = [
    {
      name: "entranceImages",
      ref: entranceImagesRef,
      exampleImage: ExcavatorEntranceImage,
      imageTextName: "ทางเข้ารถขุด",
      onRemoveImage: onRemoveImageHandler("entranceImages"),
      errors: errors.entranceImages,
    },
    {
      name: "areaImages",
      ref: areaImagesRef,
      exampleImage: ExcavatorAreaImage,
      imageTextName: "พื้นที่ทำงาน",
      onRemoveImage: onRemoveImageHandler("areaImages"),
      errors: errors.areaImages,
    },
    {
      name: "obstructionImages",
      ref: obstructionImagesRef,
      exampleImage: ExcavatorObstructionImage,
      imageTextName: `สิ่งกีดขวางอื่นๆ ${
        !obstructionImages?.length ? "ถ้ามี" : ""
      }`,
      onRemoveImage: onRemoveImageHandler("obstructionImages"),
      errors: errors.obstructionImages,
    },
  ];

  const checkRecommendedCode = async (code) => {
    if (!code) {
      setValue("recommendCodeJobDesc", "");
      return clearErrors("recommendedCode");
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/reccode/jobs/${code}`,
      {
        headers: {
          access_token,
        },
      }
    );
    const { exist, description } = await response.json();

    if (!exist) {
      setValue("recommendCodeJobDesc", "");
      return setError("recommendCodeJob", {
        type: "Recommended code not found",
        message: `ไม่พบรหัส ${code.toUpperCase()} ในระบบ กรุณากรอกรหัสแนะนำใหม่`,
      });
    }
    setValue("recommendCodeJobDesc", description);
    return clearErrors("recommendCodeJob");
  };

  const submitFormJob = async (form) => {
    const serviceDate = format(form.serviceDate, FORMAT.date);
    const serviceTime = format(form.serviceTime, FORMAT.time);

    const locationDetails = {
      map: {
        lat: form.mapLat,
        lon: form.mapLng,
        province: form.mapProvince,
        district: form.mapDistrict,
        subDistrict: form.mapSubDistrict,
        zipcode: form.mapZipcode,
      },
      manual: {
        province: form.province,
        district: form.district,
        subDistrict: form.subDistrict,
        zipcode: form.zipcode,
        address: form.address,
        landmark: form.landmark,
      },
    };

    const baseBody = {
      service: form.service,
      serviceType: jobName,
      serviceDate: serviceDate,
      serviceTime: serviceTime,
      recommendCodeJob: form.recommendCodeJob,
      recommendCodeJobDesc: form.recommendCodeJobDesc,
      paymentMethod: form.paymentMethod,
      ...(editPage && { id: jobId }),
      ...(editPage && deletedImages.length && { deletedImages }),
    };

    const othersBody = {
      drone: {
        fieldName: form.fieldName,
        fieldImages: form.fieldImages?.map((image) => ({
          filename: image.filename,
          data: image.data,
        })),
        plantType: form.plantType,
        detail: form.detail,
        preparedChemical: form.preparedChemical,
      },
      combine: {
        service: `เกี่ยว${form.plantType}`,
        fieldName: form.fieldName,
        fieldImages: form.fieldImages?.map((image) => ({
          filename: image.filename,
          data: image.data,
        })),
        detail: form.detail,
        plantType: form.plantType,
      },
      tractor: {
        fieldName: form.fieldName,
        fieldImages: form.fieldImages?.map((image) => ({
          filename: image.filename,
          data: image.data,
        })),
        detail: form.detail,
      },
      excavator: {
        jobDetail: form.jobDetail,
        entranceImages: form.entranceImages?.map((image) => ({
          filename: image.filename,
          data: image.data,
        })),
        areaImages: form.areaImages?.map((image) => ({
          filename: image.filename,
          data: image.data,
        })),
        obstructionImages: form.obstructionImages?.map((image) => ({
          filename: image.filename,
          data: image.data,
        })),
        coordinateSource: form?.coordinateSource,
        ...locationDetails?.[form?.coordinateSource],
      },
    };

    const body = {
      ...baseBody,
      ...othersBody[jobName],
    };

    if (!editPage) {
      const createdJobResponse = await createNewJob(body).unwrap();
      return navigate(ROUTES.JOB_SEARCHING(createdJobResponse.id), {
        replace: true,
      });
    }
    await updateJob(body).unwrap();
    return navigate(ROUTES.MENU_FARMER, { replace: true });
  };

  const deleteJobHandler = async () => {
    const swalResp = await new Swal({
      titleText: `ยืนยันลบงาน ${jobId} หรือไม่`,
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "80%",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: true,
      customClass: { container: classes.swal2Delete },
    });
    if (swalResp.isConfirmed) {
      await deleteJob(jobId).unwrap();
      navigate(ROUTES.MENU_FARMER, { replace: true });
    }
  };

  const submitErrorForm = (form) => {
    console.log("form error: ", form);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const pageHeader = {
    drone: {
      icon: DroneIcon,
      detail: "ใช้บริการฉีดพ่นสาร",
    },
    combine: {
      icon: CombineIcon,
      detail: "ใช้บริการเก็บเกี่ยว",
    },
    tractor: {
      icon: TractorIcon,
      detail: "ใช้บริการเตรียมดิน/อัดฟาง",
    },
    excavator: {
      icon: ExcavatorIcon,
      detail: "ใช้บริการรถขุด",
      topSection: (
        <TopExcavator
          control={control}
          value={service}
          pickerOptions={formServiceOptions.services}
          mainServiceOptions={selectedMasterServiceByType?.mainService}
          addressOptions={addressDropdownInputs}
          isEditing={editPage}
          formValues={{
            service: service,
            coordinateSource,
            mapLat,
            mapLng,
            mapProvince,
            mapDistrict,
            mapSubDistrict,
            mapZipcode,
          }}
          errors={{
            mapProvince: errors.mapProvince,
            mapDistrict: errors.mapDistrict,
            mapSubDistrict: errors.mapSubDistrict,
          }}
          handleToggleModal={toggleModalHandler}
          handleToggleDrawerPicker={toggleDrawerPickerHandler}
          map={{
            currentLocation,
            expandMap,
            onToggleExpandMap,
            onClickMap,
            onRelocateCurrentLocationHandler,
            onChangeSearchOtherLocation,
          }}
        />
      ),
    },
  };

  const jobTypeForms = {
    drone: (
      <Fragment>
        <Grid item xs={12} mb={1}>
          <Typography variant="body1" color="secondary">
            เลือกสารที่ต้องการฉีดพ่น
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <SelectPicker
            name="service"
            placeholder="เลือกสารที่ต้องการฉีดพ่น"
            control={control}
            defaultValue=""
            width={{ input: "100%" }}
            alignInner={{ value: "center", placeholder: "center" }}
            showDrawer={drawerPicker.chemicalService}
            onClickInput={toggleDrawerPickerHandler("chemicalService")}
          >
            <Navbar color="secondary" position="absolute">
              <Navbar.Left sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">
                  เลือกสารที่ต้องการฉีดพ่น
                </Typography>
              </Navbar.Left>
              <Navbar.Right sx={{ ml: "auto" }}>
                <IconButton
                  onClick={toggleDrawerPickerHandler("chemicalService")}
                >
                  <CloseIcon
                    sx={{ color: (theme) => theme.palette.common.white }}
                  />
                </IconButton>
              </Navbar.Right>
            </Navbar>
            <Options
              optionsData={formServiceOptions.services}
              selected={service}
            />
          </SelectPicker>
        </Grid>

        <DividerFullWidth
          sx={{ transform: "translate(-10%, 0)", width: "120%" }}
        />

        <Grid item xs={12} mt={4} mb={1}>
          <Typography variant="body1" color="secondary">
            ท่านต้องการให้ผู้รับจ้างเตรียมสารฉีดพ่นด้วยหรือไม่?
          </Typography>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Radio
            control={control}
            defaultValue=""
            name="preparedChemical"
            radioDirection="row"
            radioStyles={{ justifyContent: "space-around" }}
            radioOptions={[
              { id: "preparedChem-1", value: true, label: "ใช่" },
              { id: "preparedChem-2", value: false, label: "ไม่ใช่" },
            ]}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <Typography
            variant="subtitle2"
            color="error"
            sx={{ fontSize: "0.8rem" }}
          >
            ถ้าให้ผู้รับจ้างเตรียมสารฉีดพ่นให้ จะมีค่าใช้จ่ายเพิ่มเติม
          </Typography>
        </Grid>

        <DividerFullWidth
          sx={{ transform: "translate(-10%, 0)", width: "120%" }}
        />

        <Grid item xs={12} mt={4} mb={1}>
          <Typography variant="body1" color="secondary">
            เลือกชนิดพืช
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <SelectPicker
            name="plantType"
            placeholder="เลือกชนิดพืช"
            control={control}
            defaultValue=""
            width={{ input: "100%" }}
            alignInner={{ value: "center", placeholder: "center" }}
            showDrawer={drawerPicker.plantType}
            onClickInput={toggleDrawerPickerHandler("plantType")}
          >
            <Navbar color="secondary" position="absolute">
              <Navbar.Left sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">เลือกชนิดพืช</Typography>
              </Navbar.Left>
              <Navbar.Right sx={{ ml: "auto" }}>
                <IconButton onClick={toggleDrawerPickerHandler("plantType")}>
                  <CloseIcon
                    sx={{ color: (theme) => theme.palette.common.white }}
                  />
                </IconButton>
              </Navbar.Right>
            </Navbar>
            <Options
              optionsData={formServiceOptions.plantTypes}
              selected={plantType}
            />
          </SelectPicker>
        </Grid>
      </Fragment>
    ),
    combine: (
      <Fragment>
        <Grid item xs={12} mb={1}>
          <Typography variant="body1" color="secondary">
            เลือกชนิดพืชที่ต้องการเก็บเกี่ยว
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <SelectPicker
            name="plantType"
            placeholder="เลือกชนิดพืช"
            control={control}
            defaultValue=""
            width={{ input: "100%" }}
            alignInner={{ value: "center", placeholder: "center" }}
            showDrawer={drawerPicker.plantType}
            onClickInput={toggleDrawerPickerHandler("plantType")}
          >
            <Navbar color="secondary" position="absolute">
              <Navbar.Left sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">เลือกชนิดพืช</Typography>
              </Navbar.Left>
              <Navbar.Right sx={{ ml: "auto" }}>
                <IconButton onClick={toggleDrawerPickerHandler("plantType")}>
                  <CloseIcon
                    sx={{ color: (theme) => theme.palette.common.white }}
                  />
                </IconButton>
              </Navbar.Right>
            </Navbar>
            <Options
              optionsData={formServiceOptions.plantTypes}
              selected={plantType}
            />
          </SelectPicker>
        </Grid>
      </Fragment>
    ),
    tractor: (
      <Fragment>
        <Grid item xs={12} mb={1}>
          <Typography variant="body1" color="secondary">
            เลือกบริการเตรียมดิน
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <SelectPicker
            name="service"
            placeholder="เลือกบริการ"
            control={control}
            defaultValue=""
            width={{ input: "100%" }}
            alignInner={{ value: "center", placeholder: "center" }}
            showDrawer={drawerPicker.service}
            onClickInput={toggleDrawerPickerHandler("service")}
          >
            <Navbar color="secondary" position="absolute">
              <Navbar.Left sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">
                  เลือกบริการเตรียมดิน
                </Typography>
              </Navbar.Left>
              <Navbar.Right sx={{ ml: "auto" }}>
                <IconButton onClick={toggleDrawerPickerHandler("service")}>
                  <CloseIcon
                    sx={{ color: (theme) => theme.palette.common.white }}
                  />
                </IconButton>
              </Navbar.Right>
            </Navbar>
            <Options
              optionsData={formServiceOptions.services}
              selected={service}
            />
          </SelectPicker>
        </Grid>
      </Fragment>
    ),
  };

  // const pickedPromotionCodes = promoCode.filter((code) => code.isPick);

  return (
    <Fragment>
      <ScrollToTop />

      <Grid container item xs={12} mt={3} mb={2}>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          xs={12}
          mb={2}
        >
          <IconImage
            src={pageHeader[jobName].icon}
            alt={`${jobName}-icon`}
            sx={{ mb: 1 }}
          />
          <Typography variant="body1">{pageHeader[jobName].detail}</Typography>
        </Grid>

        <HoldingDivider item xs={12}>
          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />
        </HoldingDivider>

        <Form onSubmit={handleSubmit(submitFormJob, submitErrorForm)}>
          {jobTypeForms[jobName]}

          {jobName === "excavator" && pageHeader[jobName].topSection}

          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          <Grid item xs={12} mt={4} mb={2}>
            <Typography variant="body1" color="secondary">
              เลือกวันและเวลาที่ต้องการบริการ
            </Typography>
          </Grid>
          <Grid container item xs={12} mb={2}>
            <Grid item xs={6} pr={1}>
              <DatePicker
                name="serviceDate"
                control={control}
                defaultValue=""
                toolbarTitle="เลือกวันใช้บริการ"
                variant="outlined"
                placeholder="ระบุวันที่"
                size="medium"
                shouldDisableDate={disabledPastDate}
                showIcon
                showErrorText
                width={{ input: "100%" }}
              />
            </Grid>
            <Grid item xs={6} pl={1}>
              <TimePicker
                label="ระบุเวลา"
                name="serviceTime"
                control={control}
                defaultValue=""
                toolbarTitle="เลือกเวลาใช้บริการ"
                variant="outlined"
                size="medium"
                showIcon
                showErrorText
                width={{ input: "100%" }}
              />
            </Grid>
          </Grid>

          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          {jobName === VEHICLES.excavator.nameEN ? (
            <Fragment>
              <Grid item xs={12} mt={4} mb={1}>
                <Typography variant="body1" color="secondary">
                  ถ่ายภาพพื้นที่ที่ต้องการรับริการรถขุด
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography variant="subtitle2">
                  ถ่ายให้เห็นจุดที่สำคัญเช่น ทางเข้ารถขุด, พื้นที่ทำงาน,
                  สิ่งกีดขวางที่เป็นอุปสรรคต่องาน เป็นต้น
                </Typography>
              </Grid>

              {formExcavatorImages.map((formImage, idx) => (
                <Grid key={idx} item xs={12} mb={2}>
                  <ImageInput
                    ref={formImage.ref}
                    control={control}
                    defaultValue={[]}
                    name={formImage.name}
                    exampleImageSrc={formImage.exampleImage}
                    imageTextName={formImage.imageTextName}
                    onRemoveImage={formImage.onRemoveImage}
                  >
                    <Typography
                      variant="body1"
                      color={formImage.errors ? "error" : "tertiary"}
                      align="center"
                      sx={{ my: "auto", flexGrow: 1 }}
                    >
                      กดเพื่อเพิ่มรูป
                    </Typography>
                  </ImageInput>
                </Grid>
              ))}
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={12} mt={4} mb={2}>
                <Typography variant="body1" color="secondary">
                  เลือกแปลง
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SelectPicker
                  name="fieldName"
                  placeholder="เลือกแปลง"
                  control={control}
                  defaultValue=""
                  width={{ input: "100%" }}
                  alignInner={{ value: "center", placeholder: "center" }}
                  showDrawer={drawerPicker.fieldName}
                  onClickInput={toggleDrawerPickerHandler("fieldName")}
                >
                  <Navbar color="secondary" position="absolute">
                    <Navbar.Left sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">เลือกแปลง</Typography>
                    </Navbar.Left>
                    <Navbar.Right sx={{ ml: "auto" }}>
                      <IconButton
                        onClick={toggleDrawerPickerHandler("fieldName")}
                      >
                        <CloseIcon
                          sx={{ color: (theme) => theme.palette.common.white }}
                        />
                      </IconButton>
                    </Navbar.Right>
                  </Navbar>
                  <Options
                    optionsData={formFieldOptions}
                    selected={fieldName}
                  />
                </SelectPicker>
              </Grid>
              <DividerFullWidth
                sx={{ transform: "translate(-10%, 0)", width: "120%" }}
              />

              <Grid item xs={12} mt={4} mb={2}>
                <Typography variant="body1" color="secondary">
                  แนบภาพถ่ายสภาพแปลงปัจจุบัน
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <ButtonImageInput
                  name="fieldImages"
                  control={control}
                  defaultValue={[]}
                  addImages={onAddImage}
                  showStreamMedia={drawerPicker.streamMediaFieldImages}
                  onClickTakePhoto={toggleDrawerPickerHandler(
                    "streamMediaFieldImages"
                  )}
                  multiple
                />
              </Grid>
              {fieldImages?.length ? (
                <ImagesPreviewLayer
                  images={fieldImages.map((img) => img.data)}
                  imagesName="fieldImages"
                  headerText="สภาพแปลงปัจจุบัน"
                  showModal={modal.fieldImages}
                  togglePreviewModal={toggleModalHandler("fieldImages")}
                  onRemoveImage={onRemoveImageHandler("fieldImages")}
                />
              ) : null}

              <DividerFullWidth
                sx={{ transform: "translate(-10%, 0)", width: "120%" }}
              />

              <Grid item xs={12} mt={4} mb={1}>
                <Typography variant="body1" color="secondary">
                  ระบุรายละเอียดแปลงเพิ่มเติม
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextInput
                  control={control}
                  defaultValue=""
                  placeholder="ระบุรายละเอียดเพิ่มเติม"
                  name="detail"
                  width={{ input: "100%" }}
                  type="textarea"
                  multiline
                  rows={4}
                />
              </Grid>
            </Fragment>
          )}

          {/* <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          <Grid item xs={12} mt={4} mb={1}>
            <Typography variant="body1" color="secondary">
              โปรโมชั่น / รหัสแนะนำ
            </Typography>
          </Grid> */}
          {/* <Grid item xs={12} mb={2}>
            <Button
              fullWidth
              startIcon={<LocalOfferIcon />}
              variant="outlined"
              color="tertiary"
              onClick={() => {
                toggleModalHandler("promotionCode")();
                setTimeout(() => {
                  document.body.style.paddingRight = 0;
                }, 1);
              }}
              sx={{
                py: 1,
                mb: !modal.promotionCode && pickedPromotionCodes.length ? 1 : 0,
              }}
            >
              <Typography>เลือกส่วนลดและโปรโมชั่น</Typography>
            </Button>
            <PromotionCodePickerModal
              code={promoCode}
              onClickAddCode={onRevealNotListCode}
              onCheckCode={onTogglePickCodeHandler}
              onApplyCode={onApplyCodeHandler}
              openModal={modal.promotionCode}
              onCloseModal={() => {
                toggleModalHandler("promotionCode")();
                setPromoCode((prevCode) =>
                  prevCode.map((code) => ({
                    ...code,
                    isPick: false,
                  }))
                );
              }}
            />
            {!modal.promotionCode && pickedPromotionCodes.length
              ? pickedPromotionCodes.map((code) => (
                  <List
                    key={code.promotionCode}
                    disablePadding
                    sx={(theme) => ({
                      borderRadius: theme.spacing(1),
                      bgcolor: theme.palette.secondary.main,
                      mb: 1,
                    })}
                  >
                    <ListItem
                      secondaryAction={
                        <IconButton
                          onClick={() =>
                            onTogglePickCodeHandler(code.promotionCode, false)
                          }
                        >
                          <CloseIcon
                            sx={{
                              color: (theme) => theme.palette.common.white,
                            }}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1" color="white">
                          {code.promotionName}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                ))
              : null}
          </Grid> */}

          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          <Grid item xs={12} mt={4} mb={1}>
            <Typography variant="body1" color="secondary">
              รหัสแนะนำงาน (ถ้ามี)
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextInput
              name="recommendCodeJob"
              control={control}
              defaultValue=""
              placeholder="ระบุรหัสแนะนำ"
              helper={recommendCodeJobDesc}
              disabled={editPage && !!selectedCustomerJobById?.recommendCodeJob}
              onChangeInput={debounce(checkRecommendedCode, 1000)}
              width={{ input: "100%" }}
            />
            <TextInput
              name="recommendCodeJobDesc"
              control={control}
              defaultValue=""
              sx={{ display: "none" }}
            />
          </Grid>

          <DividerFullWidth
            sx={{ transform: "translate(-10%, 0)", width: "120%" }}
          />

          <Grid item xs={12} mt={4} mb={1}>
            <Typography variant="body1" color="secondary">
              วิธีการจ่ายเงิน
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Radio
              control={control}
              defaultValue=""
              name="paymentMethod"
              radioOptions={[
                {
                  id: "paymentMethod-1",
                  value: "cash",
                  color: "secondary",
                  label: (
                    <Typography variant="subtitle1">
                      จ่ายเงินสด ให้ผู้รับจ้างโดยตรง
                    </Typography>
                  ),
                  ...(paymentMethodValue === "cash" && {
                    helper: (
                      <Grid item xs={12}>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="error"
                          sx={{ fontSize: ".8rem" }}
                        >
                          กรณีจ่ายเงินสดให้ผู้รับจ้างโดยตรง
                          ทางแพลตฟอร์มจะไม่รับประกันการจ้างงาน
                        </Typography>
                      </Grid>
                    ),
                  }),
                },
                {
                  id: "paymentMethod-2",
                  value: "qr",
                  color: "secondary",
                  disabled: true,
                  label: (
                    <Typography variant="subtitle1" color="tertiary">
                      โอนเงินผ่าน
                      <Typography
                        component="span"
                        variant="subtitle1"
                        // color="error"
                        color="tertiary"
                      >
                        ระบบ
                      </Typography>
                    </Typography>
                  ),
                  helper: (
                    <Grid item xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="tertiary"
                        sx={{ fontSize: ".8rem" }}
                      >
                        ปิดช่องทางโอนเงินผ่านระบบชั่วคราว
                      </Typography>
                    </Grid>
                  ),
                },
              ]}
            />
          </Grid>

          <Grid container item xs={12} mb={2}>
            {editPage && selectedCustomerJobById?.stage === "created" ? (
              <Button
                // variant="outlined"
                color="error"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={deleteJobHandler}
                sx={{ ml: "auto" }}
              >
                ลบงาน
              </Button>
            ) : null}
            <Fab
              variant="extended"
              color="secondary"
              type="submit"
              size="small"
              sx={{
                ml:
                  editPage && selectedCustomerJobById?.stage === "created"
                    ? 2
                    : "auto",
                px: 3,
              }}
            >
              {editPage ? "บันทึก" : "ถัดไป"}
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <ActionLoading
        color="secondary"
        open={isCreating || isUpdating || isDeleting}
      >
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default JobFormPage;
