import PropTypes from "prop-types";

import { Grid, Typography } from "@mui/material";
import MapTwoToneIcon from "@mui/icons-material/MapTwoTone";

import { Card } from "base-components";

const FieldCard = ({
  id,
  name,
  province,
  district,
  subDistrict,
  address,
  size,
  onClickFieldCardHandler,
}) => {
  const clickCard = (fieldName) => () => {
    onClickFieldCardHandler(fieldName);
  };

  const village = address && (
    <Grid item>
      <Typography variant="subtitle2" color="tertiary">
        หมู่บ้าน{address}
      </Typography>
    </Grid>
  );

  return (
    <Card onClick={clickCard(name)}>
      <Grid container item direction="column" xs={10}>
        <Grid container item>
          <Grid item xs="auto" mr={1}>
            <MapTwoToneIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">แปลง{name}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" color="tertiary">
            ต.{subDistrict} อ.{district} จ.{province}
          </Typography>
        </Grid>
        {village}
      </Grid>
      <Grid
        container
        item
        xs={2.5}
        justifyContent="flex-end"
        alignItems="center"
        mr={2}
      >
        <Typography variant="body1">
          <strong>{size} ไร่</strong>
        </Typography>
      </Grid>
    </Card>
  );
};

FieldCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  subDistrict: PropTypes.string,
  district: PropTypes.string,
  province: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FieldCard.defaultProps = {
  onClickFieldCardHandler: () => {},
};

export default FieldCard;

// 70805
