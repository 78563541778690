import baseSchema from "./base-schema";

import DroneValidation from "./drone-schema";
import CombineValidation from "./combine-schema";
import TractorValidation from "./tractor-schema";
import ExcavatorValidation from "./excavator-schema";

import { VEHICLES } from "constants/Vehicles";

export { baseSchema };

export const additionalSchemas = {
  [VEHICLES.drone.nameEN]: DroneValidation,
  [VEHICLES.combine.nameEN]: CombineValidation,
  [VEHICLES.tractor.nameEN]: TractorValidation,
  [VEHICLES.excavator.nameEN]: ExcavatorValidation,
};
