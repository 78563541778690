import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateCurrentLocation } from "store/authentication/auth-slice";

import {
  evaluateGeocoding,
  getAddressManyComponents,
  getAddressComponent,
} from "helpers/Location";
import { DEFAULT_ZOOM } from "constants/Location";

const useMapLocation = ({
  location = { lat: "", lng: "", zoom: 16, defaultZoom: DEFAULT_ZOOM },
  setValueFn,
  clearErrorsFn,
  hasLocaiton = false,
}) => {
  const dispatch = useDispatch();
  const { currentLocation } = useSelector((state) => state.auth);
  const [expandMap, setExpandMap] = useState(false);
  const [showArea, setShowArea] = useState(true);

  const setMapLocation = useCallback(
    (
      fields = {
        mapLat: "",
        mapLng: "",
        mapProvince: "",
        mapDistrict: "",
        mapSubDistrict: "",
        mapZipcode: "",
      }
    ) => {
      if (!fields) return;
      for (const [key, value] of Object.entries(fields)) {
        setValueFn(key, value);
        clearErrorsFn(key);
      }
    },
    [setValueFn, clearErrorsFn]
  );

  const requestUserLocation = useCallback(() => {
    // navigator.geolocation.watchPosition((position) => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      const latLng = { lat: latitude, lng: longitude };
      if (!currentLocation.lat && !currentLocation.lng)
        dispatch(updateCurrentLocation(latLng));
    });
  }, [currentLocation, dispatch]);

  useEffect(() => {
    const checkNeedRequestLocation = () => {
      if (hasLocaiton) return;
      return requestUserLocation();
    };

    checkNeedRequestLocation();
  }, [hasLocaiton, currentLocation, requestUserLocation]);

  /* Utility functions */
  const onToggleExpandMap = () => {
    setExpandMap((prevExpand) => !prevExpand);
  };

  const onToggleArea = (map) => {
    setShowArea((prevShowArea) => {
      map.setZoom(prevShowArea ? location.zoom : location.defaultZoom);
      map.setCenter({ lat: location.lat, lng: location.lng });
      return !prevShowArea;
    });
  };

  const onClickMap = async (map, event) => {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setShowArea(false);

    const addresses = await evaluateGeocoding(latLng);
    const address = getAddressManyComponents(
      { ...latLng, zoom: location.zoom },
      map,
      addresses
    );
    setMapLocation({
      mapLat: latLng.lat,
      mapLng: latLng.lng,
      mapProvince: address.province,
      mapDistrict: address.district,
      mapSubDistrict: address.subDistrict,
      mapZipcode: address.zipcode,
    });
  };

  const onRelocateCurrentLocationHandler = async (map) => {
    setShowArea(false);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const currentLatLng = {
          lat: latitude,
          lng: longitude,
        };
        dispatch(updateCurrentLocation(currentLatLng));

        const addresses = await evaluateGeocoding(currentLatLng);
        const address = getAddressManyComponents(
          { ...currentLatLng, zoom: location.zoom },
          map,
          addresses
        );

        setMapLocation({
          mapLat: currentLatLng.lat,
          mapLng: currentLatLng.lng,
          mapProvince: address.province,
          mapDistrict: address.district,
          mapSubDistrict: address.subDistrict,
          mapZipcode: address.zipcode,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onChangeSearchOtherLocation = useCallback(
    async (map, places) => {
      let address;
      const [place] = places.getPlaces();

      if (!place.geometry) return;

      const latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      if (place.address_components.length > 4) {
        /* Address completed! => At least Country, Provine, District, Sub-district, zipcode */
        address = getAddressComponent(
          { ...latLng, zoom: location.zoom },
          map,
          place.address_components
        );
      } else {
        /* Address data not completed! */
        const addresses = await evaluateGeocoding(latLng);
        address = getAddressManyComponents(
          { ...latLng, zoom: location.zoom },
          map,
          addresses
        );
      }

      setMapLocation({
        mapLat: latLng.lat,
        mapLng: latLng.lng,
        mapProvince: address.province,
        mapDistrict: address.district,
        mapSubDistrict: address.subDistrict,
        mapZipcode: address.zipcode,
      });
    },
    [setMapLocation, location.zoom]
  );

  return {
    currentLocation,
    expandMap,
    showArea,
    onToggleExpandMap,
    onToggleArea,
    onClickMap,
    onRelocateCurrentLocationHandler,
    onChangeSearchOtherLocation,
  };
};

export default useMapLocation;
