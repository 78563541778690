import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { object, array } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Grid,
  Drawer as MuiDrawer,
  Typography,
  IconButton,
  Fab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { useStartOrEndWorkingByDriverMutation } from "services/job";
import { toggleDrawerPicker } from "store/user-interface/ui-slice";

import { ActionLoading } from "base-components";
import { ButtonImageInput } from "components/hook-forms";
import { Navbar } from "layouts/components";
import { PreviewFieldImage } from "components";

const validationSchema = object({
  images: array().min(1, "กรุณาเลือก รูปถ่าย อย่างน้อย 1 รูป"),
});

const Drawer = styled(MuiDrawer)({
  position: "relative",
});

const UploadStartOrEndImagesDrawer = ({
  jobId,
  stage,
  period,
  confirmPrice,
  onSubmittedFulfilled,
}) => {
  const dispatch = useDispatch();
  const { drawerPicker } = useSelector((state) => state.ui);

  const {
    control,
    setValue,
    watch,
    // setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const images = watch("images", []);
  const [startOrEndWorking, { isLoading: isDelivering }] =
    useStartOrEndWorkingByDriverMutation();

  const toggleDrawerPickerHandler = (state) => () => {
    dispatch(toggleDrawerPicker(state));
  };

  const onAddImageHandler = (imagesAdding = [], callback) => {
    if (!imagesAdding.length) return;
    if (!images?.length) callback([...imagesAdding]);
    else callback([...images, ...imagesAdding]);
  };

  const onRemoveImageHandler = (state) => (imageIdx, data) => {
    if (!data) return;
    const stateName = { images };
    const remainedImages = stateName[state].filter(
      (_, idx) => idx !== imageIdx
    );
    setValue(state, remainedImages);
  };

  const submitWorkingImages = async (form) => {
    const capitalize = (string) =>
      `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

    const body = {
      jobId,
      confirmPrice,
      flagEditedPrice: true,
      [`images${capitalize(period)}`]: form.images.map((image) => ({
        filename: image.filename,
        data: image.data,
      })),
    };
    await startOrEndWorking({
      jobId,
      stage,
      body,
    }).unwrap();
    toggleDrawerPickerHandler(`offer${period}Work`);
    onSubmittedFulfilled();
  };

  return (
    <Fragment>
      <Drawer
        anchor="bottom"
        open={drawerPicker[`offer${period}Work`]}
        onClose={toggleDrawerPickerHandler(`offer${period}Work`)}
      >
        <Navbar color="secondary" position="absolute">
          <Navbar.Left sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">
              ถ่ายรูปพื้นที่{period === "before" ? "ก่อนเริ่ม" : "หลังเสร็จ"}งาน
            </Typography>
          </Navbar.Left>
          <Navbar.Right sx={{ ml: "auto" }}>
            <IconButton
              onClick={toggleDrawerPickerHandler(`offer${period}Work`)}
            >
              <CloseIcon
                sx={{ color: (theme) => theme.palette.common.white }}
              />
            </IconButton>
          </Navbar.Right>
        </Navbar>
        <form onSubmit={handleSubmit(submitWorkingImages)}>
          <Grid
            item
            xs={12}
            ml={2}
            my={3}
            sx={!images?.length ? { minHeight: "30vh" } : {}}
          >
            <ButtonImageInput
              name="images"
              control={control}
              defaultValue={[]}
              addImages={onAddImageHandler}
              showStreamMedia={drawerPicker.streamMediaImages}
              onClickTakePhoto={toggleDrawerPickerHandler("streamMediaImages")}
              multiple
            />
          </Grid>
          <Grid item xs={12} mx={2} mb={2}>
            <PreviewFieldImage
              imgList={images}
              clickRemoveImage={onRemoveImageHandler("images")}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" mb={2}>
            <Fab
              size="small"
              variant="extended"
              color="primary"
              type="submit"
              sx={{ px: 3, mr: 2 }}
            >
              ตกลง
            </Fab>
          </Grid>
        </form>
      </Drawer>
      <ActionLoading open={isDelivering}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default UploadStartOrEndImagesDrawer;
