import { Grid, Typography } from "@mui/material";

import { ROLES } from "constants/Roles";
import { VEHICLES } from "constants/Vehicles";
import { JOB_STATUS } from "constants/JobStatus";

const TotalAmount = ({ role, vinType, stage, totalAmount }) => {
  const isDriver = role === ROLES.DRIVER;

  const isCreated = stage === JOB_STATUS[role]?.created?.nameEN;
  const isWaiting = stage === JOB_STATUS[role]?.waiting?.nameEN;

  if (isCreated) return null;

  if (isWaiting && vinType === VEHICLES.excavator.nameEN) return null;

  return (
    <Grid item xs={12} ml={2} mr={4} mb={2}>
      <Grid container item xs={12}>
        <Grid item xs={7} sx={{ flexGrow: 1 }}>
          <Typography variant="body1" sx={{ fontSize: "1.125rem" }}>
            <strong>{isDriver ? "รวมรายได้ทั้งหมด" : "รวมทั้งหมด"}</strong>
          </Typography>
        </Grid>

        <Grid item xs my="auto" ml="auto" mr={1}>
          <Typography variant="body1" align="right">
            <strong>{(+totalAmount).toLocaleString()}</strong>
          </Typography>
        </Grid>

        <Grid item xs my="auto">
          <Typography variant="body1">
            <strong>บาท</strong>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TotalAmount;
