import PropTypes from "prop-types";
import { Grid, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const ImgGrid = styled(Grid)(({ theme }) => ({
  "&:nth-of-type(odd)": { paddingRight: theme.spacing(1) },
  "&:nth-of-type(even)": {
    paddingLeft: theme.spacing(1),
  },
}));

const FieldImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  objectFit: "contain",
  width: "100%",
  flexGrow: 1,
}));

const PreviewFieldImage = ({ imgList, clickRemoveImage }) => {
  const handleRemoveImage = (imageIdx, data) => () => {
    clickRemoveImage(imageIdx, data);
  };

  if (!imgList?.length) return null;
  return (
    <Grid container item>
      {imgList.map((img, idx) => (
        <ImgGrid
          key={idx}
          item
          xs={6}
          sx={{ position: "relative", my: "auto" }}
          mb={1}
        >
          <IconButton
            onClick={handleRemoveImage(idx, img.data)}
            sx={{
              p: 0,
              position: "absolute",
              top: 5,
              right: 10,
              backgroundColor: (theme) => `${theme.palette.error.light}30`,
            }}
          >
            <CloseIcon color="error" />
          </IconButton>
          <FieldImage src={img.data} alt="img01" />
        </ImgGrid>
      ))}
    </Grid>
  );
};

PreviewFieldImage.propTypes = {
  imgList: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string,
      data: PropTypes.string,
      size: PropTypes.number,
    })
  ),
};

PreviewFieldImage.defaultProps = {
  imgList: [],
};

export default PreviewFieldImage;
