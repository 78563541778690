import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const InlineFormControl = styled(FormControl)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const InlineRadio = ({
  control,
  defaultValue,
  checkedColor,
  radioOptions,
  ...props
}) => {
  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const errorText = error?.message ? (
          <Grid item xs={12}>
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          </Grid>
        ) : null;

        return (
          <InlineFormControl>
            <FormLabel
              id={`${name}-inline__radio`}
              error={!!error}
              sx={(theme) => ({
                flexBasis: props.label ? "30%" : "0",
                color: theme.palette.common.black,
                ...(!!error && {
                  alignSelf: "flex-start",
                  pt: theme.spacing(1.25),
                }),
              })}
            >
              {props.label}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby={`${name}-inline__radio`}
              name={name}
              defaultValue={defaultValue}
              value={value}
              sx={{
                rowGap: 1,
                flex: `1 0 ${props.label ? "70%" : "100%"}`,
              }}
            >
              {radioOptions.map((radio) => (
                <Grid key={radio.id} item xs={radio?.columnSize?.xs ?? 6}>
                  <FormControlLabel
                    label={
                      <Typography color={error ? "error" : "default"}>
                        {radio.label}
                      </Typography>
                    }
                    labelPlacement="end"
                    value={radio.value}
                    onChange={onChange}
                    control={
                      <Radio
                        checkedIcon={
                          <CheckCircleIcon
                            sx={{
                              color: (theme) =>
                                theme.palette[checkedColor].main,
                            }}
                          />
                        }
                        icon={
                          <RadioButtonUncheckedIcon
                            color={error ? "error" : "inherit"}
                          />
                        }
                      />
                    }
                    sx={{
                      "&:not(:last-child)": {
                        mr: 6,
                        ml: "auto",
                      },
                      "&:last-child": {
                        mr: "auto",
                      },
                    }}
                  />
                </Grid>
              ))}
              {errorText}
            </RadioGroup>
          </InlineFormControl>
        );
      }}
    />
  );
};

InlineRadio.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node,
  checkedColor: PropTypes.oneOf(["primary", "secondary"]),
  radioOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ).isRequired,
};

InlineRadio.defaultProps = {
  checkedColor: "primary",
};

export default InlineRadio;
