import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import uiSlice from "./user-interface/ui-slice";
import authSlice from "./authentication/auth-slice";
import assetSlice from "./assets/asset-slice";
import masterSlice from "./master/master-slice";
import jobSlice from "./jobs/job-slice";
import machinerySlice from "store/machinery-slice";
import remasterSlice from "store/master-slice";
import serviceSlice from "store/service-slice";
import fieldSlice from "store/field-slice";
import rejobSlice from "store/job-slice";

import { api } from "services/api";

const rootPersistConfig = {
  key: "root",
  storage,
  backlist: [
    "assets",
    "ui",
    "master",
    "jobs",
    "machinery",
    "remaster",
    "service",
    "field",
    "promotion",
  ],
  whitelist: ["auth"],
  version: 1,
};

const authPersistConfig = {
  key: "auth",
  storage: sessionStorage,
};

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: persistReducer(authPersistConfig, authSlice),
  ui: uiSlice,
  assets: assetSlice.reducer,
  master: masterSlice.reducer,
  jobs: jobSlice.reducer,
  machinery: machinerySlice,
  remaster: remasterSlice,
  service: serviceSlice,
  field: fieldSlice,
  job: rejobSlice,
  // promotion:
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

/** Listener for enable
 * - refetchOnFocus
 * - refetchOnReconnect
 */
setupListeners(store.dispatch);

export default store;
