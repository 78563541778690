import { useState, useEffect, useRef, useCallback } from "react";
import { TextField } from "@mui/material";

const SearchBox = ({ map, changeLocation, searchInput }) => {
  const [searchBox, setSearchBox] = useState("");

  const inputRef = useRef();

  const onChangeLocationHandler = useCallback(
    (map, places) => () => {
      changeLocation && changeLocation(map, places);
    },
    [changeLocation]
  );

  useEffect(() => {
    if (inputRef.current && !map) {
      setSearchBox(new window.google.maps.places.SearchBox(inputRef.current));
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      window.google.maps.event.clearListeners(map, "places_changed");

      if (onChangeLocationHandler) {
        searchBox.addListener(
          "places_changed",
          onChangeLocationHandler(map, searchBox)
        );
      }
    }
  }, [map, searchBox, onChangeLocationHandler]);

  const clearSearchBox = () => {
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <TextField
      inputRef={inputRef}
      type="text"
      // autoFocus
      onFocus={clearSearchBox}
      label={searchInput.label}
      placeholder={searchInput.placeholder}
      size="small"
      sx={(theme) => ({
        margin: theme.spacing(2),
        width: "100%",
      })}
    />
  );
};

export default SearchBox;
