import { Fragment, useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Wrapper } from "@googlemaps/react-wrapper";

import { Grid, Typography, Button, IconButton, Skeleton } from "@mui/material";
import {
  Error as ErrorIcon,
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

import { SelectPicker, TextInput, Radio } from "components/hook-forms";
import { Options, DividerFullWidth, SlidingTransition } from "base-components";
import { Navbar } from "layouts/components";
import { GoogleMap, Marker } from "components/location";

import { InformationModal } from "pages/job-details/components/modals";

import { VEHICLES } from "constants/Vehicles";
import { DEFAULT_EXCAVATOR_ZOOM } from "constants/Location";

const TopExcavator = ({
  control,
  value,
  pickerOptions,
  mainServiceOptions,
  addressOptions,
  isEditing,
  formValues,
  errors,
  handleToggleModal,
  handleToggleDrawerPicker,
  map,
}) => {
  const { drawerPicker, modal } = useSelector((state) => state.ui);
  const [triggerTransition, setTriggerTransition] = useState(false);
  const coordinateSourceRef = useRef(null);

  const handleChangeRadio = () => {
    setTriggerTransition(true);
  };

  const mapTextInputs = [
    {
      id: "lat-dp-none-input",
      name: "mapLat",
      label: "latitude",
    },
    { id: "lng-dp-none-input", name: "mapLng", label: "longitude" },
    {
      id: "province-dp-none-input",
      name: "mapProvince",
      label: "จังหวัด",
    },
    {
      id: "district-dp-none-input",
      name: "mapDistrict",
      label: "อำเภอ",
    },
    {
      id: "sub-district-dp-none-input",
      name: "mapSubDistrict",
      label: "ตำบล",
    },
    {
      id: "zipcode-dp-none-input",
      name: "mapZipcode",
      label: "รหัสไปรษณีย์",
    },
  ];

  return (
    <Fragment>
      <Grid container item xs={12} mb={1}>
        <Typography
          variant="body1"
          color="secondary"
          sx={{ my: "auto", mr: 0.5 }}
        >
          เลือกบริการรถขุด
        </Typography>
        <IconButton onClick={handleToggleModal("information")}>
          <ErrorIcon
            sx={(theme) => ({
              fontSize: "1.25rem",
              color: theme.palette.secondary.main,
            })}
          />
        </IconButton>
      </Grid>
      <Grid item xs={12} mb={2}>
        <SelectPicker
          name="service"
          placeholder="เลือกประเภทบริการรถขุด"
          control={control}
          defaultValue=""
          width={{ input: "100%" }}
          alignInner={{ value: "center", placeholder: "center" }}
          showDrawer={drawerPicker.service}
          onClickInput={handleToggleDrawerPicker("service")}
        >
          <Navbar color="secondary" position="absolute">
            <Navbar.Left sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle2">เลือกบริการรถขุด</Typography>
            </Navbar.Left>
            <Navbar.Right sx={{ ml: "auto" }}>
              <IconButton onClick={handleToggleDrawerPicker("service")}>
                <CloseIcon
                  sx={{ color: (theme) => theme.palette.common.white }}
                />
              </IconButton>
            </Navbar.Right>
          </Navbar>
          <Options optionsData={pickerOptions} selected={value} />
        </SelectPicker>
      </Grid>

      <DividerFullWidth
        sx={{ transform: "translate(-10%, 0)", width: "120%" }}
      />

      <Grid item xs={12} mt={4} mb={1}>
        <Typography variant="body1" color="secondary">
          อธิบายรายละเอียดงาน
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <TextInput
          control={control}
          defaultValue=""
          placeholder="อธิบายงานที่ต้องการจ้างรถขุดมาทำงาน ตัวอย่าง: ถางป่าและปรับพื้นที่ดินสร้างบ้าน เนื้อที่ประมาณ 4 ไร่"
          name="jobDetail"
          width={{ input: "100%" }}
          type="textarea"
          multiline
          rows={5}
        />
      </Grid>

      <DividerFullWidth
        sx={{ transform: "translate(-10%, 0)", width: "120%" }}
      />

      <Grid
        item
        xs={12}
        mt={4}
        mb={formValues.coordinateSource === "map" ? 2 : 0}
        ref={coordinateSourceRef}
      >
        <Typography variant="body1" color="secondary">
          ระบุสถานที่เพื่อใช้บริการรถขุด
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Radio
          control={control}
          defaultValue=""
          name="coordinateSource"
          onChangeCallback={handleChangeRadio}
          radioDirection="column"
          radioStyles={{ justifyContent: "space-around", mt: 1 }}
          radioOptions={[
            {
              id: "map",
              value: "map",
              color: "secondary",
              label: (
                <Grid container>
                  <Grid container item xs alignItems="center">
                    <Typography variant="body1">
                      ระบุตำแหน่งด้วยแผนที่
                    </Typography>
                  </Grid>
                  {formValues.coordinateSource === "map" && (
                    <Grid container item xs="auto" ml={8}>
                      <Button
                        color="primary"
                        // variant="outlined"
                        onClick={map.onToggleExpandMap}
                        endIcon={
                          map.expandMap ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                      >
                        {map.expandMap ? "ย่อแผนที่" : "ขยายแผนที่"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ),
              children: (
                <SlidingTransition
                  show={formValues.coordinateSource === "map"}
                  elementScrollRef={coordinateSourceRef.current}
                  isTriggerTransition={triggerTransition}
                >
                  <Grid item xs={12} mt={1}>
                    <Wrapper
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      libraries={["places"]}
                      language="th"
                      render={(_status) => (
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={500}
                          width="100%"
                        />
                      )}
                    >
                      <GoogleMap
                        center={{
                          lat: map.currentLocation.lat,
                          lng: map.currentLocation.lng,
                        }}
                        selectedCenter={
                          isEditing
                            ? {
                                lat: formValues.mapLat,
                                lng: formValues.mapLng,
                              }
                            : null
                        }
                        selectedAddress={
                          formValues.mapProvince &&
                          formValues.mapDistrict &&
                          formValues.mapSubDistrict &&
                          `${formValues.mapSubDistrict}, ${formValues.mapDistrict}, ${formValues.mapProvince}, ${formValues.mapZipcode}`
                        }
                        errors={{
                          mapProvince: errors.mapProvince,
                          mapDistrict: errors.mapDistrict,
                          mapSubDistrict: errors.mapSubDistrict,
                        }}
                        zoom={DEFAULT_EXCAVATOR_ZOOM}
                        mapHeight={map.expandMap ? 700 : 400}
                        searchOther={{
                          isSearchOther: true,
                          searchInput: {
                            label: "ค้นหาตำแหน่ง",
                            placeholder: "พิมพ์ค้นหาสถานที่เพื่อใช้บริการรถขุด",
                          },
                          changeOther: map.onChangeSearchOtherLocation,
                        }}
                        isSelectCurrent={
                          map.currentLocation.lat === formValues.mapLat &&
                          map.currentLocation.lng === formValues.mapLng
                        }
                        clickMap={map.onClickMap}
                        clickCurrent={map.onRelocateCurrentLocationHandler}
                      >
                        <Marker
                          position={{
                            lat: formValues.mapLat || map.currentLocation.lat,
                            lng: formValues.mapLng || map.currentLocation.lng,
                          }}
                        />
                      </GoogleMap>
                    </Wrapper>
                  </Grid>
                </SlidingTransition>
              ),
            },
            {
              id: "manual",
              value: "manual",
              label: "ระบุตำแหน่งเอง",
              color: "secondary",
              children: (
                <SlidingTransition
                  show={formValues.coordinateSource === "manual"}
                  elementScrollRef={coordinateSourceRef.current}
                  isTriggerTransition={triggerTransition}
                >
                  <Grid container mt={2}>
                    {addressOptions.map((selectInput) => (
                      <Grid
                        item
                        xs={selectInput.size.xs}
                        key={selectInput.id}
                        sx={selectInput.sx}
                      >
                        <SelectPicker
                          label={selectInput.label}
                          name={selectInput.name}
                          placeholder={selectInput.placeholder}
                          control={control}
                          defaultValue=""
                          width={selectInput.width}
                          showEndIcon={false}
                          showDrawer={selectInput.showDrawer}
                          onClickInput={handleToggleDrawerPicker(
                            selectInput.name
                          )}
                        >
                          <Navbar
                            color="secondary"
                            position={selectInput.navPosition}
                          >
                            <Navbar.Left sx={{ flexGrow: 1 }}>
                              <Typography variant="subtitle2">
                                {selectInput.optionTitle}
                              </Typography>
                            </Navbar.Left>
                            <Navbar.Right sx={{ ml: "auto" }}>
                              <IconButton
                                onClick={handleToggleDrawerPicker(
                                  selectInput.name
                                )}
                              >
                                <CloseIcon
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.common.white,
                                  }}
                                />
                              </IconButton>
                            </Navbar.Right>
                          </Navbar>
                          <Options
                            optionsData={selectInput.options}
                            selected={selectInput.value}
                          />
                        </SelectPicker>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <TextInput
                        control={control}
                        defaultValue=""
                        name="zipcode"
                        label="รหัสไปรษณีย์"
                        type="text"
                        width={{ label: "30%", input: "80%" }}
                        sx={{ display: "none" }}
                      />
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      <TextInput
                        control={control}
                        defaultValue=""
                        name="address"
                        label="ที่อยู่"
                        placeholder="ระบุบ้านเลขที่,ซอย, ถนน"
                        type="text"
                        multiline
                        rows={2}
                        width={{ label: "15%", input: "85%" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        control={control}
                        defaultValue=""
                        name="landmark"
                        label="จุดสังเกตุอื่นๆ"
                        placeholder="(ถ้ามี) เช่น ใกล้วัดพระศรี"
                        type="text"
                        multiline
                        rows={2}
                        width={{ label: "30%", input: "80%" }}
                      />
                    </Grid>
                  </Grid>
                </SlidingTransition>
              ),
            },
          ]}
        />
      </Grid>

      {mapTextInputs.map((textInput) => (
        <TextInput
          key={textInput.id}
          control={control}
          defaultValue=""
          name={textInput.name}
          label={textInput.label}
          type="text"
          disabled={true}
          width={{ label: "30%", input: "70%" }}
          sx={{ display: "none" }}
        />
      ))}

      <InformationModal
        open={modal.information}
        onClose={handleToggleModal("information")}
        serviceName={VEHICLES.excavator.nameEN}
        mainServices={mainServiceOptions}
      />
    </Fragment>
  );
};

TopExcavator.propTypes = {
  map: PropTypes.exact({
    currentLocation: PropTypes.exact({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    expandMap: PropTypes.bool,
    onToggleExpandMap: PropTypes.func,
    onClickMap: PropTypes.func,
    onRelocateCurrentLocationHandler: PropTypes.func,
    onChangeSearchOtherLocation: PropTypes.func,
  }),
};

export default TopExcavator;
