export const VEHICLES = {
  drone: {
    nameTH: "โดรนการเกษตร",
    nameEN: "drone",
  },
  tractor: {
    nameTH: "รถแทรกเตอร์",
    nameEN: "tractor",
  },
  excavator: {
    nameTH: "รถขุด",
    nameEN: "excavator",
  },
  combine: {
    nameTH: "รถเกี่ยว",
    nameEN: "combine",
  },
};
