import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

import { ROLES } from "constants/Roles";
import { VEHICLES } from "constants/Vehicles";
import { JOB_STATUS } from "constants/JobStatus";

const TemplateDetail = ({ children }) => {
  const headerText = (
    <Grid item xs={12} mb={1}>
      <Typography variant="body1" color="secondary">
        รายละเอียดค่าจ้าง
      </Typography>
    </Grid>
  );
  return (
    <Fragment>
      {headerText}
      <Grid item xs={12} ml={2} mr={4}>
        {children}
      </Grid>
    </Fragment>
  );
};

const WageRateDetailsBreakdown = ({
  role,
  vinType,
  stage,
  invertRoleText,
  fieldSize,
  confirmPrice,
  total,
  serviceRate,
  servicePrice,
  additionalItem = [],
}) => {
  const isDriver = role === ROLES.DRIVER;
  const isFarmer = role === ROLES.FARMER;

  const isCreated = stage === JOB_STATUS[role]?.created?.nameEN;
  const isWaiting = stage === JOB_STATUS[role]?.waiting?.nameEN;

  if (isCreated)
    return isFarmer ? (
      <TemplateDetail>
        <Grid item xs={12} mb={3}>
          <Typography variant="subtitle1" color="error">
            ค่าจ้างจะแสดงหลังเลือก{invertRoleText}แล้ว
          </Typography>
        </Grid>
      </TemplateDetail>
    ) : null;

  if (isWaiting && vinType === VEHICLES.excavator.nameEN) {
    return isDriver ? (
      <TemplateDetail>
        <Grid item xs={12} mb={3}>
          <Typography variant="subtitle1" color="error">
            เสนอราคาให้{invertRoleText}
          </Typography>
        </Grid>
      </TemplateDetail>
    ) : (
      <TemplateDetail>
        <Grid item xs={12} mb={3}>
          <Typography variant="subtitle1" color="error">
            รอ{invertRoleText}เสนอราคาค่าจ้าง
          </Typography>
        </Grid>
      </TemplateDetail>
    );
  }

  let detailsBreakdown = [
    {
      label: "พื้นที่จ้างงาน",
      value: fieldSize,
      unit: "ไร่",
    },
    {
      label: "อัตราค่าจ้าง",
      value: confirmPrice,
      unit: "บาท/ไร่",
    },
    {
      label: isDriver ? "รายได้จากการรับจ้าง" : "รวมค่าจ้าง",
      value: total,
      unit: "บาท",
    },
    isDriver && {
      label: `หักค่าบริการ ${serviceRate || 0}%`,
      value: -servicePrice || 0,
      unit: "บาท",
    },
  ];

  if (vinType === VEHICLES.excavator.nameEN)
    detailsBreakdown = [
      {
        label: isDriver ? "รายได้จากการรับจ้าง" : "รวมค่าจ้าง",
        value: total,
        unit: "บาท",
      },
      isDriver && {
        label: `หักค่าบริการ ${serviceRate || 0}%`,
        value: -servicePrice || 0,
        unit: "บาท",
      },
    ];

  return (
    <TemplateDetail>
      {detailsBreakdown.map((price, idx) => (
        <Grid key={idx} container item xs={12} mb={1}>
          <Grid item xs={7} sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{price.label}</Typography>
          </Grid>

          <Grid item xs my="auto" ml="auto" mr={1}>
            <Typography variant="subtitle2" align="right">
              {price.value}
            </Typography>
          </Grid>

          <Grid item xs my="auto">
            <Typography variant="subtitle2">{price.unit}</Typography>
          </Grid>
        </Grid>
      ))}
      {additionalItem.map((item, idx) => (
        <Grid key={idx} container item xs={12} mb={1}>
          <Grid item xs={7} sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{item?.nameTH}</Typography>
          </Grid>

          <Grid item xs my="auto" ml="auto" mr={1}>
            <Typography variant="subtitle2" align="right">
              {parseInt(item?.price)?.toLocaleString()}
            </Typography>
          </Grid>

          <Grid item xs my="auto">
            <Typography variant="subtitle2">บาท</Typography>
          </Grid>
        </Grid>
      ))}
    </TemplateDetail>
  );
};

export default WageRateDetailsBreakdown;
