import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    if (
      document.readyState === "interactive" ||
      document.readyState === "complete"
    )
      scrollToTop();
  }, [pathname]);
  return null;
};

export default ScrollToTop;
