import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, Typography, Skeleton } from "@mui/material";
import Swal from "sweetalert2";

import { ScrollToTop, Card } from "base-components";
import {
  ServiceTypeCard,
  AddItemCard,
  FieldCard,
  FarmerJobCard,
} from "components";

import { useGetMasterServiceTypesQuery } from "services/master";
import { selectShortServiceTypes } from "store/master-slice";

import { useGetFieldsQuery } from "services/field";

import { updateCurrentLocation } from "store/authentication/auth-slice";
import { jobActions } from "store/jobs/job-slice";
import { useHttp, useSwalStyles } from "hooks";
import { sortingDateTime } from "helpers/DateTime";

import { ROUTES } from "routes/router";
import { JOB_ENDPOINTS } from "constants/APIs";
import { VEHICLES } from "constants/Vehicles";

const FarmerMenuPage = (props) => {
  const dispatch = useDispatch();
  const { exists, tel, verifyOTP, currentLocation, currentRole } = useSelector(
    (state) => state.auth
  );
  const [hirings, setHirings] = useState([]);
  const [priorityHirings, setPriorityHirings] = useState(false);
  const navigate = useNavigate();

  const selectedShortMasterServices = useSelector(selectShortServiceTypes);
  const { isLoading: isLoadingServicesTypes } = useGetMasterServiceTypesQuery(
    undefined,
    {
      skip: selectedShortMasterServices.length,
    }
  );

  const { data: fields = [] } = useGetFieldsQuery(undefined, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const { isLoading: isLoadingJobs, sendRequest: fetchJobsData } = useHttp();

  const classes = useSwalStyles();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const fetchJobs = (signal) => {
      const filteredStages =
        "created,edited,working,waiting,inDay,sooning,delivered";

      fetchJobsData(
        {
          endpoint: JOB_ENDPOINTS.farmer.all(filteredStages),
          signal,
        },
        (respJobsGrouping) => {
          if (respJobsGrouping.length === 1 && respJobsGrouping[0]?.jobQty > 0)
            setPriorityHirings(true);
          const flatJobs = respJobsGrouping
            .map((stage) => sortingDateTime(stage.jobs).map((job) => job))
            .flat();
          setHirings(flatJobs);
        }
      );
    };

    fetchJobs(signal);

    return () => abortCont.abort();
  }, [dispatch, fetchJobsData]);

  useEffect(() => {
    const requestUserLocation = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        dispatch(updateCurrentLocation({ lat: latitude, lng: longitude }));
      });
    };

    if (!currentLocation?.lat || !currentLocation?.lng) requestUserLocation();
  }, [dispatch, currentLocation]);

  /**
   * Middleware function for checking farmer information before another action
   * @param {Function} next callback function to trigger if has farmer information(tel)
   * @param {String} argsNextFunc data for using in next function
   * @returns {Function} if has just one field will first call next callback function
   *                     if ndon't have any fields will navigate to register information page
   */
  const checkFarmerInformation =
    (next) =>
    async (argsNextFunc = null) => {
      if (tel && verifyOTP) {
        return next(argsNextFunc);
      }
      const swalResp = await new Swal({
        titleText: "กรุณาลงทะเบียนข้อมูลส่วนตัวก่อน",
        text: "จำเป็นต้องใช้ข้อมูลส่วนตัวของท่านก่อนทำเริ่มทำธุรกรรมใดๆ กรุณากดปุ่ม เพิ่มข้อมูลส่วนตัว เพื่อเพิ่มข้อมูลส่วนตัวของท่าน",
        icon: "info",
        confirmButtonText: "เพิ่มข้อมูลส่วนตัว",
        width: "80%",
        showCloseButton: true,
        customClass: {
          container: classes.swal2Container,
          confirmButton: classes.swal2ConfirmButton,
        },
      });
      return (
        swalResp.isConfirmed &&
        navigate(ROUTES.USER_INFORMATION_FORM("farmer"), {
          state: { action: exists ? "update" : "create", source: "new" },
        })
      );
    };

  const onClickCreateJob = async (jobName) => {
    /* Excavator job not required fields */
    if (jobName === VEHICLES.excavator.nameEN)
      return navigate(ROUTES.JOB_FORM(jobName, "create"));

    /* New regitsted driver */
    if (!fields?.length) {
      const swalResp = await new Swal({
        titleText: "ไม่มีแปลงเกษตรสำหรับจ้างงาน",
        text: "กรุณากดปุ่ม เพิ่มแปลง เพื่อเพิ่มแปลงเกษตรของท่านก่อนเริ่มต้นจ้างงาน",
        icon: "info",
        confirmButtonText: "เพิ่มแปลง",
        width: "80%",
        showCloseButton: true,
        customClass: {
          container: classes.swal2Container,
          confirmButton: classes.swal2ConfirmButton,
        },
      });
      return (
        swalResp.isConfirmed &&
        navigate(ROUTES.FIELD_FORM("create"), {
          state: { actions: "firstCreate", serviceName: jobName },
        })
      );
    }

    return navigate(ROUTES.JOB_FORM(jobName, "create"));
  };

  const onClickMoreJobs = () => {
    navigate(ROUTES.JOBS(currentRole));
  };

  const onClickJobCard = (jobId) => {
    const selectedJob = hirings.find((job) => job.id === jobId);
    dispatch(
      jobActions.addSelected({ name: "selectedJob", data: selectedJob })
    );
    return navigate(ROUTES.JOB_DETAIL(jobId));
  };

  const onClickAddField = () => {
    navigate(ROUTES.FIELD_FORM("create"));
  };

  const onClickFieldCard = (fieldName) => {
    navigate(ROUTES.FIELD_FORM("edit", fieldName));
  };

  const serviceTypeElements = isLoadingServicesTypes
    ? [...Array(4)].map((_, idx) => (
        <Grid
          key={idx}
          container
          item
          direction="column"
          justifyContent="center"
          xs={5.5}
          mb={3}
          sx={{
            flexGrow: 1,
            "&:nth-of-type(odd)": { mr: 3 },
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={130}
            sx={{ borderRadius: (theme) => theme.spacing(1) }}
          />
        </Grid>
      ))
    : selectedShortMasterServices?.length
    ? selectedShortMasterServices.map((service) => (
        <ServiceTypeCard
          key={service.nameEN}
          imgUrl={service.image}
          nameTH={service.nameTH}
          nameEN={service.nameEN}
          clickName={checkFarmerInformation(onClickCreateJob)}
          clickImage={checkFarmerInformation(onClickCreateJob)}
        />
      ))
    : null;

  const fieldCardElements = fields?.length
    ? fields.map((field) => (
        <FieldCard
          key={field.id}
          id={field.id}
          name={field.name}
          province={field.province}
          district={field.district}
          subDistrict={field.subDistrict}
          address={field.address}
          size={field.size}
          onClickFieldCardHandler={onClickFieldCard}
        />
      ))
    : null;

  let jobsCardElements = [...Array(3)].map((_, idx) => (
    <Skeleton
      key={idx}
      animation="wave"
      variant="rectangular"
      height={115}
      sx={{ borderRadius: (theme) => theme.spacing(1.5), mb: 2 }}
    />
  ));
  if (!isLoadingJobs && !hirings?.length && priorityHirings)
    jobsCardElements = (
      <Card
        sx={{
          py: 2,
          justifyContent: "center",
          borderRadius: (theme) => theme.spacing(1.5),
          boxShadow: "none",
        }}
      >
        <Typography align="center" variant="body1" color="primary">
          <strong>กด เพิ่มเติม เพื่อดูงานอื่นๆของคุณ</strong>
        </Typography>
      </Card>
    );
  if (!isLoadingJobs && !hirings?.length && !priorityHirings)
    jobsCardElements = (
      <Card
        sx={{
          py: 2,
          justifyContent: "center",
          borderRadius: (theme) => theme.spacing(1.5),
          boxShadow: "none",
        }}
      >
        <Typography
          align="center"
          variant="body1"
          sx={{ color: (theme) => theme.palette.error.light }}
        >
          <strong>คุณยังไม่ได้จ้างงาน</strong>
        </Typography>
      </Card>
    );
  if (!isLoadingJobs && hirings?.length)
    jobsCardElements = hirings.map((job) => (
      <FarmerJobCard
        key={job.id}
        jobId={job.id}
        vinType={job.vinType}
        status={job.stage}
        serviceType={job.serviceType}
        fieldName={job.fieldName}
        fieldSize={job.fieldSize}
        serviceDate={job.serviceDate}
        serviceTime={job.serviceTime}
        totalAmount={job?.pricing?.totalAmount}
        location={{
          address: job?.address,
          province: job?.province,
          district: job?.district,
          subDistrict: job?.subDistrict,
        }}
        clickCard={onClickJobCard}
      />
    ));

  return (
    <Fragment>
      <ScrollToTop />
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>เริ่มต้นจ้างงานทันที</strong>
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sx={{ flexGrow: 1 }}
        >
          {serviceTypeElements}
        </Grid>
      </Grid>

      <Grid container pt={2}>
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>สถานะงาน</strong>
          </Typography>
          <Typography
            color="secondary.main"
            variant="subtitle1"
            sx={{ my: "auto", ml: "auto" }}
            onClick={checkFarmerInformation(onClickMoreJobs)}
          >
            เพิ่มเติม
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          {jobsCardElements}
        </Grid>
      </Grid>

      <Grid container pt={2}>
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>แปลงเกษตรของฉัน</strong>
          </Typography>
          {/* <Typography variant="subtitle1" sx={{ my: "auto", ml: "auto" }}>
            รายละเอียด
          </Typography> */}
        </Grid>

        <Grid container item xs={12} mb={2}>
          {fieldCardElements}
          <AddItemCard
            text="เพิ่มแปลง"
            clickCard={checkFarmerInformation(onClickAddField)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default FarmerMenuPage;
