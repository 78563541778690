import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  // Backdrop,
  Grid,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useHttp } from "hooks";

import { authActions } from "store/authentication/auth-slice";

import { FARMER_ENDPOINTS } from "constants/APIs";
import { ROUTES } from "routes/router";

import KINLogo from "assets/kin-match-logo-fit.png";

const { liff } = window;

const ImageLogo = styled("img")({
  width: "20rem",
});

const UserValidation = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    // isLoading: isCheckExist,
    sendRequest: fetchCheckExistsUser,
  } = useHttp();
  const {
    // isLoading: isFetchUser,
    sendRequest: fetchUserData,
  } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const initailLineLiff = async (signal) => {
      let jobId, role;
      if (searchParams.has("jobId")) jobId = searchParams.get("jobId");
      if (searchParams.has("role")) role = searchParams.get("role");

      try {
        await liff.init({
          liffId: process.env.REACT_APP_LINE_LIFF_ID,
          withLoginOnExternalBrowser: true,
        });

        if (liff.isLoggedIn()) {
          const { displayName, pictureUrl, userId } = await liff.getProfile();
          const { email } = await liff.getDecodedIDToken();
          const { friendFlag } = await liff.getFriendship();
          const accessToken = liff.getAccessToken();

          const lineProfile = {
            displayName,
            pictureUrl,
            lineId: userId,
            email,
            friendFlag,
            access_token: accessToken,
          };

          const checkExistsUser = ({ result }) => {
            if (result)
              fetchUserData(
                {
                  endpoint: FARMER_ENDPOINTS.farmer,
                  headers: { access_token: accessToken },
                  signal,
                },
                (authResp) => {
                  const userData = {
                    ...lineProfile,
                    exists: true,
                    prefixname: authResp.prefixname,
                    firstname: authResp.firstname,
                    surname: authResp.surname,
                    cid: authResp.cid,
                    tel: authResp.tel,
                    birthDate: authResp.birthDate,
                    address: authResp.address,
                    subDistrict: authResp.subDistrict,
                    district: authResp.district,
                    province: authResp.province,
                    lastLogin: authResp.lastLogin,
                    payeeMethod: authResp.payeeMethod,
                    bankAccount: authResp.bankAccount,
                    bankName: authResp.bankName,
                    promptpayType: authResp.promptpayType,
                    promptpay: authResp.promptpay,
                    getztracId: authResp.getztracId,
                    verifyOTP: authResp.verifyOTP,
                    knownChannel: authResp.knownChannel,
                    recommendedCode: authResp.recommendedCode,
                    recommendedDesc: authResp.recommendedDesc,
                    pdpa_accept: authResp.pdpa_accept,
                  };
                  dispatch(authActions.updateUser(userData));
                  dispatch(authActions.updateRoles(authResp.role));
                  if (jobId && role) {
                    dispatch(authActions.setCurrentRole(role));
                    return navigate(ROUTES.JOB_DETAIL(jobId), {
                      replace: true,
                    });
                  } else if (!jobId && role) {
                    dispatch(authActions.setCurrentRole(role));
                    return navigate(ROUTES.JOBS(role), {
                      replace: true,
                    });
                  } else
                    dispatch(authActions.setCurrentRole(authResp.lastLogin));

                  if (authResp.lastLogin === "farmer") {
                    return navigate(ROUTES.MENU_FARMER, { replace: true });
                  }
                  if (authResp.lastLogin === "driver") {
                    return navigate(ROUTES.MENU_DRIVER, { replace: true });
                  }
                }
              );
            else {
              dispatch(authActions.updateUser(lineProfile));
              dispatch(authActions.resetUser());
              return navigate(ROUTES.MENU_FARMER, { replace: true });
            }
          };

          await fetchCheckExistsUser(
            {
              endpoint: FARMER_ENDPOINTS.checkExists,
              headers: { access_token: accessToken },
              signal,
            },
            checkExistsUser
          );
        } else {
          if (jobId && role) {
            dispatch(authActions.setCurrentRole(role));
            liff.login({
              redirectUri: `${window.location.origin}/jobs/${jobId}`,
            });
          } else if (!jobId && role) {
            dispatch(authActions.setCurrentRole(role));
            liff.login({
              redirectUri: `${window.location.origin}/jobs/list/${role}`,
            });
          } else liff.login();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (liff) initailLineLiff(signal);

    return () => abortCont.abort();
  }, [searchParams, dispatch, navigate, fetchCheckExistsUser, fetchUserData]);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{ height: "90vh" }}
    >
      <Grid container item justifyContent="center" xs={12} mb={3}>
        <ImageLogo src={KINLogo} alt="KasetInno__logo" />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          color: "grey.500",
          px: (theme) => theme.spacing(12),
        }}
      >
        <LinearProgress
          sx={{
            bgcolor: "#DBF3E4",
            borderRadius: "8px",
            height: ".5rem",
            "& .MuiLinearProgress-bar": { bgcolor: "#0DB14B" },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UserValidation;
