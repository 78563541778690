import { createSlice } from "@reduxjs/toolkit";
import { serviceApi } from "services/service";

// const initialState = [];

const updateServiceReducer = (state, { payload: { serviceNameEN, data } }) => {
  const updatedServiceIndex = state.findIndex(
    (service) => service.type === serviceNameEN
  );
  state[updatedServiceIndex] = data;
};

const slice = createSlice({
  name: "service",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        serviceApi.endpoints.getDriverServices.matchFulfilled,
        (state, { payload }) => {
          for (const service of payload) {
            const isDuplicatedService = state.some(
              (stateService) => stateService.type === service.type
            );
            !isDuplicatedService && state.push(service);
          }
        }
      )
      .addMatcher(
        serviceApi.endpoints.getDriverServiceByType.matchFulfilled,
        (state, { payload: { serviceNameEN, data } }) => {
          const getServiceIndex = state.findIndex(
            (service) => service.type === serviceNameEN
          );
          if (getServiceIndex === -1 && data?.type) state.push(data);
          else state[getServiceIndex] = data;
        }
      )
      .addMatcher(
        serviceApi.endpoints.registerDriverService.matchFulfilled,
        (state, { payload }) => {
          if (!payload) return state;
          state.push(payload);
        }
      )
      .addMatcher(
        serviceApi.endpoints.updateDriverService.matchFulfilled,
        updateServiceReducer
      )
      .addMatcher(
        serviceApi.endpoints.toggleDriverServiceAvailability.matchFulfilled,
        updateServiceReducer
      );
  },
});

export default slice.reducer;

export const selectServices = ({ service }) => service;
export const selectServiceByType = ({ service }, vinTypeEN) => {
  if (!vinTypeEN || !service.length) return {};
  return service.find((service) => service.type === vinTypeEN);
};
export const selectActivatedServices = ({ service }) =>
  service.filter((service) => service.stage === "active");
export const selectActivatedServiceByType = ({ service }, vinTypeEN) => {
  if (!vinTypeEN || !service.length) return {};
  return service.find(
    (service) => service.type === vinTypeEN && service.stage === "active"
  );
};
