import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  EditOutlined as EditOutlinedIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Swal from "sweetalert2";

import { useGetMasterServiceTypesQuery } from "services/master";
import { selectShortServiceTypes } from "store/master-slice";

import {
  useGetMachineryByIdQuery,
  useDeleteMachineryMutation,
} from "services/machinery";
import {
  // selectMachineries,
  selectMachineryById,
  selectActivatedMachineriesOfType,
} from "store/machinery-slice";

import { useGetDriverServiceByTypeQuery } from "services/service";
import {
  selectServiceByType,
  selectActivatedServiceByType,
} from "store/service-slice";

import { useSwalStyles } from "hooks";
import { ROUTES } from "routes/router";

import { ActionLoading } from "base-components";

import BottomDetails from "./BottomDetails";
import MiddleDetails from "./MiddleDetails";

const Image = styled("img")(({ theme }) => ({
  objectFit: "contain",
  padding: theme.spacing(0, 2),
  width: "100%",
}));

const MachineDetailPage = () => {
  const navigate = useNavigate();
  const { type: vinType, id: vinId } = useParams();

  const selectedShortMasterServices = useSelector(selectShortServiceTypes);
  useGetMasterServiceTypesQuery(undefined, {
    skip: selectedShortMasterServices.length,
  });

  const machinery = useSelector((state) => selectMachineryById(state, vinId));
  useGetMachineryByIdQuery(vinId, {
    skip: machinery && vinId === machinery.id,
    refetchOnMountOrArgChange: true,
  });
  const activatedSelectMachineries = useSelector((state) =>
    selectActivatedMachineriesOfType(state, vinType)
  );

  const selectedService = useSelector((state) =>
    selectServiceByType(state, vinType)
  );
  useGetDriverServiceByTypeQuery(vinType, {
    skip: selectedService?.type,
  });
  const activatedSelectService = useSelector((state) =>
    selectActivatedServiceByType(state, vinType)
  );

  const [deleteMachinery, { isLoading: isDeleting }] =
    useDeleteMachineryMutation();

  const classes = useSwalStyles();

  const onClickEditMachine = () => {
    navigate(ROUTES.EDIT_MACHINE_FORM(vinType, vinId));
  };

  const onClickDeleteMachine = async () => {
    if (activatedSelectMachineries.length === 1 && activatedSelectService) {
      const serviceNameTH = selectedShortMasterServices.find(
        (masterShortService) => masterShortService.nameEN === vinType
      )?.nameTH;
      const respSwalDisableService = await new Swal({
        titleText: `ไม่สามารถลบเครื่องจักรนี้ได้`,
        text: `ต้องมีเครื่องจักรสำหรับใช้งาน${serviceNameTH}อย่างน้อย 1 คัน กรุณาปิดบริการงาน${serviceNameTH}ก่อนจึงจะสามารถลบเครื่องจักรนี้ได้`,
        icon: "warning",
        confirmButtonText: `ปิดบริการ${serviceNameTH}`,
        width: "80%",
        showCloseButton: true,
        focusConfirm: true,
        customClass: {
          container: classes.swal2Container,
          confirmButton: classes.swal2WarningButton,
        },
      });
      if (respSwalDisableService.isConfirmed)
        return navigate(ROUTES.SERVICE_FORM(vinType, "edit"));
      else return;
    }

    const respSwalDel = await new Swal({
      titleText: "ยืนยันลบเครื่องจักรหรือไม่",
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "80%",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: true,
      customClass: { container: classes.swal2Delete },
    });
    if (respSwalDel.isConfirmed) {
      await deleteMachinery(vinId).unwrap();
      navigate(-1, { replace: true });
    }
  };

  return (
    <Fragment>
      <Grid container mt={3} mb={2}>
        <Grid item xs={12} mb={3}>
          <Typography variant="body1" color="secondary">
            <strong>
              {machinery?.vinType} {machinery?.vinName}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={6} mb={2} pl={2}>
          <Typography>ประเภทเครื่องจักร</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{machinery?.vinType}</Typography>
        </Grid>
        <Grid item xs={6} mb={2} pl={2}>
          <Typography>ชื่อเครื่องจักร</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{machinery?.vinName}</Typography>
        </Grid>
        <Grid item xs={6} mb={2} pl={2}>
          <Typography>ยี่ห้อ</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{machinery?.brand}</Typography>
        </Grid>

        <MiddleDetails
          vinType={vinType}
          details={{
            model: machinery?.model,
            year: machinery?.year,
            size: machinery?.size,
          }}
        />

        <Grid container item xs={12} mb={6}>
          <Grid container item direction="column" alignItems="center" xs={6}>
            <Grid item mb={2}>
              <Typography variant="subtitle2" color="secondary">
                เครื่องจักรพร้อมผู้ใช้
              </Typography>
            </Grid>
            <Grid item>
              <Image src={machinery?.image} alt="machinery-with-driver_img" />
            </Grid>
          </Grid>
          <Grid container item direction="column" alignItems="center" xs={6}>
            <Grid item mb={2}>
              <Typography variant="subtitle2" color="secondary">
                ด้านหน้าเครื่องจักร
              </Typography>
            </Grid>
            <Grid item>
              <Image
                src={machinery?.imageFront}
                alt="machinery-front-side_img"
              />
            </Grid>
          </Grid>
        </Grid>

        <BottomDetails
          vinType={vinType}
          details={{ battery: machinery?.battery, dynamo: machinery?.dynamo }}
        />

        <Grid container item direction="column" xs={12}>
          <Grid item mx="auto" mb={3}>
            <Button
              startIcon={<EditOutlinedIcon />}
              variant="outlined"
              onClick={onClickEditMachine}
              sx={({ palette }) => ({
                bgcolor: "common.white",
                color: "common.black",
                border: `1px solid ${palette.common.black}`,
                borderRadius: 2,
                "&.MuiButton-root:hover": {
                  border: `1px solid ${palette.common.tertiary}`,
                },
              })}
            >
              แก้ไขรายละเอียด
            </Button>
          </Grid>
          <Grid item mx="auto">
            <Button
              startIcon={<DeleteIcon />}
              color="error"
              onClick={onClickDeleteMachine}
            >
              ลบเครื่องจักร
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ActionLoading open={isDeleting}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default MachineDetailPage;
