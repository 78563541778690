import { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  Button,
  Menu,
  MenuItem,
  Typography,
  Drawer,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import BurstModeIcon from "@mui/icons-material/BurstMode";

import { fileToDataUri, resizeFile } from "helpers/Utilies";

import { StreamMedia } from "components";

// const { liff } = window;

const FileInput = styled("input")({
  display: "none",
});

const ButtonImageInput = ({
  control,
  defaultValue,
  multiple,
  addImages,
  showStreamMedia,
  onClickTakePhoto,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const fileRef = useRef(null);

  const handleSetMenuAnchor = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleHideMenuAnchor = () => {
    setAnchorEl(null);
  };

  const onSelectImagesHandler = (onChange) => async (e) => {
    const { files } = e.target;
    try {
      if (multiple) {
        const resizeImages = Object.values(files).map((file) =>
          resizeFile(file)
        );
        const resizeImagesPromises = await Promise.all(resizeImages);
        const imagesPromises = resizeImagesPromises.map((file) =>
          fileToDataUri(file)
        );
        const images = await Promise.all(imagesPromises);
        addImages(images, onChange);
      } else {
        const resizeImage = await resizeFile(files[0]);
        const image = await fileToDataUri(resizeImage);
        addImages(image, onChange);
      }
      handleHideMenuAnchor();
    } catch (err) {
      console.error("[BUTTON] ERRORS: ", err);
    }
  };

  const toggleDrawerPicker = () => {
    onClickTakePhoto();
    handleHideMenuAnchor();
  };

  return (
    <Controller
      control={control}
      name={rest.name}
      defaultValue={defaultValue}
      render={({ field: { name, onChange }, fieldState: { error } }) => {
        const errorText = error?.message ? (
          <FormHelperText error={!!error} sx={{ mt: 0.5 }}>
            {error.message}
          </FormHelperText>
        ) : null;

        return (
          <Fragment>
            <label htmlFor={`${name}__file-input`}>
              <FileInput
                id={`${name}__file-input`}
                ref={fileRef}
                name={name}
                multiple={multiple}
                type="file"
                accept="image/*"
                onChange={onSelectImagesHandler(onChange)}
                disabled={rest.disabled}
              />
            </label>
            <Button
              variant="outlined"
              color={error ? "error" : "tertiary"}
              startIcon={<CameraAltIcon />}
              onClick={(e) => {
                if (navigator.userAgent.includes("Android"))
                  handleSetMenuAnchor(e);
                else fileRef.current.click();
              }}
              sx={{
                boxShadow: "1px 1px 2px #9e9e9e",
              }}
            >
              ถ่ายภาพ
            </Button>

            {errorText}

            <Menu
              id="profile-menu"
              aria-labelledby="profile-menu-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleHideMenuAnchor}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              sx={(theme) => ({
                "& .MuiPaper-root": {
                  borderRadius: theme.spacing(1),
                  width: theme.spacing(20),
                },
              })}
            >
              <MenuItem
                onClick={() => {
                  fileRef.current.click();
                }}
                divider
                dense
              >
                <Typography variant="subtitle2">คลังรูปภาพ</Typography>
                <BurstModeIcon fontSize="small" sx={{ ml: "auto" }} />
              </MenuItem>
              <MenuItem dense onClick={toggleDrawerPicker}>
                <Typography variant="subtitle2">ถ่ายภาพ</Typography>
                <CameraAltIcon fontSize="small" sx={{ ml: "auto" }} />
              </MenuItem>
            </Menu>

            <Drawer
              anchor="bottom"
              open={showStreamMedia}
              onClose={toggleDrawerPicker}
            >
              <StreamMedia
                height="500px"
                onCapture={addImages}
                onCaptureCallback={onChange}
                onCloseStream={onClickTakePhoto}
              />
            </Drawer>
          </Fragment>
        );
      }}
    />
  );
};

ButtonImageInput.propTypes = {
  multiple: PropTypes.bool,
};

ButtonImageInput.defaultProps = {
  multiple: false,
  onSelectImages: () => {},
};

export default ButtonImageInput;
