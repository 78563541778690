import PropTypes from "prop-types";
import { useTransition, animated } from "@react-spring/web";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const AnimatedBox = animated(Box);

const SlidingBox = styled(AnimatedBox)(({ sx, style }) => ({
  ...sx,
  ...style,
}));

const SlidingTransition = ({
  show,
  elementScrollRef,
  isTriggerTransition,
  children,
  ...rest
}) => {
  const transition = useTransition(show, {
    from: {
      transform: "translateY(-20%)",
      transformOrigin: "0 0",
      opacity: 0,
    },
    enter: {
      transform: "translateY(0)",
      transformOrigin: "0 0",
      opacity: 1,
    },
    leave: {
      transform: "translateY(-20%)",
      transformOrigin: "0 0",
      opacity: 0,
    },
    onChange: (_result, _spring, item) => {
      // console.group("onChange Slide");
      // console.log("_result: ", _result);
      // console.log("_spring: ", _spring);
      // console.log("item: ", item);
      // console.groupEnd();
    },
    onRest: (_result, _spring, item) => {
      if (item && elementScrollRef && isTriggerTransition)
        elementScrollRef.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
    },
  });

  return transition((style, show) =>
    show ? (
      <SlidingBox sx={rest.sx} style={style} {...rest}>
        {children}
      </SlidingBox>
    ) : null
  );
};

SlidingTransition.propTypes = {
  show: PropTypes.bool,
  isTriggerTransition: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SlidingTransition.defaultProps = {
  show: false,
  isTriggerTransition: false,
};

export default SlidingTransition;
