import { Fragment } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import th from "date-fns/locale/th";

import { TextField as MuiTextField, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== "size",
})(({ theme, size, error }) => {
  const isMedium = size === "medium";
  return {
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
      top: -6,
      "&.Mui-focused": {
        top: 0,
      },
    },
    "& .MuiInputBase-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.25 : 0.5),
        paddingBottom: theme.spacing(isMedium ? 1.25 : 0.5),
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.25 : 0.5),
        paddingBottom: theme.spacing(isMedium ? 1.25 : 0.5),
        paddingLeft: theme.spacing(isMedium ? 1.25 : 0.5),
      },
    },
    ...(!!error && {
      "& .MuiSvgIcon-root": {
        fill: theme.palette.error.main,
      },
    }),
  };
});

const TimePicker = ({
  control,
  defaultValue,
  onChangeInputTime,
  shouldDisableTime,
  ...props
}) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ control, defaultValue, name: props.name });

  const onChangeTime = (callback) => (newValue) => {
    if (onChangeInputTime) return onChangeInputTime(name, newValue, callback);
    callback(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
      <DesktopTimePicker
        toolbarTitle={props.toolbarTitle}
        disabled={props.disabled}
        value={value}
        mask="__:__"
        inputFormat="HH:mm"
        ampm={false}
        minutesStep={5}
        onChange={onChangeTime(onChange)}
        renderInput={(params) => {
          const errorText = error?.message && (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          );

          return (
            <Fragment>
              <TextField
                {...params}
                label={props.label}
                name={name}
                error={!!error}
                size={props.size}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: (theme) => theme.palette.common.black,
                  },
                }}
              />
              {errorText}
            </Fragment>
          );
        }}
      />
    </LocalizationProvider>
  );
};

TimePicker.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  onChangeInputTime: PropTypes.func,
  shouldDisableTime: PropTypes.func,
  showErrorText: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

TimePicker.defaultProps = {
  showErrorText: false,
  size: "small",
  variant: "standard",
};

export default TimePicker;
