import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFormState, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, boolean, string } from "yup";
import { Wrapper } from "@googlemaps/react-wrapper";

import {
  Grid,
  Stack,
  Typography,
  Fab,
  Button,
  IconButton,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

import { selectCurrentRole } from "store/authentication/auth-slice";
import {
  useGetMasterServiceNameQuery,
  useGetMasterAddressQuery,
} from "services/master";
import {
  selectMasterServiceByType,
  selectMasterAddress,
} from "store/master-slice";
import { useGetMachineriesOfDriverQuery } from "services/machinery";
import { selectMachineriesByType } from "store/machinery-slice";
import {
  useRegisterDriverServiceMutation,
  useUpdateDriverServiceMutation,
  useToggleDriverServiceAvailabilityMutation,
  useGetDriverServiceByTypeQuery,
} from "services/service";
import { selectServiceByType } from "store/service-slice";
import {
  toggleDrawerPicker,
  toggleAccordion,
} from "store/user-interface/ui-slice";

import { useMapLocation, useSwalStyles } from "hooks";

import {
  Options,
  ScrollToTop,
  SlidingTransition,
  HoldingDivider,
  DividerFullWidth,
  IconImage,
  ActionLoading,
} from "base-components";
import {
  TextInput,
  Radio,
  SelectPicker,
  AccordionSelector,
} from "components/hook-forms";
import { Navbar } from "layouts/components";
import { GoogleMap, Marker, Area } from "components/location";

import { blankPalette } from "styles/Theme";
import { DEFAULT_EXCAVATOR_ZOOM, LOCATION_ZOOM } from "constants/Location";
import { ROUTES } from "routes/router";
import UnorderedList from "./components/UnorderedList";
import ExcavatorIcon from "assets/excavator.png";

const validationSchema = object().shape({
  mining: boolean(),
  miningPricePerDay: number().when("mining", (mining, schema) =>
    mining
      ? schema.positive("ค่าจ้างต้องมากกว่า 0").typeError("ระบุ ค่าจ้างต่อวัน")
      : schema.transform((value) => (isNaN(value) ? undefined : value))
  ),
  miningPricePerHour: number().when("mining", (mining, schema) =>
    mining
      ? schema
          .positive("ค่าจ้างต้องมากกว่า 0")
          .typeError("ระบุ ค่าจ้างต่อชั่วโมง")
      : schema.transform((value) => (isNaN(value) ? undefined : value))
  ),
  drilling: boolean(),
  drillingPricePerDay: number().when("drilling", (drilling, schema) =>
    drilling
      ? schema.positive("ค่าจ้างต้องมากกว่า 0").typeError("ระบุ ค่าจ้างต่อวัน")
      : schema.transform((value) => (isNaN(value) ? undefined : value))
  ),
  drillingPricePerHour: number().when("drilling", (drilling, schema) =>
    drilling
      ? schema
          .positive("ค่าจ้างต้องมากกว่า 0")
          .typeError("ระบุ ค่าจ้างต่อชั่วโมง")
      : schema.transform((value) => (isNaN(value) ? undefined : value))
  ),
  other: boolean(),
  otherPricePerDay: number().when("other", (other, schema) =>
    other
      ? schema.positive("ค่าจ้างต้องมากกว่า 0").typeError("ระบุ ค่าจ้างต่อวัน")
      : schema.transform((value) => (isNaN(value) ? undefined : value))
  ),
  otherPricePerHour: number().when("other", (other, schema) =>
    other
      ? schema
          .positive("ค่าจ้างต้องมากกว่า 0")
          .typeError("ระบุ ค่าจ้างต่อชั่วโมง")
      : schema.transform((value) => (isNaN(value) ? undefined : value))
  ),
  movingCost: number()
    .positive("ระบุค่า มากกว่า 0 เท่านั้น")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("ระบุ ค่าขนย้ายเบื้องต้น"),
  coordinateSource: string().required(
    "กรุณาเลือก วิธีการระบุจุดที่ท่านสามารถรับงานได้"
  ),
  radius: number()
    .integer("ระบุเป็น จำนวนเต็ม เท่านั้น")
    .positive("ระบุค่า มากกว่า 0 เท่านั้น")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("ระบุ รัศมีการรับงาน"),
  mapProvince: string().test({
    name: "mapProvince-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapProvince",
        });
      return true;
    },
  }),
  mapDistrict: string().test({
    name: "mapDistrict-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapDistrict",
        });
      return true;
    },
  }),
  mapSubDistrict: string().test({
    name: "mapSubDistrict-required-when-coordinateSource-equal-to-map",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "map" && !value)
        return this.createError({
          message: "กรุณาเลือกตำแหน่งจากแผนที่",
          path: "mapSubDistrict",
        });
      return true;
    },
  }),
  province: string().test({
    name: "province-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก จังหวัด",
          path: "province",
        });
      return true;
    },
  }),
  district: string().test({
    name: "district-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก อำเภอ",
          path: "district",
        });
      return true;
    },
  }),
  subDistrict: string().test({
    name: "subDistrict-required-when-coordinateSource-equal-to-manual",
    test(value, { parent: { coordinateSource } }) {
      if (coordinateSource && coordinateSource === "manual" && !value)
        return this.createError({
          message: "กรุณาเลือก ตำบล",
          path: "subDistrict",
        });
      return true;
    },
  }),
});

const Form = styled("form")({
  position: "relative",
});

const ExcavatorFormPage = () => {
  const dispatch = useDispatch();
  const [triggerTransition, setTriggerTransition] = useState(false);
  const coordinateSourceRef = useRef(null);
  const navigate = useNavigate();
  const { name: serviceType, action: paramAction } = useParams();
  const editPage = paramAction === "edit";

  const {
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [
    mining,
    drilling,
    other,
    mapLat,
    mapLng,
    mapProvince,
    mapDistrict,
    mapSubDistrict,
    mapZipcode,
    province,
    district,
    subDistrict,
  ] = getValues([
    "mining",
    "drilling",
    "other",
    "mapLat",
    "mapLng",
    "mapProvince",
    "mapDistrict",
    "mapSubDistrict",
    "mapZipcode",
    "province",
    "district",
    "subDistrict",
  ]);
  const { errors } = useFormState({ control });
  const [radius, coordinateSource] = useWatch({
    control,
    name: ["radius", "coordinateSource"],
  });

  const currentRole = useSelector(selectCurrentRole);
  const { drawerPicker, accordion } = useSelector((state) => state.ui);
  /* GET Master service */
  const selectedMasterServiceByType = useSelector((state) =>
    selectMasterServiceByType(state, serviceType)
  );
  useGetMasterServiceNameQuery(serviceType, {
    skip: selectedMasterServiceByType?.id,
  });

  /* GET Master address */
  const selectedMasterAddress = useSelector(selectMasterAddress);
  useGetMasterAddressQuery(undefined, {
    skip: selectedMasterAddress.length,
  });

  /* GET Machineries */
  const selectedMachineriesByType = useSelector((state) =>
    selectMachineriesByType(state, serviceType)
  );
  useGetMachineriesOfDriverQuery(undefined, {
    skip: selectedMachineriesByType.length,
  });

  /* GET Service (If already register) */
  const selectedServiceByType = useSelector((state) =>
    selectServiceByType(state, serviceType)
  );
  useGetDriverServiceByTypeQuery(serviceType, {
    skip: selectedServiceByType?.type,
  });

  /* POST register new Service */
  const [registerNewService, { isLoading: isRegistering }] =
    useRegisterDriverServiceMutation();
  /* PUT update Service */
  const [updateService, { isLoading: isUpdating }] =
    useUpdateDriverServiceMutation();
  /* PUT disable Service */
  const [disableService, { isLoading: isDiablingService }] =
    useToggleDriverServiceAvailabilityMutation();

  const {
    currentLocation,
    expandMap,
    onToggleExpandMap,
    showArea,
    onToggleArea,
    onClickMap,
    onRelocateCurrentLocationHandler,
    onChangeSearchOtherLocation,
  } = useMapLocation({
    location: {
      lat: mapLat,
      lng: mapLng,
      zoom: LOCATION_ZOOM,
      defaultZoom: DEFAULT_EXCAVATOR_ZOOM,
    },
    setValueFn: setValue,
    clearErrorsFn: clearErrors,
    hasLocation: editPage,
  });
  const classes = useSwalStyles();

  const toggleAccordionHandler = useCallback(
    (state, isOpen = null) => {
      dispatch(
        toggleAccordion({
          name: state,
          ...(typeof isOpen === "boolean" ? { isOpen } : null),
        })
      );
    },
    [dispatch]
  );

  const toggleDrawerPickerHandler = useCallback(
    (state) => () => {
      dispatch(toggleDrawerPicker(state));
    },
    [dispatch]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change") {
        for (let service of selectedMasterServiceByType.mainService)
          if (!value[service.nameEN]) {
            setValue(`${service.nameEN}PricePerDay`, "");
            setValue(`${service.nameEN}PricePerHour`, "");
          }
        const checkHaveSomeServices =
          selectedMasterServiceByType.mainService.some(
            (service) => value[service.nameEN]
          );
        if (checkHaveSomeServices)
          for (let service of selectedMasterServiceByType.mainService)
            clearErrors(service.nameEN);

        if (name === "coordinateSource") {
          const setDefaultFields = {
            map() {
              const manualFields = [
                "province",
                "district",
                "subDistrict",
                "zipcode",
                "address",
                "landmark",
              ];
              for (let field of manualFields) {
                setValue(field, "");
                clearErrors(field);
              }
            },
            manual() {
              const mapFields = [
                "mapLat",
                "mapLng",
                "mapProvince",
                "mapDistrict",
                "mapSubDistrict",
                "mapZipcode",
              ];
              for (let field of mapFields) {
                setValue(field, "");
                clearErrors(field);
              }
            },
          };
          setDefaultFields[value.coordinateSource]();
        }
      }

      if (name === "province") {
        setValue("district", "");
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }

      if (name === "district") {
        setValue("subDistrict", "");
        setValue("zipcode", "");
      }
    });

    return () => subscription.unsubscribe();
  }, [
    watch,
    selectedMasterServiceByType,
    setValue,
    clearErrors,
    toggleAccordionHandler,
  ]);

  useEffect(() => {
    if (!selectedServiceByType?.type) return;
    const {
      coordinateSource,
      lat,
      lon: lng,
      province,
      district,
      subDistrict,
      zipcode,
      address,
      landmark,
    } = selectedServiceByType.workingArea;
    for (let service of selectedServiceByType.services) {
      setValue(service.nameEN, true);
      setValue(`${service.nameEN}PricePerDay`, service.price.perDay);
      setValue(`${service.nameEN}PricePerHour`, service.price.perHour);
      toggleAccordionHandler(service.nameEN, true);
    }
    setValue("coordinateSource", coordinateSource);
    setValue("movingCost", selectedServiceByType.movingCost);
    setValue("radius", selectedServiceByType.radius);
    if (coordinateSource === "map") {
      setValue("mapLat", lat);
      setValue("mapLng", lng);
      setValue("mapProvince", province);
      setValue("mapDistrict", district);
      setValue("mapSubDistrict", subDistrict);
      setValue("mapZipcode", zipcode);
    }
    if (coordinateSource === "manual") {
      setValue("province", province);
      setValue("district", district);
      setValue("subDistrict", subDistrict);
      setValue("zipcode", zipcode);
      setValue("address", address);
      setValue("landmark", landmark);
    }
  }, [selectedServiceByType, setValue, toggleAccordionHandler]);

  const handleChangeRadio = () => {
    setTriggerTransition(true);
  };

  const submitFormService = async (form) => {
    if (!form.mining && !form.drilling && !form.other) {
      const fieldsError = selectMasterServiceByType.mainService.map(
        (service) => service.nameEN
      );
      for (let field of fieldsError) {
        setError(field, {
          type: "requiredOne",
          message: "กรุณาเลือก บริการที่ใช้รับงานอย่างน้อย 1 บริการ",
        });
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    const services = [];
    for (const service of selectedMasterServiceByType.mainService) {
      if (form[service.nameEN] && typeof form[service.nameEN] === "boolean") {
        const selectedServiceType = {
          type: service.name,
          price: {
            perDay: form[`${service.nameEN}PricePerDay`],
            perHour: form[`${service.nameEN}PricePerHour`],
          },
        };
        services.push(selectedServiceType);
      }
    }

    const locationDetails = {
      map: {
        lat: form.mapLat,
        lon: form.mapLng,
        province: form.mapProvince,
        district: form.mapDistrict,
        subDistrict: form.mapSubDistrict,
        zipcode: form.mapZipcode,
      },
      manual: {
        province: form.province,
        district: form.district,
        subDistrict: form.subDistrict,
        zipcode: form.zipcode,
        address: form.address,
        landmark: form.landmark,
      },
    };

    const body = {
      serviceType,
      services,
      movingCost: form.movingCost,
      radius: form.radius,
      workingArea: {
        coordinateSource: form.coordinateSource,
        ...locationDetails[form.coordinateSource],
      },
    };

    if (!editPage) {
      await registerNewService(body).unwrap();
      return navigate(ROUTES.MENU_DRIVER, { replace: true });
    }
    await updateService(body).unwrap();
    return navigate(ROUTES.MENU_DRIVER, { replace: true });
  };

  const submitErrorForm = (form) => {
    if (!mining && !drilling && !other) {
      const fieldsError = selectedMasterServiceByType?.mainService.map(
        (service) => service.nameEN
      );
      for (let field of fieldsError)
        setError(field, {
          type: "requiredOne",
          message: "กรุณาเลือก บริการที่ใช้รับงานอย่างน้อย 1 บริการ",
        });
    }

    for (const service of selectedMasterServiceByType.mainService)
      if (
        form[`${service.nameEN}PricePerDay`] ||
        form[`${service.nameEN}PricePerHour`]
      )
        toggleAccordionHandler(service.nameEN, true);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDisableService = async () => {
    const swalResult = await new Swal({
      titleText: `ยืนยันยกเลิกให้บริการ${selectedMasterServiceByType.nameTH}หรือไม่`,
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "80%",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: true,
      customClass: { container: classes.swal2Delete },
    });
    if (swalResult.isConfirmed) {
      const disabledServiceResponse = await disableService({
        action: "disabled",
        serviceName: serviceType,
      }).unwrap();
      if (
        disabledServiceResponse?.error &&
        disabledServiceResponse?.jobs?.length
      ) {
        const swalErrorResult = await new Swal({
          titleText: `พบงานใหม่รอการยืนยันสถานะงาน`,
          text: "ไม่สามารถปิดบริการชั่วคราวได้ กรุณายืนยัน งานใหม่ ที่คงค้างอยู่ให้เรียบร้อยก่อนปิดบริการ",
          icon: "warning",
          cancelButtonText: "กลับหน้าเดิม",
          confirmButtonText: "จัดการงาน",
          width: "80%",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          reverseButtons: true,
          customClass: {
            container: classes.swal2Container,
            confirmButton: classes.swal2WarningButton,
            cancelButton: classes.swal2CancelButton,
          },
        });
        if (swalErrorResult.isConfirmed)
          return navigate(ROUTES.JOBS(currentRole));
      } else return navigate(-1, { replace: true });
    }
  };

  const disabledPrice = (inputName) => {
    const disableds = {
      mining: !mining,
      drilling: !drilling,
      other: !other,
    };
    return disableds[inputName];
  };

  const mapTextInputs = useMemo(
    () => [
      {
        id: "lat-dp-none-input",
        name: "mapLat",
        label: "latitude",
      },
      { id: "lng-dp-none-input", name: "mapLng", label: "longitude" },
      {
        id: "province-dp-none-input",
        name: "mapProvince",
        label: "จังหวัด",
      },
      {
        id: "district-dp-none-input",
        name: "mapDistrict",
        label: "อำเภอ",
      },
      {
        id: "sub-district-dp-none-input",
        name: "mapSubDistrict",
        label: "ตำบล",
      },
      {
        id: "zipcode-dp-none-input",
        name: "mapZipcode",
        label: "รหัสไปรษณีย์",
      },
    ],
    []
  );

  const addressDropdownInputs = useMemo(() => {
    const noValueOption = ({ reqFieldTH, reqFieldEN, field }) => [
      {
        id: `no-${reqFieldEN}-value`,
        label: (
          <Typography variant="body1">{`กรุณาเลือก ${reqFieldTH} ก่อน`}</Typography>
        ),
        value: "",
        onClickOption() {
          toggleDrawerPickerHandler(field)();
        },
      },
    ];

    return [
      {
        id: "province-select-input",
        name: "province",
        label: "จังหวัด",
        placeholder: "เลือก จังหวัด",
        width: { label: "15%", input: "85%" },
        showDrawer: drawerPicker.province,
        optionTitle: "เลือกจังหวัด",
        value: province,
        size: { xs: 12 },
        sx: { mb: 2 },
        navPosition: "sticky",
        options: selectedMasterAddress.map((province) => ({
          id: province.id,
          label: <Typography variant="body1">{province.name}</Typography>,
          value: province.name,
          onClickOption() {
            setValue("province", province.name);
            clearErrors("province");
            toggleDrawerPickerHandler("province")();
          },
        })),
      },
      {
        id: "district-select-input",
        name: "district",
        label: "อำเภอ",
        placeholder: "เลือก อำเภอ",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.district,
        optionTitle: "เลือกอำเภอ",
        value: district,
        size: { xs: 6 },
        sx: { pr: 0.5, mb: 2 },
        navPosition: "absolute",
        options: province
          ? selectedMasterAddress
              .find(({ name: provinceName }) => provinceName === province)
              ?.districts.map(({ name: districtName }) => ({
                id: districtName,
                label: <Typography variant="body1">{districtName}</Typography>,
                value: districtName,
                onClickOption() {
                  setValue("district", districtName);
                  clearErrors("district");
                  toggleDrawerPickerHandler("district")();
                },
              }))
          : noValueOption({
              reqFieldEN: "province",
              reqFieldTH: "จังหวัด",
              field: "district",
            }),
      },
      {
        id: "subDistrict-select-input",
        name: "subDistrict",
        label: "ตำบล",
        placeholder: "เลือก ตำบล",
        width: { label: "30%", input: "70%" },
        showDrawer: drawerPicker.subDistrict,
        optionTitle: "เลือกตำบล",
        value: subDistrict,
        size: { xs: 6 },
        sx: { pl: 0.5, mb: 2 },
        navPosition: "absolute",
        options:
          province && district
            ? selectedMasterAddress
                .find(({ name: provinceName }) => provinceName === province)
                ?.districts.find(
                  ({ name: districtName }) => districtName === district
                )
                ?.subDistricts.map(({ name: subDistrictName, zipcode }) => ({
                  id: subDistrictName,
                  label: (
                    <Typography variant="body1">{subDistrictName}</Typography>
                  ),
                  value: subDistrictName,
                  onClickOption() {
                    setValue("subDistrict", subDistrictName);
                    setValue("zipcode", zipcode);
                    clearErrors("subDistrict");
                    toggleDrawerPickerHandler("subDistrict")();
                  },
                }))
            : noValueOption({
                reqFieldEN: !province ? "province" : "district",
                reqFieldTH: !province ? "จังหวัด" : "อำเภอ",
                field: "subDistrict",
              }),
      },
    ];
  }, [
    selectedMasterAddress,
    drawerPicker,
    province,
    district,
    subDistrict,
    setValue,
    clearErrors,
    toggleDrawerPickerHandler,
  ]);

  const errorNotPickService =
    errors.mining && errors.drilling && errors.other ? (
      <Grid item xs={12} ml={1} mb={2}>
        <FormHelperText error>{errors.mining.message}</FormHelperText>
      </Grid>
    ) : null;

  return (
    <Fragment>
      <ScrollToTop />
      <Grid container mt={3}>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          xs={12}
          mb={2}
        >
          <IconImage src={ExcavatorIcon} alt="excavator-icon" sx={{ mb: 1 }} />
          <Typography variant="body1">ลงทะเบียนผู้ให้บริการรถขุด</Typography>
        </Grid>

        <HoldingDivider item xs={12}>
          <DividerFullWidth
            sx={{ transform: "translate(-5%, 0)", width: "110%" }}
          />
        </HoldingDivider>

        <Form onSubmit={handleSubmit(submitFormService, submitErrorForm)}>
          <Grid item xs={12} mb={1}>
            <Typography variant="body1" color="secondary">
              <strong>เครื่องจักรที่ใช้รับงาน</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ul>
              {selectedMachineriesByType.map((excavator) => (
                <li key={excavator.id}>
                  <Typography variant="subtitle2">
                    {excavator.vinName}
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>

          <HoldingDivider item xs={12}>
            <DividerFullWidth
              sx={{ transform: "translate(-5%, 0)", width: "110%" }}
            />
          </HoldingDivider>

          <Grid item xs={12} mb={2}>
            <Typography variant="body1" color="secondary">
              <strong>เลือกบริการที่ใช้รับงานและระบุค่าจ้าง</strong>
            </Typography>
          </Grid>

          {mapTextInputs.map((textInput) => (
            <TextInput
              key={textInput.id}
              control={control}
              defaultValue=""
              name={textInput.name}
              label={textInput.label}
              type="text"
              disabled={true}
              width={{ label: "30%", input: "70%" }}
              sx={{ display: "none" }}
            />
          ))}

          <Grid item xs={12} mb={!!errorNotPickService ? 0 : 2}>
            {selectedMasterServiceByType?.mainService.map((masterService) => (
              <AccordionSelector
                key={masterService.nameEN}
                expanded={accordion[masterService.nameEN]}
                onToggle={toggleAccordionHandler}
                control={control}
                defaultValue={false}
                name={masterService.nameEN}
                label={`งาน${masterService.name}`}
                sx={{ mb: 1.5 }}
              >
                <Stack>
                  {masterService.formDescriptions.map((description, idx) => (
                    <Typography key={idx} variant="subtitle2" sx={{ mb: 1.5 }}>
                      {description}
                    </Typography>
                  ))}
                  <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    <u>กำหนดราคารับจ้าง(เป็นราคาเบื้องต้นหรือขั้นต่ำ)</u>
                  </Typography>
                  <TextInput
                    control={control}
                    defaultValue=""
                    name={`${masterService.nameEN}PricePerDay`}
                    placeholder="ระบุราคา"
                    label={
                      <Typography component="span">
                        <small>ราคารายวัน</small>
                      </Typography>
                    }
                    labelTail={
                      <Typography component="span">
                        <small>บาทต่อวัน</small>
                      </Typography>
                    }
                    type="number"
                    alignValue="center"
                    disabled={disabledPrice(masterService.nameEN)}
                    width={{ label: "32%", input: "36%", labelTail: "32%" }}
                    sx={{ mb: 2 }}
                  />
                  <TextInput
                    control={control}
                    defaultValue=""
                    name={`${masterService.nameEN}PricePerHour`}
                    placeholder="ระบุราคา"
                    label={
                      <Typography component="span">
                        <small>ราคารายชั่วโมง</small>
                      </Typography>
                    }
                    labelTail={
                      <Typography component="span">
                        <small>บาทต่อชั่วโมง</small>
                      </Typography>
                    }
                    type="number"
                    alignValue="center"
                    disabled={disabledPrice(masterService.nameEN)}
                    width={{ label: "32%", input: "36%", labelTail: "32%" }}
                    sx={{ mb: 2 }}
                  />
                </Stack>
              </AccordionSelector>
            ))}
          </Grid>

          {errorNotPickService}

          <HoldingDivider item xs={12}>
            <DividerFullWidth
              sx={{ transform: "translate(-5%, 0)", width: "110%" }}
            />
          </HoldingDivider>

          <Grid item xs={12} mb={2}>
            <TextInput
              control={control}
              defaultValue=""
              name="movingCost"
              placeholder="ระบุราคา"
              label="ค่าขนย้ายรถเริ่มต้น"
              labelTail="บาท"
              type="number"
              alignValue="center"
              width={{ label: "40%", input: "45%", labelTail: "15%" }}
              sx={{ mb: 1 }}
            />
            <Typography variant="subtitle2" sx={{ fontSize: ".75rem" }}>
              ราคาจ้างที่ระบุเป็นราคาเบื้องต้น
              ท่านสามารถประเมินราคาจ้างได้อีกครั้งหลังจากเห็นรายละเอียดงานจ้าง
            </Typography>
          </Grid>

          <HoldingDivider item xs={12}>
            <DividerFullWidth
              sx={{ transform: "translate(-5%, 0)", width: "110%" }}
            />
          </HoldingDivider>

          <Grid item xs={12} mb={2}>
            <Typography variant="body1" color="secondary">
              <strong>ระบุจุดที่ท่านสามารถรับงานได้</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} mb={1}>
            <Typography
              component="label"
              variant="body1"
              htmlFor="radius__input"
            >
              ระยะทางสูงสุดที่สามารถรับงานได้
            </Typography>
          </Grid>
          <Grid item xs={12} mb={4} ref={coordinateSourceRef}>
            <TextInput
              control={control}
              defaultValue={50}
              name="radius"
              placeholder="ระบุระยะทางสูงสุด"
              labelTail="กิโลเมตร"
              type="number"
              alignValue="center"
              width={{ input: "70%", labelTail: "30%" }}
              sx={{ mb: 1 }}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <Radio
              control={control}
              defaultValue=""
              name="coordinateSource"
              label={
                <Typography variant="body1">จุดที่สามารถรับงานได้</Typography>
              }
              onChangeCallback={handleChangeRadio}
              radioDirection="column"
              radioStyles={{ justifyContent: "space-around", mt: 1 }}
              radioOptions={[
                {
                  id: "map",
                  value: "map",
                  color: "primary",
                  label: (
                    <Grid container>
                      <Grid container item xs alignItems="center">
                        <Typography variant="body1">
                          ระบุตำแหน่งด้วยแผนที่
                        </Typography>
                      </Grid>
                      {coordinateSource === "map" && (
                        <Grid container item xs="auto" ml={8}>
                          <Button
                            color="primary"
                            // variant="outlined"
                            onClick={onToggleExpandMap}
                            endIcon={
                              expandMap ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )
                            }
                            // sx={{ ml: "auto" }}
                          >
                            {expandMap ? "ย่อแผนที่" : "ขยายแผนที่"}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  ),
                  children: (
                    <SlidingTransition
                      show={coordinateSource === "map"}
                      elementScrollRef={coordinateSourceRef.current}
                      isTriggerTransition={triggerTransition}
                    >
                      <Grid item xs={12} mt={1}>
                        <Wrapper
                          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                          libraries={["places"]}
                          language="th"
                          render={(_status) => (
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              height={500}
                              width="100%"
                            />
                          )}
                        >
                          <GoogleMap
                            center={{
                              lat: currentLocation.lat,
                              lng: currentLocation.lng,
                            }}
                            selectedCenter={
                              editPage
                                ? {
                                    lat: mapLat,
                                    lng: mapLng,
                                  }
                                : null
                            }
                            selectedAddress={
                              mapProvince &&
                              mapDistrict &&
                              mapSubDistrict &&
                              `${mapSubDistrict}, ${mapDistrict}, ${mapProvince}, ${mapZipcode}`
                            }
                            errors={{
                              mapProvince: errors.mapProvince,
                              mapDistrict: errors.mapDistrict,
                              mapSubDistrict: errors.mapSubDistrict,
                            }}
                            zoom={DEFAULT_EXCAVATOR_ZOOM}
                            mapHeight={expandMap ? 700 : 400}
                            radius={radius}
                            searchOther={{
                              isSearchOther: true,
                              searchInput: {
                                label: "ค้นหาตำแหน่ง",
                                placeholder:
                                  "พิมพ์ค้นหาตำแหน่งที่ต้องการรับงาน",
                              },
                              changeOther: onChangeSearchOtherLocation,
                            }}
                            isSelectCurrent={
                              currentLocation.lat === mapLat &&
                              currentLocation.lng === mapLng
                            }
                            showArea={showArea}
                            clickMap={onClickMap}
                            clickCurrent={onRelocateCurrentLocationHandler}
                            clickToggleArea={onToggleArea}
                          >
                            <Marker
                              position={{
                                lat: mapLat || currentLocation.lat,
                                lng: mapLng || currentLocation.lng,
                              }}
                            />

                            {showArea && (
                              <Area
                                center={{
                                  lat: mapLat || currentLocation.lat,
                                  lng: mapLng || currentLocation.lng,
                                }}
                                // Radius in meter
                                radius={radius * 1000}
                                strokeColor={blankPalette.secondary.main}
                                strokeOpacity={0.75}
                                strokeWeight={2}
                                fillColor={blankPalette.secondary.main}
                                fillOpacity={0.25}
                              />
                            )}
                          </GoogleMap>
                        </Wrapper>
                      </Grid>

                      {radius ? (
                        <Grid item xs={12}>
                          <UnorderedList>
                            <li>
                              <Typography variant="subtitle2">
                                ระบบจะแสดงผลการจ้างงานภายในรัศมี {radius}{" "}
                                กิโลเมตร จากจุดที่ท่านระบุไว้
                              </Typography>
                            </li>
                            <li>
                              <Typography variant="subtitle2">
                                กรณีแสดงรัศมีอยู่
                                จะไม่สามารถกดเลือกพื้นที่ในบริเวณรัศมีได้
                                หากต้องการเลือกพื้นที่ใหม่ กรุณากด{" "}
                                <strong>ซ่อนรัศมี</strong> ซ้ำอีกครั้ง
                                เพื่อซ่อนรัศมีก่อน
                              </Typography>
                            </li>
                          </UnorderedList>
                        </Grid>
                      ) : null}
                    </SlidingTransition>
                  ),
                },
                {
                  id: "manual",
                  value: "manual",
                  label: "ระบุตำแหน่งเอง",
                  color: "primary",
                  children: (
                    <SlidingTransition
                      show={coordinateSource === "manual"}
                      elementScrollRef={coordinateSourceRef.current}
                      isTriggerTransition={triggerTransition}
                    >
                      <Grid container mt={2}>
                        {addressDropdownInputs.map((selectInput) => (
                          <Grid
                            item
                            xs={selectInput.size.xs}
                            key={selectInput.id}
                            sx={selectInput.sx}
                          >
                            <SelectPicker
                              label={selectInput.label}
                              name={selectInput.name}
                              placeholder={selectInput.placeholder}
                              control={control}
                              defaultValue=""
                              width={selectInput.width}
                              showEndIcon={false}
                              showDrawer={selectInput.showDrawer}
                              onClickInput={toggleDrawerPickerHandler(
                                selectInput.name
                              )}
                            >
                              <Navbar
                                color="secondary"
                                position={selectInput.navPosition}
                              >
                                <Navbar.Left sx={{ flexGrow: 1 }}>
                                  <Typography variant="subtitle2">
                                    {selectInput.optionTitle}
                                  </Typography>
                                </Navbar.Left>
                                <Navbar.Right sx={{ ml: "auto" }}>
                                  <IconButton
                                    onClick={toggleDrawerPickerHandler(
                                      selectInput.name
                                    )}
                                  >
                                    <CloseIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.common.white,
                                      }}
                                    />
                                  </IconButton>
                                </Navbar.Right>
                              </Navbar>
                              <Options
                                optionsData={selectInput.options}
                                selected={selectInput.value}
                              />
                            </SelectPicker>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="zipcode"
                            label="รหัสไปรษณีย์"
                            type="text"
                            width={{ label: "30%", input: "80%" }}
                            sx={{ display: "none" }}
                          />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="address"
                            label="ที่อยู่"
                            placeholder="ระบุบ้านเลขที่,ซอย, ถนน"
                            type="text"
                            multiline
                            rows={2}
                            width={{ label: "15%", input: "85%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            control={control}
                            defaultValue=""
                            name="landmark"
                            label="จุดสังเกตุอื่นๆ"
                            placeholder="(ถ้ามี) เช่น ใกล้วัดพระศรี"
                            type="text"
                            multiline
                            rows={2}
                            width={{ label: "30%", input: "80%" }}
                          />
                        </Grid>
                        {radius ? (
                          <Grid item xs={12}>
                            <UnorderedList>
                              <li>
                                <Typography variant="subtitle2">
                                  ระบบจะแสดงผลการจ้างงานภายในรัศมี {radius}{" "}
                                  กิโลเมตร จากจุดที่ท่านระบุไว้
                                </Typography>
                              </li>
                            </UnorderedList>
                          </Grid>
                        ) : null}
                      </Grid>
                    </SlidingTransition>
                  ),
                },
              ]}
            />
          </Grid>

          <Grid container item xs={12} mb={4}>
            {editPage && (
              <Button
                color="error"
                size="small"
                startIcon={<CloseIcon />}
                onClick={handleDisableService}
                sx={{ ml: "auto" }}
              >
                ปิดบริการชั่วคราว
              </Button>
            )}
            <Fab
              variant="extended"
              color="secondary"
              type="submit"
              size="small"
              sx={{ ml: editPage ? 2 : "auto", px: 3 }}
            >
              บันทึก
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <ActionLoading open={isRegistering || isUpdating || isDiablingService}>
        <Typography textAlign="center" variant="h5" color="common.white">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default ExcavatorFormPage;
